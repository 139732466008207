import React, { Component } from "react";
import { imgUrlForZhongZhi } from '@/utils/index';
import { divideMathText } from '@/utils/util';
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { renderHTML } from "@/utils/react-mathjax";
import axios from '../../../../axios';
import { getExamTestList } from '../../../../api';
import styles from './index.module.scss';

class AudioPlay extends Component {
  constructor(props) {
    super();
    this.state = {
      isPlay: false,
    };
  }

  componentDidMount() {
    this.getExamTestList();
  }

  getExamTestList = () => {
    let { resId } = this.props;
    axios.get(getExamTestList, {
      params: {
        examId: resId
      }
    }).then(res => {
      if (!res) return;
      this.setState({
        activeFlagList: res?.testList || [],
        examName: res?.examName || ''
      });
    });
  }

  optionRender = option => {
    if (option.includes('wordDivDot')) {
      option = option.replace(/<p>/gim, "");
      return option;
    }
    return option;
  }

  renderAnswersText = (testItem, testType) => {
    if (testType === 1) {
      return testItem?.courseTestChooseAnwser?.find(item => item?.rightFlag)?.optionName;
    }
    if (testType === 2) {
      return (testItem?.courseTestChooseAnwser || testItem?.courseTestChooseAnwser)?.filter(item => item?.rightFlag)?.map(item => item?.optionName)?.join(' ');
    }
    if (testType === 4) {
      return testItem?.courseTestJudgeAnwser?.rightFlag ? <CheckOutlined style={{ transform: 'translateY(-4px)' }} /> : <CloseOutlined style={{ transform: 'translateY(-4px)' }} />;
    }
    if (testType === 3 || testType === 5) {
      let str = '';
      (testItem.courseTestBlankEasyAnwser || []).map((item, index) => {
        if (index === testItem?.courseTestBlankEasyAnwser?.length - 1) {
          str += item?.answerText;
        } else {
          str += item?.answerText + '、';
        }
      });
      return renderHTML(divideMathText(str));
    }
  }

  changeIsShowAnswer = item => {
    let { activeFlagList } = this.state;
    this.setState({
      activeFlagList: activeFlagList?.map(i => {
        if (i?.id === item?.id) {
          return {
            ...i, isShowAnswer: !i?.isShowAnswer
          };
        }
        return i;
      })
    });
  }

  renderTestQuestions = (testList) => {
    let { currentTestId } = this.state;
    return testList?.map((item, index) => (<div key={index} className={`${styles.testItem} ${currentTestId === item?.id ? styles.active : ''}`} onClick={() => {
      this.setState({
        currentTestId: item?.id
      });
    }}>
      <div className={styles.stem}>
        <div className={styles.stemL}>
          {index + 1 + '.'}
        </div>
        <div className={styles.stemR}>
          {renderHTML(divideMathText(item?.stem))}
        </div>
      </div>
      {
        item?.courseTestChooseAnwser &&
        <>
          {
            (item?.courseTestChooseAnwser || []).sort(function (a, b) {
              return (a.optionName + "").localeCompare(
                b.optionName + ""
              );
            }).map((itm, index) => (
              <div className={styles.answer_vo}
                key={index}>
                <span className={`${styles.option_letter}`} >
                  {itm.optionName + '.'}
                </span>
                <div className={styles.answer_content}>
                  {renderHTML(this.optionRender(itm.optionValue))}
                </div>
              </div>
            ))}
        </>
      }
      {
        item?.testType === 4 && <>
          <div className={styles.answer_vo}
            key={index}>
            <span className={`${styles.option_letter}`} >
              A.
            </span>
            <div className={styles.answer_content}>正确</div>
          </div>
          <div className={styles.answer_vo}
            key={index}>
            <span className={`${styles.option_letter}`} >
              B.
            </span>
            <div className={styles.answer_content}>错误</div>
          </div>
        </>
      }
      <div className={styles.showAnalysisBtnContainer}>
        <div className={styles.showAnalysisBtn} onClick={() => {
          this.changeIsShowAnswer(item);
        }}>
          <img className={styles.showAnalysisBtnImg} src={imgUrlForZhongZhi("showAnalysisBtnImg.png")} alt="" />
          <div className={styles.showAnalysisBtnContent}>
            <span>答案解析</span>
            {
              item?.isShowAnswer ?
                <img src={imgUrlForZhongZhi("showAnalysisBtnContentImgUp.png")} alt="" />
                :
                <img src={imgUrlForZhongZhi("showAnalysisBtnContentImg.png")} alt="" />
            }
          </div>
        </div>
      </div>
      <div className={styles.analysisContainer} style={{ display: item?.isShowAnswer ? 'block' : 'none' }}>
        <div className={styles.analysisContainerT}>
          <span>【答案】
            {this.renderAnswersText(item, item?.testType)}
          </span>
        </div>
        <div className={styles.analysisContainerB}>
          <span className={styles.analysisLeft}>【解析】</span>
          <div className={styles.analysisRight}>
            {renderHTML(divideMathText(item?.resolution || "暂无解析"))}
          </div>
        </div>
      </div>
    </div>));
  }

  render() {
    const { activeFlagList } = this.state;

    return <div className={styles.outWrapper}>
      {
        this.renderTestQuestions(activeFlagList)
      }
    </div>;
  }
}

export default AudioPlay;
