import React, { Component } from 'react';
// import Paginations from '@@/Paginations';
import { connect } from 'react-redux';
import { updateCrumbsData } from '../../store/action';
import { Table, Space, Button, Modal, Form, Input, Radio, Row, Tooltip, message, Card, Select, DatePicker, Cascader } from 'antd'
import Crumbs from '@@/Crumbs';
import { imgUrlForZhongZhi } from '@/utils';
import { uploadRequireTypeData } from '@/utils/uploadTypeRequire';
import {
  list,
} from '@/api';
import axios from '../../axios';
import styles from './index.module.scss';
import UploadModal from './components/uploadModal/index'
class LearnCaseDemo extends Component {
  formRef = React.createRef();
  state = {
    noticeList: [],
    visible: false,
    uploadTypeInfo: ""
  }

  componentDidMount() {
    this.queryStudentNotice();
    this.props.updateCrumbsData([
      {
        name: '首页',
        path: ''
      },
      {
        name: '消息',
        path: ''
      }
    ]);
  }

  queryStudentNotice = () => {
    axios.post(list, {
      "pageNum": 1,
      "pageSize": 100,
      uid: this.props.user.uid
      // uid: 1
    }).then(res => {
      if (!res) return;
      this.setState({
        noticeList: res?.messages?.data || []
      });
    });
  }

  toDetail = item => {
    if (!item?.courseId) return;
    this.props.history.push(`/courseCenter/courseDetails?id=${item?.courseId}&courseType=${'courseType'}`);
  }
  upLoad = (type) => {

    
    this.setState({
      visible: true,
      uploadTypeInfo: uploadRequireTypeData[type]
    })
  }
  renderItem = (data) => {
    if (data?.length > 0) {
      return data?.map((item, index) => (<div className={styles.item} key={index} onClick={this.toDetail.bind(this, item)}>
        <img className={styles.left} src={imgUrlForZhongZhi("Icon_system-message.png")} alt="" />
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <span className={styles.rightTopL}>系统消息</span>
            <span className={styles.rightTopR}>{item?.publishTime}</span>
          </div>
          <div className={styles.rightBottom}>{item?.message}</div>
        </div>
      </div>));
    }
    return '暂无消息';
  }

  render() {
    let { visible, uploadTypeInfo } = this.state;
    console.log(visible)
    return (
      <div className={styles.wrapper}>
        <div className={styles.crumbsWrapper}>
          <Crumbs />
        </div>
        <div className={styles.container}>
          <Button onClick={this.upLoad.bind(this, 'picture')}>上传图片</Button>
          <Button onClick={this.upLoad.bind(this, 'video')}>上传视频</Button>
          <Button onClick={this.upLoad.bind(this, 'audio')}>上传音频</Button>
          <Button onClick={this.upLoad.bind(this, 'docs')}>上传文档</Button>
          <Button onClick={this.upLoad.bind(this, 'courseware')}>上传课件</Button>
        </div>

        {visible ? <Modal
          title={`上传${uploadTypeInfo.zh}`}
          visible maskClosable={false} closable={false} width={600} onCancel={() => {
            this.setState({
              visible: false
            })
          }}
          onOk={() => {
            console.log('下架提示')
          }}
        >
          <UploadModal ref={this.formRef} uploadTypeInfo={uploadTypeInfo} />
        </Modal> : null}


      </div>
    );
  }
}
const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
});
export default connect(({ crumbsData, user }) => ({ crumbsData, user }), mapAction)(LearnCaseDemo);
