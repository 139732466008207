import React from "react";
import {connect} from 'react-redux';
import {Carousel, Input, message, Modal} from 'antd';
import styles from './index.module.scss';
import md5 from 'md5';
import axios from '../../axios';
import {
  authorize,
  bindMobileAndLogin,
  conditionSchoolNotice,
  count,
  courseTypeList,
  getCode,
  home,
  logout,
  password,
  querySchoolByRealmName,
  queryTeacherBanner,
  teacherLogin
} from '../../api';
import {clearToken, updateToken, updateUser} from '../../store/action';
// import { getParamWithHash } from '../../utils/util';
import {dymicSettingWebsiteInfo, getIp, imgUrlForZhongZhi, imgUrlForZhongZhiApi} from '../../utils/index';
import {getParam} from '../../utils/util';
import Empty from '../../components/Empty/index';

const preview = getIp();

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      isShowLogin: false,
      loginPhone: '',
      password: '',
      isShowForgetPassWord: false,
      forgetPassWordPhone: '',
      forgetPassWordVerifyCode: '',
      forgetPassWordCount: '',
      isPhoneWrong: false,
      forgetPassWordPassword: '',
      majorCourseList: [],
      publicCourseList: [],
      bannerList: [{
        bannerPath: imgUrlForZhongZhi('BannerDefault.png')
      }],
      noticeList: [],
      currentNoticeIndex: 0,
      messageCount: 0,
      schoolName: '',
      schoolObj: {},
      isShowBind: false,
      bindPhone: '',
      bindVerifyCode: '',
      isSignOut: false
    };
  }

  componentDidMount() {
    let { code } = getParam();
    if (code) {
      this.authorize(code);
    }
    this.initData();
    let ele = document.getElementById('wrapper');
    if (ele) {
      ele.addEventListener('scroll', () => {
        this.eleScrollTop = ele.scrollTop;
        this.setState({});
      });
    }
  }

  authorize = code => {
    axios.get(authorize, {
      params: {
        code
      }
    }).then(res => {
      if (!res) return;
      let { sessionResult, token, authUser } = res;
      if (!token) {
        this.setState({
          isShowBind: true,
          openId: sessionResult?.openId
        }, () => {
          this.props.history.replace('/home');
        });
      } else {
        this.props.updateToken(token);
        this.props.updateUser({ ...authUser, token });
        this.props.history.replace('/home');
        setTimeout(() => {
          this.initData();
        }, 1000);
      }
    });
  }

  count = () => {
    if (!this.props.user.uid) return;
    axios.get(count, {
      params: {
        uid: this.props.user.uid
      }
    }).then(res => {
      if (!res) return;
      this.setState({
        messageCount: res
      });
    });
  }

  courseTypeList = () => {
    // 未检验token过期的
    axios.get(courseTypeList);
  }

  initData = () => {
    // this.getHomeList();
    // this.queryTeacherBanner();
    // this.queryStudentNotice();
    this.count();
    this.querySchoolByRealmName();
    // this.courseTypeList();
  }

  querySchoolByRealmName = () => {
    axios.get(querySchoolByRealmName, {
      params: {
        realmName: getIp()
      }
    }).then(res => {
      if (!res) return;
      let { schoolName, schoolId, logoUrl } = res;

      dymicSettingWebsiteInfo(schoolName, logoUrl)

      this.getHomeList(schoolId);
      this.queryTeacherBanner(schoolId);
      this.queryStudentNotice(schoolId);
      this.setState({
        schoolName: schoolName.toString(),
        schoolObj: res
      });
    });
  }

  getHomeList = (schoolId) => {
    axios.get(home, {
      params: {
        schoolId: schoolId || this.props.user.schoolId
      }
    }).then(res => {
      if (!res) return;
      let { majorCourseList, publicCourseList } = res;
      this.setState({
        majorCourseList,
        publicCourseList
      });
    });
  }

  queryTeacherBanner = (schoolId) => {
    axios.post(queryTeacherBanner, {
      "pageNum": 1,
      "pageSize": 1000,
      schoolId: schoolId || this.props.user.schoolId,
    }).then(res => {
      if (!res) return;
      if (res?.data?.length > 0) {
        this.setState({
          bannerList: res?.data
        });
      }
    });
  }

  queryStudentNotice = (schoolId) => {
    clearInterval(this.timer);
    this.timer = null;
    axios.post(conditionSchoolNotice, {
      "pageNum": 1,
      "pageSize": 100,
      schoolId: schoolId || this.props.user.schoolId,
      hasRelease: 1,
      // uid: this.props.user.uid
      // uid: 1
    }).then(res => {
      if (!res) return;
      this.setState({
        noticeList: res?.data || [],
        currentNoticeIndex: 0
      }, () => {
        this.timer = setInterval(() => {
          let { noticeList, currentNoticeIndex } = this.state;
          let length = noticeList?.length;
          if (currentNoticeIndex < length - 1) {
            this.setState({
              currentNoticeIndex: currentNoticeIndex + 1
            });
          } else {
            this.setState({
              currentNoticeIndex: 0
            });
          }
        }, 2000);
      });
    });
  }

  // queryStudentNotice = () => {
  //   clearInterval(this.timer);
  //   this.timer = null;
  //   axios.post(list, {
  //     "pageNum": 1,
  //     "pageSize": 100,
  //     uid: this.props.user.uid
  //     // uid: 1
  //   }).then(res => {
  //     if (!res) return;
  //     this.setState({
  //       noticeList: res?.messages?.data || [],
  //       currentNoticeIndex: 0
  //     }, () => {
  //       this.timer = setInterval(() => {
  //         let { noticeList, currentNoticeIndex } = this.state;
  //         let length = noticeList?.length;
  //         if (currentNoticeIndex < length - 1) {
  //           this.setState({
  //             currentNoticeIndex: currentNoticeIndex + 1
  //           });
  //         } else {
  //           this.setState({
  //             currentNoticeIndex: 0
  //           });
  //         }
  //       }, 2000);
  //     });
  //   });
  // }

  componentDidUpdate() {

  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  checkIsLogin = (callBack, ...arg) => {
    let token = this.props.user?.token;
    if (token) {
      typeof (callBack) === 'function' && callBack(...arg);
    } else {
      this.setState({
        isShowLogin: true
      });
    }
  }

  toTop = () => {
    let ele = document.getElementById('wrapper');
    if (ele) {
      this.timer = setInterval(() => {
        ele.scrollTop -= 50;
        if (ele.scrollTop <= 0) {
          clearInterval(this.timer);
          ele.scrollTop = 0;
        }
      }, 10);
    }
  }

  toDetailPage = (id, courseType, item) => {
    this.props.history.push(`/courseCenter/courseDetails?id=${id}&courseType=${courseType}&isJiaoXueKongJianPage1=${item?.courseName}`);
    this.props.updateUser({ ...this.props.user, majorId: item?.majorId, subjectId: item?.subjectId });
  }

  toNoticeDetailPage = (currentNotice) => {
    // this.props.history.push(`/systemMessage`);
    this.props.history.push(`/zzgg/notice_dateil?flag=0&id=${currentNotice?.id}`);
  }

  renderItem = (data, courseType) => {
    return data?.map((item, index) => (<div key={index} className={`${styles.item} ${false ? styles.active : ''}`} onClick={this.checkIsLogin.bind(this, this.toDetailPage, item?.id, courseType, item)}>
      <img className={styles.itemImg} src={imgUrlForZhongZhiApi(item?.coverFileId)} alt="" />
      <p className={styles.itemTitle}>{item?.courseName}</p>
      <div className={styles.itemNameContainer}>
        <img className={styles.itemNameImg} src={imgUrlForZhongZhi("itemNameImg.png")} alt="" />
        {/* <span>{item?.bookAuthor}</span> */}
        <span>{item?.teaName}</span>
      </div>
      {/* <p className={styles.itemPressTitle}>高等教育出版社/朱熹/2021</p> */}
      <p className={styles.itemPressTitle}>{item?.press && item?.press + '/'}{item?.bookAuthor && item?.bookAuthor + '/'}{item?.publishDate && item?.publishDate.substring(0, item?.publishDate.indexOf('-'))}</p>
    </div>));
  }

  cancle = () => {
    this.setState({
      isShowLogin: false,
      isShowForgetPassWord: false,
      isShowBind: false,
    });
  }

  prevent = e => {
    e && e.stopPropagation();
  }

  changeLoginPhone = (e) => {
    this.setState({
      loginPhone: e.target.value
    });
  }

  changePassword = (e) => {
    this.setState({
      password: e.target.value
    });
  }

  changeForgetPassWordPhone = (e) => {
    this.setState({
      forgetPassWordPhone: e.target.value
    });
  }

  changeBindPhone = (e) => {
    this.setState({
      bindPhone: e.target.value
    });
  }

  changeForgetPassWordPassword = (e) => {
    this.setState({
      forgetPassWordPassword: e.target.value
    });
  }

  getBindCode = () => {
    let { bindPhone } = this.state;
    // 获取验证码
    axios.post(getCode, {
      mobile: bindPhone,
      "type": 1
    }).then(() => {
      message.success('发送成功');
      this.setState({
        forgetPassWordCount: 60
      }, () => {
        this.timer = setInterval(() => {
          let { forgetPassWordCount } = this.state;
          if (!forgetPassWordCount) {
            clearInterval(this.timer);
            return;
          }
          this.setState({
            forgetPassWordCount: forgetPassWordCount - 1
          });
        }, 1000);
      });
    });
  }
  getCode = () => {
    let { forgetPassWordPhone } = this.state;
    if (!forgetPassWordPhone) {
      message.destroy();
      message.warning('请输入手机号');
      return;
    }
    let reg = /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
    if (reg.test(forgetPassWordPhone + '')) {
      this.setState({
        isPhoneWrong: false
      });
      // 获取验证码
      axios.post(getCode, {
        mobile: forgetPassWordPhone,
        "type": 3
      }).then(() => {
        message.success('发送成功');
        this.setState({
          forgetPassWordCount: 60
        }, () => {
          this.timer = setInterval(() => {
            let { forgetPassWordCount } = this.state;
            if (!forgetPassWordCount) {
              clearInterval(this.timer);
              return;
            }
            this.setState({
              forgetPassWordCount: forgetPassWordCount - 1
            });
          }, 1000);
        });
      });
    } else {
      this.setState({
        isPhoneWrong: true
      });
    }
  }

  toHome = () => {

  }
  toZzgg = () => {
    this.props.history.push('/zzgg');
  }

  toCourseCenter = (type) => {
    let url = '';
    if (type == 2) {
      url = `?type=${type}`
    }
    localStorage.setItem('type', type);
    this.props.history.push(`/courseCenter${url}`);
  }

  toMycourse = () => {
    this.props.history.push('/teacher');
  }

  changePasswordOk = () => {
    // 密码登录
    let { loginPhone, password, schoolObj } = this.state;
    axios.post(teacherLogin, {
      "username": loginPhone,
      "password": md5(password),
      "schoolId": schoolObj?.schoolId || 2,
      "hasCheck": false,   // false不进行学校校验，本地登录用的
      // "hasCheck": window.location.origin?.includes('localhost') ? false : true,   // false不进行学校校验，本地登录用的
      // "platform":0
    }).then(res => {
      let { token, authUser } = res;
      this.props.updateToken(token);
      this.props.updateUser({ ...authUser, token, mobile: loginPhone });
      this.setState({
        isShowLogin: false
      });
      setTimeout(() => {
        this.initData();
      }, 1000);
    });
  }

  changeForgetPassWordOk = () => {
    let { forgetPassWordPhone, forgetPassWordVerifyCode, forgetPassWordPassword } = this.state;
    axios.post(password, {
      "mobile": forgetPassWordPhone,
      "oneCode": forgetPassWordVerifyCode,
      "password": md5(forgetPassWordPassword)
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('密码重置成功');
      this.setState({
        isShowForgetPassWord: false
      });
    });
  }

  bindPhoneOk = () => {
    let { bindPhone, bindVerifyCode, openId } = this.state;
    axios.post(bindMobileAndLogin, {
      "openId": openId,
      "telNum": bindPhone,
      "verifyCode": bindVerifyCode,
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('绑定成功');
      let { token, authUser } = res;
      this.props.updateToken(token);
      this.props.updateUser({ ...authUser, token });
      setTimeout(() => {
        this.initData();
      }, 1000);
      this.setState({
        isShowBind: false
      });
    });
  }

  logout = () => {
    const { isSignOut } = this.state;
    if (!isSignOut) {
      this.setState({ isSignOut: !isSignOut })
      return;
    }
    axios.post(logout).then(res => {
      if (!res) return;
      message.destroy();
      message.success('退出成功');
      this.props.clearToken();
      this.setState({ isSignOut: false })
    });;
  }

  transFormjumpUrl = (item) => {
    console.log(item)
    let { jumpType, jumpFlag, courseType } = item;
    // 0课程,1公告,2外链
    // return `${getIp()}/#/notice_dateil?flag=1&id=32`
    let url = ""
    if (jumpType === 0) {
      url = `${getIp()}/#/courseCenter/courseDetails?id=${jumpFlag}&courseType=${courseType}`;
    }
    if (jumpType === 1) {
      url = `${getIp()}/#/zzgg/notice_dateil?flag=0&id=${jumpFlag}`;
    }
    if (jumpType === 2) {
      url = jumpFlag;
    }
    console.log(url)
    window.open(url, "_blank");
  }

  loginInOtherWays = () => {
    // let origin = 'https://test-api-zz.yunquancn.com';
    let origin = 'https://www.dianjitongedu.net/#/loginWX?domain=' + preview;
    // let origin = window.location.origin + '/#/home';
    origin = encodeURIComponent(origin);
    // console.log(encodeURI(encodeURI(origin)));
    window.location.replace(`https://open.weixin.qq.com/connect/qrconnect?appid=wx9a807831ac065fa2&redirect_uri=${origin}&response_type=code&scope=snsapi_login&state=1`);
  }

  render() {
    let { isSignOut, isShowLogin, loginPhone, password, isShowForgetPassWord, forgetPassWordPhone, forgetPassWordVerifyCode, forgetPassWordCount, isPhoneWrong, forgetPassWordPassword, majorCourseList, publicCourseList, bannerList, noticeList, currentNoticeIndex, messageCount, schoolName, schoolObj, isShowBind, bindPhone, bindVerifyCode } = this.state;
    let { user } = this.props;
    let { fullName, avatarUrl, token } = user;
    let currentNotice = noticeList[currentNoticeIndex] || {};
    return (
      <div className={styles.wrapper} id='wrapper'>
        <div className={styles.nav}>
          {/* <img className={styles.homeLogo} src={imgUrlForZhongZhi("homeLogo.png")} alt="" /> */}
          <div className={styles.homeLogoContainer}>
            <img src={schoolObj?.logoUrl} alt="" />
            <div className={styles.homeLogoRight}>
              <p className={styles.homeLogoRightT}>{schoolObj?.schoolName}</p>
              <p className={styles.homeLogoRightB}>{schoolObj?.schoolMotto}</p>
              {/* <p className={styles.homeLogoRightT}>{'schoolObj?.schoolName'}</p>
              <p className={styles.homeLogoRightB}>{'schoolObj?.email'}</p> */}
            </div>
          </div>
          <div className={`${styles.navItem} ${styles.active}`} onClick={this.checkIsLogin.bind(this, this.toHome)}>
            首页
            <div></div>
          </div>
          <div className={styles.navItem} onClick={this.checkIsLogin.bind(this, this.toCourseCenter.bind(this, 1))}>课程中心</div>
          <div className={styles.navItem} onClick={this.checkIsLogin.bind(this, this.toZzgg)}>公告通知</div>
          <div className={styles.navItem} onClick={this.checkIsLogin.bind(this, this.toMycourse)}>教师空间</div>

          {
            token ? <div className={styles.sheaderUser}>
              <div className={styles.sheaderUserImgContainer}>
                <img className={styles.sheaderUserImg} src={avatarUrl ? avatarUrl : imgUrlForZhongZhi('sheader-user-img-default.png')} alt="" />
                <div className={styles.sheaderUserexit} onClick={this.logout}>退出</div>
              </div>
              <span className={styles.sheaderUserName}>{fullName}</span>
              <div className={styles.sheaderUserMessage} onClick={() => {
                this.props.history.push('/systemMessage');
              }}>
                <img className={styles.sheaderUserMessage} src={imgUrlForZhongZhi('sheader-user-message-home.png')} alt="" />
                {
                  messageCount > 0 && <div className={styles.sheaderUserMessageTip} />
                  // <div className={styles.sheaderUserMessageTip} />
                }
              </div>
            </div>
              :
              <div className={styles.signBtn} onClick={() => {
                this.setState({
                  isShowLogin: true
                });
              }}>登录</div>
          }
        </div>
        <div className={styles.header}>

          <Carousel autoplay>

            {
              bannerList?.map((item, index) => (<div onClick={this.checkIsLogin.bind(this, this.transFormjumpUrl.bind(this, item))} target="_blank"><img key={index} className={styles.img} src={item?.bannerPath} alt="" /></div>))
              // bannerList?.map((item, index) => (<a href={item?.jumpFlag}><img key={index} className={styles.img} src={item?.bannerPath} alt="" /></a>))
            }
            {/* <a href={'https://fanyi.baidu.com/'}><img className={styles.img} src={imgUrlForZhongZhi("Banner@2x.png")} alt="" /></a> */}
          </Carousel>
        </div>
        {
          Object.keys(currentNotice)?.length > 0 && <div className={styles.messageContainer}>
            <div className={styles.messageContainer_con}>
              <img className={styles.messageImg} src={imgUrlForZhongZhi("messageLogo.png")} alt="" />
              {/* <span>{currentNotice?.message}</span> */}
              <span>{currentNotice?.title}</span>
              <div className={styles.messageBtn} onClick={this.checkIsLogin.bind(this, this.toNoticeDetailPage, currentNotice)}>查看详细</div>
              {/* <span> 人民教育出版社人教数字教育研究院2020年度课题申报通知!</span>
            <div className={styles.messageBtn}>查看详细</div> */}
            </div>
          </div>
        }
        <div className={styles.center}>

          <div className={styles.centerTop}>

            <div className={styles.titleContainer}>
              公共课
              <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt="" />
              {
                publicCourseList?.length > 0 && <div className={styles.titleMore} onClick={this.checkIsLogin.bind(this, this.toCourseCenter.bind(this, 1))}>
                  查看更多
                </div>
              }
            </div>
            <div className={`${styles.content} ${styles.content1}`}>
              {
                publicCourseList?.length > 0
                  ?
                  this.renderItem(publicCourseList || [], 1)
                  :
                  <Empty />
              }
              {/* <div className={styles.item}>
              <img className={styles.itemImg} src={imgUrlForZhongZhi("Banner@2x.png")} alt="" />
              <p className={styles.itemTitle}>语文基础模块一</p>
              <div className={styles.itemNameContainer}>
                <img className={styles.itemNameImg} src={imgUrlForZhongZhi("Banner@2x.png")} alt="" />
                <span>张老师</span>
              </div>
              <p className={styles.itemPressTitle}>高等教育出版社/朱熹/2021</p>
            </div> */}
            </div>
          </div>

          <div className={styles.centerbottom}>
            <img className={styles.homeCenterBg} src={imgUrlForZhongZhi("homeCenterBg.png")} alt="" />
            <div className={styles.centerbottomContainer}>
              <div className={styles.titleContainer}>
                专业课
                <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt="" />
                {
                  majorCourseList?.length > 0 && <div className={styles.titleMore} onClick={this.checkIsLogin.bind(this, this.toCourseCenter.bind(this, 2))}>
                    查看更多
                  </div>
                }
              </div>
              <div className={styles.content}>
                {/* {
                  this.renderItem(majorCourseList || [], 2)
                } */}
                {
                  majorCourseList?.length > 0
                    ?
                    this.renderItem(majorCourseList || [], 1)
                    :
                    <Empty />
                }
              </div>
            </div>
          </div >
          {
            this.eleScrollTop > 0 && <img className={styles.toTop} src={imgUrlForZhongZhi("toTop.png")} alt="" onClick={this.toTop} />
          }
        </div>
        <div className={styles.bottom}>
          {/* <div className={styles.bottomContainer}>
            <span>关于我们</span>
            <span className={styles.bottomline}>/</span>
            <span>帮助中心</span>
            <span className={styles.bottomline}>/</span>
            <span>意见反馈</span>
            <span className={styles.bottomline}>/</span>
            <span>用户协议</span>
            <p>Copyright &copy;2021 济南智学酷教育科技有限公司 All Rights Reserved. 鲁ICP备18048664号-1</p>
          </div> */}
          <div className={styles.bottomContainerTwo}>
            {/* <p className={styles.schoolName}>河南工业学校</p> */}
            <p className={styles.schoolName}>{schoolObj?.schoolName}</p>
            <div className={styles.addreddPhoneEmail}>
              <span>地址：{schoolObj?.address}</span>
              <span>电话：{schoolObj?.mobile}</span>
              <span>E-mail：{schoolObj?.email}</span>
            </div>
            <div className={styles.agreement}>
              <span onClick={() => {
                window.open('/#/UserAgreement');
              }}>用户协议</span>
              <span>Copyright &copy;2021  鲁ICP备18048664号-1  </span>
            </div>
          </div>
        </div>
        {
          // isShowLogin && <div className={styles.modalWrapper} onClick={this.cancle}>
          isShowLogin && <div className={styles.modalWrapper}>
            <div className={styles.changePasswordContainer} onClick={this.prevent}>
              <p className={styles.changePasswordTitle}>教师账号登录</p>
              <p className={styles.schoolContainer}>
                <img src={imgUrlForZhongZhi('schoolIcon.png')} alt="" />
                {/* <span>河北工业学校</span> */}
                <span>{schoolName || '暂无学校, 请联系客服'}</span>
              </p>
              <img className={styles.modalClose} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={this.cancle} />
              <Input prefix={<img className={styles.loginPhoneIcon} src={imgUrlForZhongZhi('loginPhoneIcon.png')} alt="" />} placeholder="工号/手机号" value={loginPhone} onChange={this.changeLoginPhone} />
              <Input.Password prefix={<img className={styles.loginPhoneIcon} src={imgUrlForZhongZhi('passWordIcon.png')} alt="" />} placeholder="密码" value={password} onChange={this.changePassword} />
              <p className={styles.forgetPassWord}><span onClick={() => {
                this.setState({
                  isShowForgetPassWord: true,
                  isShowLogin: false,
                });
              }}>忘记密码</span></p>
              <div className={styles.modalBtn} onClick={this.changePasswordOk}>确认</div>
              <div className={styles.loginInOtherWays}>
                <p></p>
                <span>其它方式登录</span>
                <p></p>
              </div>
              <div className={styles.loginInOtherWaysImg} onClick={this.loginInOtherWays}>
                <img src={imgUrlForZhongZhi('loginInOtherWaysImg.png')} alt="" />
              </div>
            </div>
          </div>
        }
        {
          // isShowForgetPassWord && <div className={styles.modalWrapper} onClick={this.cancle}>
          isShowForgetPassWord && <div className={styles.modalWrapper}>
            <div className={styles.changePasswordContainer} onClick={this.prevent}>
              <p className={styles.changePasswordTitle}>重置密码</p>
              <img className={styles.modalClose} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={this.cancle} />

              <Input placeholder="手机号" value={forgetPassWordPhone} onChange={this.changeForgetPassWordPhone} />
              {/* 防止自动填充 开始 */}
              <input type="password" autoComplete="new-password" style={{ display: 'none' }} />
              {/* 防止自动填充 结束 */}
              <div className={styles.codeContainer}>
                <Input value={forgetPassWordVerifyCode} style={{ width: '2.25rem' }} placeholder="验证码" onChange={(e) => {
                  this.setState({
                    forgetPassWordVerifyCode: e.target.value
                  });
                }} />
                {
                  forgetPassWordCount ? <div className={`${styles.getCodeBtn} ${styles.disabled}`}>
                    <span>{forgetPassWordCount}s重试</span>
                  </div> :
                    <div className={styles.getCodeBtn} onClick={this.getCode}><span>发送验证码</span></div>
                }
              </div>

              <Input.Password style={{ marginBottom: '0.42rem' }} placeholder="设置6位以上数字、字母组合的密码" value={forgetPassWordPassword} onChange={this.changeForgetPassWordPassword} />

              {/* {
                isPhoneWrong && <p className={styles.passwordWrongMessage}>手机号码输入不正确，请重新输入</p>
              } */}
              <div className={styles.modalBtn} onClick={this.changeForgetPassWordOk}>确认</div>
              <div className={styles.loginInOtherWays}>
                <p></p>
                <span>其它方式登录</span>
                <p></p>
              </div>
              <div className={styles.loginInOtherWaysImg} onClick={this.loginInOtherWays}>
                <img src={imgUrlForZhongZhi('loginInOtherWaysImg.png')} alt="" />
              </div>
            </div>
          </div>
        }
        {
          // 绑定手机号
          isShowBind && <div className={styles.modalWrapper}>
            <div className={styles.changePasswordContainer} onClick={this.prevent}>
              <p className={styles.changePasswordTitle}>绑定手机号</p>
              <img className={styles.modalClose} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={this.cancle} />
              <Input placeholder="手机号" value={bindPhone} onChange={this.changeBindPhone} />
              <div className={styles.codeContainer}>
                <Input value={bindVerifyCode} style={{ width: '2.25rem' }} placeholder="验证码" onChange={(e) => {
                  this.setState({
                    bindVerifyCode: e.target.value
                  });
                }} />
                {
                  forgetPassWordCount ? <div className={`${styles.getCodeBtn} ${styles.disabled}`}>
                    <span>{forgetPassWordCount}s重试</span>
                  </div> :
                    <div className={styles.getCodeBtn} onClick={this.getBindCode}><span>发送验证码</span></div>
                }
              </div>
              <p className={styles.passwordWrongMessage} style={{ marginBottom: '0.19rem', height: '0.24rem' }}>{!bindPhone ? '请输入手机号' : ' '}</p>
              <div className={styles.modalBtn} style={{ marginBottom: '0.45rem' }} onClick={this.bindPhoneOk}>确认</div>
              <p className={styles.modalBtnAgreement}>登录即代表同意<span>《多技通用户隐私协议》</span></p>
            </div>
          </div>
        }
        {
          isSignOut && <Modal
            className="class_modeld"
            style={{ width: '234px' }}
            visible={isSignOut}
            onCancel={this.handleDissolveChange}
            footer={null}
            destroyOnClose
          >
            <div className="class_modeld-con" >
              <div className='class_modeld_center' style={{ color: '#686F7E' }}>
                确定要退出登录吗？
              </div>
              <div className='class_modeld_button'>
                <button className='class_modeld_buttonno' onClick={() => {
                  this.setState({ isSignOut: false })
                }}>取消</button>
                <button onClick={() => { this.logout() }} className='class_modeld_buttonyes'>确定</button>
              </div>

            </div>
          </Modal>
        }
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateToken(token) {
    dispatch(updateToken(token));
  },
  updateUser(user) {
    dispatch(updateUser(user));
  },
  clearToken() {
    dispatch(clearToken());
  },
});

export default connect(({ user }) => ({ user }), mapAction)(Home);
