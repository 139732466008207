import React from "react";
import { connect } from 'react-redux';
import { Carousel, Input, message } from 'antd';
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import styles from './index.module.scss';
import axios from '../../axios';
import { getExamTestList } from '../../api';
import { updateToken, updateUser, clearToken } from '../../store/action';
import { imgUrlForZhongZhi, imgUrlForZhongZhiApi, getIp } from '../../utils/index';
import { divideMathText, getParam, pausedVideoAndAudio } from '../../utils/util';
import { renderHTML } from "../../utils/react-mathjax";
import Empty from '../../components/Empty';

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      currentTestId: '',
      activeFlagList: [],
      examName: '',
      tempAry: []
    };
  }

  computedNumber = () => {
    // 计算答题题号
    let { activeFlagList } = this.state;
    let singleChoiceQuestions = activeFlagList?.find(item => item?.testType === 1);
    let multipleChoiceQuestions = activeFlagList?.find(item => item?.testType === 2);
    let judgeChoiceQuestions = activeFlagList?.find(item => item?.testType === 4);
    let FillInTheBlanksQuestions = activeFlagList?.find(item => item?.testType === 3);
    let answerQuestions = activeFlagList?.find(item => item?.testType === 5);
    let tempAry = [];
    if (singleChoiceQuestions) {
      tempAry.push(singleChoiceQuestions);
    }
    if (multipleChoiceQuestions) {
      tempAry.push(multipleChoiceQuestions);
    }
    if (judgeChoiceQuestions) {
      tempAry.push(judgeChoiceQuestions);
    }
    if (FillInTheBlanksQuestions) {
      tempAry.push(FillInTheBlanksQuestions);
    }
    if (answerQuestions) {
      tempAry.push(answerQuestions);
    }
    tempAry = tempAry?.map((item, index) => {
      return { ...item, indexNumber: index + 1 };
    });
    this.setState({
      tempAry
    });
  }

  getExamTestList = (examId) => {
    if (!examId) return;
    axios.get(getExamTestList, {
      params: {
        examId
      }
    }).then(res => {
      if (!res) return;
      this.setState({
        activeFlagList: res?.testList || [],
        // examName: res?.examName || ''
      }, () => {
        this.computedNumber();
      });
    });
  }

  componentDidMount() {
    let { examName } = this.props;
    if (!examName) {
      let { examId, examName, isTaskPoint } = getParam();
      this.setState({
        examName: examName || '',
        isTaskPoint: isTaskPoint || ''
      });
      this.getExamTestList(examId);
    } else {
      let { activeFlagList, examName, isTaskPoint } = this.props;
      this.setState({
        activeFlagList: activeFlagList || [],
        examName: examName || '',
        isTaskPoint: isTaskPoint || ''
      }, () => {
        this.computedNumber();
      });
    }
    // let { activeFlagList, examName,isTaskPoint } = this.props;
    // if (examId) {
    //   this.getExamTestList();
    // } else {
    //   this.setState({
    //     activeFlagList: activeFlagList || [],
    //     examName: examName || '',
    //     isTaskPoint:isTaskPoint || ''
    //   }, () => {
    //     this.computedNumber();
    //   });
    // }
    setTimeout(() => {
      pausedVideoAndAudio();
    }, 1000);
  }

  optionRender = option => {
    if (option.includes('wordDivDot')) {
      option = option.replace(/<p>/gim, "");
      return option;
    }
    return option;
  }

  changeIsShowAnswer = item => {
    let { activeFlagList } = this.state;
    this.setState({
      activeFlagList: activeFlagList?.map(i => {
        if (i?.id === item?.id) {
          return {
            ...i, isShowAnswer: !i?.isShowAnswer
          };
        }
        return i;
      })
    });
  }

  renderAnswersText = (testItem, testType) => {
    if (testType === 1) {
      return testItem?.courseTestChooseAnwser?.find(item => item?.rightFlag)?.optionName;
    }
    if (testType === 2) {
      return (testItem?.courseTestChooseAnwser || testItem?.courseTestChooseAnwser)?.filter(item => item?.rightFlag)?.map(item => item?.optionName)?.join(' ');
    }
    if (testType === 4) {
      return testItem?.courseTestJudgeAnwser?.rightFlag ? <CheckOutlined style={{ transform: 'translateY(-4px)' }} /> : <CloseOutlined style={{ transform: 'translateY(-4px)' }} />;
    }
    if (testType === 3 || testType === 5) {
      let str = '';
      (testItem.courseTestBlankEasyAnwser || []).map((item, index) => {
        if (index === testItem?.courseTestBlankEasyAnwser?.length - 1) {
          str += item?.answerText;
        } else {
          str += item?.answerText + '、';
        }
      });
      return renderHTML(divideMathText(str));
    }
  }

  renderTestQuestions = (testList, testType) => {
    let { currentTestId } = this.state;
    // if (testType === 1 || testType === 3 || testType === 4) {
    return testList?.map((item, index) => (<div key={index} className={`${styles.testItem} ${currentTestId === item?.id ? styles.active : ''}`} onClick={() => {
      this.setState({
        currentTestId: item?.id
      });
    }}>
      <div className={styles.stem}>
        <div className={styles.stemL}>
          {index + 1 + '.'}
        </div>
        <div className={styles.stemR}>
          {renderHTML(divideMathText(item?.stem))}
        </div>
      </div>
      {
        item?.courseTestChooseAnwser &&
        <>
          {
            (item?.courseTestChooseAnwser || []).sort(function (a, b) {
              return (a.optionName + "").localeCompare(
                b.optionName + ""
              );
            }).map((itm, index) => (
              <div className={styles.answer_vo}
                key={index}>
                <span className={`${styles.option_letter}`} >
                  {itm.optionName + '.'}
                </span>
                <div className={styles.answer_content}>
                  {renderHTML(this.optionRender(itm.optionValue))}
                </div>
              </div>
            ))}
        </>
      }
      {
        testType === 4 && <>
          <div className={styles.answer_vo}
            key={index}>
            <span className={`${styles.option_letter}`} >
              A.
            </span>
            <div className={styles.answer_content}>正确</div>
          </div>
          <div className={styles.answer_vo}
            key={index}>
            <span className={`${styles.option_letter}`} >
              B.
            </span>
            <div className={styles.answer_content}>错误</div>
          </div>
        </>
      }
      <div className={styles.showAnalysisBtnContainer}>
        <div className={styles.showAnalysisBtn} onClick={() => {
          this.changeIsShowAnswer(item);
        }}>
          <img className={styles.showAnalysisBtnImg} src={imgUrlForZhongZhi("showAnalysisBtnImg.png")} alt="" />
          <div className={styles.showAnalysisBtnContent}>
            <span>答案解析</span>
            {
              item?.isShowAnswer ?
                <img src={imgUrlForZhongZhi("showAnalysisBtnContentImgUp.png")} alt="" />
                :
                <img src={imgUrlForZhongZhi("showAnalysisBtnContentImg.png")} alt="" />
            }
          </div>
        </div>
      </div>
      <div className={styles.analysisContainer} style={{ display: item?.isShowAnswer ? 'block' : 'none' }}>
        <div className={styles.analysisContainerT}>
          <span>【答案】
            {this.renderAnswersText(item, testType)}
          </span>
        </div>
        <div className={styles.analysisContainerB}>
          <span className={styles.analysisLeft}>【解析】</span>
          <div className={styles.analysisRight}>
            {renderHTML(divideMathText(item?.resolution || "暂无解析"))}
          </div>
        </div>
      </div>
    </div>));
    // }
  }

  render() {
    let { activeFlagList, tempAry, examName, isTaskPoint } = this.state;
    let singleChoiceQuestions = activeFlagList?.filter(item => item?.testType === 1);
    let multipleChoiceQuestions = activeFlagList?.filter(item => item?.testType === 2);
    let judgeChoiceQuestions = activeFlagList?.filter(item => item?.testType === 4);
    let FillInTheBlanksQuestions = activeFlagList?.filter(item => item?.testType === 3);
    let answerQuestions = activeFlagList?.filter(item => item?.testType === 5);
    let { user } = this.props;
    let { fullName, avatarUrl, token } = user;
    return (
      <div className={styles.wrapper}>
        <div className={styles.exitContainer} onClick={() => {
          let { onClose } = this.props;
          if (onClose) {
            onClose();
          } else {
            window.close();
          }
        }}>
          <img className={styles.exitImg} src={imgUrlForZhongZhi("exitImgPreview.png")} alt="" />
          <span>退出预览</span>
        </div>
        <div className={styles.container}>
          <p className={styles.title}>{examName}</p>
          {
            isTaskPoint && <div className={styles.TaskPointContainer}>
              <img className={styles.TaskPointImg} src={imgUrlForZhongZhi("TaskPointImg.png")} alt="" />
              <span>任务点</span>
            </div>
          }
          {
            activeFlagList?.length > 0 ?
              <div className={styles.center}>
                {
                  singleChoiceQuestions.length > 0 && <div className={styles.questionsContainer}>
                    <div className={styles.questionsType}>
                      <img src={imgUrlForZhongZhi("questionsTypeBg.png")} alt="" />
                      <span className={styles.questionsTypeNumber}>{tempAry?.find(item => item?.testType === 1)?.indexNumber}</span>
                      <span className={styles.questionsTypeText}>单选题</span>
                    </div>
                    {
                      this.renderTestQuestions(singleChoiceQuestions, 1)
                    }
                  </div>
                }
                {
                  multipleChoiceQuestions.length > 0 && <div className={styles.questionsContainer}>
                    <div className={styles.questionsType}>
                      <img src={imgUrlForZhongZhi("questionsTypeBg.png")} alt="" />
                      <span className={styles.questionsTypeNumber}>{tempAry?.find(item => item?.testType === 2)?.indexNumber}</span>
                      <span className={styles.questionsTypeText}>多选题</span>
                    </div>
                    {
                      this.renderTestQuestions(multipleChoiceQuestions, 2)
                    }
                  </div>
                }
                {
                  judgeChoiceQuestions.length > 0 && <div className={styles.questionsContainer}>
                    <div className={styles.questionsType}>
                      <img src={imgUrlForZhongZhi("questionsTypeBg.png")} alt="" />
                      <span className={styles.questionsTypeNumber}>{tempAry?.find(item => item?.testType === 4)?.indexNumber}</span>
                      <span className={styles.questionsTypeText}>判断题</span>
                    </div>
                    {
                      this.renderTestQuestions(judgeChoiceQuestions, 4)
                    }
                  </div>
                }
                {
                  FillInTheBlanksQuestions.length > 0 && <div className={styles.questionsContainer}>
                    <div className={styles.questionsType}>
                      <img src={imgUrlForZhongZhi("questionsTypeBg.png")} alt="" />
                      <span className={styles.questionsTypeNumber}>{tempAry?.find(item => item?.testType === 3)?.indexNumber}</span>
                      <span className={styles.questionsTypeText}>填空题</span>
                    </div>
                    {
                      this.renderTestQuestions(FillInTheBlanksQuestions, 3)
                    }
                  </div>
                }
                {
                  answerQuestions.length > 0 && <div className={styles.questionsContainer}>
                    <div className={styles.questionsType}>
                      <img src={imgUrlForZhongZhi("questionsTypeBg.png")} alt="" />
                      <span className={styles.questionsTypeNumber}>{tempAry?.find(item => item?.testType === 5)?.indexNumber}</span>
                      <span className={styles.questionsTypeText}>解答题</span>
                    </div>
                    {
                      this.renderTestQuestions(answerQuestions, 5)
                    }
                  </div>
                }
              </div>
              :
              <Empty title="暂无试题" />
          }
        </div>
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateToken(token) {
    dispatch(updateToken(token));
  },
  updateUser(user) {
    dispatch(updateUser(user));
  },
  clearToken() {
    dispatch(clearToken());
  },
});

export default connect(({ user }) => ({ user }), mapAction)(Home);
