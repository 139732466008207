// import _lodash from 'lodash';


// 获取地址栏参数
export const getParam = function () {
  let arr = window.location.href.split('?');
  if (arr.length <= 1) return {};
  let qs = arr[1],
    args = {},
    items = qs.length ? qs.split("&") : [],
    item = null,
    name = null,
    value = null,
    i = 0,
    len = items.length;
  for (i = 0; i < len; i++) {
    item = items[i].split("=");
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args || {};
};

// 获取地址栏参数(有#情况)
export const getParamWithHash = function () {
  let arr = window.location.search.split('?');
  if (arr.length <= 1) return {};
  let qs = arr[1],
    args = {},
    items = qs.length ? qs.split("&") : [],
    item = null,
    name = null,
    value = null,
    i = 0,
    len = items.length;
  for (i = 0; i < len; i++) {
    item = items[i].split("=");
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    if (name.length) {
      args[name] = value;
    }
  }
  return args || {};
};

export const setTimes = function (value) {
  let secondTime = parseInt(value);
  let min = 0;
  let h = 0;
  let result = "";
  if (secondTime > 60) {
    min = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    if (min > 60) {
      h = parseInt(min / 60);
      min = parseInt(min % 60);
    }
  }
  result = `${h.toString().padStart(2, "0")}:${min
    .toString()
    .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
  return result;
};

export const setTimesMin = function (value) {
  let secondTime = parseInt(value);
  let min = 0;
  // let h = 0;
  let result = "";
  if (secondTime > 60) {
    min = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    // if (min > 60) {
    //   h = parseInt(min / 60);
    //   min = parseInt(min % 60);
    // }
  }
  result = `${min}:${secondTime.toString().padStart(2, "0")}`;
  return result;
};

export const VarTypeOf = (value) => {
  // return "[object Object] [object Array] [object Undefined] [object Null] ..."
  let res = Object.prototype.toString.call(value),
    rex = /^\[([a-z]{6})\s+([a-z]+)\]$/ig;
  let t = res.replace(rex, "$2");
  if (t) {
  }
  return t;
};

function GetSlideAngle(dx, dy) {
  return Math.atan2(dy, dx) * 180 / Math.PI;
}
//根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
function GetSlideDirection(startX, startY, endX, endY) {
  var dy = startY - endY;
  var dx = endX - startX;
  // console.log(dy,dx);
  var result = 0;
  //如果滑动距离太短
  if (Math.abs(dx) < 2 && Math.abs(dy) < 2) {
    return result;
  }
  var angle = GetSlideAngle(dx, dy);
  if (angle >= -45 && angle < 45) {
    result = 4;
  } else if (angle >= 45 && angle < 135) {
    result = 1;
  } else if (angle >= -135 && angle < -45) {
    result = 2;
  } else if ((angle >= 135 && angle <= 180) || (angle >= -180 && angle < -135)) {
    result = 3;
  }
  return result;
}
let timer = {};
function debounce(func, timeout, ccc) {
  clearTimeout(timer.current);
  // 每次调用都指定timeout后再执行
  timer.current = setTimeout(() => {
    // console.log( Array.from(arguments),ccc)
    func(ccc)
    // func.apply(this, Array.from(arguments));
  }, timeout);
  // return ccc
}
export const move = (accept, id) => {
  console.log(11111111);
  // var h = document.documentElement.clientHeight;
  // console.log(mybody)

  // mybody.style.height = h + 'px';
  //返回角度
  if (!id) return
  const myBody = document.getElementById(id);
  // console.log("myBody===========",myBody);
  //滑动处理
  var startX, startY;
  myBody.addEventListener('touchstart', function (ev) {
    // ev.preventDefault();
    startX = ev.touches[0].pageX;
    startY = ev.touches[0].pageY;
  }, false);
  myBody.addEventListener('touchmove', function (ev) {
    var endX, endY;
    ev.preventDefault();
    endX = ev.changedTouches[0].pageX;
    endY = ev.changedTouches[0].pageY;
    var direction = GetSlideDirection(startX, startY, endX, endY);
    // console.log(direction);
    switch (direction) {
      case 0:
        debounce(accept, 100, "没滑动")
        // accept("没滑动")
        break;
      case 1:
        debounce(accept, 100, "向上")
        // accept("向上")
        break;
      case 2:
        debounce(accept, 100, "向下")
        // accept("向下")
        break;
      case 3:
        debounce(accept, 100, "向左")
        // accept("向左")
        break;
      case 4:
        debounce(accept, 100, "向右")
        // accept("向右")
        break;
      default:
    }
  }, false);

};

export const setTimesMin_ = function (value) {
  // let secondTime = parseInt(value);
  let secondTime = Math.round(value);
  let min = 0;
  // let h = 0;
  let result = "";
  if (secondTime > 60) {
    min = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    // if (min > 60) {
    //   h = parseInt(min / 60);
    //   min = parseInt(min % 60);
    // }
  }
  result = `${min
    .toString()
    .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
  return result;
};

export const divideMathText = (str) => {
  if (!str) { return ''; }
  let reg = /\[(\d+)\/(\d+)\]/ig;
  return str.replace(reg, v => {
    return v.replace('[', '$').replace(']', '$').replace('/', '\\over');
  });
};

export const getInnerText = (str) => {
  let div = document.createElement('div');
  div.innerHTML = str || '';
  let newText = div.innerText;
  div = null;
  return newText;
};

export const pausedVideoAndAudio = () => {
  let audios = document.getElementsByTagName('audio');
  let videos = document.getElementsByTagName('video');
  let eles = [...audios, ...videos];
  eles.forEach(item => {
    item.onplay = event => {
      eles.forEach(itm => {
        if (itm !== event.target) {
          itm.pause();
        }
      });
    };
  });
};
