import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paginations from '@@/Paginations';
import { updateCrumbsData } from '../../store/action';
import Crumbs from '@@/Crumbs';
import Empty from '../../components/Empty/index';
import { imgUrlForZhongZhi, imgUrlForZhongZhiApi } from '@/utils';
import {
  getCourseListBySchool,
  courseTypeList,
  subjectList,
  queryColleges,
  queryCollegeMajors,
} from '@/api';
import axios from '../../axios';
import styles from './index.module.scss';
import { getParam } from '../../utils/util';

class CourseCenter extends Component {
  constructor() {
    super();
    this.state = {
      paginationList: {
        current: 1,
        pageSize: 12,
        total: 1,
      },
      courseTypeListData: [],
      type: 1,    // 1 是公共课，2是专业课
      subjectListData: [],
      currentSubjectValue: '0',
      collegesList: [],
      currentCollegesId: 0,
      majorCategoriesList: [],
      currentMajorCategoriesId: 0,
      courseList: [],
    };
  }

  componentDidMount() {
    let type = +(localStorage.getItem('type') || 1);
    let currentSubjectValue = localStorage.getItem('currentSubjectValue') || '0';
    let currentCollegesId = +(localStorage.getItem('currentCollegesId') || 0);
    let currentMajorCategoriesId = +(localStorage.getItem('currentMajorCategoriesId') || 0);
    this.setState({
      type: type,
      currentSubjectValue,
      currentCollegesId,
      currentMajorCategoriesId
    }, () => {
      this.courseTypeList();
    });
  }

  // componentDidMount() {
  //   let type = 0;
  //   getParam().type == 2 ? type = 2 : type = 1;
  //   this.setState({
  //     type
  //   }, () => {
  //     this.courseTypeList();
  //   })
  // }

  courseTypeList = () => {
    axios.get(courseTypeList).then(res => {
      if (!res) return;
      this.setState({
        courseTypeListData: res
      }, () => {
        this.subjectList();
        this.queryColleges();
        // this.subjectList();
      });
    });
  }

  subjectList = () => {
    axios.get(subjectList).then(res => {
      if (!res) return;
      this.setState({
        subjectListData: res,
        // currentSubjectValue: res[0]?.value
      }, () => {
        this.getCourseListBySchool();
      });
    });
  }

  // 院系
  queryColleges = () => {
    let schoolId = this.props.user?.schoolId;
    if (!schoolId) return;
    axios.post(queryColleges, {
      schoolId: this.props.user?.schoolId
    }).then(res => {
      if (!res) return;
      this.setState({
        collegesList: res,
        // currentCollegesId: res[0]?.id
      }, () => {
        this.queryCollegeMajors();
      });
    });
  }

  // 专业
  queryCollegeMajors = () => {
    let collegeId = this.state.currentCollegesId;
    // if (!collegeId) return;
    axios.post(queryCollegeMajors, {
      collegeId,
      schoolId: this.props.user?.schoolId
    }).then(res => {
      if (!res) {
        return;
      };
      this.setState({
        majorCategoriesList: res,
        // currentMajorCategoriesId: res[0]?.id
        // currentMajorCategoriesId: 0
      }, () => {
        this.getCourseListBySchool();
      });
    });
  }

  getCourseListBySchool = () => {
    let { paginationList, type, currentSubjectValue, currentCollegesId, currentMajorCategoriesId } = this.state;
    let param = {};
    if (type === 1) {
      param = {
        pageNum: paginationList?.current,
        pageSize: paginationList?.pageSize,
        courseType: type,
        subjectId: currentSubjectValue,
        schoolId: this.props.user?.schoolId,
      };
    } else {
      param = {
        pageNum: paginationList?.current,
        pageSize: paginationList?.pageSize,
        courseType: type,
        collegeId: currentCollegesId,
        majorId: currentMajorCategoriesId,
        // majorCategoryId: currentMajorCategoriesId,
        schoolId: this.props.user?.schoolId,
      };
    }
    axios.post(getCourseListBySchool, param).then(res => {
      if (!res) return;
      let { total, records } = res;
      this.setState({
        paginationList: {
          ...this.state.paginationList,
          total
        },
        courseList: records
      });
    });
  }

  toDetail = index => {
    console.log(index);
  }

  renderItem = (data) => {
    return data?.map((item, index) => (<div key={index} className={`${styles.item} ${index === 0 ? styles.active : ''}`} onClick={this.toDetailPage.bind(this, item)}>
      <img className={styles.itemImg} src={imgUrlForZhongZhiApi(item?.coverFileId)} alt="" />
      {/* <p className={styles.itemTitle}>语文基础模块一</p> */}
      <p className={styles.itemTitle}>{item?.courseName}</p>
      <div className={styles.itemNameContainer}>
        <img className={styles.itemNameImg} src={imgUrlForZhongZhi("itemNameImg.png")} alt="" />
        {/* <span>张老师</span> */}
        {/* <span>{item?.bookAuthor}</span> */}
        <span>{item?.teaName}</span>
      </div>
      {/* <p className={styles.itemPressTitle}>高等教育出版社/朱熹/2021</p> */}
      <p className={styles.itemPressTitle}>{item?.press && item?.press + '/'}{item?.bookAuthor && item?.bookAuthor + '/'}{item?.publishDate && item?.publishDate}</p>
    </div>));
  }

  // 分页器 动态获取到页码
  onChangeCurPageOrPageSize = (current, pageSize) => {
    console.log(current, pageSize);
    this.setState({
      paginationList: {
        current,
        pageSize: 12,
        // total: 180,
      },
    }, () => {
      this.getCourseListBySchool();
    });
  }

  changeSubject = currentSubjectValue => {
    this.setState({
      currentSubjectValue
    }, () => {
      this.getCourseListBySchool();
    });
  }

  changeColleges = currentCollegesId => {
    this.setState({
      currentCollegesId,
      currentMajorCategoriesId: 0
    }, () => {
      this.queryCollegeMajors();
    });
  }

  changeMajorCategories = currentMajorCategoriesId => {
    this.setState({
      currentMajorCategoriesId
    }, () => {
      this.getCourseListBySchool();
    });
  }

  toDetailPage = (item) => {
    let { type, currentSubjectValue, currentCollegesId, currentMajorCategoriesId } = this.state;
    localStorage.setItem('type', type);
    localStorage.setItem('currentSubjectValue', currentSubjectValue);
    localStorage.setItem('currentCollegesId', currentCollegesId);
    localStorage.setItem('currentMajorCategoriesId', currentMajorCategoriesId);
    // this.props.history.push(`/courseCenter/courseDetails?id=${item?.id}&courseType=${this.state.type}&isJiaoXueKongJianPage=${item?.courseName}`);
    this.props.history.push(`/courseCenter/courseDetails?id=${item?.id}&courseType=${this.state.type}&isJiaoXueKongJianPage1=${item?.courseName}`);
  }

  render() {
    let { type, courseTypeListData, subjectListData, currentSubjectValue, collegesList, currentCollegesId, majorCategoriesList, currentMajorCategoriesId, courseList } = this.state;
    const { current, pageSize, total } = this.state.paginationList;
    console.log(currentMajorCategoriesId,'-----')
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.nav}>
              <span className={styles.navName}>类型</span>
              <div className={styles.navItem}>
                {
                  courseTypeListData?.map((item, index) => (<span key={index} className={type === item?.value ? styles.active : ''} onClick={() => {
                    this.setState({
                      type: item?.value,
                      currentSubjectValue: '0',
                      currentCollegesId: 0,
                      currentMajorCategoriesId: 0,
                    }, () => {
                      this.queryCollegeMajors();
                      this.getCourseListBySchool();
                    });
                  }}>{item?.title}</span>))
                }
                {/* <span className={type === 1 ? styles.active : ''} onClick={() => {
                  this.setState({
                    type: 1
                  });
                }}>公共课</span>
                <span onClick={() => {
                  this.setState({
                    type: 2
                  });
                }} className={type === 2 ? styles.active : ''}>专业课</span> */}
              </div>
            </div>
            {
              type === 1 ?
                <div className={styles.nav}>
                  <span className={styles.navName}>学科</span>
                  <div className={styles.navItem}>
                    <span className={currentSubjectValue == '0' ? styles.active : ''} onClick={this.changeSubject.bind(this, '0')}>全部</span>
                    {
                      subjectListData?.map((item, index) => (<span className={currentSubjectValue === item?.value ? styles.active : ''} key={index} onClick={this.changeSubject.bind(this, item?.value)}>{item?.title}</span>))
                    }
                  </div>
                </div>
                :
                <>
                  <div className={styles.nav}>
                    <span className={styles.navName}>院系</span>
                    <div className={styles.navItem}>
                      {
                        collegesList?.length > 0 && <span onClick={this.changeColleges.bind(this, 0)} className={currentCollegesId == 0 ? styles.active : ''}>全部</span>
                      }
                      {
                        collegesList?.map((item, index) => (<span onClick={this.changeColleges.bind(this, item?.id)} key={index} className={currentCollegesId === item?.id ? styles.active : ''}>{item?.collegeName}</span>))
                      }
                    </div>
                  </div>
                  <div className={styles.nav}>
                    <span className={styles.navName}>专业</span>
                    <div className={styles.navItem}>
                      {
                        majorCategoriesList?.length > 0 && <span onClick={this.changeMajorCategories.bind(this, 0)} className={currentMajorCategoriesId == 0 ? styles.active : ''}>全部</span>
                      }
                      {
                        majorCategoriesList?.map((item, index) => (<span onClick={this.changeMajorCategories.bind(this, item?.id)} key={index} className={currentMajorCategoriesId === item?.id ? styles.active : ''}>{item?.majorName}</span>))
                      }
                      {/* {
                        majorCategoriesList?.map((item, index) => (<span onClick={this.changeMajorCategories.bind(this, item?.id)} key={index} className={currentMajorCategoriesId === item?.id ? styles.active : ''}>{item?.majorCategory}</span>))
                      } */}
                    </div>
                  </div>
                </>
            }
          </div>
          <div className={styles.center} style={{minHeight:window.screen.height-418,}}>
            {
              courseList?.length > 0
                ?
                this.renderItem(courseList)
                :
                <Empty />
            }
          </div>
          <div className={styles.footer}>
            <Paginations
              current={current}
              pageSize={pageSize}
              total={total}
              handleChange={this.onChangeCurPageOrPageSize}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ user }) => ({ user }))(CourseCenter);
