
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, Fragment } from 'react'
import { connect } from 'react-redux';
import { Upload, Progress, Space, Button, Modal, Form, Input, Radio, Row, Tooltip, message, Card, Select, DatePicker, Cascader } from 'antd'

import { updateUser } from '@/store/action';
import { uploadFile } from '@/api';
import { imgUrlForZhongZhi, imgUrlForZhongZhiApi, getIp } from '@/utils/index';
import { UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';
import './index.scss';

const FormItem = Form.Item


const uploadModal = forwardRef(({ uploadTypeInfo,uploadType, ...props }, ref) => {

  const [majorData, setMajorData] = useState([]);

  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([]);
  const [percent, setPercent] = useState(0);
  const [imgShow, setImgShow] = useState(true);
  const [fileInfo, setFileInfo] = useState({});
  const [fileFormat, setFileFormat] = useState("");
  const [upladInfo, setUpladInfo] = useState()

  const onFinished = () => {
    return form;
  }
  useImperativeHandle(ref, () => {
    return {
      onFinished
    }
  })
  const reUpload = () => {
  
    setImgShow(true);
    setFileInfo({})
    setFileList([])
  }
  const beforeUpload = file => {
    console.log("file",file)
    const nameArr = file?.name.split('.');
    const format = nameArr[nameArr.length - 1];
    const isLtSize = file.size / 1024 / 1024 ;
    console.log(isLtSize,uploadTypeInfo.size);
    console.log(file?.name.replace('.'+format,''),777)
    setFileFormat(format)
    console.log(uploadTypeInfo.prevfixList.includes('.' + format),isLtSize)
    // && uploadTypeInfo.zh != '附件'
    if(file.size == 0){
      message.error('请勿上传空文件');
      return false
    }
    if ((!uploadTypeInfo.prevfixList.includes('.' + format))) {
      message.error('文件格式不正确');
      setFileList([])
      return false
    }
    if (isLtSize>uploadTypeInfo.size) {
      message.error(`${uploadTypeInfo.zh}大小不能超过${uploadTypeInfo.size}M`);
      return false
    }
    // 文档限制字数
    if(uploadType == 'docs'&&(file?.name.replace('.'+format,'')).length>32){
      message.error(`${uploadTypeInfo.zh}文件名不能超过32个字`);
      return false
    }
  }
  const handleChange = (info) => {
    console.log(info)
    // console.log(info.fileList.slice())
    const name = info?.file?.name;
    const size = (info?.file?.size / 1024).toFixed(2);

    setFileList(info.fileList.slice()); // Note: A new object must be used here!!!

    const event = info.event;
    if (info.file.status === 'uploading') {
      console.log("uploading");
      setFileInfo({ name, size })
      setImgShow(false)
    }
    if (info.file.status === 'done') {
      console.log("done");
      const code = info?.file?.response?.code;
      if (code == 0) {
        let result = info?.file?.response?.result;
        result.fileInfo = fileInfo;
        result.fileFormat = fileFormat;
       setUpladInfo(result)
        message.success(`${name} 上传成功`);
        setFileList([]);
      } else {
        message.success(`${name} 上传失败`);
      }

      setImgShow(false);

    } else if (info.file.status === 'error') {
      console.log("error")
      setFileList([]);
      setFileInfo({})
      message.error(`${info.file.name} file upload failed.`);
    }

    if (event) { // 一定要加判断，不然会报错
      let percent = Math.floor((event.loaded / event.total) * 100);
      console.log(event)
      setPercent(percent)
      console.log("percent", percent) // percent就是进度条的数值
    }
  }
  // 文件上传
  const uploadProps = {
    name: 'file',
    action: uploadFile,
    multiple: false,
    fileList,
    listType: 'text',
    beforeUpload: beforeUpload,
    onChange: handleChange,
    data: {
      uid: props?.user?.uid,
      bizType: 4
    },
    onRemove(file) {
      setFileList([]);
    },

    progress: {
      strokeColor: {
        '0%': '#00C250',
        '100%': '#00C250',
      },
      strokeWidth: 3,
      format: (percent,successPercent) => {
        return `${parseFloat(percent.toFixed(2))}%${successPercent}`
      },
    },
  };
  const progessProps = {
    type: 'line',
    percent: percent,
    showInfo:false,
    strokeColor: {
      '0%': '#00C250',
      '100%': '#00C250',
    },
    strokeWidth: 4,
    format: (percent) => {
     return `${parseFloat(percent.toFixed(2))}%`;
    },
    // strokeWidth:258
  }
  return (<div className="uploadContainer">

    <div className={`upload ${!imgShow ? 'upLoadDone' : ''}`}>
      <div className="upLoadImgBox">
        {
          imgShow ? <img className="upLoadImg" src={imgUrlForZhongZhi('upLoad.png')} alt="" /> :
            <div className="fileInfo">
              <p className="top">
                <img src={imgUrlForZhongZhi(`${uploadType != 'docs'?'icon_'+uploadType+'.png':fileFormat=='pdf'?'icon_pdf.png':'icon_word.png'}`)} alt="" className='fileIcon' />
                {fileInfo?.name}
              </p>
              <Progress {...progessProps} style={{ height: '4px' }} />
              <p className="bottom">
                <span>{percent}%</span>
                <span>{fileInfo?.size}KB</span>
              </p>
            </div>
        }

        <Upload accept={uploadTypeInfo.prevfixList.toString()} {...uploadProps} key="3624">
          {
            imgShow ? <Button icon={null} className='btnBlue'>插入{uploadTypeInfo.zh}</Button> : <div onClick={reUpload} className="reUpload">
            <img src={imgUrlForZhongZhi('icon_refresh.png')} alt="" className='refresh'/>
            重新上传
            </div>
          }
        </Upload>
      </div>
    </div>
    {
      imgShow ?
        <div className="specRequire">
          {
            uploadTypeInfo.tips.map((item, index) => {
              return (
                <p>{index + 1}、{item}</p>
              )
            })
          }
        </div> : <div className='btnBox'>
          <Button className='btnBlue' onClick={() => {
             typeof props.uploadResult === 'function' && props.uploadResult(upladInfo);
          }}
          disabled={upladInfo?false:true}
          >确定</Button>
        </div>

    }

  </div>)


})
const mapAction = (dispatch) => ({
  updateUser(user) {
    dispatch(updateUser(user));
  },
});
export default connect(({ user }) => ({ user }), mapAction)(uploadModal);
