import React from "react";
import {Select, Tooltip, Modal, Row, Col, Spin} from 'antd';
import styles from './index.module.scss';
import { CompactPicker } from 'react-color'; import ReactDOM from 'react-dom';
import { imgUrlForZhongZhi } from '../../../../utils/index';
import { uploadRequireTypeData } from '@/utils/uploadTypeRequire';
import UploadModal from '@/components/uploadModal/index'
import TestsOrLinkModal from '@/components/TestsOrLinkModal/index'
import { CloseOutlined } from '@ant-design/icons';
import { saveExam, updateExam } from '@/api'
import { fileTypeToValueMap } from "../../utils"
import axios from "../../../../axios";
import _lodash from 'lodash'

const { Option } = Select;
let ue;
let $;
let initState = {
  lineheightVisible: false,
  nextId: 1,
  color: '#000',
  isShowColorTip: false,
  isStrong: false,
  isItalics: false,
  updateVisible: false,
  uploadTypeInfo: {},
  uploadType: "",
  mode: 'edit',
  fontFamily: 'songti',
  fontSize: 10,
  insertordered: null,
  insertunordered: null,
  h1Active: false,
  h3Active: false,
  fontFamilyList: [
    { label: '', name: 'songti', val: '宋体,SimSun' },
    { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
    { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
    { label: '', name: 'heiti', val: '黑体, SimHei' },
    { label: '', name: 'lishu', val: '隶书, SimLi' },
    { label: '', name: 'andaleMono', val: 'andale mono' },
    { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
    { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
    { label: '', name: 'comicSansMs', val: 'comic sans ms' },
    { label: '', name: 'impact', val: 'impact,chicago' },
    { label: '', name: 'timesNewRoman', val: 'times new roman' }
  ],
  fontSizeList: [10, 11, 12, 14, 16, 18, 20, 24, 36],
  insertorderedList: {
    'num': '1,2,3...',
    'num1': '1),2),3)...',
    'num2': '(1),(2),(3)...',
    'cn': '一,二,三....',
    'cn1': '一),二),三)....',
    'cn2': '(一),(二),(三)....',
    //系统自带
    'decimal': '1,2,3...',
    'lower-alpha': 'a,b,c...',
    'lower-roman': 'i,ii,iii...',
    'upper-alpha': 'A,B,C',
    'upper-roman': 'I,II,III...'
  },
  insertunorderedList: {
    'dash': '— 破折号', //-破折号
    'dot': ' 。 小圆圈', //系统自带
    'circle': '○ 小圆圈',  // '○ 小圆圈'
    'disc': '● 小圆点',    // '● 小圆点'
    'square': '■ 小方块'   //'■ 小方块'
  },
  formatTooltipVisible: false,
  teachinglinkData: [
    {
      id: "",
      displayOrder: 0,
      name: '',
      flag: 1, // add = 0  或  update = 1
      canDel: false  // 是否删除 false 不能删除 或  TRUE 可以删除
    }
  ],
  filesData: [
    // {
    //   fileId: '',
    //   name: '',
    //   isTask: false,
    //   isOrigin: false,
    //   size: ''
    // }
  ],
  delIds: [],
  isUEFocus: true,
  //改成跳到新页面
  // isShowPreviewOfTestQuestions: false, // 是否显示查看试卷弹窗
  // isShowEditOfTestQuestions: false, // 是否显示编辑试卷弹窗
  examId: "",
  selTitle: "",
  delModalVisible: false,
  examName: "",
};
class RichMedia extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = initState
  }



  componentDidMount() {

    this.props.onRef && this.props.onRef(this);
    let { catalogId, courseId, resResource } = this.props
    // debugger
    if (window.UE) {
      ue = window.UE.getEditor('learningCaseEditor', {
        toolbars: [['spechars', 'kityformula', 'link']],
        lang: 'zh-cn',

        minFrameHeight: 800, //语言
        initialFrameWidth: '100%',
        initialFrameHeight: 800,

        // imageScaleEnabled: false,
        initialStyle: "body:{font-family:'songti';font-size:10}"
      })

      // this.getUEditorContent(courseId, catalogId)
      if (resResource.hasOwnProperty('stepList')) {
        this.parseUEditorContent(resResource)
      }

      // ue.ready(() => {
      //   // editor准备好之后才可以使用
      //   ue.setContent('');
      //   this.getUEditorContent(courseId, catalogId)
      // });


    }
    if (window.$) {
      $ = window.$
    }
    // console.log('555555555555555555555555');
    window.setFilesData = this.setFilesData.bind(this);


    let that = this;

    window.onload = () => {
      const domUtils = window.UE.dom.domUtils;
      domUtils.on(ue.body, 'focus', function () {
        that.setState({ isUEFocus: true }, () => {
          // console.log(this.state.isUEFocus)
        })
      })
      domUtils.on(ue.body, 'blur', function () {
        // that.setState({isUEFocus: false})
      })
    }
    this.onMsg()

  }
  onMsg = () => {
    let that = this;
    window.onmessage = function (event) {
      // console.log('event111 ===========', event)
      let data = event.data;
      if (data.operatorType == "teachinglink") {
        let newData = data.data;
        let { teachinglinkData: tdata, delIds } = that.state;
        let index = tdata.findIndex(item => item.id == newData.id)
        if (data.isDel) {
          if (newData.canDel) {
            tdata.splice(index, 1)
            if (newData.flag == 1) {
              delIds.push(newData.id)
            }
          }
        } else {
          tdata[index] = newData;
        }
        tdata.forEach((item, index) => {
          item.displayOrder = index
        })
        that.setState({
          teachinglinkData: tdata
        }, () => {
          // console.log('data======', tdata)
        })
      } else {
        window.setFilesData(data)
      }
    }
  }

  componentWillUnmount() {
    if (ue) {
      ue.ready(function () {
        ue.destroy()
      })
    }
  }
  //关闭预览弹窗需要重写window.onmessage方法
  componentWillReceiveProps(nextProps) {
    // console.log('isPreviewModal', nextProps?.isPreviewModal)
    if (!nextProps?.isPreviewModal) {
      this.onMsg()
    }
    if (nextProps.resResource) {
      // console.log('编辑期更新');
      // console.log({...initState});
      this.setState({
        ...initState
      }, () => {
        this.parseUEditorContent(nextProps.resResource)
      })

    } else {
      ue.setContent('')
    }
    // if (nextProps.courseId != this.props.courseId || nextProps.catalogId != this.props.catalogId) {
    //   this.getUEditorContent(nextProps.courseId, nextProps.catalogId)
    // }
  }

  // 关闭试卷弹窗
  // closeTestsModal = () => {
  //   this.setState({
  //     isShowPreviewOfTestQuestions: false,
  //     isShowEditOfTestQuestions: false,
  //   });
  // }

  // getUEditorContent = (courseId, catalogId, attach = 1) => {

  //   axios.get(getResourceByCatalog, { params: { catalogId, courseId, attach } }).then(res => {
  //     this.parseUEditorContent(res)
  //   }).catch(err => {
  //     ue.setContent('')
  //   })
  // }

  parseUEditorContent = (res) => {
    // console.log("res=================", res)
    let { stepList = [] } = res;
    //解析除环节数据和文件数据
    let hasDefaultHuanjie = stepList.findIndex(item => _lodash.isNil(item.stepName));
    let defaultHuanjie;
    if (hasDefaultHuanjie < 0) {
      defaultHuanjie = this.state.teachinglinkData
    }
    let teachinglinkData = (defaultHuanjie || []).concat(stepList.map(item => ({
      id: item.id,
      displayOrder: hasDefaultHuanjie < 0 ? item.displayOrder + 1 : item.displayOrder,
      name: item.stepName,
      flag: 1, // add = 0  或  update = 1
      canDel: true  // 是否删除 false 不能删除 或  TRUE 可以删除)
    })));
    // && item.resType !== 7
    let fileList = _lodash.flatMap(stepList.map(item => (item.resourceList || []).filter(item => item.resType !== 0)));
    // console.log("fileList", fileList)
    // console.log("teachinglinkData", teachinglinkData)
    let filesData = fileList.map(item => ({
      fileUrl: item.url, fileId: item.resId, fileFormat: item.extName, downloadUrl: '',
      fileInfo: { name: item.resTitle, size: item.fileSize, uploadType: fileTypeToValueMap.get(item.resType).value, zh: uploadRequireTypeData[fileTypeToValueMap.get(item.resType).value].zh },
      hasSitu: item?.hasSitu,
      hasTask: item?.hasTask,
    }))
    // console.log("filesData", filesData);
    // debugger
    let resArr = []
    // console.log("insertStepList", stepList);
    stepList.forEach(item => {
      // debugger
      if ((item.stepName || "").length > 0) {
        resArr.push(this.getIframForOne(teachinglinkData.find(titem => titem.id == item.id), true));
      }
      if (item.resourceList && Array.isArray(item.resourceList)) {

        item.resourceList.forEach(ritem => {
          // || ritem.resType == 7
          if (ritem.resType == 0) {
            resArr.push(ritem.resContent)
          } else {
            resArr.push(this.getIframForOne(filesData.find(fitem => fitem.fileId == ritem.resId), false));
          }
        })
      }
    })

    // console.log("resArr=>", resArr)
    // return;
    this.setState({
      teachinglinkData,
      filesData
    }, () => {
      ue.ready(function () {
        // debugger
        ue.setContent(resArr.join(''))
      })
    })

  }

  setFilesData = (data) => {
    let { filesData, uploadType } = this.state;
    // // console.log(data)
    // editType 0-删除 1-修改
    if (data?.editType && data?.editType === '0') {
      // 删除确认弹窗
      // this.delFile(data)
      // return
      this.setState({
        delModalVisible: true,
        delModalDate: data
      })
    } else if (data?.editType && data?.editType === '1') {
      let i = filesData.findIndex(item => item.fileId == data?.fileData?.fileId);
      if (i >= 0) {
        filesData[i] = data?.fileData;
      }
      // 为测试需要告知后台
      if (uploadType == 'tests') {
        axios.post(updateExam, { examName: data?.fileData?.fileInfo?.name, fileId: data?.fileData?.fileId }).then(res => {

        }).catch(err => { })
      }
    } else if (data?.editType && data?.editType === '2.1') { //编辑
      // console.log(data)
      let { fileId, hasTask } = data?.fileData
      let { name } = data?.fileData?.fileInfo
      window.open(`${window.location.origin}/#/editOfTestQuestions?examId=${fileId}&courseId=${this.props.courseId || ''}&examName=${name}&isTaskPoint=${hasTask ? true : ''}`, "_blank");

      // this.setState({
      //   examId: data?.fileData?.fileId,
      //   isShowEditOfTestQuestions: data?.fileData?.showEdit || false,
      // });


    } else if (data?.editType && data?.editType === '2.2') { //预览
      let { fileId, hasTask } = data?.fileData
      let { name } = data?.fileData?.fileInfo
      window.open(`${window.location.origin}/#/previewOfTestQuestions?examId=${fileId}&examName=${name}&isTaskPoint=${hasTask ? true : ''}`, "_blank");


      // this.setState({
      //   examId: data?.fileData?.fileId,
      //   isShowPreviewOfTestQuestions: data?.fileData?.showPreview || false,
      // });


    }
    this.setState({
      filesData
    }, () => {
      // // console.log("修改后", this.state.filesData)
    })
  }
  delFile = (delModalDate) => {
    let { filesData } = this.state;
    const domUtils = window.UE.dom.domUtils;
    let id = 'p_' + delModalDate?.fileData?.fileId;
    let node = document.getElementById(id);
    let i = filesData.findIndex(item => item.fileId == delModalDate?.fileData?.fileId);
    if (i >= 0) {
      filesData.splice(i, 1);
    }
    // console.log(id, filesData, 'delFile');
    // console.log(window.UE.dom, document,)
    // let eduEle = document.getElementById("edui1_iframeholder");
    let eduEle = $('div[id$=_iframeholder]')[0];
    var dslist = $('div[id$=_iframeholder]');
    // // console.log(dslist);
    // // console.log("eduEle=>",eduEle);
    // // console.log("childNodes=>",eduEle.childNodes);
    // // console.log("eduEle.firstChild=>",eduEle?.firstChild);
    if (!eduEle) return;
    let selNode = eduEle?.firstChild?.contentWindow?.document.getElementById(id);
    if (selNode) {
      domUtils.remove(selNode, false, () => {
        this.setState({
          filesData
        }, () => {
          // console.log("删除后", this.state.filesData)
        })
      });

    }
  }
  //只修改试卷名称

  handleChange = (cmd, value) => {

    ue.ready(function () {
      ue.execCommand(cmd, value)
    })
  }

  changeTitle = (cmd, value) => {
    let params = {}, that = this, h1Active = !this.state.h1Active, h3Active = !this.state.h3Active;
    let hasP = ue.queryCommandValue(cmd)
    let hasState = ue.queryCommandState(cmd)
    // console.log(value, hasP)
    // console.log("cmd", cmd)
    let range = ue.selection.getRange();
    range.select()
    let text = ue.selection.getText()
    ue.ready(function () {
      ue.execCommand('bold')
    })
    if (value == 'h1') {
      ue.execCommand(cmd, hasP == 'h1' ? 'p' : 'h1')
      this.setState({ selTitle: hasP == 'h1' ? '' : 'h1' })
    }
    if (value == 'h3') {
      ue.execCommand(cmd, hasP == 'h3' ? 'p' : 'h3')
      this.setState({ selTitle: hasP == 'h3' ? '' : 'h3' })
    }

  }

  changeUeditorCommand = (cmd, value) => {
    // console.log(cmd, value)
    if (ue) {
      ue.ready(function () {
        if (!value) {
          ue.execCommand(cmd)
        } else {
          ue.execCommand(cmd, value)
        }

        ue.fireEvent('selectedchange')
      })
    }
  }

  colorChange = (colorCode) => {
    let hex = colorCode.hex;
    ue.ready(function () {
      ue.execCommand('forecolor', hex)
    })
    this.setState({
      isShowColorTip: false
    });
  }
  //打开上传框
  upLoad = (type) => {
    this.setState({
      updateVisible: true,
      uploadTypeInfo: uploadRequireTypeData[type],
      uploadType: type
    })
  }
  //传回上传成功后数据
  // {"code":0,"message":"成功","result":{"fileId":"0d386302d80b43ec9abc6ce925cf709a","cdnUrl":"https://zzcdn.yunquancn.com/test/sakai/img/32920220120/0d386302d80b43ec9abc6ce925cf709a.docx","downloadUrl":"test/sakai/img/32920220120/0d386302d80b43ec9abc6ce925cf709a.docx"}}
  uploadResult = (resulte) => {
    // console.log(resulte);
    this.setState({
      updateVisible: false
    }, () => {
      this.insertDOMToEditor(resulte)
    })
    // // console.log(resulte)
  }
  closeModal = () => {
    this.setState({
      updateVisible: false
    })
  }
  // 传回链接信息 例如：{linkName: 'test', linkAddress: 'www.baidu.com'}
  transLink = (linkInfo) => {
    // console.log("linkInfo========", linkInfo)
    this.insertDOMToEditor(linkInfo)
  }
  // 传回测试 例如：{testName: 'test'}
  transTest = async (testInfo) => {
    // console.log(testInfo)

    let { uploadType, nextId, mode, filesData } = this.state
    let fileInfo = { zh: '测试', name: testInfo?.testName, uploadType }
    // const fileId = await saveExam({name:testInfo?.testName})

    axios.post(saveExam, { examName: testInfo?.testName }).then(res => {
      // console.log(res, "saveExam")
      if (res) {
        const fileId = res;
        // let fileId = '123456'
        let obj = { fileId, fileInfo, hasTask: 1 }
        filesData.push(obj);
        this.setState({
          filesData,
          examName: testInfo?.testName
        })
        var url = "/ueditor/modules/insertTests.html";
        //前端生成fileId
        var a = '<iframe data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '" scrolling="no"  class="docframe"  mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe>'
        // var a = '<div data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '"><iframe scrolling="no"  class="docframe" type="' + uploadType + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe></div>'
        ue.ready(function () {
          ue.execCommand("inserthtml", a)
        })
      }


    }).catch(err => {
      ue.setContent('')
    })



  }




  insertDOMToEditor = (result) => {
    let { uploadType, nextId, mode, uploadTypeInfo, filesData, isUEFocus } = this.state;
    //   if(!isUEFocus) {
    //     return ;
    //  }
    // console.log(uploadType, "uploadType");
    if (uploadType == 'link') {
      let address = result?.linkAddress;
      // console.log(address.search('http'), "address.search('http')")
      if (address.search('http') != 0) address = "http://" + address;
      let obj = { fileId: address, fileInfo: { name: result.linkName } }
      filesData.push(obj);
      this.setState({
        filesData
      })
      var a = '<a data-split="1" type="' + uploadType + '" data-fileId="' + address + '" cdata_tag="p" id="p_' + address + '" href="' + address + '" target="_blank">' + result.linkName + '</a>'
      // var a = '<div data-split="1" type="' + uploadType + '"  cdata_tag="p" data-fileId="' + address + '"><a href="' + address + '" target="_blank">' + result.linkName + '</a></div>'
      ue.ready(function () {
        ue.execCommand('inserthtml', a)
      })
      return;
    }
    if (uploadType == 'docs' || uploadType == 'courseware' || uploadType == 'picture' || uploadType == 'video' || uploadType == 'audio' || uploadType == 'file') {

      let { fileId, cdnUrl, downloadUrl, fileInfo, fileFormat } = result;
      fileInfo.zh = uploadTypeInfo.zh;
      fileInfo.uploadType = uploadType;
      let obj = { "fileUrl": cdnUrl, "downloadUrl": downloadUrl, "fileId": fileId, fileInfo, fileFormat }
      filesData.push(obj);
      this.setState({
        filesData
      })
      if (uploadType == 'docs' || uploadType == 'courseware') {

        var url = "/ueditor/modules/insertdoc.html"
        var a = '<iframe data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '" scrolling="no"  class="docframe" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe>'
        // var a = '<div data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '"><iframe scrolling="no"  class="docframe" type="' + "docs" + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe></div>'

        ue.ready(function () {
          ue.execCommand("inserthtml", a)
        })
        return;
      }

      if (uploadType == 'video') {
        var url = "/ueditor/modules/insertvideo.html"
        var a = '<iframe data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '" scrolling="no"  class="docframe"  mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe>'
        // var a = '<div data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '"><iframe scrolling="no"  class="docframe" type="' + uploadType + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe></div>'
        ue.ready(function () {
          ue.execCommand("inserthtml", a)
        })
        return;
      }
      if (uploadType == 'audio') {
        var url = "/ueditor/modules/insertaudio.html";
        var a = '<iframe  data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '" scrolling="no"  class="docframe"  mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe>'
        // var a = '<div data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '"><iframe scrolling="no"  class="docframe" type="' + uploadType + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe></div>'
        ue.ready(function () {
          ue.execCommand("inserthtml", a)
        })
        return;
      }
      if (uploadType == 'file') {
        var url = "/ueditor/modules/insertAttachFiles.html"
        var a = '<iframe data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '" scrolling="no"  class="docframe"  mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe>'
        // var a = '<div data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="p_' + fileId + '"><iframe scrolling="no"  class="docframe" type="' + uploadType + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe></div>'
        ue.ready(function () {
          ue.execCommand("inserthtml", a)
        })
        return;
      }
      // if (uploadType == 'picture') {
      //   let { fileId, cdnUrl, downloadUrl, fileInfo } = result
      //   // ue.execCommand("insertimage", { src: cdnUrl, width:'100%'})
      //   ue.execCommand("inserthtml", `<img src="${cdnUrl}" style="width:100%" />`)
      //   return;
      // }
      if (uploadType == 'picture') {
        var url = "/ueditor/modules/insertPicture.html"
        var a = '<iframe data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '"  cdata_tag="p" id="p_' + fileId + '" scrolling="no"  class="docframe" type="' + uploadType + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="500px"></iframe>'
        // var a = '<div data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '"  cdata_tag="p" id="p_' + fileId + '"><iframe scrolling="no"  class="docframe" type="' + uploadType + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="500px"></iframe></div>'
        ue.ready(function () {
          ue.execCommand("inserthtml", a)
        })
        return;
      }
    }

  }
  marchNum = (num) => {
    var arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
    var arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿');//可继续追加更高位转换值
    if (!num || isNaN(num)) {
      return "零";
    }
    var english = num.toString().split("")
    var result = "";
    for (var i = 0; i < english.length; i++) {
      var des_i = english.length - 1 - i;//倒序排列设值
      result = arr2[i] + result;
      var arr1_index = english[des_i];
      result = arr1[arr1_index] + result;
    }
    //将【零千、零百】换成【零】 【十零】换成【十】
    result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
    //合并中间多个零为一个零
    result = result.replace(/零+/g, '零');
    //将【零亿】换成【亿】【零万】换成【万】
    result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
    //将【亿万】换成【亿】
    result = result.replace(/亿万/g, '亿');
    //移除末尾的零
    result = result.replace(/零+$/, '')
    //将【零一十】换成【零十】
    //result = result.replace(/零一十/g, '零十');
    //貌似正规读法是零一十
    //将【一十】换成【十】
    result = result.replace(/^一十/g, '十');
    return result;
  }
  insertTeachingLinkToEditor = () => {
    let { nextId, mode, teachinglinkData, isUEFocus } = this.state
    // debugger
    // if(!isUEFocus) {
    //    return ;
    // }
    // console.log(nextId, mode, teachinglinkData, isUEFocus)
    let obj = {
      id: nextId,
      displayOrder: teachinglinkData[teachinglinkData.length - 1].displayOrder + 1,
      name: '环节' + this.marchNum(teachinglinkData[teachinglinkData.length - 1].displayOrder + 1),
      flag: 0,
      canDel: true
    }


    ue.ready(function () {
      var url = "/ueditor/modules/insertTeachingLink.html"
      var a = '<iframe data-nextId="' + nextId + '" style="padding:0;margin:0;"  id="teachinglink_' + nextId + '" scrolling="no"  class="docframe" type="teachinglink" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe>'
      // var a = '<h2 data-nextId="' + nextId + '" style="padding:0;margin:0;"  id="teachinglink_' + nextId + '"><iframe scrolling="no"  class="docframe" type="teachinglink" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="auto"></iframe></h2>'
      ue.execCommand('inserthtml', a)
    })

    teachinglinkData = [].concat(teachinglinkData);
    //此处错误，应为实际顺序
    teachinglinkData.push(obj)
    // teachinglinkData.forEach((item,index)=>{
    //   item.displayOrder = index
    // })
    // console.log("插入teachinglinkData=>", teachinglinkData)
    this.setState({ nextId: nextId + 1, teachinglinkData })

  }

  getIframForOne = (info, isHuanjie) => {
    // console.log(info, isHuanjie, "getIframForOne");
    let encodeInfo = encodeURIComponent(JSON.stringify(info))
    let url = "";
    if (isHuanjie) {
      url = "/ueditor/modules/insertTeachingLink.html"
      return `<iframe data-nextId="${info.id}" style="padding:0;margin:0;"  id="teachinglink_${info.id}" scrolling="no"  class="docframe" type="teachinglink" mode="edit" src="${url}" nextId="${info.id}" data="${encodeInfo}" frameborder="0" width="100%"  min-height="auto"></iframe>`
      // return `<h2 data-nextId="${info.id}" style="padding:0;margin:0;"  id="teachinglink_${info.id}">
      // <iframe scrolling="no"  class="docframe" type="teachinglink" mode="edit" src="${url}" nextId="${info.id}" data="${encodeInfo}" frameborder="0" width="100%"  min-height="auto"></iframe>
      // </h2>`
    }
    let uploadType = info.fileInfo.uploadType;
    switch (uploadType) {
      case 'docs':
      case 'courseware':
        url = "/ueditor/modules/insertdoc.html"
        break;
      case 'video':
        url = "/ueditor/modules/insertvideo.html"
        break;
      case 'audio':
        url = "/ueditor/modules/insertaudio.html";

        break;
      case 'file':
        url = "/ueditor/modules/insertAttachFiles.html"
        break;
      case 'picture':
        url = "/ueditor/modules/insertPicture.html"
        break;
      case 'tests':
        url = "/ueditor/modules/insertTests.html"
        break;
      default:
        break;
    }
    let res = "";
    if (uploadType == 'link') {
      res = `<a data-split="1" type="${uploadType}" data-fileId="${info.fileId}" id="p_${info.fileId}" href="${info.fileId}" target="_blank">${info.fileInfo.name}</a>`
      // res = `<p data-split="1" type="${uploadType}" data-fileId="${info.fileId}" id="p_${info.fileId}"><a href="${info.fileId}" target="_blank">${info.fileInfo.name}</a></p>`
    } else {
      // res = '<iframe data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '"  cdata_tag="p" id="p_' + fileId + '" scrolling="no"  class="docframe" type="' + uploadType + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="100%"  min-height="500px"></iframe>'

      res = `<iframe data-split="1" type="${uploadType}" data-fileId="${info.fileId}" id="p_${info.fileId}" scrolling="no"  class="docframe" mode="edit" src="${url}" nextId="${info.fileId}" data="${encodeInfo}" frameborder="0" width="100%"  min-height="500px"></iframe>`
      // res = `<p data-split="1" type="${uploadType}" data-fileId="${info.fileId}" id="p_${info.fileId}"><iframe scrolling="no"  class="docframe" type="' + uploadType + '" mode="edit" src="${url}" nextId="${info.fileId}" data="${encodeInfo}" frameborder="0" width="100%"  min-height="500px"></iframe></p>`
    }
    // // console.log(res, "7777777777");
    return res
  }
  getHtml = () => {
    ue.sync();
    // // console.log(ue.body.lastChild.innerHTML == "<br>")

    // console.log(ue.body)
    // console.log(ue.body.childNodes)
    // console.log(ue.body.children)

    let children = ue.body.childNodes;
    // var rx = /<body[^>]*>([\s\S]+?)<\/body>/i
    // var m = rx.exec(ue.body);
    // if (m) m = m[1];
    // console.log(m)
    return children;
    // let text = ue.getContent()
    // return text;
  }

  checkEmpty = () => {
    return ue.getContent().length == 0;
  }
  render() {
    console.log("编译器的 渲染 ")
    let { lineheightVisible, updateVisible, uploadTypeInfo, uploadType,
       fontSizeList, fontFamilyList, fontSize, fontFamily, selTitle, delModalVisible, delModalDate } = this.state;
    let { color, isShowColorTip, isStrong, isItalics } = this.state;

    const catalogId = this.props.resResource?.catalogId
    const isLoading = undefined === catalogId

    return (
  // </Spin>

      <div className={styles.wrapper} onClick={() => {
        this.setState({
          isShowColorTip: false
        });
      }}>

        {/*加载的loading动画*/}
        <div className={styles.loadingBox} hidden={!isLoading}>
          <Spin className={styles.spin}
                tip={'加载中...'}
                size={'large'}
                spinning={isLoading}
          >
            <div className={styles.loadingContent}></div>
          </Spin>
        </div>

        <div className={styles.top}>
          <div className={styles.item} onClick={() => {
            ue.ready(function () {
              ue.execCommand('undo')
            })
          }}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_undo_active@2x.png")} alt="" />
            <p className={styles.iconText}>撤销</p>
          </div>
          <div className={styles.item} onClick={() => {
            ue.ready(function () {
              ue.execCommand('redo')
            })
          }}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_redo_active@2x.png")} alt="" />
            <p className={styles.iconText}>重做</p>
          </div>
          <div className={styles.line}></div>
          <div className={styles.item} onClick={this.insertTeachingLinkToEditor}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_teachinglink_active@2x.png")} alt="" />
            <p className={styles.iconText}>教学环节</p>
          </div>
          <div className={styles.item} onClick={this.upLoad.bind(this, 'courseware')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_courseware_active@2x.png")} alt="" />
            <p className={styles.iconText}>课件</p>
          </div>
          <div className={styles.item} onClick={this.upLoad.bind(this, 'picture')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_freeimages_active@2x.png")} alt="" />
            <p className={styles.iconText}>图片</p>
          </div>

          <div className={styles.item} onClick={this.upLoad.bind(this, 'docs')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_freefiles_active@2x.png")} alt="" />
            <p className={styles.iconText}>文档</p>
          </div>
          <div className={styles.item} onClick={this.upLoad.bind(this, 'video')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_freeinsertvideo_active@2x.png")} alt="" />
            <p className={styles.iconText}>视频</p>
          </div>
          <div className={styles.item} onClick={this.upLoad.bind(this, 'audio')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_audio_active@2x.png")} alt="" />
            <p className={styles.iconText}>音频</p>
          </div>
          <div className={styles.item} onClick={this.upLoad.bind(this, 'tests')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_question_active@2x(1).png")} alt="" />
            <p className={styles.iconText}>测试</p>
          </div>
          <div className={styles.item} onClick={this.upLoad.bind(this, 'link')}
          // onClick={() => {
          //   ue.ready(function () {
          //     ue.execCommand('callopendialog', 'link')
          //   })}}
          >
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_insertlink_active@2x.png")} alt="" />
            <p className={styles.iconText}>链接</p>

          </div>
          <div className={styles.item} onClick={this.upLoad.bind(this, 'file')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_insertattachment_active@2x.png")} alt="" />
            <p className={styles.iconText}>附件</p>
          </div>
          <div className={styles.line}></div>
          <div className={styles.titleContainer}>
            <div className={`${styles.titleContainerItem} ${selTitle == 'h1' && styles.active}`} onClick={this.changeTitle.bind(this, 'paragraph', 'h1')}>
              <p className={styles.titleContainerItemOneT}>标题</p>
              <p className={styles.titleContainerItemOneB}>一级标题</p>
            </div>
            <div className={`${styles.titleContainerItem} ${selTitle == 'h3' && styles.active}`} onClick={this.changeTitle.bind(this, 'paragraph', 'h3')}>
              <p className={styles.titleContainerItemTwoT}>标题</p>
              <p className={styles.titleContainerItemTwoB}>二级标题</p>
            </div>
          </div>
          <div className={styles.fontContainer}>
            <p style={{ marginBottom: '0.04rem' }}>
              <Select defaultValue={fontFamily} style={{ width: '100%', height: '100%' }} onChange={this.handleChange.bind(this, 'fontfamily')}
                suffixIcon={
                  <img className={styles.fontContainerImg} src={imgUrlForZhongZhi("selectDown.png")} alt="" />
                }
                options={fontFamilyList.map(item => ({ label: item.val, value: item.name }))}
              />
            </p>
            <p>
              <Select defaultValue={fontSize} style={{ width: '100%', height: '100%' }} onChange={this.handleChange.bind(this, 'fontsize')}
                suffixIcon={
                  <img className={styles.fontContainerImg} src={imgUrlForZhongZhi("selectDown.png")} alt="" />
                }
                options={fontSizeList.map(item => ({ value: item + 'px', label: item }))}
              >
              </Select>
            </p>
          </div>
          <div className={styles.line}></div>

          <div className={`${styles.item} ${isStrong ? styles.active : ''}`} onClick={() => {
            this.setState({
              isStrong: !isStrong
            }, () => {
              if (this.state.isStrong) {
                ue.ready(function () {
                  ue.execCommand('bold')
                })
              }
            });
          }}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_bold_active@2x.png")} alt="" />
            <p className={styles.iconText}>加粗</p>
          </div>

          <div className={`${styles.item} ${isItalics ? styles.active : ''}`} onClick={() => {
            this.setState({
              isItalics: !isItalics
            }, () => {
              if (this.state.isItalics) {
                ue.ready(function () {
                  ue.execCommand('italic')
                })
              }
            });
          }}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_italic_active@2x.png")} alt="" />
            <p className={styles.iconText}>斜体</p>
          </div>
          <div className={styles.item}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_underline_active@2x.png")} alt="" />
            <Tooltip overlayClassName={"huaxian-dropdown"} overlayInnerStyle={{
              backgroundColor: '#ffffff',
              paddingTop: 12,
              paddingBottom: 12,
              width: 60
            }} placement="bottom" title={
              <Row gutter={[8, 16]} justify="center" align="middle">
                <Col span={24} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'underline', '')}>

                  <div className={styles['icon_label']}>下划线</div>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'strikethrough', '')}>

                  <div className={styles['icon_label']}>删除线</div>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'overline', '')}>

                  <div className={styles['icon_label']}>上划线</div>
                </Col>
              </Row>
            } trigger="click">
              <p className={styles.iconText}>
                <span>划线</span>
                <img className={styles.selectDown} src={imgUrlForZhongZhi("selectDown.png")} alt="" />
              </p>
            </Tooltip>
          </div>

          <div className={styles.item} onClick={(e) => {
            e && e.stopPropagation();
          }}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_forecolor_active@2x.png")} alt="" />
            <Tooltip overlayClassName="itemForColor" placement="bottom" visible={isShowColorTip} onClick={() => {
              this.setState({
                isShowColorTip: true
              });
            }} title={
              <CompactPicker color={color} onChange={this.colorChange} onClick={(e) => {
                e && e.stopPropagation();
              }} />
            }>
              <p className={styles.iconText} onClick={(e) => {
                e && e.stopPropagation();
                this.setState({
                  isShowColorTip: true
                });
              }}>
                <span>颜色</span>
                <img className={styles.selectDown} src={imgUrlForZhongZhi("selectDown.png")} alt="" />
              </p>
            </Tooltip>
          </div>
          <div className={styles.item} >
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_menuformat_active@2x.png")} alt="" />
            <Tooltip overlayClassName={'format-dropdown'} overlayInnerStyle={{
              backgroundColor: '#ffffff',
              paddingTop: 12,
              paddingBottom: 12,
              width: 120
            }} trigger="click" placement="bottom" title={
              <>
                <Row gutter={[8, 16]} justify="center" align="middle">
                  <Col span={12} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'backcolor', '#eeeeee')}>
                    <div className={styles['icon_label']}>底色</div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'backcolor', 'transparent')}>
                    <div className={styles['icon_label']}>收起</div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'addindent', 1)}>
                    <div className={styles['icon_label']}>加缩进</div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'addindent', -1)}>

                    <div className={styles['icon_label']}>减缩进</div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'justify', 'left')}>

                    <div className={styles['icon_label']}>左对齐</div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'justify', 'right')}>

                    <div className={styles['icon_label']}>右对齐</div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'center' }} onClick={this.changeUeditorCommand.bind(this, 'justify', 'center')}>

                    <div className={styles['icon_label']}>居中</div>
                  </Col>
                  <Col span={12}></Col>
                </Row>
              </>//.execCommand( 'justify', 'center' );
            } >
              <p className={styles.iconText} >
                <span>格式</span>
                <img className={styles.selectDown} src={imgUrlForZhongZhi("selectDown.png")} alt="" />
              </p>
              <div style={{ position: 'fixed' }} id="formatTooltipText"></div>

            </Tooltip>
          </div>
          <div className={styles.item} onClick={() => {
            ue.ready(function () {
              ue.execCommand("removeFormat")
            })
          }}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_removeformat_active@2x.png")} alt="" />
            <p className={styles.iconText}>清除格式</p>
          </div>
          <div className={styles.item} onClick={this.changeUeditorCommand.bind(this, 'formatmatch')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_formatmatch_active@2x.png")} alt="" />
            <p className={styles.iconText}>格式刷</p>
          </div>
          <div className={styles.item}>
            <Tooltip visible={lineheightVisible} overlay={<div style={{ width: 80 }}>
              {
                [1, 1.5, 1.75, 2, 3, 4, 5].map(item => (<div key={"linheight_" + item} onClick={() => {
                  let that = this;
                  ue.ready(function () {
                    ue.execCommand('lineheight', item)
                    that.setState({ lineheightVisible: false })
                  })
                }}>{item}</div>))
              }

            </div>} placement="bottomRight">
              <div onClick={() => {
                this.setState({ lineheightVisible: true })
              }}>
                <img className={styles.icon} src={imgUrlForZhongZhi("Icon_lineheight_active@2x.png")} alt="" />

                <p className={styles.iconText}>
                  <span>行距</span>
                  <img className={styles.selectDown} src={imgUrlForZhongZhi("selectDown.png")} alt="" />
                </p>
              </div>
            </Tooltip>
          </div>
          <div className={styles.item} onClick={this.changeUeditorCommand.bind(this, 'insertunorderedlist')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_insertunorderedlist_active@2x.png")} alt="" />
            <p id="unorderText" className={styles.iconText}>目录</p>
            {/* <Tooltip visible={true} title={<Row>
              {
                Object.keys(insertunorderedList || {}).map(item => (<Col span={24}><span></span><span></span></Col>))
              }

            </Row>} getPopupContainer={() => document.getElementById('unorderText')} placement="bottom">

            </Tooltip> */}
          </div>
          <div className={styles.item} onClick={this.changeUeditorCommand.bind(this, 'insertorderedlist')}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_insertorderedlist_active@2x.png")} alt="" />
            <p className={styles.iconText}>编号</p>
          </div>
          <div className={styles.line}></div>
          <div className={styles.item} onClick={() => {
            ue.ready(function () {
              ue.execCommand('callkityformula')
            })
          }}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_kityformula_active@2x.png")} alt="" />

            <p className={styles.iconText}>
              <span>公式</span>
              <img className={styles.selectDown} src={imgUrlForZhongZhi("selectDown.png")} alt="" />
            </p>
          </div>
          <div className={styles.item} onClick={() => {
            ue.ready(function () {
              ue.execCommand('insertspechars')
            })
          }}>
            <img className={styles.icon} src={imgUrlForZhongZhi("Icon_spechars_active@2x.png")} alt="" />

            <p className={styles.iconText}>
              <span>符号</span>
              <img className={styles.selectDown} src={imgUrlForZhongZhi("selectDown.png")} alt="" />
            </p>
          </div>
        </div>


        <div className={styles.bottom}>
          <div className={styles.bottomContainer}>
            <div id="learningCaseEditor" name="learningCaseEditor" style={{ height: '100%' }}></div>
          </div>
        </div>

        {updateVisible ? <Modal
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px' }}>
              <b style={{ color: "#17181A" }}>{uploadTypeInfo.zh == '测试' ? uploadTypeInfo.zh : '插入' + uploadTypeInfo.zh}</b>
              {/* <b style={{ color: "#17181A" }}>{`插入${uploadTypeInfo.zh}`}</b> */}
              <CloseOutlined style={{ lineHeight: '24px', color: '#69707F', cursor: 'poninter' }}
                onClick={this.closeModal} />
            </div>
          }
          visible maskClosable={false} closable={false} width={556} onCancel={() => {
            this.setState({
              updateVisible: false
            })
          }}
          onOk={() => { }}
          bodyStyle={{ padding: '20px' }}
          footer={null}
          centered
        >
          {
            (uploadType !== "tests" && uploadType !== "link") && <UploadModal ref={this.formRef} uploadTypeInfo={uploadTypeInfo} uploadType={uploadType} uploadResult={this.uploadResult} closeModal={this.closeModal} />
          }
          {
            (uploadType === "tests" || uploadType === "link") && <TestsOrLinkModal catalogId={catalogId}  uploadType={uploadType} transLink={this.transLink} transTest={this.transTest} closeModal={this.closeModal} />
          }
        </Modal> : null}
        {delModalVisible ? <Modal
          className="class_modeld"
          visible
          onCancel={() => {
            this.setState({ delModalVisible: false })
          }}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className='class_modeld_title'>
              <span>删除资源</span>
            </div>
            <div className='class_modeld_center'>
              请确认是否删除该资源？
            </div>
            <div className='class_modeld_button'>
              <button className='class_modeld_buttonno' onClick={() => {
                this.setState({ delModalVisible: false })
              }}>取消</button>
              <button onClick={() => {
                this.setState({
                  delModalVisible: false
                }, () => {
                  // console.log("删除")
                  this.delFile(delModalDate);
                })
              }} className='class_modeld_buttonyes'>确定</button>
            </div>
          </div>
        </Modal> : null}

        {/* {
          isShowPreviewOfTestQuestions && <div className='pages_catalog_editor_test_bg'>
            <PreviewOfTestQuestions onClose={this.closeTestsModal} examId={examId} />
          </div>
        }
        {
          isShowEditOfTestQuestions && <div className='pages_catalog_editor_test_bg'>
            <EditOfTestQuestions onClose={this.closeTestsModal} examId={examId} examName={examName} courseId={this.props.courseId} />
          </div>
        } */}

      </div>
    );
  }
}

export default RichMedia;
