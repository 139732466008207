import React, {Component} from 'react';
import {connect} from 'react-redux';
import {DatePicker, Form, Input, message, Modal, Popover, Select, Tooltip, Upload} from 'antd';
import {imgUrlForZhongZhi} from '@/utils';
import {updateCrumbsData, updateUser} from '@/store/action';
import Paginations from '@@/Paginations';
import _ from 'lodash';
import md5 from 'md5';
import {
  alreadJoin,
  applyCourseList,
  applyExampleCourse,
  copyCourse,
  courseCover,
  delCourse,
  endCourse,
  getCourseListByTeacher,
  getNotReadApplyCount,
  getShowCourseListByTeacher,
  query2CollegeMajors,
  saveCourse,
  subjectList,
  teacherLogin,
  uploadFile
} from '@/api';
import Crumbs from '@@/Crumbs';
import Personal from './components/Personal/index';
import axios from '../../axios';
import {move} from '@/utils/util';
import './styles.less';
import ImgCrop from "antd-img-crop";

const FormItem = Form.Item;
const {Option} = Select;

const nameRules = {required: true, message: "请输入课程名称"};
const passwordRules = {required: true, message: "请选择课程类型"};

class MyCourse extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      RequiredFields: {
        courseName: '',
        courseType: '',
        subjectId: '',
        collegeId: '',//所属院系
        majorId: '',//所属专业
      },
      titleName: 1,
      courseName: 1,
      screenPitch: 1,
      paginationList: {
        current: 1,
        pageSize: 10,
        pages: 0,
        total: 18,
      },
      paginationApplyList: {
        current1: 1,
        pageSize1: 10,
        pages1: 0,
        total1: 18,
      },
      paginationSchoolExampleList: {
        current: 1,
        pageSize: 12,
        pages: 10,
        total: 18,
      },
      paginationPlatformExampleList: {
        current: 1,
        pageSize: 12,
        pages: 10,
        total: 18,
      },
      teacherId: 1,
      applyCourseNum: 0,
      page: 0,
      defaultcover: 999999999,//默认图
      covers: [],//默认封面
      coverFileId: '',
      isModal: false,
      isClassModal: false,
      isaddModal: false,
      classContent: '',
      applyType: [],
      inputNextpage: true,
      nextpage: true,
      subjectListData: [],//学科列表
      provinceData: [],
      cities: [],
      schoolLessonData: [],
      platformLessonData: [],
      platformJoin: false,
      schoolJoin: false,
      courseList: [
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
        {photo: 'icon-add.png', name: 'gggggggggg'},
      ],
      applyTitle: [
        '序号', '课程名称', '课程类型', '所属学科/专业(方向)', '提交时间', '示范课程库', '状态', '操作'
      ],
      applyList: [
        {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈', f: '哈哈哈', g: '哈哈哈', h: '哈哈哈'},
        {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈', f: '哈哈哈', g: '哈哈哈', h: '哈哈哈'},
        {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈', f: '哈哈哈', g: '哈哈哈', h: '哈哈哈'},
        {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈', f: '哈哈哈', g: '哈哈哈', h: '哈哈哈'},
        {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈', f: '哈哈哈', g: '哈哈哈', h: '哈哈哈'},
      ],
      delCourseId: '',
      loginPassword: '',
      isCopy: false,
      isEndCourseModal: false,
    };
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      uid: this.props.user.uid,
      schoolId: this.props.user.schoolId,
      teacherId: this.props.user.uid,
    }, () => {
      this.getNotReadApplyCount().then();
      this.getCourseListByTeacher().then();
      this.subjectList().then();
      this.query2CollegeMajors(this.props.user.schoolId).then();
      this.getShowCourseListByTeacher(1, 12, 'school').then();
      this.getShowCourseListByTeacher(1, 12, 'platform').then();
      this.setTitle(1);
    });
  }

  setTitle = (value) => {
    let name = '';
    value == 1 ? name = "我的课程" : name = '我的示范课';
    if (value == 1) {

    }
    this.props.updateCrumbsData([
      {
        name: '教师空间',
        path: ''
      },
      {
        name: '课程管理',
        path: ''
      },
      {
        name: name,
        path: ''
      },
    ]);
  };
  //个人 课程中心
  cutTitle = (title) => {
    if (title == 1) {
      this.setTitle(this.state.courseName);
      this.setState({
        titleName: 1
      });
    } else {
      this.setState({
        titleName: 2
      });
    }
  };

  //我 的课程切换 示范课
  cutSetCourse = (title) => {
    if (title == 1) {
      this.setTitle(1);
      this.setState({
        courseName: 1
      });
    } else {
      this.setTitle(2);
      this.setState({
        courseName: 2
      });
    }
  };
  // 学科列表
  subjectList = async () => {
    const param = {};
    const res = await axios.get(subjectList, {params: param});
    console.log(res);
    this.setState({
      subjectListData: res
    });
  };
  // 查询课程默认封面
  courseCover = async () => {
    const param = {};
    const res = await axios.get(courseCover, {params: param});
    console.log(res);
    this.setState({
      covers: res.covers,
      defaultcover: 0,
      coverFileId: res.covers[0]?.fileId,
      fileUrl: res.covers[0]?.fileUrl
    }, () => {
      if (document.getElementById('answerItem')) {
        move(this.spread, 'answerItem');
      }

    });
  };
  // 查询课程列表
  getCourseListByTeacher = async (pageNum = 1, pageSize = 12, displayOrderType = 1) => {
    /**
     * displayOrderType 排序类型：默认1创建时间2最新打开3
     */
    const {teacherId} = this.state;
    console.log(teacherId);
    const param = {
      displayOrderType,
      pageNum,
      pageSize,
      teacherId
      // bufferTime:'11',
    };
    console.log(param);
    const res = await axios.post(getCourseListByTeacher, param);

    const {current, size, total, records, pages} = res;
    console.log(res);
    const paginationList = {
      current,
      pageSize: size,
      pages,
      total,
    };
    console.log(paginationList);
    this.setState({
      courseList: records,
      paginationList
    });
  };

  // 教师示范课程
  getShowCourseListByTeacher = async (pageNum = 1, pageSize = 12, applyType) => {
    const {teacherId} = this.state;
    const param = {
      pageNum,
      pageSize,
      teacherId,
      applyType
      // bufferTime:'11',
    };
    console.log("param", param);
    const res = await axios.post(getShowCourseListByTeacher, param);
    const {current, size, total, records, pages} = res;
    console.log(records, res);
    let pagination = {
      current: current,
      pageSize: size,
      pages: pages,
      total: total,
    };

    if (applyType == "school") {
      let schoolLessonData = records;

      console.log("paginationSchoolExampleList", pagination);
      this.setState({
        schoolLessonData,
        paginationSchoolExampleList: pagination
      });
    } else if (applyType == "platform") {
      let platformLessonData = records;
      console.log("paginationPlatformExampleList", pagination);
      this.setState({
        platformLessonData,
        paginationPlatformExampleList: pagination
      });
    }
  };
  // 未读数量
  getNotReadApplyCount = async () => {
    // console.log(param);
    const res = await axios.get(getNotReadApplyCount);
    console.log(res);
    this.setState({
      applyCourseNum: res.result == 0 ? 0 : res,
    }, () => {
      console.log(this.state.applyCourseNum);
    });
  };
  // 我的申请记录
  applyCourseList = async (pageNum = 1, pageSize = 10) => {
    const param = {
      pageNum,
      pageSize,
      uid: this.state.uid
      // bufferTime:'11',
    };
    // console.log(param);
    const res = await axios.post(applyCourseList, param);
    console.log(res);
    const {current, size, total, records, pages} = res;
    const paginationApplyList = {
      current1: current,
      pageSize1: size,
      pages1: pages,
      total1: total,
    };
    this.getNotReadApplyCount();
    this.setState({
      applyList: records,
      // applyCourseNum: records.length,
      paginationApplyList
    });
  };

  // 申请示范课
  applyExampleCourse = async () => {
    this.setState({
      isClassModal: !this.state.isClassModal,
    });
    const {schoolId} = this.state;
    const {id} = this.state?.classContent;
    const param = {
      applyType: this.state.applyType?.length == 2 ? 'all' : this.state.applyType[0],
      courseId: id,
      schoolId
    };
    console.log(param);
    const res = await axios.post(applyExampleCourse, param);
    await this.getNotReadApplyCount();
    console.log(res);
  };
  confirmDelCourse = (delCourseId) => {
    this.setState({
      delCourseId
    }, () => {
      this.handleDelCourse();
    });
  };
  handleDelCourse = () => {
    let {isDelCourseModal} = this.state;
    this.setState({
      isDelCourseModal: !isDelCourseModal,
      loginPassword: ''
    });
  };
  setPassword = (e) => {
    this.setState({
      loginPassword: e.target.value
    });
  };
  checkPassword = async () => {
    let {loginPassword} = this.state;
    let {user} = this.props;
    let resLogin = await axios.post(teacherLogin, {
      "username": user?.mobile,
      "password": md5(loginPassword),
      "schoolId": user?.schoolId,
      "hasCheck": false,   // false不进行学校校验，本地登录用的
      // "hasCheck": window.location.origin?.includes('localhost') ? false : true,   // false不进行学校校验，本地登录用的
      // "platform":0
    });
    if (resLogin) {
      this.setState({loginPassword: ''});
      this.delCourseOk();
    }
  };
  // 删除课程
  delCourseOk = async () => {
    const param = {
      courseId: this.state.delCourseId
    };
    const res = await axios.get(delCourse, {params: param});
    this.handleDelCourse();
    if (res.code == 0) {
      message.success("删除成功");
    }
    this.getCourseListByTeacher(1, 12, this.state.screenPitch);
    console.log(res);
  };
  handleEndCourseChange = (courseId = '') => {
    this.setState({
      isEndCourseModal: !this.state.isEndCourseModal,
      courseId
    });
  };

  // 结课
  endCourse = async () => {
    let {courseId} = this.state;
    const param = {
      courseId
    };
    const res = await axios.get(endCourse, {params: param});
    if (!res) return;
    message.success('结课成功');
    this.getCourseListByTeacher(1, 12, this.state.screenPitch);
    this.handleEndCourseChange();
  };


  // 复制课程
  copyCourse = async (courseId) => {
    const {isCopy} = this.state;
    if (!isCopy) {
      this.setState({isCopy: courseId});
      return;
    }
    const param = {
      courseId
    };
    const res = await axios.get(copyCourse, {params: param});
    this.getCourseListByTeacher(1, 12, this.state.screenPitch);
    console.log(res);
    message.destroy();
    message.success('复制成功，快去编辑吧');
    this.setState({isCopy: false});
  };
  // 教师保存课程信息-
  saveCourse = async (val) => {
    console.log(val);
    const {coverFileId, teacherId, schoolId} = this.state;
    const param = {
      // classId,
      // classType: 2,
      // flag,
      // userIdList,
      hasDel: 0,
      hasExample: 0,
      collegeId: 0,
      displayOrder: 0,
      schoolId,
      coverFileId,
      teacherId
    };
    console.log(param);
    // const res = await axios.post(saveCourse, { ...val, ...param });
    await axios.post(saveCourse, {...param, ...val});
    await this.getCourseListByTeacher();
    // console.log(res);
  };

  // 学校专业级联

  query2CollegeMajors = async (schoolId) => {
    const param = {
      schoolId
    };
    console.log(param);
    const res = await axios.post(query2CollegeMajors, param);
    console.log(res);
    this.setState({
      provinceData: res,
      cities: res[0].majors,
      collegeId: res[0].collegeId,
    }, () => {
      console.log(this.state.collegeId, this.state.majorId, this.state.schoolId);
    });
  };
  // 左右切换
  spread = (param) => {
    const {page, covers} = this.state;

    // 传出值
    // let json = JSON.parse(JSON.stringify(testItem));
    console.log(param, covers);

    if (param == "向左") {
      console.log(page);
      if (page == covers.length - 4) {
        return;
      }
      this.setState({
        page: page + 1
      }, () => {
        // let selectcon = document.getElementsByClassName('EnglishTingxie-yuan')[this.state.page - 1]
        // let noselectcon = document.getElementById('page' + this.state.page)
        // document.getElementById('page' + this.state.page) && document.getElementById('page' + this.state.page).scrollIntoView({ behavior: 'smooth', inline: "center" })
        if (document.getElementById('answerItem')) {
          document.getElementById('answerItem').scrollTo(200 * this.state.page, 0);
        }
      });

    } else if (param == "向右") {
      if (page == 0) {
        return;
      }
      // console.log(page);
      this.setState({
        page: page - 1
      }, () => {
        // let selectcon = conList?.length == 1 ? document.getElementsByClassName('EnglishTingxie-yuan')[this.state.page] : document.getElementsByClassName('EnglishTingxie-yuan')[this.state.page + 1]
        // let noselectcon = document.getElementById('page' + this.state.page)
        // document.getElementById('page' + this.state.page) && document.getElementById('page' + this.state.page).scrollIntoView({ behavior: 'smooth', inline: "center" })
        if (document.getElementById('answerItem')) {
          document.getElementById('answerItem').scrollTo(200 * this.state.page, 0);
        }


      });
    }
  };
  uploadFile = async (file) => {
    console.log(file);

    const formData = new FormData();
    formData.append('file', file.originFileObj);
    console.log(formData);
    const res = await axios.post(uploadFile, {bizType: 3, file: formData, uid: 1});
    console.log(res);
  };

  schoolLesson = (selectapplyType) => {
    console.log(1);
    this.setState(({applyType}) => ({
      applyType: applyType.indexOf(selectapplyType) == -1
        ? [...applyType, selectapplyType]
        : applyType?.filter(item => item != selectapplyType)
    }), () => {
      console.log(this.state.applyType);
    });
  };


  screen = (screenPitch) => {
    this.setState({
      screenPitch
    }, () => {
      this.getCourseListByTeacher(1, 12, this.state.screenPitch);
    });
  };

  goRecord = () => {
    this.setState({
      isModal: !this.state.isModal
    }, () => {
      this.applyCourseList();
      // this.altRows('alternatecolor')
    });
  };

  // 弹窗1
  handleOk = () => {
    this.setState({
      isModal: !this.state.isModal
    });
  };

  //tanchuang
  handleClass = (classContent) => {
    console.log(444);
    this.setState({
      isClassModal: !this.state.isClassModal,
      classContent,
      applyType: []
    });
    if (classContent?.id) {
      this.alreadJoin(classContent?.id);
    }

  };

  // 添加弹窗
  handleAddModal = () => {
    this.courseCover();
    this.setState({
      isaddModal: !this.state.isaddModal,
      courseType: 0,
      RequiredFields: {
        courseName: '',
        courseType: '',
        subjectId: '',
        collegeId: '',//所属院系
        majorId: '',//所属专业
      },
    });
  };
  // 是否申请了示范课
  alreadJoin = (courseId) => {
    if (!courseId) return;
    axios.get(alreadJoin, {
      params: {
        courseId
      }
    }).then(res => {
      if (!res) return;
      let {platformJoin, schoolJoin} = res;
      this.setState({
        platformJoin,
        schoolJoin
      });
    });
  };

  altRows(id) {
    if (document.getElementsByTagName) {

      var table = document.getElementById(id);
      var rows = table.getElementsByTagName("tr");

      for (let i = 0; i < rows.length; i++) {
        if (i % 2 == 0) {
          rows[i].className = "evenrowcolor";
        } else {
          rows[i].className = "oddrowcolor";
        }
      }
    }
  }

  // 分页器 动态获取到页码
  onChangeCurPageOrPageSize = (current, pageSize, type) => {
    console.log(current, pageSize);
    const paginationList = {
      ...this.state.paginationList,
      current,
      pageSize
    };
    console.log(paginationList);
    this.toTop();
    this.setState({paginationList}, () => {
      this.getCourseListByTeacher(current, pageSize, this.state.screenPitch);
    });
  };

  // 分页器申请记录动态获取到页码
  onChangeCurPageOrPageSize1 = (current1, pageSize1) => {
    console.log(current1, pageSize1);
    const paginationApplyList = {
      ...this.state.paginationApplyList,
      current1,
      pageSize1
    };
    console.log(paginationApplyList);
    this.setState({paginationApplyList}, () => {
      this.applyCourseList(current1, pageSize1);
    });
  };

  // 分页器 公开课
  onChangePubCourseCurPageOrSize = (type, current, pageSize) => {
    console.log(current, pageSize);
    let typePagination = {
      school: 'paginationSchoolExampleList',
      platform: 'paginationPlatformExampleList',
    };
    let listName = typePagination[type];
    const pagination = {
      ...this.state[listName],
      current,
      pageSize
    };
    this.setState({[listName]: pagination}, () => {
      console.log("listName", listName);
      console.log(this.state[listName]);
      this.getShowCourseListByTeacher(current, pageSize, type);
    });
  };

  // 下一页
  nextPage = (nextpage) => {
    const {RequiredFields} = this.state;
    if (RequiredFields.courseName == '') {
      message.error('请输入课程名称');
      return;
    }
    if (RequiredFields.courseType == '') {
      message.error('请选择课程类型');
      return;
    }
    if (RequiredFields.courseType == 1) {
      if (RequiredFields.subjectId == '') {
        message.error('请选择所属学科');
        return;
      }
    }
    if (RequiredFields.courseType == 2) {
      if (RequiredFields.collegeId == '') {
        message.error('请选择所属院系');
        return;
      }
      if (RequiredFields.majorId == '') {
        message.error('请选择所属专业');
        return;
      }
    }

    if (!nextpage) {
      this.setState({
        inputNextpage: false,
        nextpage: true,
      });
      // return
    } else {
      this.setState({
        inputNextpage: true,
        nextpage: false,
      });
    }
  };
  // 上一页
  outPage = () => {
    console.log(this.state.nextpage);
    this.setState({
      nextpage: true,
      inputNextpage: true,
      isaddModal: this.state.nextpage ? false : true,
    });
  };
  onReset = () => {
    this.formRef.current.resetFields();
  };
  onFinish = (val) => {
    console.log("onfinish", val);
    this.saveCourse(val);
    this.handleAddModal();

  };
  selClass = () => {
    this.props.history.push('/courseCenter');
  };
  onFinishFailed = (val) => {
    console.log("onfinishfailed", val);
  };
  // 选择课程类型
  selectType = value => {
    console.log(value);
    this.setState({
      courseType: value
    });
  };
  // 选择默认封面
  defaultCover = (index, fileId, fileUrl) => {
    this.setState({
      defaultcover: index,
      coverFileId: fileId,
      fileUrl
    });
  };

  handleProvinceChange = (value, option) => {
    const {provinceData} = this.state;
    console.log(provinceData, value, option);
    let citiesindex;
    for (let index = 0; index < provinceData.length; index++) {
      const element = provinceData[index].collegeId;
      if (value == element) {
        citiesindex = index;
      }
    }
    // provinceData:res,
    // cities:res[0].majors,
    // secondCity:res[0].majors[0].majorName,
    if (!provinceData[citiesindex].majors) {
      return;
    }
    this.setState({
      cities: provinceData[citiesindex].majors,
    }, () => {
    });
  };
  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file?.type == 'image/gif';
    if (!isJpgOrPng) message.error('你只能上传JPG/PNG/GIF格式文件!');

    const isLt5M = file.size / 1024 / 1024 < 4;
    if (!isLt5M) message.error('图片大小不能超过4MB!');

    return isJpgOrPng && isLt5M;
  };

  handleChange = async info => {
    console.log(info);
    if (info.file.status === "done") {
      const cdnUrl = _.get(info, "file.response.result.cdnUrl");
      const fileId = _.get(info, "file.response.result.fileId");
      if (cdnUrl) {

        if (info.fileList.length === 2) {
          info.fileList = info.fileList.slice(-1);
        }
        message.success("上传成功");
        this.setState({
          defaultcover: 999999999,//默认图
          coverFileId: fileId,
          fileUrl: cdnUrl,
          fileList: info.fileList
        });
        // this.updateAvatar(fileId);
        // this.props.updateUser({ ...this.props.user, avatarUrl: cdnUrl });
        return false;
      }
    }

    if (info.file.status === "error") {
      message.error("上传失败,请重新上传");
    }
    // info.fileList = info.fileList.slice(-1);
    // console.log(info);
    // let file = info.fileList[0]
    // let fileMaxSize = 1024 * 1024 * 4
    // if ((file?.type == 'image/png' || file?.type == 'image/jpeg' || file?.type == 'image/gif') && file?.size <= fileMaxSize) {
    //   this.setState({
    //     fileList: info.fileList
    //   })
    //   // this.uploadFile(file)
    // } else {
    //   console.log('文件格式错误或大小超过4M，请重新上传');
    //   this.setState({
    //     fileList: []
    //   })
    //   message.error('文件格式错误或大小超过4M，请重新上传');
    // }
  };
  toSpaceDetailPage = (id, courseType, isJiaoXueKongJianPage, item, courseSource, courseShare) => {
    this.props.updateUser({...this.props.user, majorId: item?.majorId, subjectId: item?.subjectId});
    // window.open(path + `#/teachersspace/mycourse?id=${id}&courseType=${courseType}&isJiaoXueKongJianPage=${isJiaoXueKongJianPage}&isEdit=true&courseSource=${courseSource}&courseShare=${courseShare}`)
    this.props.history.push(`/teachersspace/mycourse?id=${id}&courseType=${courseType}&isJiaoXueKongJianPage=${isJiaoXueKongJianPage}&isEdit=true&courseSource=${courseSource}&courseShare=${courseShare}`);
  };
  toSpaceDemonDetailPage = (id, courseType, isJiaoXueKongJianPage, item) => {
    // console.log(window.location);
    this.props.updateUser({...this.props.user, majorId: item?.majorId, subjectId: item?.subjectId});
    // window.open(path + `#/demonspace/mycourse?id=${id}&courseType=${courseType}&isJiaoXueKongJianPage=${isJiaoXueKongJianPage}&isEdit=true&isDemonstrationCourse=true&applyType=${item.applyType=='platform'?'1':'2'}`)
    this.props.history.push(`/demonspace/mycourse?id=${id}&courseType=${courseType}&isJiaoXueKongJianPage=${isJiaoXueKongJianPage}&isEdit=true&isDemonstrationCourse=true&applyType=${item.applyType == 'platform' ? '1' : '2'}`);
  };
  toTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };
  getTagImage = (item) => {
    if (item?.hasDel === 1) {
      return <img className="tag-other" src={imgUrlForZhongZhi('tag_has_del.png')} alt=""/>;
    }
    switch (item.state) {
      // 上架未发布
      case 0:
        return <img className="tag-other" src={imgUrlForZhongZhi('tag_not_publish.png')} alt=""/>;
      // 上架已发布
      case 1:
        switch (item.isTeaching > 0) {
          case true:
            return <img className="tag-soling" src={imgUrlForZhongZhi('tag-soling.png')} alt=""/>;
          case false:
            return "";
          default :
            return "";
        }
      default:
        return "";
    }
  };

  render() {
    const {
      isCopy,
      fileUrl,
      titleName,
      courseName,
      courseList,
      screenPitch,
      isModal,
      applyTitle,
      applyList,
      isClassModal,
      applyCourseNum,
      applyType,
      isaddModal,
      nextpage,
      inputNextpage,
      subjectListData,
      courseType,
      provinceData,
      cities,
      schoolLessonData,
      platformLessonData,
      covers,
      defaultcover,
      page,
      paginationSchoolExampleList,
      paginationPlatformExampleList,
      isDelCourseModal,
      loginPassword,
      isEndCourseModal
    } = this.state;
    const {current, pageSize, pages, total} = this.state.paginationList;
    const {current1, pageSize1, pages1, total1} = this.state.paginationApplyList;

    const uploadProps = {
      name: "file",
      action: uploadFile,
      headers: {token: this.props.token},
      data: {
        uid: 3,
        bizType: 4
      },
      beforeUpload: this.beforeUpload,
      onChange: this.handleChange,
      // fileList: fileList   //上传文件列表
      // disabled:true
    };
    return (
      <div className="pages_news_course" id="pages_news_course">
        <div className="news_course">
          <div className="course_go_back">
            {/* <i><img src={imgUrl('news_go_back.png')} alt=""/></i> */}
            {/* <span>课程/</span>
            <span>详情</span> */}
            <Crumbs/>
          </div>

          <div className="course_main">
            <div className="main_left">
              <p className={titleName == 1 ? 'pselect' : ''} onClick={() => this.cutTitle(1)}>
                {
                  titleName == 1 ? <img src={imgUrlForZhongZhi('course-management.png')} alt=""/> :
                    <img src={imgUrlForZhongZhi('class_icon_manage_def.png')} alt=""/>
                }

                课程管理
              </p>
              <p className={titleName == 2 ? 'pselect' : ''} onClick={() => this.cutTitle(2)}>
                {
                  titleName == 1 ? <img src={imgUrlForZhongZhi('personage-center.png')} alt=""/> :
                    <img src={imgUrlForZhongZhi('class_icon_mine_pre2.png')} alt=""/>
                }

                个人中心
              </p>
            </div>
            <div className="main_right">
              {
                titleName == 1 ?
                  <div className="right_management">
                    <div className="main_right_top">
                      <div className="main_right_top_mycourse">
                        <span className={courseName == 1 ? 'spanselect' : ''}
                              onClick={() => this.cutSetCourse(1)}>我的课程</span>
                        <span className={courseName == 2 ? 'spanselect' : ''}
                              onClick={() => this.cutSetCourse(2)}>我的示范课</span>
                      </div>
                      <div className="main_right_top_mysetcourse">
                        <span className="newcourse" onClick={this.handleAddModal}>
                          <img src={imgUrlForZhongZhi('icon-add.png')} alt=""/>
                          新建课程
                        </span>
                        <span className="record" onClick={this.goRecord}>申请记录</span>
                        <Modal
                          className="checkmodeldictation"
                          visible={isModal}
                          onCancel={this.handleOk}
                          footer={null}
                          destroyOnClose
                        >
                          <div className="checkmodeldictation-con">
                            <h1>申请记录</h1>
                            <div className="con-list">
                              <table class="altrowstable" id="alternatecolor">
                                <tr>
                                  {applyTitle && applyTitle.map((val, index) => {
                                    return (
                                      <th key={index}>{val}</th>
                                    );
                                  })}
                                </tr>
                                {
                                  applyList && applyList.map((val, index) => {
                                    return (
                                      <tr key={val?.id}>
                                        <td>{index + 1}</td>
                                        <td>{val?.courseName}</td>
                                        <td>{val?.courseType == 1 ? '公共课' : '专业课'}</td>
                                        <td>{val?.courseType == 1 ? val?.subjectName : val?.majorName}</td>
                                        <td>{val?.submitTime}</td>
                                        <td>{val?.applyType == 'school' ? '学校' : '平台'}</td>
                                        <td>
                                          <div className="statewrap">
                                            <div
                                              className={val?.applyState == 2 ? 'nopass state' : val?.applyState == 1 ? 'pass state' : 'state'}></div>
                                            {val?.applyState == 2 ? '已驳回' : val?.applyState == 1 ? '已通过' : '审核中'}
                                          </div>
                                        </td>
                                        <td>
                                          <Tooltip key={val?.id} placement="topRight" title={val?.applyInfo} width="80px">
                                            {val?.applyState == 2 ? '驳回原因' : '--'}
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    );
                                  })
                                }
                                {
                                  applyList.length === 0 ? <div className="sqNull">
                                    <img src={imgUrlForZhongZhi("gonggaoNull.png")} alt=""/>
                                    <h6>当前暂无申请内容</h6>
                                  </div> : ''
                                }
                              </table>
                            </div>
                            <div className="news_footer" id="news_footer">
                              <Paginations
                                current={current1}
                                pageSize={pageSize1}
                                pages={pages1}
                                total={total1}
                                handleChange={this.onChangeCurPageOrPageSize1}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
                              />
                            </div>
                          </div>
                        </Modal>
                        {applyCourseNum == 0 ? '' :
                          <div className="dot">{applyCourseNum}</div>
                        }
                      </div>
                    </div>
                    {
                      courseName == 1 ?
                        courseList?.length == 0 ?
                          <div className="main_right_center_courseno">
                            <img src={imgUrlForZhongZhi('courseNull.png')} alt=""/>
                            <p>你还没有课程，快去<span onClick={this.selClass}>选择课程</span>或者<span
                              onClick={this.handleAddModal}>新建课程</span>吧</p>
                          </div>
                          :
                          <div className="main_right_center_course">
                            <div className="main_right_center">
                              <div className="center-screen">
                                <span className={screenPitch == 1 ? 'screenselect' : ''}
                                      onClick={() => this.screen(1)}>默认排序</span>
                                <span className={screenPitch == 2 ? 'screenselect' : ''}
                                      onClick={() => this.screen(2)}>最新创建</span>
                                <span className={screenPitch == 3 ? 'screenselect' : ''}
                                      onClick={() => this.screen(3)}>最新打开</span>
                              </div>
                              <div className="center-con">
                                {courseList.map((val) => {
                                  return (
                                    <Tooltip title={val?.state === 0 ? "在课程详情页完成学习设置后，该课程将自动发布" : ""}>
                                      <div key={val?.id} className="course-every">
                                        <div className="course-every-img">
                                          <img className="poster-img" src={val?.coverFileId} alt=""/>
                                          {this.getTagImage(val)}
                                          {/*{val?.isTeaching > 0 ?*/}
                                          {/*  <img className="zaishou" src={imgUrlForZhongZhi('zaishou.png')} alt=""/>*/}
                                          {/*  :*/}
                                          {/*  ''*/}
                                          {/*}*/}

                                          <img className="copy" onClick={() => this.copyCourse(val?.id)}
                                               src={imgUrlForZhongZhi('copy.png')} alt=""/>
                                          <Popover placement="bottom"
                                            //  trigger="click"
                                            // getPopupContainer={()=>document.querySelector('#root')}
                                                   content={
                                                     <div className="popcon">
                                                       {val?.isTeaching > 0 ?
                                                         <p onClick={() => this.handleEndCourseChange(val?.id)}>结课</p> :
                                                         <p onClick={() => this.confirmDelCourse(val?.id)}>删除</p>}

                                                       <p onClick={() => this.handleClass(val)}>申请示范课</p>
                                                     </div>
                                                   }>
                                            <img className="dian" src={imgUrlForZhongZhi('dian.png')} alt=""/>
                                          </Popover>
                                          <div className="shade"
                                               onClick={() => this.toSpaceDetailPage(val?.id, val?.courseType, val?.courseName, val, val?.courseSource, val?.permissionData.courseShare)}></div>
                                        </div>
                                        <div className="course-every-name">{val?.courseName}</div>
                                      </div>
                                    </Tooltip>
                                  );
                                })}
                              </div>

                            </div>
                            <div className="news_footer" id="news_footer">
                              <Paginations
                                current={current}
                                pageSize={pageSize}
                                pages={pages}
                                total={total}
                                handleChange={this.onChangeCurPageOrPageSize}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
                              />
                            </div>
                          </div>
                        :
                        schoolLessonData?.length == 0 && platformLessonData.length == 0 ?
                          <div className="main_right_center_courseno">
                            <img src={imgUrlForZhongZhi('courseNull.png')} alt=""/>
                            <p>暂无示范课申请</p>
                          </div>
                          :
                          <div className="main_right_center_setcourse">
                            <div className="setcourse_school">
                              <div className="school_title">
                                <h1>学校示范课</h1>
                                <p>通过审核的示范课程会自动生成新课程，原课程可继续使用</p>
                              </div>
                              <div className="school_con">
                                {schoolLessonData && schoolLessonData.map((val) => {
                                  return (
                                    <div className="course-every">
                                      <div className="course-every-img">
                                        <img src={val?.coverFileId} alt=""
                                             onClick={() => this.toSpaceDemonDetailPage(val?.id, val?.courseType, val?.courseName, val)}/>
                                        {val?.hasExample == 2 && <span className="turn-tag">已驳回</span>}
                                      </div>
                                      <div className="course-every-name">{val?.courseName}</div>
                                    </div>
                                  );
                                })}
                                {schoolLessonData.length == 0 && platformLessonData.length != 0 &&
                                  <div className="courseNull">

                                    <img src={imgUrlForZhongZhi("courseNull.png")} alt=""/>
                                    <h6>暂无示范课申请</h6>
                                  </div>}

                              </div>
                              <div className="news_footer" id="news_footer">
                                <Paginations
                                  current={paginationSchoolExampleList?.current}
                                  pageSize={paginationSchoolExampleList?.pageSize}
                                  pages={paginationSchoolExampleList?.pages}
                                  total={paginationSchoolExampleList?.total}
                                  handleChange={this.onChangePubCourseCurPageOrSize.bind(this, 'school')}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
                                />
                              </div>

                            </div>
                            <div className="setcourse_school setcourse_platform">
                              <div className="school_title">
                                <h1>平台示范课</h1>
                                <p>通过审核的示范课程会自动生成新课程，原课程可继续使用。</p>
                              </div>
                              <div className="school_con">
                                {platformLessonData && platformLessonData.map((val) => {
                                  return (
                                    <div className="course-every">
                                      <div className="course-every-img">
                                        <img src={val?.coverFileId} alt=""
                                             onClick={() => this.toSpaceDemonDetailPage(val?.id, val?.courseType, val?.courseName, val)}/>
                                        {val?.hasExample == 2 && <span className="turn-tag">已驳回</span>}
                                      </div>
                                      <div className="course-every-name">{val?.courseName}</div>
                                    </div>
                                  );
                                })}
                                {platformLessonData.length == 0 && schoolLessonData.length != 0 &&
                                  <div className="courseNull">
                                    <img src={imgUrlForZhongZhi("courseNull.png")} alt=""/>
                                    <h6>暂无示范课申请</h6>
                                  </div>}
                              </div>
                              <div className="news_footer" id="news_footer">
                                <Paginations
                                  current={paginationPlatformExampleList?.current}
                                  pageSize={paginationPlatformExampleList?.pageSize}
                                  pages={paginationPlatformExampleList?.pages}
                                  total={paginationPlatformExampleList?.total}
                                  handleChange={this.onChangePubCourseCurPageOrSize.bind(this, 'platform')}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
                                />
                              </div>
                            </div>
                          </div>
                    }
                  </div>
                  :
                  <div className="right_personage">
                    <Personal/>
                  </div>
              }
            </div>
          </div>
        </div>
        <Modal
          className="class_modeld"
          visible={isClassModal}
          onCancel={this.handleClass}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className="class_modeld_title">
              <span>请选择申请的示范课类型</span>（可多选）
            </div>
            <div className="class_modeld_center">
              {/* <div style={{ color: schoolJoin ? 'gray' : '' }} onClick={() => { */}
              <div onClick={() => {
                // if (!schoolJoin) {
                this.schoolLesson('school');
                // }
              }}
                   className={applyType.indexOf('school') != -1 ? 'class_modeld_center_left class_modeld_center_right' : 'class_modeld_center_left'}>学校示范课
              </div>
              {/* <div style={{ color: platformJoin ? 'gray' : '' }} onClick={() => { */}
              <div onClick={() => {
                // if (!platformJoin) {
                this.schoolLesson('platform');
                // }
              }}
                   className={applyType.indexOf('platform') != -1 ? 'class_modeld_center_left class_modeld_center_right' : 'class_modeld_center_left'}>平台示范课
              </div>
            </div>
            <div className="class_modeld_button">
              <button className="class_modeld_buttonno" onClick={this.handleClass}>取消</button>
              <button onClick={this.applyExampleCourse} className="class_modeld_buttonyes">确定</button>
            </div>
          </div>
        </Modal>
        <Modal
          className="class_modeld"
          visible={isDelCourseModal}
          onCancel={this.handleDelCourse}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className="class_modeld_title">
              <span>删除课程</span>
            </div>
            <div className="class_modeld_center pd50">
              <Input.Password placeholder="请输入登录密码" type value={loginPassword} onChange={this.setPassword}/>
            </div>
            <div className="class_modeld_button">
              <button className="class_modeld_buttonno" onClick={this.handleDelCourse}>取消</button>
              <button onClick={this.checkPassword} className="class_modeld_buttonyes">确定</button>
            </div>
          </div>
        </Modal>
        <Modal
          className="add_modeld"
          visible={isaddModal}
          onCancel={this.handleAddModal}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className="class_modeld_title">新建课程
            </div>
            <Form
              ref={this.formRef}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              onReset={this.onReset}
              layout="vertical"
              size="middle"
              initialValues={{
                teaName: this.props.user?.fullName,
                // collegeId: provinceData[0]?.collegeId
              }}
              onValuesChange={(e) => {
                const {RequiredFields} = this.state;
                if (e.courseName) {
                  const RequFields = {
                    courseName: e.courseName,
                    courseType: RequiredFields.courseType,
                    subjectId: RequiredFields.subjectId,
                    collegeId: RequiredFields.collegeId,//所属院系
                    majorId: RequiredFields.majorId,//所属专业
                  };
                  this.setState({RequiredFields: RequFields});
                }
                if (e.courseType) {
                  const RequFields = {
                    courseType: e.courseType,
                    courseName: RequiredFields.courseName,
                    subjectId: RequiredFields.subjectId,
                    collegeId: RequiredFields.collegeId,//所属院系
                    majorId: RequiredFields.majorId,//所属专业
                  };
                  this.setState({RequiredFields: RequFields});
                }
                if (e.collegeId) {
                  const RequFields = {
                    collegeId: e.collegeId,////所属院系
                    courseType: RequiredFields.courseType,
                    courseName: RequiredFields.courseName,
                    subjectId: RequiredFields.subjectId,
                    majorId: RequiredFields.majorId,//所属专业
                  };
                  this.setState({RequiredFields: RequFields});
                }
                if (e.majorId) {
                  const RequFields = {
                    courseType: RequiredFields.courseType,
                    courseName: RequiredFields.courseName,
                    subjectId: RequiredFields.subjectId,
                    collegeId: RequiredFields.collegeId,//所属院系
                    majorId: e.majorId,//所属专业
                  };
                  this.setState({RequiredFields: RequFields});
                }
                if (e.subjectId) {
                  const RequFields = {
                    courseType: RequiredFields.courseType,
                    courseName: RequiredFields.courseName,
                    subjectId: e.subjectId,
                    collegeId: RequiredFields.collegeId,//所属院系
                    majorId: RequiredFields.majorId,//所属专业
                  };
                  this.setState({RequiredFields: RequFields});
                }
              }}
            >
              <div className={nextpage ? "class_modeld_center" : 'addno'}>
                <h1>课程信息</h1>
                <div className="add_form">
                  <div className="add_form_left">
                    <FormItem label="课程名称" name="courseName" rules={[nameRules]}>
                      <Input placeholder="请输入课程名称" maxLength={15}/>
                    </FormItem>
                    <FormItem label="课程类型" name="courseType" rules={[passwordRules]}>
                      <Select placeholder="请选择" onChange={this.selectType}>
                        <Option value="1">公共课</Option>
                        <Option value="2">专业课</Option>
                      </Select>
                    </FormItem>
                    <FormItem label="课程老师" name="teaName" rules={[passwordRules]}>
                      <Input defaultValue="张三" disabled/>
                    </FormItem>
                    {courseType == 1 ?
                      <FormItem label="所属学科" name="subjectId" rules={[{required: true, message: "请选择所属学科"}]}>
                        <Select placeholder="请选择">
                          {subjectListData && subjectListData.map((province, index) => (
                            <Option key={province?.value}>{province?.title}</Option>
                          ))}
                        </Select>
                      </FormItem>
                      :
                      courseType == 2 ?
                        <div>
                          <FormItem label="所属院系" name="collegeId" rules={[{required: true, message: "请选择所属院系"}]}>
                            <Select placeholder="请选择" onChange={this.handleProvinceChange}>
                              {provinceData && provinceData.map((province, index) => (
                                <Option key={province?.collegeId}>{province?.collegeName}</Option>
                              ))}
                            </Select>
                          </FormItem>
                          <FormItem label="所属专业" name="majorId" rules={[{required: true, message: "请选择所属专业"}]}>
                            <Select placeholder="请选择">
                              {cities && cities.map((province, index) => (
                                <Option key={province?.majorId}>{province?.majorName}</Option>
                              ))}
                            </Select>
                          </FormItem>
                        </div>


                        : ''
                    }

                  </div>
                  <div className="add_form_right">
                    <FormItem label="教材名称" name="bookName">
                      <Input placeholder="请输入教材名称"/>
                    </FormItem>
                    <FormItem label="教材作者" name="bookAuthor">
                      <Input placeholder="请输入教材作者"/>
                    </FormItem>
                    <FormItem label="出版社" name="press">
                      <Input placeholder="请输入出版社"/>
                    </FormItem>
                    <FormItem label="出版日期" name="publishDate">
                      <DatePicker placeholder="请选择日期" style={{width: '100%'}}/>
                    </FormItem>
                  </div>
                </div>
                {/* <FormItem>
                  <Button type="primary" size="large" htmlType="submit">
                    submit
                  </Button>
                </FormItem>
                <FormItem>
                  <Button type="default" size="large" htmlType="reset">
                    reset
                  </Button>
                </FormItem> */}
              </div>
              <div className={nextpage ? "addno" : 'class_modeld_center1'}>
                <div className="center1_top">
                  <div className="center1_topleft">
                    <img src={fileUrl || imgUrlForZhongZhi('ad_cover.png')} alt=""/>
                  </div>
                  <div className="center1_topright">
                    <ImgCrop aspect={526 / 296} modalTitle={'编辑封面'} modalCancel={'取消'} rotate>

                      <Upload {...uploadProps}>
                        <div className="topright_upload">
                          <img src={imgUrlForZhongZhi('icon-add.png')} alt=""/>
                          上传封面
                        </div>
                      </Upload>
                    </ImgCrop>
                    <p>
                      <div></div>
                      作为课程的宣传图，用于课程主页的显示
                    </p>
                    <p>
                      <div></div>
                      支持JPG.JPEG.PNG.GIF格式
                    </p>
                    <p>
                      <div></div>
                      文件大小4MB，1000*600以上像素
                    </p>
                    <p>
                      <div></div>
                      您可以自己上传图片，也可以从下面这些图片选择使用
                    </p>
                  </div>
                </div>
                <div className="cover_wrap">
                  <div className="cover_left" onClick={() => this.spread('向右')}>
                    <img src={page == 0 ? imgUrlForZhongZhi('goleftno.png') : imgUrlForZhongZhi('goleft.png')} alt=""/>
                  </div>
                  <div className="center1_buttom" id="answerItem">
                    <div className="EnglishTingxie-con">
                      {
                        covers && covers.map((coverval, index) => {
                          return (
                            <div id={'page' + index}
                                 onClick={() => this.defaultCover(index, coverval?.fileId, coverval?.fileUrl)}
                                 className={defaultcover == index ? 'con-img select' : 'con-img'}>
                              <img className="img_1"
                                   src={defaultcover == index ? imgUrlForZhongZhi('yselect.png') : imgUrlForZhongZhi('noselect.png')}
                                   alt=""/>
                              <img className="img_2" src={coverval?.fileUrl} alt=""/>
                              <p>封面1</p>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                  <div className="cover_right" onClick={() => this.spread('向左')}>
                    <img
                      src={page == covers.length - 4 ? imgUrlForZhongZhi('gorightno.png') : imgUrlForZhongZhi('goright.png')}
                      alt=""/>
                  </div>
                </div>

              </div>

              <div className="class_modeld_button">
                <button type="button" className="class_modeld_buttonno"
                        onClick={this.outPage}>{nextpage ? "取消" : '上一步'} </button>
                <button type={inputNextpage ? "button" : ''} htmlType="submit" className="class_modeld_buttonyes"
                        onClick={() => this.nextPage(nextpage)}>{nextpage ? "下一步" : '确定'}</button>
              </div>

            </Form>
          </div>
        </Modal>
        <Modal
          className="class_modeld"
          visible={isCopy}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className="class_modeld_title">
              <span>复制课程</span>
            </div>
            <div className="class_modeld_center pd50" style={{textAlign: 'left'}}>
              复制该课程的课时内容和作业形成新的课程，您可以单独维护，请确认是否复制?
            </div>
            <div className="class_modeld_button">
              <button className="class_modeld_buttonno" onClick={() => {
                this.setState({isCopy: false});
              }}>取消
              </button>
              <button onClick={() => {
                this.copyCourse(isCopy);
              }} className="class_modeld_buttonyes">确定
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          className="class_modeld"
          visible={isEndCourseModal}
          onCancel={this.handleEndCourseChange}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            {/* <div className='class_modeld_title'>
                <span>解散班级</span>
              </div> */}

            <div className="class_modeld_center" style={{padding: '0 40px 0'}}>
              所有班级都将进入结课模式，学习行为将不会被统计。请确认是否结课？
            </div>
            <div className="class_modeld_button">
              <button className="class_modeld_buttonno" onClick={this.handleEndCourseChange}>取消</button>
              <button onClick={this.endCourse} className="class_modeld_buttonyes">确定</button>
            </div>

          </div>
        </Modal>
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
  updateUser(user) {
    dispatch(updateUser(user));
  },
});
export default connect(({crumbsData, user}) => ({crumbsData, user}), mapAction)(MyCourse);
