/*
 * @Descripttion:
 * @version:
 * @Author: nliu
 * @Date: 2020-11-06 19:29:32
 * @LastEditors: nliu
 * @LastEditTime: 2020-11-10 19:46:54
 */
import _axios from 'axios';
import * as history from 'history';
import { message } from 'antd';

import { request, response } from './interceptors';
// import { queries } from '@testing-library/react';

const axios = _axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

axios.interceptors.request.use(request);
axios.interceptors.response.use(response, err => {
  if (err.response) {
    if (err.response.status === 401 || err.response.status === 4011) {
      // let href = window.location.href;
      // if (href.includes('localhost')) {
      history.createHashHistory().push('/home');
      // message.destroy();
      // message.error('登录过期,请重新登录');
      localStorage.clear();
      // } else {
      // history.createHashHistory().push('/home');
      // }
      // window.location.reload();
    }
  }
});
export default axios;
