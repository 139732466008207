import React from "react";
import styles from './index.module.scss';
import axios from '../../axios';
import {getResourceByCatalog, getCatalogFile} from '../../api';
import {imgUrlForZhongZhi} from '../../utils/index';
import {getParam, pausedVideoAndAudio} from '../../utils/util';
import VideoPlay from "./components/VideoPlay/VideoPlay";
import AudioPlay from "./components/AudioPlay/AudioPlay";
import Test from "./components/Test/Test";
// import { Empty } from "antd";
import Empty from '@@/Empty';
import env from "@/env";

const {xDocUrl, xDocUrlHttps} = env;

class AttendClass extends React.Component {
  constructor() {
    super();
    this.state = {
      status: 1,    // 资源列表收起展开状态，1 收起  2 展开
      stepList: [],  // 环节列表
      currentResourseObj: {},
      openUrl: ''
    };
  }

  componentDidMount() {
    this.getCatalogFile();

    document.addEventListener("keydown", this.escFunction, false);
    if (this.state?.currentResourseObj?.resType == 6) {
      setTimeout(() => {
        pausedVideoAndAudio();
        this.aa()
      }, 1000);
    }

  }

  componentDidUpdate() {
    let that = this;
    if (this.state?.currentResourseObj?.resType == 6) {
      this.aa();
      window.onmessage = function (event) {
        console.log('event ===========', event)
        if (event.data.editType === '0') {
          that.aa(event.data.url)
        }
      }
    }
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      // alert("点击退出");
      window.history.back();
    }
  }

  getCatalogFile = () => {
    let {catalogId, id, courseId} = getParam();
    axios.get(getCatalogFile, {
      params: {
        catalogId,
        courseId: courseId || id,
      }
    }).then(res => {
      if (!res) return;
      let {fileVOList} = res;
      let coursewareInfo = fileVOList?.find(item => item?.detailType === 2);
      let currentResourseObj
      if (coursewareInfo) {
        currentResourseObj = {
          displayOrder: 0,
          extName: "pptx",
          id: 9999,
          resId: coursewareInfo?.detailFileId,
          resTitle: "课件",
          resType: 1,
          url: coursewareInfo?.fileUrl
        }
      }

      this.setState({
        fileVOList,
        coursewareInfo,
        currentResourseObj
      });
      this.getResourceByCatalog();
    });
  }
  getResourceByCatalog = (attach = 1) => {
    let {catalogId, courseId} = getParam();
    axios.get(getResourceByCatalog, {
      params: {
        catalogId,
        courseId,
        attach
      }
    }).then(res => {
      if (!res) return;
      let {stepList} = res;
      let {currentResourseObj} = this.state;
      console.log("res", res);
      this.setState({
        // stepList: stepList?.filter(item => item?.stepName)
        stepList: stepList
      }, () => {
        let {stepList} = this.state;
        let resourceList = [];
        stepList.forEach(item => {
          resourceList.push(...(item?.resourceList || []));
        });
        console.log("currentResourseObj", currentResourseObj);

        if (!currentResourseObj) {
          this.setState({
            currentResourseObj: (resourceList?.filter(i => (i?.resType !== 0) && i?.resType !== 8) || [])[0] || {}
          });
        }
      });
    });
  }

  renderStepList = data => {
    return data?.map((item, index) => {
      return (<div className={styles.linkItemWrapper} key={index}>
        {
          item?.stepName && <p className={styles.stepName}>{item?.stepName}</p>
        }
        {
          this.renderResourseList(item?.resourceList?.filter(i => (i?.resType !== 0) && i?.resType !== 8), item?.displayOrder)
        }
        {/* && i?.resType !== 8 */}
      </div>);
    });
  }

  iconFn = item => {
    let resType = item?.resType;
    let extName = item?.extName;
    //资源类型<>0=文本&1=课件&2=图片&3=文档&4=视频&5=音频&6=测试&7=链接&8=附件
    if (resType === 1) {
      return 'ppt.png';
    }
    if (resType === 2) {
      return 'image.png';
    }
    if (resType === 3 && extName != 'pdf') {
      return 'word.png';
    }
    if (resType === 3 && extName == 'pdf') {
      return 'pdf.png';
    }
    if (resType === 4) {
      return 'video.png';
    }
    if (resType === 5) {
      return 'audio.png';
    }
    if (resType === 6) {
      return 'tests.png';
    }
    if (resType === 7) {
      return 'h5.png';
    }
    if (resType === 8) {
      return 'file.png';
    }
    return 'pdf.png';
  }

  renderResourseList = (data, displayOrder) => {
    let {currentResourseObj} = this.state;
    console.log("renderResourseList,data", data);
    if (data?.length > 0) {
      return data?.map((item, index) => (<div key={index}
                                              className={`${styles.resourseItem} ${currentResourseObj?.id === item?.id ? styles.active : ''}`}
                                              onClick={() => {
                                                this.setState({
                                                  currentResourseObj: {}
                                                }, () => {
                                                  this.setState({
                                                    currentResourseObj: item || {}
                                                  });
                                                });
                                              }}>
        <img src={imgUrlForZhongZhi(this.iconFn(item))} alt=""/>
        <div className={styles.resourseItemTitle}>
          {item?.resTitle}
        </div>
      </div>));
    }
    // else if(){

    // }
    return displayOrder > 0 && <p className={styles.noResource}>该环节下暂无资源展示</p>


  }

  renderEnclosure = currentResourseObj => {
    let {url, hasSitu, extName} = currentResourseObj;
    if (!url) return;
    if (['pdf', 'ppt', 'pptx', 'pptm', 'docx', 'doc', 'txt'].indexOf(extName) !== -1) {
      return <iframe title="iframe" src={url.includes('https') ? (xDocUrlHttps + url) : (xDocUrl + url)} width="100%"
                     frameBorder='0'></iframe>;
    }
    if (['bmp', 'jpg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng'].indexOf(extName) !== -1) {
      return <img className={styles.img} src={url} alt=""/>;
    }
    if (['rm', 'rmvb', 'mov', 'mtv', 'dat', 'wmv', 'avi', '3gp', 'mp4'].indexOf(extName) !== -1) {
      return <VideoPlay src={url} hasSitu={hasSitu}/>;
    }
    if (['mp3', 'm4a'].indexOf(extName) !== -1) {
      return <AudioPlay src={url} hasSitu={hasSitu}/>;
    }
    return <div style={{width: '100%', height: '100%', background: '#fff'}}>
      <iframe allowFullScreen title="iframe" src={url} width="100%" frameBorder='0'></iframe>
    </div>;
  }

  renderResourse = currentResourseObj => {
    // resType = 7;
    let {resType, url, resId, hasSitu} = currentResourseObj;
    // console.log(currentResourseObj, "currentResourseObj");
    // resType = 6;
    // resId = 1
    // url = 'https://cdn.zjyjc.com/%E8%B5%84%E6%BA%90/%E6%95%99%E8%BE%85/31a082c039cb612e3f13c4974b6f28a4.pptx';
    // url = 'https://img2.baidu.com/it/u=690487400,3736912659&fm=253&fmt=auto&app=120&f=JPEG?w=1200&h=675';
    // url = 'https://v.zjy.partner.taozhi.online/b48c42fb1204435c8083836abdc2f55d/4c2486f3ffa947cd9bed07135eb2945b-fafc9a92197908014ccce3ff51a24995-ld.mp4';
    // url = 'https://zjyjc.yuque.com/docs/share/f0f50224-9b96-4c41-bcc1-b5711804fdda?view=doc_embed&from=app';
    // url = 'https://cdn.zjyjc.com/%E8%B5%84%E6%BA%90/%E6%95%99%E8%BE%85/%E7%99%BE%E5%B7%9D%E8%B5%84%E6%BA%90/%E9%AB%98%E4%B8%AD%E8%B5%84%E6%BA%90/%E8%8B%B1%E8%AF%AD/%E5%BF%85%E4%BF%AE%E7%AC%AC%E4%B8%80%E5%86%8C/%E9%9F%B3%E9%A2%91/4%40%40%40fdb6de81bffc4ed2b537c9b9b17a4f18.mp3';
    if (!url) {
      return <Empty title="暂无内容"/>
    }
    ;
    if (resType === 1 || resType === 3) {
      return <iframe title="iframe" src={url.includes('https') ? (xDocUrlHttps + url) : (xDocUrl + url)} width="100%"
                     frameBorder='0'></iframe>;
    }
    if (resType === 2) {
      return <img className={styles.img} src={url} alt=""/>;
    }
    if (resType === 4) {
      return <VideoPlay src={url} hasSitu={hasSitu}/>;
    }
    if (resType === 5) {
      return <AudioPlay src={url} hasSitu={hasSitu}/>;
    }
    if (resType === 6) {
      return resId ? <Test resId={resId}/> : '';
    }
    if (resType === 7) {
      // return <iframe allowFullScreen title="iframe" src={url} width="100%" frameBorder='0'></iframe>;
      return <div style={{width: '100%', height: '100%', background: '#fff'}}>
        <iframe allowFullScreen title="iframe" src={url} width="100%" frameBorder='0'></iframe>
      </div>;
    }
    if (resType === 8) {
      return this.renderEnclosure(currentResourseObj);
    }
  }
  aa = (url = '') => {
    console.log('url', url);
    const domUtils = window.UE.dom.domUtils;
    var iframes = document.getElementsByTagName("iframe");
    for (var i = 0; i < iframes?.length; i++) {
      //   iframes[i].contentWindow.document.getElementById('jsAudio').onclick = function(){
      //   alert("1");
      //  };
      //  console.log('iframes',iframes[i])
      console.log('iframes-----------------', iframes[i].contentWindow.document.getElementById('jsAudio'))
      let selNode = iframes[i].contentWindow.document.getElementById('jsAudio');
      if (selNode) {
        if (selNode.className.match('aa') && selNode.src != url) {
          selNode.pause()
          iframes[i].contentWindow.document.getElementById('input1').hidden = false
          iframes[i].contentWindow.document.getElementById('input2').hidden = true
          console.log('input2-----------------', iframes[i].contentWindow.document.getElementById('input2'))
        }
      }
    }
  }

  render() {
    let {status, stepList, currentResourseObj, coursewareInfo} = this.state;
    console.log(currentResourseObj);
    return (
      <div className={styles.wrapper}>
        <img className={styles.close} src={imgUrlForZhongZhi("attendClassCloseImg.png")} alt="" onClick={() => {
          this.props.history.go(-1);
        }}/>
        <div className={styles.container}>
          <p className={styles.title}>{currentResourseObj?.resTitle}</p>
          <div className={stepList.length === 0 ? `${styles.center} ${styles.center2}` : styles.center}>
            {
              status === 1 &&
              <img className={styles.openList} src={imgUrlForZhongZhi("openList.png")} alt="" onClick={() => {
                this.setState({
                  status: 2
                });
              }}/>
            }
            {
              status === 2 && <>
                <img className={styles.closeList} src={imgUrlForZhongZhi("openListClose.png")} alt="" onClick={() => {
                  this.setState({
                    status: 1
                  });
                }}/>
                <div className={styles.resourseListContainer}>
                  <p className={styles.resourceTitle}>课件</p>
                  <div className={styles.linkItemWrapper}>
                    {coursewareInfo ? this.renderResourseList([{
                      displayOrder: 0,
                      extName: "pptx",
                      id: 9999,
                      resId: coursewareInfo?.detailFileId,
                      resTitle: "课件",
                      resType: 1,
                      url: coursewareInfo?.fileUrl
                    }], 0) : <div className={styles.noResource}>暂无课件</div>}
                  </div>
                  <p className={styles.resourceTitle}>资源列表</p>
                  {this.renderStepList(stepList)}
                </div>
              </>
            }
            <div className={styles.resourseContainer}>
              {
                currentResourseObj && this.renderResourse(currentResourseObj)
              }
              {
                stepList.length === 0 && <div style={{
                  color: '#8E9AB3',
                  width: '200px',
                  textAlign: 'center',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}>
                  <img src={imgUrlForZhongZhi("emptyNormal.png")} style={{maxWidth: '120px', height: 'auto'}}/>
                  <p style={{marginTop: '18px', fontSize: '14px'}}> 暂无内容</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AttendClass;
