import * as types from './constant';

import { State, Action } from './interface';

const initialState: State = {
  token: null,
  phone: null,
  user: {},
  crumbsData: [],
  noticeData:{},
  curseVOData:{},
  msgCount:null
};

export default function (state = initialState, action: Action): State {
  const {
    payload: {
      token = null,
      phone = null,
      user = {},
      crumbsData = [],
      noticeData={},
      curseVOData={},
      msgCount=null
    } = {}, type } = action;

  switch (type) {
    case types.UPDATE_TOKEN:
      return {
        ...state,
        token,
      };
    case types.UPDATE_PHONE:
      return {
        ...state,
        phone,
      };
    case types.CLEAR_TOKEN:
      return {
        ...initialState,
      };
    case types.UPDATE_USER:
      return {
        ...state,
        user,
      };
    case types.CLEAR_USER:
      return {
        ...state,
        user: {},
      };
    case types.UPDATE_CRUMBS_DATA:
      return {
        ...state,
        crumbsData,
      };
    case types.HOME_SAVE_NOTICE_DATA:
      return {
        ...state,
        noticeData,
      };
    case types.SAVE_CURSEVO_DATA:
      return {
        ...state,
        curseVOData,
      };
    case types.UPDATE_MEG_COUNT:
      return {
        ...state,
        msgCount,
      };

    default:
      return state;
  }
}


