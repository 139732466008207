import React, { Component } from 'react';
import Paginations from '@@/Paginations';
import { connect } from 'react-redux';
import { updateCrumbsData, updateMsgCount } from '../../store/action';
import Crumbs from '@@/Crumbs';
import { count } from '@/api';
import { imgUrlForZhongZhi } from '@/utils';
import { Modal } from 'antd';
import {
  list,
} from '@/api';
import axios from '../../axios';
import styles from './index.module.scss';
import Empty from '@@/Empty';

class SystemMessage extends Component {
  state = {
    noticeList: [],
    paginationList: {
      current: 1,
      pageSize: 10,
      total: 10,
      isAuthVisible: false
    },
  }

  componentDidMount() {
    this.queryStudentNotice();
    this.props.updateCrumbsData([
      {
        name: '首页',
        path: '/'
      },
      {
        name: '消息',
        path: ''
      }
    ]);
    this.count()
  }
  count = () => {
    axios.get(count, {
      params: {
        uid: this.props.user.uid
      }
    }).then(res => {
      if (!res) return;
      this.props.updateMsgCount(res);
    });
  }
  queryStudentNotice = () => {
    let { paginationList } = this.state;
    axios.post(list, {
      pageNum: paginationList?.current,
      pageSize: paginationList?.pageSize,
      uid: this.props.user.uid
      // uid: 1
    }).then(res => {
      if (!res) return;
      let { messages } = res;
      this.count()
      this.setState({
        paginationList: {
          ...paginationList,
          total: messages?.total
        },
        noticeList: messages?.data || []
      });
    });
  }

  toDetail = item => {
    if (!item?.courseId) return;
    // this.props.history.push(`/courseCenter/courseDetails?id=${item?.courseId}&courseType=${'courseType'}`);
    // hasExample  是否是示范课  0否1是
    console.log(item, "toDetail");
    if (item?.hasAuth) {
      if (item.hasExample == 1) {
        this.props.history.push(`/demonspace/mycourse?id=${item?.courseId}&courseType=${2}&isJiaoXueKongJianPage=${item.courseName}&isEdit=true&isDemonstrationCourse=true`);
      } else {
        this.props.history.push(`/teachersspace/mycourse?id=${item?.courseId}&courseType=${1}&isJiaoXueKongJianPage=${item.courseName}&isEdit=true`);
      }
    } else {
      this.setState({ isAuthVisible: true })
    }

    //  window.open(path+`#/teachersspace/mycourse?id=${id}&courseType=${courseType}&isJiaoXueKongJianPage=${isJiaoXueKongJianPage}&isEdit=true`)
    // window.open(path+`#/demonspace/mycourse?id=${id}&courseType=${courseType}&isJiaoXueKongJianPage=${isJiaoXueKongJianPage}&isEdit=true&isDemonstrationCourse=true`)
  }

  renderItem = (data) => {
    if (data?.length > 0) {
      return data?.map((item, index) => (<div className={styles.item} key={index} onClick={this.toDetail.bind(this, item)}>
        <img className={styles.left} src={imgUrlForZhongZhi("Icon_system-message.png")} alt="" />
        <div className={styles.right}>
          <div className={styles.rightTop}>
            <span className={styles.rightTopL}>系统消息</span>
            <span className={styles.rightTopR}>{item?.publishTime.replace(/-/g, '/').substring(0, 16)}</span>
          </div>
          <div className={styles.rightBottom}>{item?.message}</div>
        </div>
      </div>));
    }
    // return '暂无消息';
    return <Empty title="暂无消息" />;
  }

  // 分页器 动态获取到页码
  onChangeCurPageOrPageSize = (current, pageSize) => {
    this.setState({
      paginationList: {
        ...this.state.paginationList,
        current,
        pageSize: 10,
      },
    }, () => {
      document.documentElement.scrollTop = 0;
      this.queryStudentNotice();
    });
  }

  render() {
    let { noticeList, paginationList, isAuthVisible } = this.state;
    const { current, pageSize, total } = paginationList;

    return (
      <div className={styles.wrapper}>
        <div className={styles.crumbsWrapper}>
          <Crumbs />
        </div>
        {
          noticeList?.length > 0
            ?
            <div className={styles.container} style={{ minHeight: window.screen.height - 210 - 218,  paddingBottom: '70px',marginBottom: '30px', position: 'relative' }}>
              {
                this.renderItem(noticeList)
              }
            
                <p style={{ position: 'absolute',bottom:'30px',left:'50%',transform: 'translateX(-50%)'}}>
                  <Paginations
                    current={current}
                    pageSize={pageSize}
                    total={total}
                    handleChange={this.onChangeCurPageOrPageSize}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
                  /> 
                </p>
               
              
            </div>
            :
            <Empty title="暂无消息" />
        }
        <Modal
          className="class_modeld"
          visible={isAuthVisible}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className='class_modeld_title'>
              <span>暂无权限</span>
            </div>
            <div className='class_modeld_center pd50' style={{ textAlign: 'left' }}>
              授权已被取消，暂无访问权限
            </div>
            <div className='class_modeld_button'>
              <button onClick={() => { this.setState({ isAuthVisible: false }) }} className='class_modeld_buttonyes'>确定</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
  updateMsgCount(msgCount) {
    dispatch(updateMsgCount(msgCount));
  },
});
export default connect(({ crumbsData, user }) => ({ crumbsData, user }), mapAction)(SystemMessage);
