import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import querystring from 'querystring';
import { updateToken, updateUser } from '../../store/action';
import styles from './index.module.scss';
import axios from '../../axios';
import { queryUserInfo, teacherVirtualLogin } from '../../api';

const LoginBackstage = (props) => {
  const history = useHistory();

  const tiaozhuan = async () => {
    let path = '/';
    let { location } = history;
    let search = (location.search || "").substr(1);

    let param = querystring.parse(search);
    let type = param.type;
    if (type === '5') {            // 教学空间
      path = `/teacher?id=${param.courseId}&courseType=${param.courseType}`;
    } else if (type === '6') {            //  教学空间课程封面
      path = `/teachersspace/mycourse?id=${param.courseId}&courseType=${param.courseType}&isDemonstrationCourse=${param.isDemonstrationCourse ? param.isDemonstrationCourse : ''}&isEdit=${param.isEdit ? param.isEdit : ''}&isJiaoXueKongJianPage=${param.isJiaoXueKongJianPage ? param.isJiaoXueKongJianPage : ''}&onlyCheck=${param.onlyCheck}`;
      // path = `/courseCenter/courseDetails?id=${param.courseId}&courseType=${param.courseType}`;
    } else if (type === '7') {            //  教学空间课程封面示范课
      path = `/demonspace/mycourse?id=${param.courseId}&courseType=${param.courseType}&isDemonstrationCourse=${param.isDemonstrationCourse ? param.isDemonstrationCourse : ''}&isEdit=${param.isEdit ? param.isEdit : ''}&isJiaoXueKongJianPage=${param.isJiaoXueKongJianPage ? param.isJiaoXueKongJianPage : ''}`;
      // path = `/courseCenter/courseDetails?id=${param.courseId}&courseType=${param.courseType}`;
    }
    let user = {
      humanName: decodeURIComponent(param.humanName || ''),
      id: "",
      initFlag: 0,
      portraitId: "",
      pressFlag: false,
      reviewStatus: "",
      roleTypeId: "",
      token: "",
      unitId: "",
      unitLogo: "",
      unitName: "",
      unitType: "",
      usernameInitFlag: "",
      uid: param.uid,
    };
    // const res = await axios.post(queryUserInfo, { uid: param.uid });
    const res = await axios.post(teacherVirtualLogin, { uid: param.uid });
    // user = { ...user, id: param.uid, token: param.token, avatarUrl: res?.avatarUrl };
    let { token, authUser } = res;
    props.updateToken(token);
    props.updateUser({ ...authUser, token });
    // props.updateToken(param.token);
    // props.updateUser(user);
    history.replace(path);
  };

  useEffect(() => {
    tiaozhuan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className={styles.container}>
    <Spin>正在跳转...</Spin>
  </div>;
};
const mapAction = (dispatch) => ({
  updateToken(token) {
    dispatch(updateToken(token));
  },
  updateUser(user) {
    dispatch(updateUser(user));
  },
});

export default connect(null, mapAction)(LoginBackstage);
