import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import React from 'react';
import { message,Modal } from 'antd';
import Nav from './Nav';
import axios from '@/axios';
import { count, logout, querySchoolByRealmName } from '@/api';
import { clearToken,updateMsgCount } from '../../store/action';
import NavForJiaoXueKongJian from './NavForJiaoXueKongJian';
import NavForJiaoXueKongJiandemon from './NavForJiaoXueKongJiandemon'
import NavForJiaoXueKongJianNavTwo from './NavForJiaoXueKongJianNavTwo'
import './css/header.scss';
import {imgUrl, imgUrlForZhongZhi, showImg, domain_, getIp, dymicSettingWebsiteInfo} from '../../utils';
import { getParam } from '../../utils/util';

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      messageCount: 0,
      schoolObj: {},
      isSignOut:false
    };
  }

  componentDidMount() {
    console.log("走了");
    this.count();
    this.querySchoolByRealmName();
  }

  querySchoolByRealmName = () => {
    axios.get(querySchoolByRealmName, {
      params: {
        realmName: getIp()
      }
    }).then(res => {
      if (!res) return;
      const {schoolName, logoUrl} = res
      dymicSettingWebsiteInfo(schoolName, logoUrl)

      this.setState({
        schoolObj: res
      });
    });
  }

  count = () => {
    axios.get(count, {
      params: {
        uid: this.props.user.uid
      }
    }).then(res => {
      if (!res) return;
      this.props.updateMsgCount(res);
      // this.setState({
      //   messageCount: res
      // });
    });
  }

  logout = () => {
    const {isSignOut} = this.state;
    if ( !isSignOut ){
      this.setState({isSignOut:!isSignOut})
      return ;
    }
    axios.post(logout).then(res => {
      if (!res) return;
      message.destroy();
      message.success('退出成功');
      this.setState({isSignOut:false})
      this.props.clearToken();
    });;
  }

  render() {
    let { isJiaoXueKongJianPage,courseSource } = getParam();
    let { messageCount, schoolObj,isSignOut } = this.state;
    let { avatarUrl } = this.props.user;
    let { msgCount } = this.props;
    // console.log("this.props",this.props);
    // console.log('isJiaoXueKongJianPage',isJiaoXueKongJianPage,window.location);
    return (
      <div className="sheader-container d-flex align-items-center">
        <div className={`${(window.location.hash=== '#/teacher') || (window.location.hash==='#/zzgg') ||(window.location.hash==='#/courseCenter')|| (window.location.hash === '#/systemMessage') || window.location.hash.split("?")[0] === '#/zzgg/notice_dateil' ? 'sheader-logo sheader-logo1':'sheader-logo '} flex-shrink-0 d-flex ${(window.location.hash=== '#/teacher') || (window.location.hash==='#/zzgg') ||(window.location.hash==='#/courseCenter')?'':'justify-content-center'} align-items-center ${isJiaoXueKongJianPage ? 'jiaoXueKongJian' : ''}`}>
          {/* { */}
          {/* isJiaoXueKongJianPage ? */}
          {/* <img src={imgUrlForZhongZhi(isJiaoXueKongJianPage ? 'teacherLogoBlue.png' : 'homeLogoBlue.png')} alt="" /> */}
          <div className={'homeLogoContainer'} onClick={() => {
            if (isJiaoXueKongJianPage){
              this.props.history.push('/teacher');
              return
            };
            this.props.history.push('/home');
          }}>
            <img src={schoolObj?.logoUrl} alt="" />
            {(window.location.hash=== '#/teacher') || (window.location.hash==='#/zzgg') ||(window.location.hash==='#/courseCenter') || (window.location.hash === '#/systemMessage') ||window.location.hash.split("?")[0] === '#/zzgg/notice_dateil' ?
            <div className={'homeLogoRight'}>
              <p className={'homeLogoRightT'}>{schoolObj?.schoolName}</p>
              <p className={'homeLogoRightB'}>{schoolObj?.schoolMotto}</p>
              {/* <p className={'homeLogoRightT'}>{schoolObj?.schoolName}</p>
              <p className={'homeLogoRightB'}>{schoolObj?.email}</p> */}
            </div>
            :<div className={''}></div>}
          </div>
          {/* :
              <Link to="/home">
                <img src={imgUrlForZhongZhi(isJiaoXueKongJianPage ? 'teacherLogoBlue.png' : 'homeLogoBlue.png')} alt="" />
              </Link>
          } */}

        </div>

        {
          isJiaoXueKongJianPage && <div className="sheader-jiaoXueKongJianLine"></div>
        }
        {
          isJiaoXueKongJianPage && <span className="sheader-jiaoXueKongJianText">{isJiaoXueKongJianPage}</span>
        }
        <nav className="flex-grow-1 d-flex navWrap">
          {
            // isJiaoXueKongJianPage ? <NavForJiaoXueKongJian /> : <Nav />
            // window.location.pathname?.indexOf('teachersspace') != -1 ? <NavForJiaoXueKongJian /> : window.location.pathname?.indexOf('demonspace') != -1 ? <NavForJiaoXueKongJiandemon /> : <Nav />
            window.location.hash?.indexOf('teachersspace') != -1 ? (courseSource == 2 ? <NavForJiaoXueKongJianNavTwo /> : <NavForJiaoXueKongJian />) : window.location.hash?.indexOf('demonspace') != -1 ? <NavForJiaoXueKongJiandemon /> : <Nav />
          }
        </nav>
        <div className="sheader-user">
          <div className='sheader-user-img-container'>
            <img className='sheader-user-img' src={avatarUrl ? avatarUrl : imgUrlForZhongZhi('sheader-user-img-default.png')} alt="" />
            <div className='sheaderUserexit' onClick={this.logout}>退出</div>
          </div>
          {/* <img className="sheader-user-img" src={imgUrlForZhongZhi('sheader-user-img-default.png')} alt="" /> */}
          <span className="sheader-user-name">{this.props.user.fullName}</span>
          <div className="sheader-user-message" onClick={() => {
            this.props.history.push('/systemMessage');
            // this.count()
          }}>
            <img className="sheader-user-message" src={imgUrlForZhongZhi('sheader-user-message.png')} alt="" />
            {
              msgCount > 0 && <div className="sheader-user-message-tip" />
              // <div className="sheader-user-message-tip" />
            }
          </div>
        </div>
        {
          isSignOut &&  <Modal
            className="class_modeld"
            style={{width:'234px'}}
            visible={isSignOut}
            onCancel={this.handleDissolveChange}
            footer={null}
            destroyOnClose
          >
          <div className="class_modeld-con" >
            <div className='class_modeld_center' style={{color:'#686F7E'}}>
              确定要退出登录吗？
            </div>
            <div className='class_modeld_button'>
              <button className='class_modeld_buttonno' onClick={()=>{
                this.setState({isSignOut:false})
              }}>取消</button>
              <button onClick={()=>{this.logout()}} className='class_modeld_buttonyes'>确定</button>
            </div>

          </div>
          </Modal>
        }
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  clearToken() {
    dispatch(clearToken());
  },
  updateMsgCount(msgCount) {
    dispatch(updateMsgCount(msgCount));
  },
});

export default withRouter(connect((state) => {
  return {
    portraitId: state.user.portraitId,
    userData: state.user,
    mediaId: state.mediaId,
    user: state.user,
    msgCount: state.msgCount
  };
}, mapAction)(Header));
