import React from "react";
import {message, Upload} from 'antd';
import {uploadFile} from '@/api';
import {getIp, imgUrlForZhongZhi} from '@/utils';
import {connect} from 'react-redux';
import _ from 'lodash';
import './index.scss';
import env from "@/env";

const preview = getIp();
const {xDocUrl, xDocUrlHttps} = env;

class TeachPlan extends React.Component {
  constructor() {
    super();
    this.state = {
      resourceExt: 'https://cdn.zjyjc.com/%E8%B5%84%E6%BA%90/%E6%95%99%E8%BE%85/868ecab2c048829aac46b93a6fa734d6.pptx'
    };
  }

  componentDidMount() {
  }

  beforeUpload = file => {
    console.log(file);
    const isJpgOrPng = file.type === 'application/vnd.ms-powerpoint';
    if (!isJpgOrPng) message.error('你只能上传ppt格式文件!');

    // const isLt5M = file.size / 1024 / 1024 < 5;
    // if (!isLt5M) message.error('图片大小不能超过5MB!');

    return isJpgOrPng;
  }

  handleChange = async info => {
    console.log(info.file);
    if (info.file.status === "done") {
      const cdnUrl = _.get(info, "file.response.result.cdnUrl");
      const fileId = _.get(info, "file.response.result.fileId");
      if (cdnUrl) {
        message.success("上传成功");
        console.log(fileId);
        this.setState({
          resourceExt: cdnUrl
        });

        return false;
      }
    }

    if (info.file.status === "error") {
      message.error("上传失败,请重新上传");
    }
  };

  render() {
    const {resourceExt} = this.state;
    const uploadProps = {
      name: "file",
      action: uploadFile,
      headers: {token: this.props.token},
      beforeUpload: this.beforeUpload,
      onChange: this.handleChange,
      data: {
        uid: this.props.user?.uid,
        bizType: 4
      },
      // fileList: fileList   //上传文件列表
      // disabled:true
    };
    return (
      <div className='teach_plan_page'>
        <div className='teach_plan_page_con'>
          {
            resourceExt ?
              <iframe
                width='100%'
                height='100%'
                frameBorder='0'
                src={
                  resourceExt.includes("https")
                    ? xDocUrlHttps + resourceExt
                    : resourceExt.includes("http")
                      ? xDocUrl + resourceExt
                      : xDocUrl + preview + resourceExt
                }
              />
              :
              <div className='teach_plan_page_conno'>
                <img src={imgUrlForZhongZhi('upLoad.png')} alt=""/>
                <div>
                  <Upload {...uploadProps}>
                    <div className='button'>
                      上传课件
                    </div>
                  </Upload>
                </div>
                <p>还没有上传课件，请上传～</p>
              </div>
          }

        </div>
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  // clearToken() {
  //   dispatch(clearToken());
  // },
  // updateUser(user) {
  //   dispatch(updateUser(user));
  // },
});
export default connect(({user, token}) => ({user, token}), mapAction)(TeachPlan);
