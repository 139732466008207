/*
 * @Descripttion:
 * @version:
 * @Author: nliu
 * @Date: 2020-10-30 15:33:04
 * @LastEditors: nliu
 * @LastEditTime: 2021-01-11 14:57:11
 */
import { message } from 'antd';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as history from 'history';
import store from '../store';
import getHearders from '../utils';
import {clearToken} from "../store/action";

let timer: any = null;
function showError(msg: string): void {
  timer && clearTimeout(timer);
  timer = setTimeout(() => {
    message.destroy();
    message.error(msg);
  }, 1000);
}

export function request(config: AxiosRequestConfig): AxiosRequestConfig {
  const { token = '', user } = store.getState();
  return {
    timeout: 1,
    ...config,
    headers: {
      ...config.headers,
      ...getHearders(token),
      schoolId: (user as any).schoolId,
      uid: (user as any).uid,
    },
  };
}

export function response(response: AxiosResponse): AxiosResponse | any {
  const { data, status, statusText, config } = response;
  const { headers } = config;

  let msg = '网络错误~';

  if (statusText) msg = statusText;
  if (status >= 200 && status <= 300) {
    if (data) {
      if (headers.showAll1010 === 'true' && data.code !== 0) {
        return data;
      }
      if (data.code === 0) {
        if (data.result && data.result !== true) {
          return data.result;
        }
        if (data.result === '') {
          return data.result;
        }
        return data;
      }

      if ((data.code >= 400 && data.code < 500) || data.code === 4011) {

        if (data.code === 401 || data.code === 4011) {
          // let href = window.location.href;
          // if (href.includes('localhost')) {
          history.createHashHistory().push('/home');
          message.destroy();
          message.error('您的登录信息已过期,请重新登录');
          localStorage.clear();
          store.dispatch(clearToken())

          // } else {
          //   history.createHashHistory().push('/home');
          // }
          // showError(msg);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
      if (data.code === 2) {
        message.error('该账号被禁用');
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
      msg = data.message || msg;
    } else {
      msg = '服务器错误~';
    }
  }


  showError(msg);
  return Promise.reject(msg);
}
