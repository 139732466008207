import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './css/nav.scss';

function Nav() {
  const { pathname } = useLocation();
  const links = useMemo(
    () => [
      {
        name: '课程',
        path: '/demonspace/mycourse',
      },
    ],
    []
  );

  return (
    <>
      {
        links.map(({ name, path }) => (
          <Link
            to={path+(window as  any).location.hash}
            key={name}
            className={
              `mag-r-60 snav-link text-decoration-none ${(new RegExp(path, 'g')).test(pathname) ? 'active' : ''}`
            }
          >
            <span>{name}</span>
          </Link>
        ))
      }
    </>
  );
}

export default Nav;
