import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import RouterView from './router';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
// import axios from './axios';
// import { userUseRecordExit } from './api';

function App(props: any) {
  useEffect(() => {
    // 禁止页面拖动
    document.ondragstart = function () {
      return false;
    };
  }, []);

  // console.log(props)

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterView />
      </PersistGate>
    </Provider>
  );
}



export default App;
