import React, {Component} from 'react';
import {connect} from 'react-redux';
import {message, Modal} from 'antd';
import {updateCrumbsData} from '@/store/action';
import Crumbs from '@@/Crumbs';
import SelectTreeCmpForDetail from './components/SelectTreeCmpForDetail/index';
import SelectTreeCmpForSetting from './components/SelectTreeCmpForSetting/index';
import RichTextPreview from '@/components/RichTextPreview/index';
import Empty from '@/components/Empty/index';
import {imgUrlForZhongZhi, imgUrlForZhongZhiApi, getIp} from '@/utils';
import {getParam} from '@/utils/util';
import {
  getCourseInfo,
  addTeaCourse,
  applyExampleCourse,
  alreadJoin,
  getCatalogFile,
  getCatalogSetting,
  settingPermission,
  getResourceByCatalog,
  submitApply,
  createBackupCourse,
  recoverCourse
} from '@/api';

import axios from '../../axios';
import styles from './index.module.scss';
import env from "@/env";

const preview = getIp();
const {xDocUrl, xDocUrlHttps} = env;
const targetData = {};

class CourseDetailsDetailed extends Component {
  constructor() {
    super();
    this.state = {
      navType: 3,        // 1 教案   2 课件   3 学案
      courseVO: {},
      catalogList: [],
      isJoin: false,
      isShowDemonstrationCourse: false,
      applyType: [],
      platformJoin: false,
      schoolJoin: false,
      fileVOList: [],              // 详情类型<>1=教案&2=课件
      openUrl: '',
      isShowSet: false,
      modelType: 1,          // 模式<>1=自由模式&2=闯关模式
      setCatalogList: [],          // 设置的章节数
      delCatalogList: [],
      isShowRestoreHistoricalVersion: false,
      stepList: [],//预览内容
      catalogId: '',
      selectedKeys: '',
    };
  }

  toTop = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  componentDidMount() {
    let {catalogId} = getParam();
    this.getCourseInfo();
    this.getCatalogSetting();
    this.toTop();
    let selectedKeys = localStorage.getItem('/teachersspace/courseDetailsDetailed/selectedKeys');
    this.setState({
      catalogId,
      selectedKeys: selectedKeys || catalogId
    });
  }

  getCatalogSetting = () => {
    let {courseId} = getParam();
    axios.get(getCatalogSetting, {
      params: {
        courseId
      }
    }).then(res => {
      if (!res) return;
      let {catalogList, modelType, delCatalogList} = res;
      this.setState({
        setCatalogList: this.transformData(catalogList),
        modelType: modelType,
        delCatalogList
      });
    });
  }
  // 富文本内容预览内容
  getResourceByCatalog = (catalogId, attach = 1) => {
    let {id, courseId} = getParam();
    axios.get(getResourceByCatalog, {
      params: {
        catalogId,
        courseId: courseId || id,
        attach
      }
      // params: {
      //   catalogId:'05c9d80054d2528d5639710a5c222aef',
      //   courseId:'0a6d0fb2287d732a62907ede09070115'
      // }
    }).then(res => {
      if (!res) return;
      this.setState({
        stepList: res.stepList
      });
      console.log(res);
    });
  }
  getCatalogFile = catalogId => {
    let {id, courseId} = getParam();
    axios.get(getCatalogFile, {
      params: {
        catalogId,
        courseId: courseId || id,
      }
    }).then(res => {
      if (!res) return;
      let {fileVOList} = res;
      this.setState({
        fileVOList,
        openUrl: fileVOList?.find(item => item?.detailType === this.state.navType)?.fileUrl
      });
    });
  }

  transformData = catalogList => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (childList && Array.isArray(childList)) {
        return {...item, name: item?.catalogName, children: this.transformData(item?.childList)};
      }
      return {...item, name: item?.catalogName};
    });
  }

  getChildren = (arr = [], ary, ary_) => {
    const first = arr[0];
    const first_ = arr[0];
    ary.push(first?.id);
    ary_.push(first_);
    if (first?.children) {
      this.getChildren(first?.children, ary, ary_);
    }
  }

  getChildrenId(data) {
    const ary = [];
    const ary_ = [];
    this.getChildren(data, ary, ary_);
    return {
      ary,
      ary_,
    };
  }

  getCourseInfo = () => {
    let {courseId, catalogId} = getParam();
    catalogId = localStorage.getItem('/teachersspace/courseDetailsDetailed/selectedKeys') || catalogId;
    axios.get(getCourseInfo, {
      params: {
        courseId,
      },
    }).then(res => {
      if (!res) return;
      let {courseVO, catalogList} = res;
      this.setState({
        courseVO,
        catalogList: this.transformData(catalogList),
      }, () => {
        const {ary} = this.getChildrenId(this.state.catalogList);
        //获取路由传参的章节
        this.getCatalogFile(catalogId);
        // this.getCatalogFile(ary[ary?.length - 1]);
        //
        this.getResourceByCatalog(catalogId);
        // this.getResourceByCatalog(ary[ary?.length - 1]);
        console.log(catalogList, catalogId);
        this.loopsTree(catalogList)
        console.log("targetData", targetData);
        //获取父节点

        const target = this.getNode(catalogId)
        console.log("target-----------------", target);
        this.setState({
          // catalogId: ary[ary?.length - 1],
          target
        });
      });
      this.setTitle(1);
    });
  }
  loopsTree = (data = [], parent) => {
    return data.map(({childList, id: value}) => {
      const node = {
        value,
        parent
      }
      targetData[value] = node;
      node.childList = this.loopsTree(childList, node);
      return
    })
  }

  getNode = (value) => {
    let node = [];
    let currentNode = targetData[value];
    console.log("currentNode", currentNode);
    if (currentNode) {
      node.push(currentNode.value);
      if (currentNode.parent) {
        node = [...this.getNode(currentNode.parent.value), ...node];
      }
      return node

    }
  }

  setTitle = (navType) => {
    let navName = '教案';
    if (navType === 2) {
      navName = '教学资源';
    } else if (navType === 3) {
      navName = '进入学习';
    }
    this.props.updateCrumbsData([
      {
        name: window.location.hash?.indexOf('courseCenter') != -1 ? '课程中心' : '教学空间',
        path: window.location.hash?.indexOf('courseCenter') != -1 ? '/courseCenter' : '/teacher'
      },
      {
        name: '课时详情',
        path: '-1'
        // path: window.location.hash?.indexOf('teachersspace') != -1 ? '/teachersspace/mycourse' : '/demonspace/mycourse'
      },
      {
        name: navName,
        path: ''
      },
    ]);
  }

  addTeaCourse = () => {
    let {courseId} = getParam();
    axios.get(addTeaCourse, {
      params: {
        courseId
      }
    }).then(res => {
      // this.setState({
      //   isJoin: res
      // });
      if (res?.result) {
        message.destroy();
        message.success('加入我的课程成功，快进入教学空间-我的课程查看吧');
      }
    });
  }

  schoolLesson = (selectapplyType) => {
    this.setState(({applyType}) => ({
      applyType: applyType.indexOf(selectapplyType) == -1
        ? [...applyType, selectapplyType]
        : applyType?.filter(item => item != selectapplyType)
    }), () => {
      console.log(this.state.applyType);
    })
  }

  // 申请示范课
  applyExampleCourse = async () => {
    this.setState({
      isClassModal: !this.state.isClassModal
    });
    const {schoolId, courseId} = getParam();
    const param = {
      applyType: this.state.applyType?.length == 2 ? 'all' : this.state.applyType[0],
      courseId,
      schoolId
    };
    const res = await axios.post(applyExampleCourse, param);
    console.log(res);
    this.alreadJoin();
    this.setState({
      isShowDemonstrationCourse: false
    }, () => {
      message.success('提交成功，请等待管理员审核')
    });
  }

  alreadJoin = () => {
    const {courseId} = getParam();
    if (!courseId) return;
    axios.get(alreadJoin, {
      params: {
        courseId
      }
    }).then(res => {
      if (!res) return;
      let {platformJoin, schoolJoin} = res;
      this.setState({
        platformJoin,
        schoolJoin
      });
    });
  }

  // 切换章节
  onTreeChapter = (selectChapterValue, expandedKeys) => {
    document.documentElement.scrollTop = 0;
    this.getCatalogFile(expandedKeys[expandedKeys?.length - 1]);
    this.getResourceByCatalog(expandedKeys[expandedKeys?.length - 1]);
    this.setState({
      catalogId: expandedKeys[expandedKeys?.length - 1],
    });
  }
  changeSelectedKeys = selectedKeys => {
    localStorage.setItem('/teachersspace/courseDetailsDetailed/selectedKeys', selectedKeys);
    this.setState({
      selectedKeys
    });
  }
  onNodeSelect = () => {
    this.changeNavType(3)
  }
  changeNavType = navType => {

    this.setState({
      navType,
      openUrl: this.state.fileVOList?.find(item => item?.detailType === navType)?.fileUrl
    });
    this.setTitle(navType);
  }

  attendClass = () => {
    let {catalogId} = this.state;
    let {id} = getParam();
    this.props.history.push(`/attendClass?catalogId=${catalogId}&courseId=${id}`);
  }

  changeStateChildren = (catalogList, checkedId, setting) => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (childList && Array.isArray(childList)) {
        return {...item, setting, children: this.changeStateChildren(item?.children, checkedId, setting)};
      }
      return {...item, setting};
    });
  }

  changeState = (catalogList, checkedId, setting) => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (checkedId === item?.id) {
        if (childList && Array.isArray(childList)) {
          return {...item, setting, children: this.changeStateChildren(item?.children, checkedId, setting)};
        }
        return {...item, setting};
      } else {
        if (childList && Array.isArray(childList)) {
          return {...item, children: this.changeState(item?.children, checkedId, setting)};
        }
        return {...item};
      }
    });
  }

  // changeState = (catalogList, checkedId) => {
  //   return catalogList?.map(item => {
  //     let childList = item?.childList;
  //     if (childList && Array.isArray(childList)) {
  //       return { ...item, name: item?.catalogName, children: this.transformData(item?.childList) };
  //     }
  //     return { ...item, name: item?.catalogName };
  //   });
  // }

  upDataSettingTree = (checkedId, setting) => {
    let {setCatalogList} = this.state;
    this.setState({
      setCatalogList: this.changeState(setCatalogList, checkedId, setting)
    }, () => {
      this.setState({
        setCatalogList: this.changeParentState(this.state.setCatalogList)
      }, () => {
        this.setState({
          setCatalogList: this.changeParentState(this.state.setCatalogList)
        });
      });
    });
  }

  changeParentState = setCatalogList => {
    return setCatalogList?.map(item => {
      if (item?.children && Array.isArray(item?.children)) {
        return {
          ...item,
          setting: item?.children?.every(i => i?.setting),
          children: this.changeParentState(item?.children),
          childList: this.changeParentState(item?.childList)
        };
      }
      return item;
    });
  }

  transformDataResvers = catalogList => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (childList && Array.isArray(childList)) {
        return {...item, childList: this.transformDataResvers(item?.children)};
      }
      return item;
    });
  }

  findCheckedIds = catalogList => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (item?.setting) {
        this.checkedIds.push(item?.id);
      }
      if (childList && Array.isArray(childList)) {
        this.findCheckedIds(item?.children);
      }
    });
  }

  settingPermission = () => {
    let {courseId} = getParam();
    let {setCatalogList, modelType, delCatalogList} = this.state;
    this.checkedIds = [];
    this.findCheckedIds(setCatalogList);
    if (!modelType) {
      message.destroy();
      message.error('请选择学习模式');
      return;
    }
    axios.post(settingPermission, {
      courseId,
      delCatalogList,
      modelType,
      catalogList: [],
      chooseCatalogList: this.checkedIds
      // catalogList: this.transformDataResvers(setCatalogList)
    }).then(res => {
      if (res) {
        message.destroy();
        message.success('设置成功');
        this.setState({
          isShowSet: false
        });
      }
    });
  }

  submitForReview = () => {
    const {courseId} = getParam();
    if (!courseId) return;
    axios.get(submitApply, {
      params: {
        courseId
      }
    }).then(res => {
      if (!res) return;
      message.success('提交成功');
      console.log(res);
      this.getCourseInfo();
      this.getCatalogSetting();
    });
  }

  restoreHistoricalVersionOk = () => {
    //恢复历史版本
    const {courseId} = getParam();
    axios.post(recoverCourse, {courseId}).then(res => {
      console.log(res);
      // if (!res) return;
      if (!res) return;
      message.success("恢复成功");
      this.setState({
        isShowRestoreHistoricalVersion: false
      })
      this.getCourseInfo();
      this.getCatalogSetting();
    });

  }
  // 示范课创建副本
  createBackupCourse = () => {
    let {id} = getParam();
    axios.get(createBackupCourse, {
      params: {
        courseId: id
      }
    }).then(res => {
      console.log(res);
      // if (!res) return;

    });
  }
  // 去课时编辑
  goCatalogEditor = () => {
    if (window.location.hash?.indexOf('demonspace') != -1) {
      this.createBackupCourse();
      this.props.history.push(`/demonspace/mycourse/catalogEditor?id=${getParam().id}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage}&isEdit=${1}`)
    } else {
      this.props.history.push(`/teachersspace/mycourse/catalogEditor?id=${getParam().id}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage}&isEdit=${1}`)
    }
  }

  render() {
    let {
      isEdit,
      courseId,
      isDemonstrationCourse,
      courseCatalogEdit,
      courseShare,
      isJiaoXueKongJianPage,
      isJiaoXueKongJianPage1,
      onlyCheck = ''
    } = getParam();
    let {
      navType,
      courseVO,
      catalogList,
      isJoin,
      isShowDemonstrationCourse,
      applyType,
      schoolJoin,
      platformJoin,
      openUrl,
      isShowSet,
      modelType,
      setCatalogList,
      isShowRestoreHistoricalVersion,
      target,
      expandedKeys,
      selectedKeys
    } = this.state;
    // let openUrl = 'https://cdn.zjyjc.com/%E8%B5%84%E6%BA%90/%E6%95%99%E8%BE%85/31a082c039cb612e3f13c4974b6f28a4.pptx'
    console.log("courseVO==", courseVO)
    console.log(isEdit, isDemonstrationCourse, onlyCheck, (isEdit && isDemonstrationCourse && !onlyCheck) === true)
    return (
      <div className={styles.wrapper}>
        <div className={styles.crumbsWrapper}><Crumbs/></div>
        <div className={styles.left}>
          <div className={styles.leftTop}>
            <img className={styles.coverImg}
                 src={courseVO?.coverFileId?.includes('http') ? courseVO?.coverFileId : imgUrlForZhongZhiApi(courseVO?.coverFileId)}
                 alt=""/>
            {/* <p>英语基础模块一</p> */}
            <p>{isJiaoXueKongJianPage === 'undefined' && isJiaoXueKongJianPage1 === 'undefined' ? '' : isJiaoXueKongJianPage || isJiaoXueKongJianPage1}</p>
            {
              !onlyCheck &&
              <>
                {
                  isEdit && isDemonstrationCourse ?
                    // 示范课
                    <>
                      {courseVO.hasExample != 2 && <div
                        className={`${styles.joinMyCourseBtn} ${courseVO?.join || isJoin ? styles.joinMyCourseBtnAdded : ''}`}
                        onClick={this.submitForReview}>提交审核</div>}
                    </>
                    :
                    <div
                      className={`${styles.joinMyCourseBtn} ${(courseVO?.join || isJoin) && false ? styles.joinMyCourseBtnAdded : ''}`}
                      onClick={() => {
                        if (isEdit) {
                          this.setState({
                            isShowDemonstrationCourse: true
                          });
                          return;
                        }
                        // if (!courseVO?.join && !isJoin) {
                        this.addTeaCourse();
                        // }
                      }}>
                      {
                        isEdit ? '申请示范课' : '加入我的课程'
                      }
                      {/* {
                    isEdit ? '申请示范课' : <>
                      {courseVO?.join || isJoin ? '已加入我的课程' : '加入我的课程'}
                    </>
                  } */}
                    </div>
                }
              </>

            }
          </div>
          <div className={styles.leftBottom}>
            {
              isEdit && courseCatalogEdit && JSON.parse(courseCatalogEdit) &&
              <div className={styles.titleContainerR} onClick={this.goCatalogEditor}>
                <img src={imgUrlForZhongZhi("titleContainerRImg.png")} alt=""/>
                <span>编辑</span>
              </div>
            }
            <div className={styles.leftBottomTitle}>目录</div>
            <div className={styles.leftBottomContent}>
              {
                catalogList?.length > 0 && target ?
                  <SelectTreeCmpForDetail selectTreeData={catalogList} defaultExpandedKeys={target}
                                          catalogId={getParam()?.catalogId} onTreeActive={this.onTreeChapter}
                                          onNodeSelect={this.onNodeSelect} selectedKeys={selectedKeys}
                                          changeSelectedKeys={this.changeSelectedKeys}/>
                  :
                  <div className={styles.leftBottomContentEmptyContainer}>
                    <img src={imgUrlForZhongZhi("leftBottomContentEmptyContainer.png")} alt=""/>
                    {courseCatalogEdit && JSON.parse(courseCatalogEdit) ?
                      <p>暂无目录，现在去<span>编辑</span></p>
                      :
                      <p>暂无目录</p>
                    }
                  </div>
              }
            </div>
            {
              isEdit ?
                <div className={styles.leftBottomFooter}>
                  <div className={styles.leftBottomFooterL} onClick={() => {
                    this.props.history.push(`/teachersspace/recycleBin?courseId=${courseId}&id=${getParam().id}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage}&isEdit=${getParam().isEdit}`);
                  }}>
                    <img src={imgUrlForZhongZhi("recycleBinImg.png")} alt=""/>
                    <span>回收站</span>
                  </div>
                  {
                    //审核中不展示
                    isEdit && isDemonstrationCourse && courseVO?.applyState != 0 ?
                      // 示范课
                      <div className={styles.leftBottomFooterR} onClick={() => {
                        this.setState({
                          isShowRestoreHistoricalVersion: true
                        });
                      }}>
                        <img src={imgUrlForZhongZhi("restoreHistoricalVersion.png")} alt=""/>
                        <span>恢复历史版本</span>
                      </div>
                      :
                      <div className={styles.leftBottomFooterR} onClick={() => {
                        if (setCatalogList?.length > 0) {
                          this.setState({
                            isShowSet: true
                          });
                        } else {
                          message.destroy();
                          message.warning('章节为空');
                        }
                      }}>
                        <img src={imgUrlForZhongZhi("studySetImg.png")} alt=""/>
                        <span>学习设置</span>
                      </div>
                  }
                </div>
                : ''
            }
          </div>
        </div>
        {
          false ?
            <div className={styles.right}>
              <div className={styles.rightEmpty}>
                <img src={imgUrlForZhongZhi("rightEmpty.png")} alt=""/>
              </div>
              <p className={styles.rightEmptyText}>当前无课程内容展示</p>
            </div>
            :
            <div className={styles.right}>
              {
                isEdit && !isDemonstrationCourse &&
                <div className={styles.attendClass} onClick={this.attendClass}>上课</div>
              }
              <div className={styles.rightTop}>
                <div className={`${styles.navItem} ${navType === 3 ? styles.active : ''}`}
                     onClick={this.changeNavType.bind(this, 3)}>进入学习
                </div>
                <div className={`${styles.navItem} ${navType === 2 ? styles.active : ''}`}
                     onClick={this.changeNavType.bind(this, 2)}>教学资源
                </div>
                <div className={`${styles.navItem} ${navType === 1 ? styles.active : ''}`}
                     onClick={this.changeNavType.bind(this, 1)}>教案
                </div>
              </div>
              <div className={styles.rightBottom}>
                {
                  navType === 1 && <div>
                    {
                      openUrl ? <iframe className={styles.iframe}
                                        src={openUrl.includes('https') ? (xDocUrlHttps + openUrl) : openUrl.includes('http') ? (xDocUrl + openUrl) : (xDocUrl + preview + openUrl)}
                                        width="100%" frameBorder='0'></iframe>
                        :
                        <Empty title="暂无内容"/>
                    }
                  </div>
                }
                {
                  navType === 2 && <div>
                    {
                      openUrl ? <iframe className={`${styles.iframe} ${styles.iframeCourse}`}
                                        src={openUrl.includes('https') ? (xDocUrlHttps + openUrl) : openUrl.includes('http') ? (xDocUrl + openUrl) : (xDocUrl + preview + openUrl)}
                                        width="100%" frameBorder='0'></iframe>
                        :
                        <Empty title="暂无内容"/>
                    }
                  </div>
                }
                {
                  navType === 3 && <div>

                    {console.log("652", this.state.stepList)}
                    {
                      this.state.stepList?.length > 0 ?
                        <RichTextPreview stepList={this.state.stepList}/>
                        :
                        <Empty title="暂无内容"/>
                    }

                  </div>
                }
              </div>
            </div>
        }
        <Modal
          className="class_modeld"
          visible={isShowDemonstrationCourse}
          onCancel={() => {
            this.setState({
              isShowDemonstrationCourse: false
            });
          }}
          footer={null}
        >
          <div className="class_modeld-con">
            <div className='class_modeld_title'>
              <span>请选择申请的示范课类型</span>（可多选）
            </div>
            <div className='class_modeld_center'>
              {/* <div style={{ color: schoolJoin ? 'gray' : '' }} onClick={() => { */}
              <div onClick={() => {
                // if (!schoolJoin) {
                this.schoolLesson('school');
                // }
              }}
                   className={applyType.indexOf('school') != -1 ? 'class_modeld_center_left class_modeld_center_right' : 'class_modeld_center_left'}>学校示范课
              </div>
              {/* <div style={{ color: platformJoin ? 'gray' : '' }} onClick={() => { */}
              <div onClick={() => {
                // if (!platformJoin) {
                this.schoolLesson('platform');
                // }
              }}
                   className={applyType.indexOf('platform') != -1 ? 'class_modeld_center_left class_modeld_center_right' : 'class_modeld_center_left'}>平台示范课
              </div>
            </div>
            <div className='class_modeld_button'>
              <button className='class_modeld_buttonno' onClick={() => {
                this.setState({
                  isShowDemonstrationCourse: false
                });
              }}>取消
              </button>
              <button onClick={this.applyExampleCourse} className='class_modeld_buttonyes'>确定</button>
            </div>
          </div>
        </Modal>
        {
          isShowSet && <div className={styles.showSetWraper}>
            <div className={styles.showSetContainer}>
              <p className={styles.showSetTitle}>权限设置</p>
              <img className={styles.showSetClose} onClick={() => {
                this.setState({
                  isShowSet: false
                });
              }} src={imgUrlForZhongZhi("showMoreCloseImg.png")} alt=""/>
              <div className={styles.showSetTop}>
                <p className={styles.showSetTopTitle}>学习模式</p>
                <div className={styles.showSetTopItem} onClick={() => {
                  this.setState({
                    modelType: 1
                  });
                }}>
                  <img src={imgUrlForZhongZhi(modelType === 1 ? "learning-mode-active.png" : 'learning-model.png')}
                       alt=""/>
                  <span>自由模式(全部课时均可查看)</span>
                </div>
                <div className={styles.showSetTopItem} onClick={() => {
                  this.setState({
                    modelType: 2
                  });
                }}>
                  <img src={imgUrlForZhongZhi(modelType === 2 ? "learning-mode-active.png" : 'learning-model.png')}
                       alt=""/>
                  <span>闯关模式(完成上一课时的任务点后解锁下一课时)</span>
                </div>
              </div>
              <div className={styles.showSetBottom}>
                <p className={styles.showSetTopTitle}>发放范围</p>
                {
                  setCatalogList?.length > 0 &&
                  <SelectTreeCmpForSetting expandedKeys={expandedKeys} selectTreeData={setCatalogList}
                                           upDataSettingTree={this.upDataSettingTree}
                                           onTreeActive={(selectChapterValue, expandedKeys) => {
                                             this.setState({
                                               expandedKeys
                                             });
                                           }}/>
                }
              </div>
              <div className={styles.showSetBtnContainer}>
                <div className={styles.showSetBtnL} onClick={() => {
                  this.setState({
                    isShowSet: false
                  });
                }}>取消
                </div>
                <div className={styles.showSetBtnR} onClick={this.settingPermission}>确定</div>
              </div>
            </div>
          </div>
        }
        {
          isShowRestoreHistoricalVersion && <div className={styles.showRestoreHistoricalVersionWraper}>
            <div className={styles.showRestoreHistoricalVersionContainer}>
              <p className={styles.showRestoreHistoricalVersionTitle}>恢复历史版本</p>
              <p className={styles.showRestoreHistoricalVersionContent}>确定取消当前编辑操作，把课程恢复到当前通过审核的示范课的状态吗？</p>
              <div className={styles.showRestoreHistoricalVersionBtnWrapper}>
                <div className={styles.showRestoreHistoricalVersionBtnL} onClick={() => {
                  this.setState({
                    isShowRestoreHistoricalVersion: false
                  });
                }}>取消
                </div>
                <div className={styles.showRestoreHistoricalVersionBtnR} onClick={this.restoreHistoricalVersionOk}>确定
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
});

export default connect(({crumbsData}) => ({crumbsData}), mapAction)(CourseDetailsDetailed);
