import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Tree, Empty, Modal } from "antd";
import _ from 'lodash'
import {
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import "./styles.less";
import {CatalogTitleType} from "../../../utils/publicType";

const { TreeNode } = Tree;
class SelectEditTreeCmpRight extends Component {
  editRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      expandedKeys: [],
      selectedKeys: [],
      parentKey: [],
      parentData: {
        title: '',
        key: '',
        childTitle: '',
        childKey: '',
        pos: ''
      }, // 保存给父级的数据
      delModalVisible: false,
      modalKey: {}
    }
    this.valueMap = {};
  }

  componentDidMount() {
    // 默认展开
    const { selectTreeData, type, value, expandedKeys } = this.props;
    const data = selectTreeData?.length && selectTreeData;
    const { ary, ary_ } = this.getChildrenId(data);
    this.setState({
      data,
      selectedKeys:ary
    })
    if (this.props.onRef) {
      this.props.onRef(this)
    }

    // const {ary: expandedKeys, ary_ } = this.getChildrenId(data);

    this.loops(data);

    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }

    let newExpandedKeys = expandedKeys || ary;
    if (newExpandedKeys.length !== 0) {
      if (type === 'bourseBefore') {
        this.setState({
          expandedKeys: value,
          parentData,
        })
      } else {
        this.setState({
          expandedKeys: newExpandedKeys,
          parentData,
        });
      }
    }
    document.addEventListener("keydown", this.enterFunction.bind(this), false);
  }
  enterFunction(event) {
    if (event.keyCode === 13) {
      const { data, editKey } = this.state
      this.closeNode(editKey, '', data);
    }
  }
  componentWillReceiveProps(nextProps) {
    const { selectTreeData, type, value,expandedKeys } = nextProps;
    const data = selectTreeData?.length && selectTreeData;
    const { ary_ } = this.getChildrenId(data);
    this.loops(data);
    const parentData = {
      ...this.state.parentData,
      title: ary_[0]?.name,
      key: ary_[0]?.id,
    }

    if (JSON.stringify(selectTreeData) !== JSON.stringify(this.state.data)) {
        this.setState({
          data: selectTreeData,
          parentData,
        });
    }
    if (JSON.stringify(expandedKeys) !== JSON.stringify(this.state.expandedKeys)) {
        this.setState({
          expandedKeys
        });
    }
  }

  transformDataChildren = catalogList => {
    return catalogList?.map(item => {
      let children = item?.children;
      if (children && Array.isArray(children)) {
        return { ...item, childList: this.transformDataChildren(item?.children) };
      }
      return { ...item };
    });
  }
  getChildren = (arr = [], ary, ary_) => {
    const first = arr[0];
    const first_ = arr[0];
    ary.push(first?.id);
    ary_.push(first_);
    if (first?.children) {
      this.getChildren(first?.children, ary, ary_)
    }
  }

  getChildrenId(data) {
    const ary = [];
    const ary_ = []
    this.getChildren(data, ary, ary_);
    return {
      ary,
      ary_,
    }
  }

  loops = (list, parent) => {
    return (list || []).map(({ children, id, name }) => {
      const node = (this.valueMap[id] = {
        parent,
        id,
        name,
      });
      node.children = this.loops(children, node);
      return node;
    });
  }

  getPath = (id) => {
    const path = [];
    const path_ = []
    let current = this.valueMap[id];
    while (current) {
      path.unshift(current);
      path_.unshift(current.id);
      current = current.parent;
    }

    return {
      path,
      path_
    }
  }

  //选中的回调
  onSelect = (_, obj) => {

    let { parentData, expandedKeys, data } = this.state;
    let objPath = this.getPath(obj.node.key);
    const index = expandedKeys.indexOf(obj.node.key);
    if (obj.node.className === "tree_node_title") {
      const parentData_ = {
        ...parentData,
        title: obj.node.title,
        key: obj.node.key,
        pos: obj.node.pos
      }
      // if (index !== -1) {
      //   this.setState({
      //     expandedKeys: [],
      //     parentData: parentData_,
      //   });
      // } else {
        this.setState({
          expandedKeys: objPath.path_,
          parentData: parentData_,
          selectedKeys:objPath.path_
        });
      // }
      this.props.onTreeSelect(data, objPath.path_, this.props.isModal, parentData_,obj.node.className)
    } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
      const parentData_ = {
        ...parentData,
        title: obj.node.title,
        key: obj.node.key,
        pos: obj.node.pos
      };
      // if (index !== -1) {
      //   objPath.path_.pop();

      //   this.setState({
      //     expandedKeys: objPath.path_,
      //     selectedKeys:objPath.path_
      //   });
      // } else {
        this.setState({
          expandedKeys: objPath.path_,
          selectedKeys:objPath.path_
        });
      // }
      this.props.onTreeSelect(data, objPath.path_, this.props.isModal, parentData_,obj.node.className);
    } else {
      const parentData_ = {
        ...parentData,
        childTitle: obj.node.title,
        childKey: obj.node.key,
        pos: obj.node.pos
      };

      this.setState({ parentData: parentData_, expandedKeys: objPath.path_,selectedKeys:objPath.path_ }, () => {
        if (!this.props.onTreeActive) return;
        // this.props.onTreeActive(this.state.parentData, objPath.path_);
      });
      this.props.onTreeSelect(data, objPath.path_, this.props.isModal, parentData_,obj.node.className)
    }
  };

  //展开的回调
  onExpend = (_, obj) => {
    const { parentData, data } = this.state;
    let objPath = this.getPath(obj.node.key);

    this.props.onTreeSelect(data, objPath.path_, this.props.isModal,{},obj.node.className)
    if (obj.expanded) {
      if (obj.node.className === CatalogTitleType.treeNodeTitle) {
        const parentData_ = {
          ...parentData,
          title: obj.node.title,
          key: obj.node.key,
          pos: obj.node.pos
        };

        this.setState({
          expandedKeys: [obj?.node?.key],
          parentData: parentData_,
        });
      } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
        this.setState({ expandedKeys: objPath.path_ });
      }
    } else {
      if (obj.node.className === CatalogTitleType.treeNodeTitle) {
        this.setState({ expandedKeys: [] });
      } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
        objPath.path_.pop();
        this.setState({ expandedKeys: objPath.path_ });
      }
    }
  };
  // 编辑
  onEdit = (e, key) => {
    e = e || window.event;
    e.stopPropagation();

    const { data } = this.state
    this.setState({
      editKey: key
    }, () => {
      this.editNode(key, data);
    })
  };
  // 输入
  onChange = (e, key) => {
    const { data } = this.state
    this.changeNode(key, e.target.value, data);
    this.setState({
      data
    })
  };

  // 关闭
  onClose = (e, key, defaultValue) => {
    e = e || window.event;
    e.stopPropagation();

    const { data } = this.state
    this.closeNode(key, defaultValue, data)
  };

  closeNode = (key, defaultValue, data) => {
    data.map((item) => {
      item.isEditable = false;
      // if (item.id === key) {
      //   item.value = defaultValue;
      // }
      if (item.children) {
        this.closeNode(key, defaultValue, item.children);
      } else {
        this.setState({
          // data:_.cloneDeep(data)
        })
      }
    });
  }
  // 传编辑
  editNode = (key, data) => {
    data.map((item) => {
      if (item.id === key) {
        item.isEditable = true;
      } else {
        item.isEditable = false;
      }
      // 当某节点处于编辑状态，并改变数据，点击编辑其他节点时，此节点变成不可编辑状态，value 需要回退到 defaultvalue
      item.value = item.defaultValue;
      if (item.children) {
        this.editNode(key, item.children);
      } else {
        this.setState({
          // data:_.cloneDeep(data)
        })
      }
    });
  }

  // 输入
  changeNode = (key, value, data) => {
    // console.log(value, 'value');
    data.map((item) => {
      if (item.id === key) {
        item.catalogName = value;
        item.name = value;
      }
      if (item.children) {
        this.changeNode(key, value, item.children);
      } else {
        this.setState({
          // data:_.cloneDeep(data)
        })
      }
    });
  }

  // 删除
  onDelete = (e, key) => {
    e = e || window.event;
    e.stopPropagation();


    this.setState({ delModalVisible: true, modalKey: key })

    // this.deleteNode(key, data);
    // setTreeData(data);
  };

  // 删除
  deleteNode = (key, data) =>
    data.map((item, index) => {
      if (item.id === key) {
        data.splice(index, 1);
        this.props.onTreeDel(key)

      }
      if (item.children) {
        this.deleteNode(key, item.children);
      } else {
        this.setState({
          // data:_.cloneDeep(data)
        })
      }

    });

  // 添加
  onAdd = (e) => {
    const { data,parentData } = this.state
    // console.log('add', data);
    // if (expandedKeys.indexOf(e) === -1) {
    //   expandedKeyss.push(e);
    // }
    this.addNode(e, data,parentData);
    // setExpandedKeys(expandedKeyss);
    // setTreeData(data);
    // this.loops(data);
    let changeBack = this.transformDataChildren(data)
    // console.log(changeBack);
    this.props.onTreeBackReturnData(changeBack)
    // this.setState({
    //   data:this.transformDataChildren(data)
    // })
  };
  addNode = (key, data,parentData) => {
    // console.log(key, data)
    data.map((item) => {
      // console.log(" ", item, key);
      // console.log("parentData",parentData);


      if (item.id === key) {
        let name = 'default';
        if (parentData?.pos) {
          // debugger
          let pos = parentData?.pos;
          let nameArr = pos.split('-').splice(1);
          for (let i in nameArr) {
            nameArr[i] = Number(nameArr[i]) + 1
          }
          nameArr.push((item?.children||[]).length+1)
          // nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1] + 1;
          // console.log("nameArr",nameArr)
          name = nameArr.join(".")+ " "+"目录"
        }

        if (item.children) {
          this.props.onTreeZiAdd(key + '_' + (item.children.length + 1))
          item.children.push({
            name,
            catalogName:name,
            defaultValue: name,
            id: key + '_' + (item.children.length + 1), // 这个 key 应该是唯一的。 Tip: The key should be unique
            parentKey: key,
            isEditable: false,
            displayOrder: item?.children[item.children.length - 1].displayOrder + 1,
            nodeId: item?.id,
            seniorId: item?.id,
            setting: true,
            treeCode: "",
            hasDel: 0,
            hasSureDel: 0,
          });
        } else {
          item.children = [];
          item.childList = [];
          this.props.onTreeZiAdd(key + '_' + 1)
          item.children.push({
            name,
            catalogName: name,
            defaultValue: name,
            id: key + '_' + 1, // 这个 key 应该是唯一的。 Tip: The key should be unique
            parentKey: key,
            isEditable: false,
            displayOrder: 1,
            nodeId: item?.id,
            seniorId: item?.id,
            setting: true,
            treeCode: "",
            hasDel: 0,
            hasSureDel: 0,
          });
        }
        return;
      }
      if (item.children) {
        this.addNode(key, item.children,parentData);
      }
    });
  }

  renderTitle = ({ ele, isChildren, isOuterParent }) => {
    if (this.props.isModal) {
      return (
        <div>
          <span className="numberOfTitle">{ele.name}</span>
        </div>
      )
    } else {
      if (ele.isEditable) {
        return (<div onClick={this.catCurriculum.bind(this, ele?.id)}>
          <input
            ref={this.editRef}
            className={'inputField'}
            value={ele?.name}
            onChange={(e) => this.onChange(e, ele.id)}
            onBlur={(e) => {
              this.onClose(e, ele.id, ele.defaultValue)
            }}
            autoFocus="autoFocus"
          />
          <CheckOutlined
            type="check"
            style={{ marginLeft: 10 }}
            onClick={(e) => this.onClose(e, ele.id, ele.defaultValue)}
          />
          {
            // ele?.fileCount && <span className="numberOfClassHours">{ele?.fileCount}</span>
          }
          {/* <div className="numberOfTitleRightContainer">
        <span className='numberOfTitleRightText'>查看课程</span>
        <img className='numberOfTitleRightImg' src={imgUrlForZhongZhi("numberOfTitleRightImg.png")} alt="" />
      </div> */}
        </div>);
      } else {
        return (
          <div className="editNodeBox">
            <span className="numberOfTitle">{ele.name}</span>
            <span className='operationField'>
              <EditOutlined
                style={{ marginLeft: 20 }}
                type="edit"
                onClick={(e) => this.onEdit(e, ele.id)}
              />
              {ele.parentKey === '0' ? null : (
                <DeleteOutlined
                  style={{ marginLeft: 10 }}
                  type="edit"
                  onClick={(e) => this.onDelete(e, ele.id)}
                />
              )}
            </span>
          </div>
        )
      }
    }


  }

  mapData = (children) => {
    if (children && Array.isArray(children)) {
      return children.map((ele) => {
        if (ele.children && Array.isArray(ele.children)) {
          return (
            <TreeNode
              // title={ele.name}
              // title={this.renderTitle(ele,true)}
              title={this.renderTitle({ ele, isChildren: true })}
              key={ele.id}
              className="tree_children_title"
            >
              {this.mapData(ele.children)}
            </TreeNode>
          );
        } else {
          return (
            <TreeNode
              // title={ele.name}
              title={this.renderTitle({ ele })}
              key={ele.id}
              className="tree_inside_title"
            />
          );
        }
      });
    }
    return [];
  };

  render() {
    const content = [];
    const { data, delModalVisible, modalKey,expandedKeys,selectedKeys } = this.state;
    let {firstExpandedKeys=[]} = this.props;
    data &&
      data.map((item) => {
        if (item) {
          content.push(
            <TreeNode
              // title={item.name}
              title={this.renderTitle({ ele: item, isOuterParent: true })}
              key={item.id}
              className="tree_node_title"
            >
              {this.mapData(item.children)}
            </TreeNode>
          );
        }
      });
      // let expandedKeys = [];
      // data?.length>0?expandedKeys = this.state.expandedKeys||this.getChildrenId(data).ary:null
      // console.log(this.getChildrenId(data).ary,"ary")
    return (
      <div className="components_selectTree" >
        {data?.length > 0 ? (
          <Tree
            onExpand={this.onExpend}
            // expandedKeys={['16bd73ae61f94f3696254a310ff024e6', 'fb9978bd20ced7d64fd6f83acf1eef3b', '99fd3d47adac40f1b753e652f831f4e2']}
            expandedKeys={expandedKeys.length>0?expandedKeys:''}
            selectedKeys={firstExpandedKeys.length>0?[firstExpandedKeys[firstExpandedKeys?.length-1]]:[selectedKeys[selectedKeys?.length-1]]}
            // expandedKeys={ ['e4f52dc3725e33798b9029fb46033895', '6a19bf546077e89da10cce83ffa90a2e']}
            onSelect={this.onSelect}
          >
            {content}
          </Tree>
        ) : (
          <Empty description={false} imageStyle={{ height: 215 }} />
        )}


        {delModalVisible ? <Modal
          title="删除"
          cancelText="取消"
          okText="确认"
          visible maskClosable={false} closable={false} width={600} onCancel={() => {
            this.setState({ delModalVisible: false })
          }}
          onOk={() => {
            this.setState({
              delModalVisible: false
            }, () => {
              // console.log("删除")
              this.deleteNode(modalKey, data);
            })
          }}
        >
          请确认是否删除课程目录下的课时内容?
        </Modal> : null}
      </div>
    );
  }
}

export default withRouter(SelectEditTreeCmpRight);
