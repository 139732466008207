import { RouteProps, RouteComponentProps } from "react-router-dom";

import Home from "../page/Home/Home.js"; // 首页
import LoginBackstage from "../page/LoginBackstage/LoginBackstage"; // 后台中间页
import Layout from "../components/Layout/Layout";
import LayoutEdit from "../components/Layout/LayoutEdit";
import zzgg from "../page/zzgg"; // 公告
import notice_detail from "../page/notice_detail"; // 公告详情
import MyCourse from "../page/MyCourse"; // 课程
import ClassLearning from "../page/ClassLearning"; // 班级学情
import SystemMessage from "../page/SystemMessage"; // 系统消息列表
import CourseCenter from "../page/CourseCenter/index"; // 课程中心
import CourseDetails from "../page/CourseDetails/index"; // 课程详情
import TeachingResearch from "../page/TeachingResearch/index"; // 教研
import CourseDetailsDetailed from "../page/CourseDetailsDetailed/index"; // 课程详情学习
import RecycleBin from "../page/RecycleBin/index"; // 回收站
import courseEditor from "../page/courseEditor"; // 编辑简介
import spaceCourseDetails from "../page/spaceCourseDetails/index"; // 课程详情
import catalogEditor from "../page/catalogEditor";// catalog目录编辑
import AttendClass from "../page/AttendClass/AttendClass";// 上课
import LearnCase from "../page/LearnCase";// catalog目录编辑
import PreviewOfTestQuestions from "../page/PreviewOfTestQuestions/PreviewOfTestQuestions.js"; // 试题预览
import UserAgreement from "../page/UserAgreement/UserAgreement";//用户协议
import EditOfTestQuestions from "../page/catalogEditor/components/EditOfTestQuestions/EditOfTestQuestions"; // 试题编辑
import LoginWX from "../page/LoginWX/LoginWX"; // 微信扫码登录不同域名中转页面
import ContentPreview from "../page/catalogEditor/ContentPreview/index.jsx";


interface Routes extends RouteProps {
  routes?: Array<RouteProps>;
  component?:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>
  | any;
}

const routes: Array<Routes> = [
  {
    path: "/loginBackstage",
    component: LoginBackstage,
  },
  {
    path: "/loginWX",
    component: LoginWX,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/attendClass",
    component: AttendClass,
  },
  {
    path: "/previewOfTestQuestions",
    component: PreviewOfTestQuestions,
  },
  {
    path: "/editOfTestQuestions",
    component: EditOfTestQuestions,
  },
  {
    path: "/teachersspace/mycourse/catalogEditor",
    component: LayoutEdit,
    routes: [
      { path: "/teachersspace/mycourse/catalogEditor", component: catalogEditor },
    ],
  },
  {
    path: "/demonspace/mycourse/catalogEditor",
    component: LayoutEdit,
    routes: [
      { path: "/demonspace/mycourse/catalogEditor", component: catalogEditor },
    ],
  },
  {
    path: "/",
    component: Layout,
    routes: [
      { path: "/zzgg/notice_dateil", component: notice_detail },
      { path: "/zzgg", component: zzgg },
      { path: "/teachersspace/mycourse/courseeditor", component: courseEditor },
      // { path: "/teachersspace/mycourse/catalogEditor", component: catalogEditor },
      { path: "/teachersspace/mycourse", component: spaceCourseDetails },
      { path: "/teachersspace/class_learning", component: ClassLearning },
      { path: "/teachersspace/teaching_research", component: TeachingResearch },
      { path: "/teachersspace/courseDetailsDetailed", component: CourseDetailsDetailed },
      { path: "/demonspace/courseDetailsDetailed", component: CourseDetailsDetailed },
      { path: "/demonspace/mycourse/courseeditor", component: courseEditor },
      { path: "/demonspace/mycourse", component: spaceCourseDetails },
      { path: "/teacher", component: MyCourse },
      { path: "/systemMessage", component: SystemMessage },
      { path: "/courseCenter/courseDetails", component: CourseDetails },
      { path: "/courseCenter/courseDetailsDetailed", component: CourseDetailsDetailed },
      { path: "/courseCenter", component: CourseCenter },
      { path: "/teachersspace/recycleBin", component: RecycleBin },
      { path: "/teachersspace/learncase", component: LearnCase },
      { path: "/UserAgreement", component: UserAgreement },
      { path: "/ContentPreview", component: ContentPreview },
    ],
  },
];

export default routes;
