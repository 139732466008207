import React, { useEffect } from "react";
import {
  Route,
  Switch,
  // BrowserRouter as Router,
  HashRouter as Router,
  Redirect,
  // useHistory
} from "react-router-dom";
import { Dispatch } from 'redux';
import { updateToken, updateUser } from '../store/action';
// import { getIp } from '../utils'
import routes from "./routes";
import { connect } from "react-redux";
import { ROUTER_BASE } from "../config";

interface Data {
  token?: string;
  updateToken?: Function;
  updateUser?: Function;
}

function RouterView(props: Data) {
  const token = props?.token;

  useEffect(() => {

  }, []);

  return (
    <>
      <Router basename={ROUTER_BASE}>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={(props) =>

                // !token && props.location.pathname !== '/login' && props.location.pathname !== '/ssologin' && props.location.pathname !== '/loginAndroid' && props.location.pathname !== '/loginIos' && props.location.pathname !== '/agreement' ? (
                !token && !props.location.pathname.includes("/loginBackstage")
                  && !props.location.pathname.includes("/home")
                  && !props.location.pathname.includes("/loginWX")
                  // !props.location.pathname.includes("/login") &&
                  //   !props.location.pathname.includes("/agreement") && !props.location.pathname.includes("/loginBackstage")
                  ? <Redirect to="/home" />
                  :
                  <route.component {...props} routes={route.routes} />
              }
            />
          ))}
        </Switch>
      </Router>
    </>

  );
}

const mapAction = (dispatch: Dispatch) => ({
  updateToken(token: string) {
    dispatch(updateToken(token));
  },
  updateUser(user: object) {
    dispatch(updateUser(user));
  },
});

export default connect((state) => ({ ...state }), mapAction)(RouterView);
