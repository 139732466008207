import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input, message, Upload } from "antd";
import md5 from 'md5';
import { Radio } from 'antd';
import ImgCrop from "antd-img-crop";
import _ from 'lodash';
import { updateCrumbsData, clearToken, updateUser } from '@/store/action';
import { imgUrlForZhongZhi } from '@/utils';
import { getParam } from '@/utils/util';
import {
  queryTeacherInformation,
  updateGender,
  updateStudentPassWord,
  uploadFile,
  updateAvatar,
  modifyMobileByPassWord,
  getCode,
  removeWxMobile
} from '@/api';
import axios from '@/axios';
import styles from './index.module.scss';

class Personal extends Component {
  constructor() {
    super();
    this.state = {
      isShowChangePassword: false,
      isShowChangePhone: false,
      isShowUnboundWechat: false,
      originalPassword: '',
      newPassword: '',
      isWrong: false,
      isPhoneWrong: false,
      phonePassword: '',
      phoneNumber: '',
      verifyCode: '',
      count: 0,                    // 倒计时
      personNolData: {

      },
      gender: '',                   // 性别 0 男  1女
    };
  }

  componentDidMount() {
    this.props.updateCrumbsData([
      {
        name: '教师空间',
        path: ''
      },
      {
        name: '个人中心',
        path: ''
      },
      // {
      //   name: '个人中心',
      //   path: ''
      // },
    ]);
    this.queryTeacherInformation();
  }

  removeWxMobile = () => {
    this.setState({
      isShowUnboundWechat: false
    });
    axios.post(removeWxMobile, {
      userId: this.props.user?.uid
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('解绑成功');
      this.props.clearToken();
    });
  }

  updateGender = (gender) => {
    let uid = this.props.user?.uid;
    if (!uid) return;
    axios.post(updateGender, {
      gender,
      uid
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('修改成功');
      this.queryTeacherInformation();
    });
  }

  updateStudentPassWord = () => {
    let uid = this.props.user?.uid;
    let { originalPassword, newPassword } = this.state;
    if (!uid) return;
    if (!newPassword) return;
    if (!originalPassword) return;
    axios.post(updateStudentPassWord, {
      newPassWord: md5(newPassword),
      oldPassWord: md5(originalPassword),
      uid
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('修改成功');
      this.props.clearToken();
    });
  }

  queryTeacherInformation = () => {
    let uid = this.props.user?.uid;
    axios.post(queryTeacherInformation, {
      uid
    }).then(res => {
      if (res) {
        this.setState({
          personNolData: res,
          gender: res?.gender || 0
        });
      }
    });
  }

  onChange = e => {
    this.setState({
      gender: e.target.value
    });
    this.updateGender(e.target.value);
  }

  cancle = () => {
    this.setState({
      isShowChangePassword: false,
      isShowChangePhone: false,
      isShowUnboundWechat: false,
    });
  }

  prevent = e => {
    e && e.stopPropagation();
  }

  unboundWechatOk = () => {
    this.removeWxMobile();
  }

  changeOriginalPassword = (e) => {
    this.setState({
      originalPassword: e.target.value
    });
  }

  changeNewPassword = (e) => {
    this.setState({
      newPassword: e.target.value
    });
  }

  changePhonePassword = (e) => {
    this.setState({
      phonePassword: e.target.value
    });
  }

  changePhoneNumber = (e) => {
    this.setState({
      phoneNumber: e.target.value
    });
  }

  changePasswordOk = () => {
    let { newPassword } = this.state;
    let len = newPassword?.length;
    if (len < 6 || len > 16) {
      this.setState({
        isWrong: true
      });
      return;
    }
    this.setState({
      isWrong: false
    }, () => {
      this.updateStudentPassWord();
    });
  }

  changePhoneOk = () => {
    let { phonePassword, phoneNumber, verifyCode } = this.state;
    axios.post(modifyMobileByPassWord, {
      password: md5(phonePassword),
      mobile: phoneNumber,
      code: verifyCode,
      type: 2,
      userId: this.props.user.uid
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('修改成功');
      this.setState({
        isShowChangePhone: false
      });
    });
  }

  getCode = () => {
    let { phoneNumber } = this.state;
    if (!phoneNumber) {
      message.destroy();
      message.warning('请输入手机号');
      return;
    }
    let reg = /^(13[0-9]|14[0-9]|15[0-9]|166|17[0-9]|18[0-9]|19[8|9])\d{8}$/;
    if (reg.test(phoneNumber + '')) {
      this.setState({
        isPhoneWrong: false
      });
      // 获取验证码
      axios.post(getCode, {
        mobile: phoneNumber,
        "type": 2
      }).then(() => {
        message.success('发送成功');
        this.setState({
          count: 60
        }, () => {
          this.timer = setInterval(() => {
            let { count } = this.state;
            if (!count) {
              clearInterval(this.timer);
              return;
            }
            this.setState({
              count: count - 1
            });
          }, 1000);
        });
      });
    } else {
      this.setState({
        isPhoneWrong: true
      });
    }
  }

  updateAvatar = (avatarFileId) => {
    axios.post(updateAvatar, {
      avatarFileId,
      uid: this.props.user.uid
    });
  }

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) message.error('你只能上传JPG/PNG格式文件!');

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) message.error('图片大小不能超过5MB!');

    return isJpgOrPng && isLt5M;
  }

  handleChange = async info => {
    if (info.file.status === "done") {
      const cdnUrl = _.get(info, "file.response.result.cdnUrl");
      const fileId = _.get(info, "file.response.result.fileId");
      if (cdnUrl) {
        message.success("上传成功");
        this.updateAvatar(fileId);
        this.props.updateUser({ ...this.props.user, avatarUrl: cdnUrl });
        return false;
      }
    }

    if (info.file.status === "error") {
      message.error("上传失败,请重新上传");
      return;
    }
  };

  render() {
    let { isShowUnboundWechat, isShowChangePassword, originalPassword, newPassword, isWrong, isShowChangePhone, isPhoneWrong, phonePassword, phoneNumber, verifyCode, count, personNolData, gender } = this.state;
    const imgCropProps = {
      width: 500, //裁剪宽度
      height: 300, //裁剪高度
      resize: true, //裁剪是否可以调整大小
      resizeAndDrag: true, //裁剪是否可以调整大小、可拖动
      modalTitle: "上传图片", //弹窗标题
      modalWidth: 600, //弹窗宽度
      shape: 'round',
      // disabled:true
    };

    const uploadProps = {
      name: "file",
      action: uploadFile,
      headers: { token: this.props.token },
      data: {
        uid: this.props.user?.uid,
        bizType: 1
      },
      listType: "picture-card",
      className: "avatar-uploader",
      showUploadList: false,
      beforeUpload: this.beforeUpload,
      onChange: this.handleChange,
      // disabled:true
    };

    let { avatarUrl } = this.props.user
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <span className={styles.title}>基本信息</span>
          <div className={styles.avgatorContainer}>
            <ImgCrop {...imgCropProps} >
              <Upload {...uploadProps}>
                <div className={avatarUrl ? styles.avgatorDiv : personNolData?.avatarUrl? styles.avgatorDiv : styles.avgatorDiv1 }>
                  <div>
                    <img src={avatarUrl ? avatarUrl : (personNolData?.avatarUrl || imgUrlForZhongZhi('avatarDefault.png'))} alt="" />
                  </div>
                </div>
              </Upload>
            </ImgCrop>
            {/* <div>
              <div>
                <img src={personNolData?.avatarUrl ? personNolData?.avatarUrl : imgUrlForZhongZhi('personage-center.png')} alt="" />
              </div>
            </div> */}
            <p>{personNolData?.name}</p>
          </div>
          <div className={styles.topRightContainer}>
            <div className={styles.topRightLine}>
              <span className={styles.topRightTitle}>性别</span>
              <Radio.Group onChange={this.onChange} value={gender}>
                <Radio value={0}>男</Radio>
                <Radio value={1}>女</Radio>
              </Radio.Group>
            </div>
            <div className={styles.topRightLine}>
              <span className={styles.topRightTitle}>工号</span>
              <span className={styles.topRightContent}>{personNolData?.jobON}</span>
            </div>
            <div className={styles.topRightLine}>
              <span className={styles.topRightTitle}>学校</span>
              <span className={styles.topRightContent}>{personNolData?.schoolName}</span>
            </div>
            <div className={styles.topRightLine}>
              <span className={styles.topRightTitle}>院系</span>
              <span className={styles.topRightContent}>{personNolData?.collegeName}</span>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <span className={styles.title}>账号安全</span>
          <div className={styles.bottomLine}>
            <img src={imgUrlForZhongZhi('personalPassword.png')} alt="" />
            <span className={styles.topRightTitle}>登录密码</span>
            <span className={styles.topRightContent}>{'******'}</span>
            <div className={styles.bottomLineBtn} onClick={() => {
              this.setState({
                isShowChangePassword: true,
                originalPassword: '',
                newPassword: '',
              });
            }}>修改</div>
          </div>
          <div className={styles.bottomLine} style={{ marginBottom: '30px' }}>
            <img src={imgUrlForZhongZhi('personalPhone.png')} alt="" />
            <span className={styles.topRightTitle}>登录手机号</span>
            <span className={styles.topRightContent}>{personNolData?.mobile}</span>
            <div className={styles.bottomLineBtn} onClick={() => {
              this.setState({
                isShowChangePhone: true,
                phonePassword: '',
                phoneNumber: '',
                verifyCode: '',
              });
            }}>修改</div>
          </div>
          <span className={styles.title}>微信绑定</span>
          {
            personNolData?.hasWx ?
              <div className={styles.bottomLine} >
                <img src={imgUrlForZhongZhi('personalWX.png')} alt="" />
                <span className={styles.topRightTitle}>微信账号已绑定</span>
                <span className={styles.topRightContent}>{personNolData?.mobile}</span>
                <div className={styles.bottomLineBtn} onClick={() => {
                  this.setState({
                    isShowUnboundWechat: true
                  });
                }}>解绑</div>
              </div>
              :
              <div className={styles.bottomLine} >
                <img src={imgUrlForZhongZhi('personalWX.png')} alt="" />
                <span className={styles.topRightTitle}>暂未绑定</span>
              </div>
          }
        </div>
        {
          isShowUnboundWechat && <div className={styles.modalWrapper} onClick={this.cancle}>
            <div className={styles.unboundWechatContainer} onClick={this.prevent}>
              <div className={styles.unboundWechatTitle}>
                <img src={imgUrlForZhongZhi('pup_icon_warn.png')} alt="" />
                <span>解绑提示</span>
              </div>
              <div className={styles.unboundWechatSubTitle}>您确定要解除微信绑定吗？</div>
              <div className={styles.unboundWechatBtnContainer}>
                <div className={styles.unboundWechatBtnL} onClick={this.cancle}>取消</div>
                <div className={styles.unboundWechatBtnR} onClick={this.unboundWechatOk}>确定</div>
              </div>
            </div>
          </div>
        }
        {
          // isShowChangePassword && <div className={styles.modalWrapper} onClick={this.cancle}>
          isShowChangePassword && <div className={styles.modalWrapper}>
            <div className={styles.changePasswordContainer} onClick={this.prevent}>
              <p className={styles.changePasswordTitle}>修改密码</p>
              <img className={styles.modalClose} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={this.cancle} />
              <Input type="password" placeholder="请输入原始登录密码" value={originalPassword} onChange={this.changeOriginalPassword} className="ant-input11"/>
              <Input type="password" placeholder="请设置6～16位的新密码" value={newPassword} onChange={this.changeNewPassword}className="ant-input11" />
              {
                isWrong && <p className={styles.passwordWrongMessage}>密码设置不正确，请设置6～16位的新密码</p>
              }
              <div className={styles.modalBtn} onClick={this.changePasswordOk}>确认</div>
            </div>
          </div>
        }
        {
          // isShowChangePhone && <div className={styles.modalWrapper} onClick={this.cancle}>
          isShowChangePhone && <div className={styles.modalWrapper}>
            <div className={styles.changePhoneContainer} onClick={this.prevent}>
              <p className={styles.changePasswordTitle}>修改手机号</p>
              <img className={styles.modalClose} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={this.cancle} />
              <Input placeholder="请输入登录密码" value={phonePassword} onChange={this.changePhonePassword}  className="ant-input11" allowClear/>
              <Input placeholder="请输入新手机号" value={phoneNumber} onChange={this.changePhoneNumber} />
              <div className={styles.codeContainer}>
                <Input value={verifyCode} style={{ width: '2.25rem' }} placeholder="验证码" onChange={(e) => {
                  this.setState({
                    verifyCode: e.target.value
                  });
                }} />
                {
                  count ? <div className={`${styles.getCodeBtn} ${styles.disabled}`}>
                    <span>{count}s重试</span>
                  </div> :
                    <div className={styles.getCodeBtn} onClick={this.getCode}><span>发送验证码</span></div>
                }
              </div>
              {
                isPhoneWrong && <p className={styles.passwordWrongMessage}>手机号码输入不正确，请重新输入</p>
              }
              <div className={styles.modalBtn} onClick={this.changePhoneOk}>确认</div>
            </div>
          </div>
        }
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
  clearToken() {
    dispatch(clearToken());
  },
  updateUser(user) {
    dispatch(updateUser(user));
  },
});

export default connect(({ crumbsData, user, token }) => ({ crumbsData, user, token }), mapAction)(Personal);
