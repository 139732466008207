import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Modal, Select, Tooltip, Switch, Input, message, Popconfirm} from 'antd';
import {imgUrlForZhongZhi} from '@/utils';
import Paginations from '@@/Paginations';
import {
  getCourseInfo,
  teamList,
  teamAdd,
  teamPermission,
  teamPermissionData,
  teamDel,
  teamAddList,
  teamEditList,
  teamEditPage,
  teamEditEdit,
  queryColleges
} from '@/api';
import axios from '../../axios';
import Empty from '@@/Empty';
import {getParam} from '@/utils/util';
import './styles.less';

const {Option} = Select;
const {Search} = Input;

class TeachingResearch extends Component {
  constructor(props) {
    super();
    this.state = {
      courseName: 1,
      paginationList: {
        current: 1, pageSize: 10, pages: 0, total: 20,
      },
      isModal: false,
      teamList: [],//教师列表
      teamEditList: [],//授权列表
      teacherId: '',//教师id
      PermissionData: {},//设置权限数据
      collegeId: '',//院系id
      keyword: '',//输入
      addAuthorEdit: [],
      uid: '',
      schoolId: '',
      courseId: '',
      teamTitle: ['序号', '教师', '工号', '学校', '加入时间', '操作'],
      teamNoEditTitle: ['序号', '教师', '工号', '学校', '加入时间'],
      teamEditTitle: ['序号', '教师', '工号', '院系', '允许编辑', '授权时间',],
      applyTitlenumber: {
        a: '选择', b: '教师', c: '工号'
      },
      teamEditModalTitle: {
        a: '序号', b: '教师', c: '工号', d: '院系', e: '编辑权限'
      },
      teamEditModalData: [],
      teacherList: [],//添加教师list
      selectStudentList: [],//学生选择
      selectImpowerList: [],//授权弹窗已选择的教师id列表
      selectStudentListOld: [],//备份
      delSelectList: [],//删除授权
      applyTitle: ['课时名称', '课时学习情况', '任务点完成情况', '作业完成情况', '作业统计(优/良/差)'],
      applyList: [{a: '哈哈哈哈哈哈好 哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈'}, {
        a: '哈哈哈',
        b: '哈哈哈',
        c: '哈哈哈',
        d: '哈哈哈',
        e: '哈哈哈'
      }, {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈'}, {
        a: '哈哈哈',
        b: '哈哈哈',
        c: '哈哈哈',
        d: '哈哈哈',
        e: '哈哈哈'
      }, {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈'}, {
        a: '哈哈哈',
        b: '哈哈哈',
        c: '哈哈哈',
        d: '哈哈哈',
        e: '哈哈哈'
      }, {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈'}, {
        a: '哈哈哈',
        b: '哈哈哈',
        c: '哈哈哈',
        d: '哈哈哈',
        e: '哈哈哈'
      }, {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈'}, {
        a: '哈哈哈',
        b: '哈哈哈',
        c: '哈哈哈',
        d: '哈哈哈',
        e: '哈哈哈'
      }, {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈'}, {
        a: '哈哈哈',
        b: '哈哈哈',
        c: '哈哈哈',
        d: '哈哈哈',
        e: '哈哈哈'
      }, {a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈'},],
      provinceData: [{id: ''}],
      coursePermissions: [{name: '课时编辑', value: 'courseCatalogEdit'}, {
        name: '简介编辑', value: 'courseInfoEdit'
      },
        // {name: '共享(授权)', value: 'courseShare'}
      ],
      isRemoveModal: false,

      courseInfo: {courseVO: {}}
    }
  }

  componentWillUnmount() {
  }

  componentDidMount() {
    this.setState({
      courseId: getParam()?.id,
      uid: this.props.user.uid,
      schoolId: this.props.user.schoolId,
      teacherId: this.props.user.uid,
    })
    this.teamList(getParam()?.id)
    this.getCourseInfoFun()
    this.queryColleges(this.props.user?.schoolId)
  }

  //getCourseInfoFun
  getCourseInfoFun = (courseId) => {
    let {id} = getParam();
    const self = this
    axios.get(getCourseInfo, {
      params: {
        courseId: id,
      },
    }).then(res => {
      if (!res) return;
      let {courseVO} = res;
      self.setState({
        courseInfo: res
      }, () => {
        self.replaceParamVal('isJiaoXueKongJianPage', courseVO?.courseName)
      })
      // this.replaceParamVal('courseShare', permissionData?.courseShare)
    });
  }
  replaceParamVal = (paramName, replaceWith) => {
    let oUrl = window.location.href.toString();
    let re = eval('/(' + paramName + '=)([^&]*)/gi');
    let nUrl = oUrl.replace(re, paramName + '=' + replaceWith);
    window.location.href = nUrl
  }
  // 查询学校学院列表接口
  queryColleges = async (schoolId) => {
    const param = {
      schoolId
    }
    console.log(param);
    const res = await axios.post(queryColleges, param);
    console.log(res);
    let provinceData = res
    provinceData.unshift({collegeName: '全部院系', id: ''})
    console.log(provinceData);
    this.setState({
      provinceData
    })
  }

  // 教研团队管理-列表
  teamList = async (courseId) => {
    const param = {
      courseId
    }
    console.log(param);
    const res = await axios.get(teamList, {params: param});
    console.log(res);
    this.setState({
      teamList: res.teamList, selectStudentList: []
    })
  }

  // 教研团队管理-新增教师
  teamAdd = async (teacherIds) => {
    const {courseId, schoolId} = this.state
    const param = {
      courseId, teacherIds
    }
    console.log(param);
    const res = await axios.post(teamAdd, param);
    console.log(res);
    this.teamList(courseId)
    this.teamEditList(this.state.courseId)
  }

  // 教研团队管理-移除教师
  teamDel = async () => {
    const {courseId, schoolId, teacherId} = this.state
    const param = {
      courseId, teacherId
    }
    console.log(param);
    const res = await axios.post(teamDel, param);
    console.log(res);
    this.setState({teacherId: ''})
    this.teamList(courseId)
  }

  // 教研团队管理-新增教师列表
  teamAddList = async (pageNum = 1, pageSize = 10, keyword = '', collegeId = '') => {
    const {courseId, schoolId} = this.state
    const param = {
      collegeId, courseId, schoolId, keyword, pageNum, pageSize,
    }
    console.log(param);
    const res = await axios.post(teamAddList, param);
    console.log(res);
    const paginationList = {
      current: pageNum, pageSize, total: res.teacherList.total,
    }
    this.setState({
      teacherList: res.teacherList.data, paginationList, selectStudentList: []
    })
  }

  // 教研团队管理-权限查看
  teamPermissionData = async (teacherId) => {
    const {courseId} = this.state
    const param = {
      courseId, teacherId
    }
    console.log(param);
    const res = await axios.post(teamPermissionData, param);
    console.log(res);
    this.setState({
      PermissionData: res
    })
  }
  // 教研团队管理-权限设置
  teamPermission = async (permissions, teacherId) => {
    const {courseId, schoolId} = this.state
    const param = {
      courseId, teacherId, permissions
    }
    console.log(param);
    const res = await axios.post(teamPermission, param);
    console.log(res);
  }
  // 教研团队管理-授权教师列表
  teamEditPage = async (pageNum = 1, pageSize = 10, keyword = '', collegeId = this.state.collegeId) => {
    const {courseId, schoolId} = this.state
    const param = {
      collegeId, courseId, schoolId, keyword, pageNum, pageSize,
    }
    console.log(param);
    const res = await axios.post(teamEditPage, param);
    console.log(res);
    const paginationList = {
      current: pageNum, pageSize, total: res.teacherList?.total,
    }
    let teamEditModalData = res.teacherList?.data
    let selectImpowerList = []
    let selectStudentListOld = []
    let addAuthorEdit = []
    for (let index = 0; index < teamEditModalData.length; index++) {
      const element = teamEditModalData[index]?.hasExist;
      console.log(element);
      if (element == 1) {
        selectImpowerList.push(teamEditModalData[index]?.teacherId)
        selectStudentListOld.push(teamEditModalData[index]?.teacherId)
        addAuthorEdit.push({hasEdit: true, teacherId: teamEditModalData[index]?.teacherId})
      }
    }
    console.log(teamEditModalData, paginationList, selectImpowerList, selectStudentListOld)
    this.setState({
      teamEditModalData, paginationList, selectImpowerList, selectStudentListOld, // addAuthorEdit
    })
  }
  // 教研团队管理-授权
  teamEditEdit = async (editItems, selectStudentList) => {
    const {courseId, schoolId} = this.state
    console.log(editItems);
    const param = {
      courseId, editItems
    }
    console.log(param);
    const res = await axios.post(teamEditEdit, param);
    console.log(selectStudentList);
    if (selectStudentList.length != 0) {
      // this.teamAdd(selectStudentList)
    }

    this.teamEditList(courseId)
    console.log(res);
  }

  // 教研团队管理-授权列表
  teamEditList = async (courseId) => {
    const param = {
      courseId
    }
    console.log(param);
    const res = await axios.get(teamEditList, {params: param});
    console.log(res);
    this.setState({
      teamEditList: res.teamEditList, selectTeamEdit: []
    })
  }

  // qiehuan
  cutSetCourse = (title) => {
    const paginationList = {
      current: 1, pageSize: 10, pages: 0, total: 0,
    };
    if (title == 1) {

      this.teamList(this.state.courseId)
      this.setState({
        courseName: 1, paginationList
      })
    } else {
      this.teamEditList(this.state.courseId)
      this.setState({
        courseName: 2, paginationList
      })
    }
  }

  // 分页器 动态获取到页码
  onChangeCurPageOrPageSize = (current, pageSize) => {
    console.log(current, pageSize);
    const paginationList = {
      ...this.state.paginationList, current, pageSize
    }
    console.log(paginationList);
    this.setState({paginationList}, () => {
      this.teamAddList(current, pageSize);
    });
  }
  // 分页器 动态获取到页码
  onChangeCurPageOrPageSize1 = (current, pageSize) => {
    console.log(current, pageSize);
    const paginationList = {
      ...this.state.paginationList, current, pageSize
    }
    console.log(paginationList);
    this.setState({paginationList}, () => {
      this.teamEditPage(current, pageSize)
    });
  }


  onPermission = (hasEdit, teacherId, hasExist, index) => {
    console.log(hasEdit, teacherId, hasExist);
    const {teamEditModalData} = this.state;
    // 加班级权限
    let newTeamEditModalData = teamEditModalData;
    newTeamEditModalData[index].hasEdit = hasEdit
    this.setState(({addAuthorEdit}) => ({
      addAuthorEdit: addAuthorEdit?.find(item => {
        return item.teacherId == teacherId && item.hasEdit != hasEdit
      }) == undefined ? [...addAuthorEdit, {
        hasEdit,
        teacherId
      }] : addAuthorEdit?.filter(item => item.teacherId != teacherId), teamEditModalData: newTeamEditModalData
    }), () => {
      // 取消授权的数据
      console.log(this.state.addAuthorEdit);
      console.log(this.state.selectImpowerList);

    })
    // 修改bug-4718

    if (hasExist == 0 && this.state.addAuthorEdit.indexOf(teacherId) === -1) {
      this.authSelect(teacherId, hasExist, index)
    }

    // this.teamEditEdit(checked, teacherId)
  }
  // 授权选择
  authSelect = (val, hasExist, index) => {
    const {selectImpowerList, teamEditModalData, selectStudentListOld, teamEditList, addAuthorEdit} = this.state
    console.log(val, selectImpowerList, teamEditModalData, hasExist, '--------------');

    let newTeamEditModalData = teamEditModalData;
    let allarr = []
    for (let index = 0; index < teamEditModalData.length; index++) {
      const element = teamEditModalData[index].teacherId;
      allarr.push(element)
    }
    console.log(allarr);
    let arr = selectImpowerList

    if (val == 'all') {
      console.log("all---addAuthorEdit", addAuthorEdit)
      if (selectImpowerList.length == teamEditModalData.length) {//反选
        newTeamEditModalData.forEach((item) => {
          if (selectImpowerList.indexOf(item.teacherId) != -1) {
            item.hasEdit = false
          }
        })
        this.setState({
          selectImpowerList: teamEditList.map((item) => item.teacherId),
          teamEditModalData: newTeamEditModalData,
          addAuthorEdit: []
        })
      } else {//全选
        newTeamEditModalData.forEach((item) => item.hasEdit = true)
        this.setState({
          selectImpowerList: allarr, teamEditModalData: newTeamEditModalData,
        })
      }

    } else {
      console.log(selectImpowerList?.find(item => item === val), selectImpowerList, "555")
      console.log(!teamEditList?.find(item => item?.teacherId === val), teamEditList, "666")

      //列表中没有此项，且此项不在selectImpowerList列表中

      if (!teamEditList?.find(item => item?.teacherId === val)) { //限制已在授权管理列表中无法被选中
        console.log("else---addAuthorEdit", addAuthorEdit)
        // 加班级权限
        console.log(addAuthorEdit);
        let i = addAuthorEdit.findIndex((item) => item.teacherId == val);
        if (selectImpowerList?.find(item => item === val)) { //取消选中
          console.log(2222222)
          newTeamEditModalData[index].hasEdit = false;
          if (i != -1) {
            addAuthorEdit.splice(i, 1)
          }
          this.setState({
            selectImpowerList: selectImpowerList?.filter(i => i !== val),
            teamEditModalData: newTeamEditModalData,
            addAuthorEdit
          });
        } else { //选中
          newTeamEditModalData[index].hasEdit = true;
          this.setState({
            selectImpowerList: [...selectImpowerList, val], teamEditModalData: newTeamEditModalData,
          });
        }

      }

      // return;
      // this.setState(({ selectImpowerList }) => ({
      //   selectImpowerList: selectImpowerList?.find(item => { return item == val }) == undefined
      //     ? [...selectImpowerList, val]
      //     : selectImpowerList,
      //   // : selectImpowerList?.filter(item =>selectStudentListOld.indexOf(item)!=-1&& item != val),
      // }), () => {
      //   console.log(this.state.selectImpowerList);
      //   console.log(this.state.selectImpowerList.indexOf(1));

      // })
      // if (hasExist == 1) {
      //   this.setState(({ delSelectList }) => ({
      //     delSelectList: delSelectList?.find(item => { return item == val }) == undefined
      //       ? [...delSelectList, val]
      //       : delSelectList?.filter(item => item != val),
      //   }), () => {
      //     // 取消授权的数据
      //     console.log(this.state.delSelectList);

      //   })
      // }
      setTimeout(() => {
        console.log(this.state.addAuthorEdit);
        console.log(this.state.selectImpowerList);
      }, 500)
    }
  }
  // 选择
  selectStudent = (val) => {
    const {selectStudentList, teacherList} = this.state
    console.log(val, teacherList);
    let allarr = []
    for (let index = 0; index < teacherList.length; index++) {
      const element = teacherList[index].teacherId;
      allarr.push(element)
    }
    console.log(allarr);
    let arr = selectStudentList
    if (val == 'all') {
      console.log(222222222222);
      if (selectStudentList.length == teacherList.length) {
        this.setState({
          selectStudentList: []
        })
      } else {
        this.setState({
          selectStudentList: allarr
        })
      }
    } else {
      this.setState(({selectStudentList}) => ({
        selectStudentList: selectStudentList?.find(item => {
          return item == val
        }) == undefined ? [...selectStudentList, val] : selectStudentList?.filter(item => item != val),
      }), () => {
        console.log(this.state.selectStudentList);
        console.log(this.state.selectStudentList.indexOf(1));

      })
    }

  }
  // 新增教师弹窗
  addTeach = () => {
    this.handleOk()
    this.teamAddList()
  }
  // 输入
  onSearch = (value) => {
    console.log(value);
    this.teamAddList(1, 10, value)
  }

  //授权输入
  onEditSearch = (value) => {
    console.log(value);
    this.teamEditPage(1, 10, value)
  }

  // 添加教师
  handleAddTeacher = () => {
    const {selectStudentList} = this.state;
    if (selectStudentList?.length == 0) {
      message.destroy();
      message.error("请选择教师");
      return
    }
    this.handleOk()
    this.teamAdd(selectStudentList)
  }

  // 授权弹窗
  handleTeamEdit = () => {
    this.handleOk()
    this.teamEditPage()
  }

  // 确认授权
  inputEdit = () => {
    const {addAuthorEdit, selectImpowerList, selectStudentListOld, teamEditList, teamEditModalData} = this.state
    // let num  = 0
    // for (let index = 0; index < teamEditList.length; index++) {
    //   if (teamEditList[index].hasEdit == 1) {
    //     num ++;
    //   }
    // }
    // if ( selectImpowerList.length - num != addAuthorEdit.length ) {
    //   message.warning('请选择是否允许编辑') ;;return;
    // }

    this.setState({
      addAuthorEdit: []
    })

    let selectStudentList = this.getArrDifference(selectImpowerList, selectStudentListOld)
    let add = []
    for (let index = 0; index < addAuthorEdit.length; index++) {
      const element = addAuthorEdit[index].teacherId;
      add.push(element)
    }
    console.log("selectStudentList", selectStudentList, "add", add)
    for (let index = 0; index < selectImpowerList.length; index++) {

      const element1 = selectImpowerList[index];
      if (add?.indexOf(element1) == -1) {
        let ii = teamEditModalData.find(item => item.teacherId === element1);
        console.log(ii);
        addAuthorEdit.push({teacherId: element1, hasEdit: ii.hasEdit})
      } else {
        // addAuthorEdit=addAuthorEdit
      }
    }
    console.log(addAuthorEdit, "addAuthorEdit");
    console.log(teamEditModalData, "teamEditModalData");
    console.log(selectStudentList, selectImpowerList, add, "inputEdit");
    //解开
    this.handleOk()
    this.teamEditEdit(addAuthorEdit, add)
  }

  handleOk = () => {
    this.setState({
      isModal: !this.state.isModal
    })
  }

  // 比较数组取不同
  getArrDifference(arr1, arr2) {
    return arr1.concat(arr2).filter(function (v, i, arr) {
      return arr.indexOf(v) === arr.lastIndexOf(v);
    });
  }


  openPermission = (teacherId) => {
    console.log(teacherId);
    this.teamPermissionData(teacherId)
    this.setState({
      teacherId, isPermission: !this.state.isPermission
    })
  }
  openRemoveModal = (teacherId) => {
    console.log(teacherId);
    this.setState({
      teacherId, isRemoveModal: true
    })
  }
  Permission = () => {
    this.setState({
      isPermission: !this.state.isPermission
    })
  }

  // 改变设置
  changePermissionData = (val, index) => {
    const {PermissionData} = this.state
    PermissionData[val] = !index
    console.log(val, index, PermissionData);
    this.setState({
      PermissionData
    })
  }

  // 保存设置
  savePermission = () => {
    const {PermissionData, teacherId} = this.state
    this.Permission()
    this.teamPermission(PermissionData, teacherId)
  }

  // 选择院系
  handleTeacherChange = value => {
    const {provinceData, keyword} = this.state;
    console.log(value, provinceData);
    this.setState({
      collegeId: value
    })
    this.teamAddList(1, 10, keyword, value)
  };
  handleEditChange = value => {
    const {provinceData, keyword} = this.state;
    console.log(value, provinceData);
    this.setState({
      collegeId: value
    })
    this.teamEditPage(1, 10, keyword, value)
  };

  //弹窗1
  studentList1 = (modalIndex) => {
    console.log(modalIndex);
    const {
      teacherList,
      applyTitle,
      applyList,
      provinceData,
      cities,
      secondCity,
      diCity,
      diCitySelect,
      modalNumber,
      applyTitlenumber,
      selectStudentList
    } = this.state;
    const {current, pageSize, pages, total} = this.state.paginationList;

    return (<div className="studentlist">
      <h1>添加教师</h1>
      <div className="con-list">
        <div className='con-list_select'>
          <Select style={{width: '28%'}} defaultValue={'全部院系'} onChange={this.handleTeacherChange}>
            {provinceData.map(province => (<Option key={province?.id}>{province?.collegeName}</Option>))}
          </Select>
          <Search placeholder="请输入关键字进行查询" onSearch={this.onSearch} style={{width: '70%'}}/>
        </div>
        {teacherList && teacherList.length > 0 ? <table className="altrowstable" id="alternatecolor">
          <thead>
          <tr>
            {/* {applyTitle && applyTitle.map((val, index) => {
                return (
                  <th key={index}>{val}</th>
                )
              })} */}
            <th>
              <div className='selectxuhaowrap'>
                <div
                  className={selectStudentList.length == teacherList.length ? 'selectxuhao pass' : 'selectxuhao'}
                  onClick={() => this.selectStudent('all')}></div>
                {applyTitlenumber.a}
              </div>
            </th>
            <th>{applyTitlenumber.b}</th>
            <th>{applyTitlenumber.c}</th>
          </tr>
          </thead>
          <tbody>
          {teacherList.map((val, index) => {
            return (<tr key={index} onClick={() => this.selectStudent(val?.teacherId)}
                        className={selectStudentList.indexOf(val?.teacherId) == -1 ? '' : 'selecttr'}>
              <td>
                <div className='selectxuhaowrap'>
                  {console.log(selectStudentList, val?.teacherId)}
                  <div
                    className={selectStudentList.indexOf(val?.teacherId) == -1 ? 'selectxuhao' : 'selectxuhao pass'}
                  ></div>
                  {index + 1}
                </div>
              </td>
              <td><img className='imgavatar'
                       src={val?.avatar ? val?.avatar : imgUrlForZhongZhi('teacherPhoto.png')}
                       alt=""/> {val?.fullName}</td>
              <td>{val?.jobNo}</td>
            </tr>)
          })}
          </tbody>

        </table> : <div className='teachingresearch_empty'>
          <Empty title="暂无教师"/>
        </div>

        }

      </div>
      {total > 0 && <div className='news_footer' id='news_footer'>
        <Paginations
          current={current}
          pageSize={pageSize}
          pages={pages}
          total={total}
          handleChange={this.onChangeCurPageOrPageSize}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
        />
      </div>}

      <div className='input'>
        <button className='input_no' onClick={this.handleOk}>取消</button>
        <button className='input_save' onClick={this.handleAddTeacher}>添加</button>
      </div>

    </div>)
  }
  //弹窗2
  studentList2 = (modalIndex) => {
    const {
      applyTitle,
      applyList,
      provinceData,
      cities,
      secondCity,
      diCity,
      diCitySelect,
      modalNumber,
      teamEditModalTitle,
      selectStudentList,
      teamEditModalData,
      selectImpowerList
    } = this.state;
    const {current, pageSize, pages, total} = this.state.paginationList;

    console.log(modalIndex);
    console.log("selectImpowerList=======", selectImpowerList);
    console.log("teamEditModalData=======", teamEditModalData);
    return (<div className="studentlist">
      <div className="studentlist_top">
        <h1>授权编辑</h1>
        <div className='studentlist_right'>
          {/* <span className='newcourse'>
              <span>
                <Switch size="small" defaultChecked={false} onChange={this.onPermission} />
              </span>
              全部允许
            </span> */}
        </div>
      </div>
      <div className="con-list">
        <div className='con-list_select'>
          <Select style={{width: '28%'}} defaultValue={'全部院系'} onChange={this.handleEditChange}>
            {provinceData.map(province => (<Option key={province?.id}>{province?.collegeName}</Option>))}
          </Select>
          <Search placeholder="请输入关键字查询" onSearch={this.onEditSearch} style={{width: '70%'}}/>
        </div>

        <table class="altrowstable" id="alternatecolor">
          <thead>
          <tr>
            {/* {applyTitle && applyTitle.map((val, index) => {
                return (
                  <th key={index}>{val}</th>
                )
              })} */}
            <th>
              <div className='selectxuhaowrap'>
                <div
                  className={selectImpowerList.length == teamEditModalData.length ? 'selectxuhao pass' : 'selectxuhao'}
                  onClick={() => this.authSelect('all')}></div>
                {teamEditModalTitle.a}
              </div>
            </th>
            <th>{teamEditModalTitle.b}</th>
            <th>{teamEditModalTitle.c}</th>
            <th>{teamEditModalTitle.d}</th>
            <th>{teamEditModalTitle.e}</th>
          </tr>
          </thead>
          <tbody>
          {teamEditModalData && teamEditModalData.map((val, index) => {
            return (<tr key={index} className={selectImpowerList.indexOf(val?.teacherId) == -1 ? '' : 'selecttr'}>
              <td>
                <div className='selectxuhaowrap'
                     onClick={() => this.authSelect(val?.teacherId, val?.hasExist, index)}>
                  <div
                    className={selectImpowerList.indexOf(val?.teacherId) == -1 ? 'selectxuhao' : 'selectxuhao pass'}
                  ></div>
                  {index + 1}
                </div>
              </td>
              <td><img className='imgavatar' src={val?.avatar ? val?.avatar : imgUrlForZhongZhi('teacherPhoto.png')}
                       alt=""/> {val?.fullName}</td>
              <td>{val?.jobNo}</td>
              <td>{val?.collegeName}</td>
              <td><Switch size="small" key={val?.hasEdit} defaultChecked={val?.hasEdit} checked={val?.hasEdit}
                          onChange={(checked) => this.onPermission(checked, val?.teacherId, val?.hasExist, index)}/>
              </td>
            </tr>)
          })}
          </tbody>
        </table>
      </div>
      <div className='news_footer' id='news_footer'>
        <Paginations
          current={current}
          pageSize={pageSize}
          pages={pages}
          total={total}
          handleChange={this.onChangeCurPageOrPageSize1}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
        />
      </div>
      <div className='input'>
        <button className='input_no' onClick={this.handleOk}>取消</button>
        <button className='input_save' onClick={this.inputEdit}>确定</button>
      </div>

    </div>)
  }

  render() {
    const {
      courseName,
      teamList,
      teamTitle,
      teamNoEditTitle,
      isModal,
      isPermission,
      coursePermissions,
      PermissionData,
      teamEditTitle,
      teamEditList,
      isRemoveModal,
      courseInfo
    } = this.state
    // const {courseShare} = getParam();
    console.log("teamList=>", teamList)
    console.log("teamEditList=>", teamEditList)

    // 当前账号能不能操作
    const isCanAuthorization = ((courseInfo.courseVO?.courseSource ?? 4) != 4) || false
    const teamCurrentTitleList = isCanAuthorization ? teamTitle : teamNoEditTitle


    // section 教研团队管理
    const teachingGroupManager = (<div className='teachingresearch_wrap'>
      <div className='teachingresearch_title'>
        <h1>团队教师</h1>
        {
          isCanAuthorization
          &&
          <span className='newcourse' onClick={this.addTeach}>
            <img src={imgUrlForZhongZhi('icon-add.png')} alt=""/>
            新增教师
          </span>
        }
      </div>
      <div className="teachingresearch-list">
        {teamList?.length > 0 ? <table className="altrowstable" id="alterdetails">
          <tr>
            {teamCurrentTitleList && teamCurrentTitleList.map((val, index, array) => {
              return (<th key={index}>{val}</th>)
            })}
          </tr>
          {teamList.map((val, index) => {
            return (<tr key={index}>
              <td>{index + 1}</td>
              <td><img className='imgavatar'
                       src={val?.avatar ? val?.avatar : imgUrlForZhongZhi('teacherPhoto.png')}
                       alt=""/> {val?.fullName}</td>
              <td>{val?.jobNo}</td>
              <td>{val?.schoolName}</td>
              <td>{val?.joinTime}</td>
              <td className='operation' hidden={!isCanAuthorization}>
                          <span className='operation_span' onClick={() => this.openPermission(val?.teacherId)}><img
                            src={imgUrlForZhongZhi('icon_install.png')} alt=""/><span>设置</span></span>
                <span className='operation_span' onClick={() => this.openRemoveModal(val?.teacherId)}><img
                  src={imgUrlForZhongZhi('icon_remove.png')} alt=""/><span>移除</span></span>
                {/* <Popconfirm overlayClassName='myPopconfirm' title={<><p>提示</p><p>确定要移除该老师吗？</p></>} okText="确定" cancelText="取消" onConfirm={() => {
                                this.teamDel(val?.teacherId);
                              }}>
                                <span>移除</span>
                              </Popconfirm> */}
              </td>
            </tr>)
          })}
        </table> : <Empty title="暂无团队教师"/>}

      </div>
    </div>)
    // section 授权管理
    const authorizationManager = (<div className='permissions_wrap'>
      <div className='teachingresearch_title'>
        <div className='teachingresearch_title_span'>
          <h1>授权管理</h1>
          <span>通过授权可以把当前课程生成一个副本给其他</span>
        </div>
        {
          isCanAuthorization
          &&
          <span className='newcourse' onClick={this.handleTeamEdit}>授权</span>
        }

      </div>
      <div className="teachingresearch-list">
        {teamEditList?.length > 0 ? <table className="altrowstable" id="alterdetails">
          <tr>
            {teamEditTitle && teamEditTitle.map((val, index) => {
              return (<th key={index}>{val}</th>)
            })}
          </tr>
          {teamEditList.map((val, index) => {
            return (<tr key={index}>
              <td>{index + 1}</td>
              <td><img className='imgavatar'
                       src={val?.avatar ? val?.avatar : imgUrlForZhongZhi('teacherPhoto.png')}
                       alt=""/> {val?.fullName}</td>
              <td>{val?.jobNo}</td>
              <td>{val?.collegeName}</td>
              <td>{val?.hasEdit ? '是' : '否'}</td>
              <td>{val?.editTime}</td>
            </tr>)
          })}
        </table> : <Empty title="暂无授权"/>}

      </div>
    </div>)

    return (

      <div className='teachingresearch_con'>
        <div className='teachingresearch_top'>
          <div className='teachingresearch_con_top'>
            <span className={courseName == 1 ? 'spanselect' : ''}
                  onClick=
                    {() => this.cutSetCourse(1)}
            >教研团队管理</span>
            <span className={courseName == 2 ? 'spanselect' : ''} onClick={() => this.cutSetCourse(2)}>授权管理</span>
          </div>
        </div>

        {courseName == 1 ? teachingGroupManager : authorizationManager}
        `
        <Modal
          className="researchmodal"
          visible={isModal}
          onCancel={this.handleOk}
          footer={null}
          destroyOnClose
        >
          <div className="checkmodeldictation-con">
            {courseName == 1 ? this.studentList1() : this.studentList2()}
          </div>
        </Modal>
        <Modal
          className="permission_modeld remove_modeld"
          style={{width: '234px'}}
          visible={isRemoveModal}
          onCancel={() => {
            this.setState({isRemoveModal: false, teacherId: ''})
          }}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className='class_modeld_remove_title'><img src={imgUrlForZhongZhi('tipsWarn.png')}
                                                            alt=""/><span>提示</span>
            </div>
            <div className='class_modeld_center mtb-15' style={{color: '#686F7E'}}>
              确定要移除该老师吗？
            </div>
            <div className='class_modeld_button'>
              <button className='class_modeld_buttonno'
                      onClick={() => {
                        this.setState({isRemoveModal: false, teacherId: ''})
                      }}
              >取消
              </button>
              <button onClick={() => {
                this.setState({
                  isRemoveModal: false
                }, () => {
                  this.teamDel();
                })
              }} className='class_modeld_buttonyes'>确定
              </button>
            </div>

          </div>
        </Modal>
        <Modal
          className="permission_modeld"
          visible={isPermission}
          onCancel={this.Permission}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className='class_modeld_title'>
              <span>权限设置</span>
            </div>
            <div className='class_modeld_center'>
              <div className='class_modeld_center_left'>
                <div className='main_left_titlename'>
                  课程权限
                  <div></div>
                </div>
                <div className='main_left_con'>
                  {coursePermissions && coursePermissions.map((val) => {
                    return (<div key={val?.value}
                                 onClick={() => this.changePermissionData(val?.value, PermissionData[val?.value])}>
                      <img src={PermissionData[val?.value] ? imgUrlForZhongZhi('icon-yes.png') : ''} alt=""/>
                      {val.name}
                    </div>)

                  })}
                </div>

              </div>
              {/* <div onClick={() => this.schoolLesson('platform')} className='class_modeld_center_right'>
                <div className='main_left_titlename'>
                  作业权限
                  <div></div>
                </div>
              </div> */}
            </div>
            <div className='class_modeld_button'>
              <button onClick={this.Permission} className='class_modeld_buttonno'>取消</button>
              <button onClick={this.savePermission} className='class_modeld_buttonyes'>保存</button>
            </div>
          </div>
        </Modal>
      </div>)
  }
}

export default connect(({user}) => ({user}))(TeachingResearch)
