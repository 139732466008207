import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getParam, imgUrl, } from '@/utils/util';
// import { saveNews } from '@/actions/home'
import {
  teacherNoticeDetails,
  download
} from '@/api';
import axios from '../../axios';
import { imgUrlForZhongZhi, getIp } from '@/utils';
import { saveNotice } from '../../store/action';
import './styles.less';
const preview = getIp();

class notice_detail extends Component {
  state = {
    id: '',
    noticeFileVOList: [],
    noticeData: {}
  }
  componentWillUnmount() {
    this.props.saveNotice({})
  }

  componentDidMount() {
    this.setState({
      id: getParam().id
    }, () => {
      this.teacherNoticeDetails(this.state.id)
    })
  }
  // 详情
  teacherNoticeDetails = async (id) => {
    const param = {
      id
    }
    console.log(param);
    const res = await axios.post(teacherNoticeDetails, param);
    console.log(res);
    this.setState({
      noticeFileVOList: res.noticeFileVOList,
      noticeData: res
    })
  }
  goBack = () => {
    const { flag = 0 } = getParam();
    /**
     * flag  --- 0 首页
     * flag  --- 1 新闻页面
    */
    if (flag && flag === '0') {
      this.props.history.push('/')
    } else if (flag && flag === '1') {
      this.props.history.push('/zzgg')
    }
  }

  render() {
    // const { noticeData } = this.props;
    const { noticeFileVOList, noticeData } = this.state
    console.log(noticeData)
    return (
      <div className='pages_news_detail' id='pages_news_detail'>
        <div className='news_detail'>
          <div className='detail_go_back'>
            {/* <i><img src={imgUrl('news_go_back.png')} alt=""/></i> */}
            <span onClick={this.goBack}>公告通知/</span>
            <span>详情</span>
          </div>

          <div className='detail_main'>
            <h1 className='detail_title'>{noticeData && noticeData.title && noticeData.title}</h1>

            <div className='detail_times'>
              {/* 2021.3.3 9.30 */}
              {noticeData && noticeData.updateTime && noticeData.updateTime.replace(" ", ":").replace(/\:/g, "-").split("-")[0] + '年' + 
              noticeData.updateTime.replace(" ", ":").replace(/\:/g, "-").split("-")[1] + '月' +
              noticeData.updateTime.replace(" ", ":").replace(/\:/g, "-").split("-")[2] + '日'}

              {noticeFileVOList && noticeFileVOList.map((val) => {
                return (
                  <div key={val?.fileSize} className='detail_down'>
                    {(val.extName == 'jpeg' || val.extName == 'jpg' || val.extName == 'png') && <img src={imgUrlForZhongZhi('icon_file.png')} alt="" />}
                    {(val.extName == 'doc' || val.extName == 'docx' || val.extName == 'xls' || val.extName == 'xlsx') && <img src={imgUrlForZhongZhi('word.png')} alt="" />}
                    {(val.extName == 'ppt' || val.extName == 'pdf' ) && <img src={imgUrlForZhongZhi('ppt.png')} alt="" />}
                    {/* <img src={"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd4b2b3da5f6fc299b01ebc5ac92fd5818d8baa64d7fd9474ffe8c8d8aedaf2a2"} */}
                    {/* alt="" /> */}
                    {val?.fileName}
                    {/* .{ val?.extName} */}
                    <span className='a' onClick={() => {
                      let iframe = document.getElementById("iframeDownLoad");
                      iframe.src = `${preview + download}?id=${val?.fileId}`;
                    }}>下载</span>
                  </div>
                );

              })}
              <p></p>
            </div>

            <div className='detail_content' dangerouslySetInnerHTML={{ __html: noticeData && noticeData.content && noticeData.content }}>
            </div>
            <div style={{ display: "none" }}>
              <iframe title='iframeDownLoad' id={"iframeDownLoad"} src=""></iframe>
            </div>

            {/* <div className='detail_footer'>
              <p>
                <img src={showImg( noticeData && noticeData.newsCover && noticeData.newsCover)} alt=""/>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}
const mapAction = (dispatch) => ({
  saveNotice(res = {}) {
    dispatch(saveNotice(res));
  },
});
export default connect(({ }) => ({}), mapAction)(notice_detail);
