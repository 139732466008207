/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2022-01-27 13:56:40
 * @LastEditors: nliu
 * @LastEditTime: 2022-01-27 20:58:35
 */
export const   fileTypeMap = new Map([
  ["text", {value:0, label:'文本'}], // 0=文本&1=课件&2=图片&3=文档&4=视频&5=音频&6=测试&7=链接&8=附件
  ["courseware", {value:1, label:'课件'}],
  ["picture", {value:2, label:'图片'}],
  ["docs", {value:3, label:'文档'}],
  ["video", {value:4, label:'视频'}],
  ["audio", {value:5, label:'音频'}],
  ["tests", {value:6, label:'测试'}],
  ["link", {value:7, label:'链接'}],
  ["file", {value:8, label:'附件'}],
])

export const   fileTypeToValueMap = new Map([
  [0, {value:"text", label:'文本'}], // 0=文本&1=课件&2=图片&3=文档&4=视频&5=音频&6=测试&7=链接&8=附件
  [1, {value:"courseware", label:'课件'}],
  [2, {value:"picture", label:'图片'}],
  [3, {value:"docs", label:'文档'}],
  [4, {value:"video", label:'视频'}],
  [5, {value:"audio", label:'音频'}],
  [6, {value:"tests", label:'测试'}],
  [7, {value:"link", label:'链接'}],
  [8, {value:"file", label:'附件'}],
])