import React, { Component } from "react";
import { Tree, Empty, Button, message } from "antd";
import { imgUrlForZhongZhi } from '@/utils';
import "./styles.less";
import { getParam } from '@/utils/util';
import {CatalogTitleType} from "../../../../utils/publicType";
const result = [
  {
    id: "1",
    name: "第一单元",
    children: [
      {
        id: "1-1",
        name: "1 天地人",
        children: [
          {
            id: "1-1-1",
            name: "1 天地人 - 1",
            children: [
              {
                id: "1-1-1-1",
                name: "1 天地人 - 1 - 1",
              },
              {
                id: "1-1-1-2",
                name: "1 天地人 - 1 -2",
              },
            ]
          },
          {
            id: "1-1-2",
            name: "2 金木水火土 - 1",
            children: [
              {
                id: "1-1-2-1",
                name: "2 金木水火土 - 1- 1",
              },
              {
                id: "1-1-2-2",
                name: "2 金木水火土 - 1 -2",
              },
            ]
          }
        ]
      },
      {
        id: "1-2",
        name: "2 金木水火土",
        children: [
          {
            id: "1-2-1",
            name: "1 金木水火土 - 1",
          },
          {
            id: "1-2-2",
            name: "2 金木水火土 - 2",
          }
        ]
      },
      {
        id: "1-3",
        name: "3 口耳目",
      }
    ],
  },
  {
    id: "2",
    name: "第二单元",
    children: [
      {
        id: "2-1",
        name: "1 a o e",
      },
      {
        id: "2-2",
        name: "2 i u ü y w",
      }
    ],
  },
  {
    id: "3",
    name: "全书复习",
    children: [
      {
        id: "3-1",
        name: "全书复习",
      },
    ],
  },
];

const { TreeNode } = Tree;
export default class SelectTreeCmpForDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedKeys: [],
      selectedKeys: [],
      parentKey: [],
      parentData: {
        title: '',
        key: '',
        childTitle: '',
        childKey: '',
      }, // 保存给父级的数据
    }
    this.valueMap = {};
  }

  componentDidMount() {
    // console.log(getParam(),"getParam")
    // 默认展开
    const { selectTreeData, type, value, expandedKeys,catalogId, defaultExpandedKeys} = this.props;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData;

    // const {ary: expandedKeys, ary_ } = this.getChildrenId(data);
    const { ary, ary_ } = this.getChildrenId(data);
    this.loops(data);
    console.log("loopdata",data);
    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }
    let newExpandedKeys = expandedKeys || ary;
    this.setState({
      expandedKeys:defaultExpandedKeys,
    })
    // if (newExpandedKeys.length !== 0) {
    //   if (type === 'bourseBefore') {
    //     this.setState({
    //       expandedKeys: value,
    //       parentData,
    //     })
    //   } else {
    //     this.setState({
    //       expandedKeys: newExpandedKeys,
    //       parentData,
    //     });
    //   }

    // }
  }

  componentWillReceiveProps(nextProps) {
    const { selectTreeData, type, value,defaultExpandedKeys } = nextProps;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData;
    const { ary: expandedKeys, ary_ } = this.getChildrenId(data);

    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }
    // console.log("nextProps",nextProps);

    // console.log(JSON.stringify(selectTreeData) !== JSON.stringify(this.props.selectTreeData));
    if (JSON.stringify(selectTreeData) !== JSON.stringify(this.props.selectTreeData)) {
      if (type === 'bourseBefore') {
        this.setState({
          expandedKeys: value,
          parentData
        })
      } else {
        this.setState({
          expandedKeys:defaultExpandedKeys,
          parentData,
        });
      }
    }
  }

  getChildren = (arr = [], ary, ary_) => {
    const first = arr[0];
    const first_ = arr[0];
    ary.push(first?.id);
    ary_.push(first_);
    if (first?.children) {
      this.getChildren(first?.children, ary, ary_)
    }
  }

  getChildrenId(data) {
    const ary = [];
    const ary_ = []
    this.getChildren(data, ary, ary_);
    return {
      ary,
      ary_,
    }
  }

  loops = (list, parent) => {
    return (list || []).map(({ children, id, name }) => {
      const node = (this.valueMap[id] = {
        parent,
        id,
        name,
      });
      node.children = this.loops(children, node);
      return node;
    });
  }

  getPath = (id) => {
    const path = [];
    const path_ = []
    let current = this.valueMap[id];

    while (current) {
      path.unshift(current);
      path_.unshift(current.id);
      current = current.parent;
    }

    return {
      path,
      path_
    }
  }

  //选中的回调
  onSelect = (_, obj) => {
    let { parentData, expandedKeys } = this.state;
    let objPath = this.getPath(obj.node.key);
    const index = expandedKeys.indexOf(obj.node.key);
    console.log("objPath",objPath,obj);
    if (obj.node.className === CatalogTitleType.treeNodeTitle) {
      const parentData_ = {
        ...parentData,
        title: obj.node.title,
        key: obj.node.key
      }

      if (index !== -1) {
        this.setState({
          expandedKeys: [],
          parentData: parentData_,
        });
      } else {
        this.setState({
          expandedKeys: objPath.path_,
          parentData: parentData_,
        });
      }
    } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
      if (index !== -1) {
        objPath.path_.pop();

        this.setState({
          expandedKeys: objPath.path_,
        });
      } else {
        this.setState({
          expandedKeys: objPath.path_,
        });
      }

    } else {
      const parentData_ = {
        ...parentData,
        childTitle: obj.node.title,
        childKey: obj.node.key,
      };

      this.setState({ parentData: parentData_, expandedKeys: objPath.path_ }, () => {
        if (!this.props.onTreeActive) return;
        //bug-5664
        if(obj.node.children){return}
        this.props.onTreeActive(this.state.parentData, objPath.path_);
      });
    }
    if(!obj.node.children){
      this.props.changeSelectedKeys(obj.node.key);
    }
    this.props.onNodeSelect();
  };

  //展开的回调
  onExpend = (_, obj) => {
    const { parentData } = this.state;
    let objPath = this.getPath(obj.node.key);
    console.log(_, obj,objPath)
    if (obj.expanded) {
      if (obj.node.className.match(CatalogTitleType.treeNodeTitle)) {
        const parentData_ = {
          ...parentData,
          title: obj.node.title,
          key: obj.node.key
        };

        this.setState({
          expandedKeys: [obj?.node?.key],
          parentData: parentData_,
        });
      } else if (obj.node.className.match('tree_children_title')) {
        this.setState({ expandedKeys: objPath.path_ });
      }
    } else {
      if (obj.node.className.match(CatalogTitleType.treeNodeTitle)) {
        this.setState({ expandedKeys: [] });
      } else if (obj.node.className.match('tree_children_title')) {
        objPath.path_.pop();
        this.setState({ expandedKeys: objPath.path_ });
      }
    }
  };

  catCurriculum = id => {
    console.log(id);
  }

  renderTitle = ({ ele, isChildren, isOuterParent }) => {
    // if (isOuterParent) {
    //   return (<div>
    //     <span className="numberOfTitle">{ele.name}</span>
    //     <div className="numberOfTitleRightContainer">
    //       <span className='numberOfTitleRightText'>[{ele?.childNum || 0}课时]</span>
    //     </div>
    //   </div>);
    // }
    if (isChildren || isOuterParent) {
      return ele.name;
    }
    // console.log("renderTitle=>",ele, isChildren, isOuterParent)
    return (<div onClick={this.catCurriculum.bind(this, ele?.id)}>
      <span className="numberOfTitle" title={ele.name}>{ele.name}</span>
      {/* <span className="numberOfTitle">{ele.name?.length > 10 ? ele.name?.slice(0, 10) + '...' : ele.name}</span> */}
      {
        ele?.fileCount!=0 && ele?.fileCount&& <span className="numberOfClassHours">{ele?.fileCount}</span>
      }
      {/* <div className="numberOfTitleRightContainer">
        <span className='numberOfTitleRightText'>查看课程</span>
        <img className='numberOfTitleRightImg' src={imgUrlForZhongZhi("numberOfTitleRightImg.png")} alt="" />
      </div> */}
    </div>);
  }

  mapData = (children) => {
    if (children && Array.isArray(children)) {
      return children.map((ele) => {
        if (ele.children && Array.isArray(ele.children)) {
          return (
            <TreeNode
              // title={ele.name}
              // title={this.renderTitle(ele,true)}
              title={this.renderTitle({ ele, isChildren: true })}
              key={ele.id}
              className={`tree_children_title lev_${ele.lev}`}
            >
              {this.mapData(ele.children)}
            </TreeNode>
          );
        } else {
          return (
            <TreeNode
              // title={ele.name}
              title={this.renderTitle({ ele })}
              key={ele.id}
              className={`tree_inside_title lev_${ele.lev}`}
            />
          );
        }
      });
    }
    return [];
  };
  confirmLev= (data,lev)=>{
    for(let i in data){
      let item = data[i];
      item.lev = lev;
      if(item.children){
        this.confirmLev(item.children,lev+1)
      }
    }
  }
  render() {
    const content = [];
    const { selectTreeData,catalogId,expandedKeys,selectedKeys } = this.props;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData?.map(item => ({ ...item, isOuterParent: true }));

    //遍历确认层级

    this.confirmLev(data,1)
    console.log("data",data);
    data &&
      data.map((item) => {
        if (item) {
          content.push(
            <TreeNode
              // title={item.name}
              title={this.renderTitle({ ele: item, isOuterParent: true })}
              key={item.id}
              className={`tree_node_title lev_${item?.lev}`}
            >
              {this.mapData(item.children)}
            </TreeNode>
          );
        }
      });

    return (
      <div className="components_selectTree1" onClick={(e) => e.stopPropagation()}>
        {data?.length > 0 ? (
          <Tree
            onExpand={this.onExpend}
            expandedKeys={this.state.expandedKeys}
            onSelect={this.onSelect}
            selectedKeys={[selectedKeys]}
            // defaultSelectedKeys={[catalogId]}
            // defaultExpandedKeys = {'605596d3941abbc806e864dfb629eb66'}
            // defaultExpandAll
          >
            {content}
            {/* {console.log("content",content)}
            {console.log("catalogId",catalogId)}
            {console.log("expandedKeys",expandedKeys&&expandedKeys.toString())} */}
          </Tree>
        ) : (
          <Empty description={false} imageStyle={{ height: 215 }} />
        )}
      </div>
    );
  }
}
