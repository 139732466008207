import React from "react";
import styles from './index.module.scss';
import { imgUrlForZhongZhi, imgUrlForZhongZhiApi, getIp } from '../../utils/index';

class Empty extends React.Component {
  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    let { imgUrl, title } = this.props;
    return (
      // <div className={styles.wrapper}>
      <div className={styles.container}>
        <img src={imgUrlForZhongZhi(imgUrl ? imgUrl : "emptyNormal.png")} alt="" />
        <p>{title ? title : '当前分类暂无课程内容'}</p>
      </div>
      // </div>
    );
  }
}

export default Empty;
