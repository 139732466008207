export const uploadRequireTypeData = {
    "courseware":{
        "tips":["文档格式规格要求：ppt、pptx","文档大小规格要求：50M以内(含50M）"],
        "zh":"课件",
        "prevfixList":['.ppt','.pptx'],
        "size":50
        //application/vnd.ms-powerpoint  application/vnd.openxmlformats-officedocument.presentationml.presentation
    },
    "picture":{
        "tips":["支持上传格式：png；jpg；jpep；bmp；gif；psd；pcx；cdr；dxf","图片大小规格要求：10M以内(含10M)"],
        "zh":"图片",
        "prevfixList":['.png','.jpg','.jpep','.bmp','.gif','.psd','.pcx','.cdr','.dxf'],
        "size":10
        // image/png image/jpeg image/bmp image/gif 都是空的
    },
    "docs":{
        "tips":["文档格式规格要求：doc、docx、pdf","文档大小规格要求：50M以内(含50M）","文档文件请使用office2007以上版本编辑，文件名称不要超过32个字"],
        "zh":"文档",
        "prevfixList":['.doc', '.docx','.pdf'],
        "size":50
        //"application/msword" application/vnd.openxmlformats-officedocument.wordprocessingml.document  application/pdf
    },
    "video":{
        "tips":["支持上传格式：mp4","视频大小规格要求：500M以内（含500M）"],
        // "tips":["支持上传格式：mp4、rmvb、avi、mkv","视频大小规格要求：100M以内（含100M）"],
        "zh":"视频",
        "prevfixList":['.mp4'],
        "size":500
        // video/mp4   "video/avi"   "video/x-matroska"
    },
    "audio":{
        "tips":["音频格式规格要求：MP3、wav","音频大小规格要求：50M以内(含50M）"],
        "zh":"音频",
        "prevfixList":['.mp3','.wav'],
        "size":50
        // audio/mpeg 
    },
    "file":{
        "tips":[],
        "zh":"附件",
        "prevfixList":['.ppt','.pptx','.png','.jpg','.jpep','.bmp','.gif','.psd','.pcx','.cdr','.dxf','.doc', '.docx','.pdf','.mp4','.mp3','.wav'],
        "size":50
    },
    "tests":{
        "zh":"测试",
    },
    "link":{
        "zh":"链接",
    },

}

