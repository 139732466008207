import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'antd';
import './index.less';

class reportTemplateItem extends Component {
  render() {
    const { pageSizeOptions, total, current, pageSize, showQuickJumper, showSizeChanger, showTotalFlag } = this.props;

    return (
      <Fragment>
        {total ?
          <div className="component-paginations" >
            <Pagination
              pageSizeOptions = { pageSizeOptions }
              pageSize = { pageSize }
              showQuickJumper = { showQuickJumper }
              showSizeChanger = { showSizeChanger }
              // showTotal = { showTotalFlag ? this.showPage : this.showTotal }
              current = { current }
              total = { total }
              onChange = { this.onChange }
              onShowSizeChange = { this.onShowSizeChange }
              itemRender={this.itemRender}
              showTitle={false}
            />
          </div> : ''
        }
      </Fragment>
    );
  }

  // showTotal = total => {
  //   return `总计 ${total} 条`;
  // }

  // showPage = () => {
  //   return `总计 ${this.props.pages} 页`;
  // }
  // 页码变化时将当前页和每页条数传出去
  onChange = (pageNumber, pageSize) => {
    // console.log('onChange: ', pageNumber, pageSize);
    this.props.handleChange(pageNumber, pageSize);
  }
  // 每页条数变化时将当前页和每页条数传出去
  onShowSizeChange = (current, pageSize)=> {
    // console.log("onShowSizeChange：",current, pageSize);
    this.props.handleChange(1, pageSize);
  }
  itemRender=(current, type, originalElement)=>{
    if (type === 'prev') {
      return <a style={{userSelect:'none'}}>上一页</a>;
    }
    if (type === 'next') {
      return <a style={{userSelect:'none'}}>下一页</a>;
    }
    return originalElement;
  }
}

reportTemplateItem.propTypes = {
  pageSizeOptions: PropTypes.array,
  total: PropTypes.number,
  current: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  showQuickJumper: PropTypes.bool,
  showSizeChanger: PropTypes.bool,
  showTotalFlag: PropTypes.bool,
};

reportTemplateItem.defaultProps = {
  pageSizeOptions: ['5', '10', '20'],
  total: 0,  //必传，不解释
  current: 1, //必传 ，用于查询新条件，重置，条数改变跳转到第一页（用于分页组件改变）
  pageSize: 24, //必传 ，用于查询新条件，重置，初始化每页条数（用于分页组件改变）
  showQuickJumper: false,
  showSizeChanger: false,
  showTotalFlag: true,
};
export default reportTemplateItem;
