import React, {PureComponent} from 'react';


class FullScreenMask extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isShow: false
    }
  }

  componentDidMount () {
    // 在子组件中调用父组件的方法,并把当前的实例传进去
    this.props.onMaskShowControlRef(this)
  }

  changeState(isShow) {
    this.setState({isShow})
  }

  render() {
    const {
      isShow
    } = this.state;

    return (
      <div className='full-screen-save-mask-loading'
           hidden={!isShow}
      >
        {/*<Spin tip={'保存中...'} size={'large'}>*/}
        <div className='full-screen-mask-content'></div>
        {/*</Spin>*/}

      </div>
    )
  }
}

export default (FullScreenMask);
