import React, {Component} from 'react';
import {Modal, message, Spin} from 'antd';
import Crumbs from '@@/Crumbs';
import SelectTreeCmp from '@@/SelectTreeCmp';
import Empty from '@@/Empty';
import {imgUrlForZhongZhi, imgUrlForZhongZhiApi} from '@/utils';
import {getParam} from '@/utils/util';
import {
  addTeaCourse,
  applyExampleCourse,
  alreadJoin,
  createBackupCourse
} from '@/api';
import axios from '@/axios';
import styles from './index.module.scss';

const expandedKeys = [];

class CourseDetailsComponent extends Component {
  constructor() {
    super();
    this.state = {
      isShowMore: false,
      isJoin: false,       // 是否加入我的课程
      applyType: [],
      platformJoin: false,
      schoolJoin: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.alreadJoin();
    }, 1000);
    document.documentElement.scrollTop = 0;
  }

  alreadJoin = () => {
    const {id} = this.props?.courseVO;
    if (!id) return;
    axios.get(alreadJoin, {
      params: {
        courseId: id
      }
    }).then(res => {
      if (!res) return;
      let {platformJoin, schoolJoin} = res;
      this.setState({
        platformJoin,
        schoolJoin
      });
    });
  }

  // 示范课创建副本
  createBackupCourse = () => {
    let {id} = getParam();
    axios.get(createBackupCourse, {
      params: {
        courseId: id
      }
    }).then(res => {
      console.log(res);
      // if (!res) return;

    });
  }

  renderInfoContainerItem = data => {
    return data?.map((item, index) => (<div key={index} className={styles.infoContainerItem}>
      <div className={styles.infoContainerItemImg}>
        <img src={item?.avatar ? item?.avatar : imgUrlForZhongZhi('avatarDefault.png')} alt=""/>
      </div>
      <div className={styles.infoContainerItemRight}>
        <div className={styles.infoContainerItemRightName}>{item?.fullName}</div>
        <div className={styles.infoContainerItemRightDesc}>{item?.collegeName}</div>
      </div>
    </div>));
  }

  // 切换章节
  onTreeChapter = (selectChapterValue, expandedKeys) => {
    console.log(selectChapterValue, expandedKeys)
    // this.props.onOk(selectChapterValue, expandedKeys);
    // this.props.onCancle();
  }

  addTeaCourse = () => {
    // if (this.state.isJoin || this.canClick) return;
    // this.canClick = true;
    let {id} = this.props;
    axios.get(addTeaCourse, {
      params: {
        courseId: id
      }
    }).then(res => {
      // this.canClick = false;
      // this.setState({
      //   isJoin: res?.result
      // }, () => {
      if (res?.result) {
        message.destroy();
        message.success('加入我的课程成功，快进入教学空间-我的课程查看吧');
      }
      // });
    });
  }

  schoolLesson = (selectapplyType) => {
    this.setState(({applyType}) => ({
      applyType: applyType.indexOf(selectapplyType) == -1
        ? [...applyType, selectapplyType]
        : applyType?.filter(item => item != selectapplyType)
    }), () => {
      console.log(this.state.applyType);
    })
  }

  // 申请示范课
  applyExampleCourse = async () => {
    this.setState({
      isClassModal: !this.state.isClassModal
    });
    const {schoolId, id} = this.props?.courseVO;
    const param = {
      applyType: this.state.applyType?.length == 2 ? 'all' : this.state.applyType[0],
      courseId: id,
      schoolId
    };
    console.log(param);
    if (this.state.applyType.length == 0) {
      message.warn('请选择示范课类型');
      return
    }
    const res = await axios.post(applyExampleCourse, param);
    console.log(res);
    if (res) {
      message.destroy();
      message.success('提交成功，请等待管理员审核');
    }
    this.alreadJoin();
    this.setState({
      isShowDemonstrationCourse: false
    });
  }


  // 编辑课程简介
  toCourseEditorPage = () => {
    this.createBackupCourse();
    if (window.location.hash?.indexOf('demonspace') != -1) {
      this.props.history.push(`/demonspace/mycourse/courseeditor?id=${getParam().id}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage}&isDemonstrationCourse=${getParam().isDemonstrationCourse || ''}&isEdit=${1}&courseSource=${getParam()?.courseSource}`);
    } else {
      this.props.history.push(`/teachersspace/mycourse/courseeditor?id=${getParam().id}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage}&isDemonstrationCourse=${getParam().isDemonstrationCourse || ''}&isEdit=${1}&courseSource=${getParam()?.courseSource}`);
    }
  }
  // 编辑课时
  goCatalogEditor = () => {
    if (window.location.hash?.indexOf('demonspace') != -1) {
      this.createBackupCourse();
      this.props.history.push(`/demonspace/mycourse/catalogEditor?id=${getParam().id}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage}&isEdit=${1}&courseSource=${getParam()?.courseSource}`)
    } else {
      this.props.history.push(`/teachersspace/mycourse/catalogEditor?id=${getParam().id}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage}&isEdit=${1}&courseSource=${getParam()?.courseSource}`)
    }
  }

  renderContent = (isDemonstrationCourse, catalogList, isEdit, teamTeachers, courseVO, expandedKeys, permissionData) => {
    if (!isDemonstrationCourse && isEdit) {
      return <div className={styles.contentBottom}>
        <div className={styles.contentBottomL} style={{width: isDemonstrationCourse ? '100%' : ''}}>
          {
            catalogList?.length > 0 && <div className={styles.titleContainer}>
              课程目录
              <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
              {
                isEdit && permissionData?.courseCatalogEdit && catalogList?.length > 0 &&
                <div className={styles.titleContainerR} onClick={this.goCatalogEditor}>
                  <img src={imgUrlForZhongZhi("titleContainerRImg.png")} alt=""/>
                  <span>编辑课时</span>
                </div>
              }
            </div>
          }
          <div style={{minHeight: catalogList?.length > 0 ? '8.97rem' : '4.92rem', position: 'relative'}}>
            {
              catalogList?.length > 0
                ?
                <SelectTreeCmp isEdit={isEdit} isDemonstrationCourse={isDemonstrationCourse}
                               schoolId={courseVO?.schoolId} courseId={courseVO?.id} selectTreeData={catalogList}
                               expandedKeys={expandedKeys} onTreeActive={this.onTreeChapter}
                               permissionData={permissionData}/>
                :
                isEdit
                  ?
                  <div className={styles.contentBottomEmpty}>
                    <div className={styles.titleContainer}>
                      课程目录
                      <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
                    </div>
                    {permissionData?.courseCatalogEdit ?
                      <div className={styles.emptyCenterContainer}>
                        <img src={imgUrlForZhongZhi("emptyGrayCenterContainerImg.png")} alt=""/>
                        <p>还没有制作课程，快去创建课时制作课程吧~</p>
                        <div onClick={this.goCatalogEditor}>创建课时</div>
                      </div>
                      :
                      <div className={styles.emptyCenterContainer}>
                        <img src={imgUrlForZhongZhi("emptyNormal.png")} alt=""/>
                        <p>暂无课程内容</p>
                      </div>
                    }
                  </div>
                  :
                  <Empty title="暂无课程内容"/>
            }
          </div>
        </div>
        <div className={styles.contentBottomR}>
          <div className={styles.titleContainer}>
            教研团队
            <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
          </div>
          <div className={styles.infoContainer} style={{marginLeft: teamTeachers?.length > 0 ? '' : '-40px'}}>
            {teamTeachers?.length > 0 ? this.renderInfoContainerItem(teamTeachers) : <Empty title="暂无教研团队，快去添加教师吧！"/>}
          </div>
        </div>
      </div>;
    }
    return <div className={styles.contentBottom}>
      <div className={styles.contentBottomL} style={{width: '100%'}}>
        {
          catalogList?.length > 0 && <div className={styles.titleContainer}>
            课程目录
            <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
            {
              isEdit && permissionData?.courseCatalogEdit &&
              <div className={styles.titleContainerR} onClick={this.goCatalogEditor}>
                <img src={imgUrlForZhongZhi("titleContainerRImg.png")} alt=""/>
                <span>编辑课时</span>
              </div>
            }
          </div>
        }
        <div style={{minHeight: catalogList?.length > 0 ? '8.97rem' : '4.92rem', position: 'relative'}}>
          {
            catalogList?.length > 0
              ?
              <SelectTreeCmp isEdit={isEdit} isDemonstrationCourse={isDemonstrationCourse} schoolId={courseVO?.schoolId}
                             courseId={courseVO?.id} selectTreeData={catalogList} expandedKeys={expandedKeys}
                             onTreeActive={this.onTreeChapter}/>
              :
              isEdit
                ?
                <div className={styles.contentBottomEmpty}>
                  <div className={styles.titleContainer}>
                    课程目录
                    <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
                  </div>
                  <div className={styles.emptyCenterContainer}>
                    <img src={imgUrlForZhongZhi("emptyGrayCenterContainerImg.png")} alt=""/>
                    <p>还没有制作课程，快去创建课时制作课程吧~</p>
                    <div onClick={this.goCatalogEditor}>创建课时</div>
                  </div>
                </div>
                :
                <Empty title="暂无课程内容"/>
          }
        </div>
      </div>
    </div>;
  }

  render() {
    let {isShowMore, isJoin, isShowDemonstrationCourse, applyType} = this.state;
    let {isEdit, courseVO, catalogList, teamTeachers, isDemonstrationCourse, permissionData} = this.props;
    let str = courseVO?.information;
    if (str) {
      let tag = new RegExp('<img src=', 'ig');
      let tag2 = new RegExp('width="100" height="100"/>', 'ig');
      let tag3 = new RegExp('/>', 'ig');
      str = str.replace(tag, (v) => {
        return '<a target="_blank" href='
      }).replace(tag2, (v) => {
        return ''
      }).replace(tag3, (v) => {
        return ' style="color:#2252f2">图片</a>'
      })

    }
    console.log('请求数据', courseVO)
    console.log("hasExample", courseVO.hasExample)
    return (
      <div className={styles.wrapper}>
        <Spin tip={'加载课程数据中...'}
              spinning={undefined === courseVO?.id}
              style={{
                height: '1000px',
                margin: '323px 0px 60px',
                // background: 'red'
              }}>

          <img className={styles.courseDetailsImg} src={imgUrlForZhongZhi("courseDetailsImg.png")} alt=""/>
          <div className={styles.content} hidden={undefined === courseVO?.id}>
            <Crumbs/>
            <div className={styles.contentTop}>
              <div className={styles.contentTopT}>
                <div className={styles.contentTopTL}>
                  {courseVO?.hasExample == 2 && <span className={styles.turnTag}>已驳回</span>}
                  <img
                    src={courseVO?.coverFileId?.includes('http') ? courseVO?.coverFileId : imgUrlForZhongZhiApi(courseVO?.coverFileId)}
                    alt=""/>
                </div>
                <div className={styles.contentTopTR}>
                  <div className={styles.contentTopTRTitleContainer}>
                    <img
                      src={imgUrlForZhongZhi(courseVO?.courseType === '2' ? 'professional courses.png' : "publicCourse.png")}
                      alt=""/>
                    <span>{courseVO?.courseName}</span>
                    {
                      isEdit && permissionData?.courseInfoEdit &&
                      <div className={styles.contentTopTRTitleContainerR} onClick={() => this.toCourseEditorPage()}>
                        <img src={imgUrlForZhongZhi("contentTopTRTitleContainerRImg.png")} alt=""/>
                        <span>编辑简介</span>
                      </div>
                    }
                  </div>
                  {
                    str && <div className={styles.introduce}>
                      {/* {
                      str?.length > 70 ? str?.slice(0, 70) + '...' : str
                    } */}
                      <div className={styles.introBox} dangerouslySetInnerHTML={{__html: str}}></div>


                      <div className={styles.catMore} onClick={() => {
                        this.setState({
                          isShowMore: true
                        });
                      }}>查看更多
                      </div>
                    </div>
                  }
                  <div className={styles.essentialInformation}>
                    <div className={styles.headPortrait}>
                      <img
                        src={courseVO?.avatarFileId ? courseVO?.avatarFileId : imgUrlForZhongZhi('avatarDefault.png')}
                        alt=""/>
                    </div>
                    <div className={styles.teaName}>{courseVO?.teaName}</div>
                    <img className={styles.bookImg} src={imgUrlForZhongZhi("bookImg.png")} alt=""/>
                    {
                      courseVO?.courseType === '1' ?
                        <span>{courseVO?.subjectName}</span>
                        :
                        <div className={styles.professionalCourses}>
                          <p>{courseVO?.collegeName}</p>
                          <p>{courseVO?.majorName}</p>
                        </div>
                    }
                    <div className={styles.essentialInformationLine}></div>
                    <img className={styles.classHour} src={imgUrlForZhongZhi("classHour.png")} alt=""/>
                    <span>{courseVO?.courseHours || 0}</span>
                  </div>
                  <div className={styles.joinUsTop}></div>
                  {
                    !isDemonstrationCourse && !getParam()?.onlyCheck && <div
                      className={`${styles.joinUs} ${((courseVO?.join || isJoin) && !isEdit) && false ? styles.prohibit : ''}`}
                      onClick={() => {
                        console.log(444)
                        if (isEdit) {
                          this.setState({
                            isShowDemonstrationCourse: true,
                            applyType: []  //bug-5181
                          });
                          return;
                        }
                        // if (!courseVO?.join || !isJoin) {
                        this.addTeaCourse();
                        // }
                      }}>
                      {
                        isEdit ? '申请示范课' : '加入我的课程'
                      }
                      {/* {
                      isEdit ? '申请示范课' : <>
                        {courseVO?.join || isJoin ? '加入我的课程' : '加入我的课程'}
                      </>
                    } */}
                    </div>
                  }

                </div>
              </div>
              <div className={styles.contentTopB}>
                <div className={styles.contentTopBItem}>
                  <div className={styles.contentTopBItemLine}></div>
                  <span className={styles.contentTopBItemGraySpan}>教材</span>
                  <span>{courseVO?.bookName || '--'}</span>
                </div>
                <div className={styles.contentTopBItem}>
                  <div className={styles.contentTopBItemLine}></div>
                  <span className={styles.contentTopBItemGraySpan}>出版社</span>
                  <span>{courseVO?.press || '--'}</span>
                </div>
                <div className={styles.contentTopBItem}>
                  <div className={styles.contentTopBItemLine}></div>
                  <span className={styles.contentTopBItemGraySpan}>作者</span>
                  <span>{courseVO?.bookAuthor || '--'}</span>
                </div>
                <div className={styles.contentTopBItem}>
                  <div className={styles.contentTopBItemLine}></div>
                  <span className={styles.contentTopBItemGraySpan}>出版日期</span>
                  <span>{courseVO?.publishDate || '--'}</span>

                </div>
              </div>
            </div>
            {
              this.renderContent(isDemonstrationCourse, catalogList, isEdit, teamTeachers, courseVO, expandedKeys, permissionData)
            }
            {
              catalogList?.length > 0 ?
                <div style={{display: 'none'}} className={styles.contentBottom}>
                  <div className={styles.contentBottomL} style={{width: isDemonstrationCourse ? '100%' : ''}}>
                    <div className={styles.titleContainer}>
                      课程目录
                      <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
                      {
                        isEdit && permissionData?.courseCatalogEdit &&
                        <div className={styles.titleContainerR} onClick={this.goCatalogEditor}>
                          <img src={imgUrlForZhongZhi("titleContainerRImg.png")} alt=""/>
                          <span>编辑课时</span>
                        </div>
                      }
                    </div>
                    <div style={{minHeight: '8.97rem'}}>
                      {
                        catalogList?.length > 0 &&
                        <SelectTreeCmp isEdit={isEdit} isDemonstrationCourse={isDemonstrationCourse}
                                       schoolId={courseVO?.schoolId} courseId={courseVO?.id}
                                       selectTreeData={catalogList}
                                       expandedKeys={expandedKeys} onTreeActive={this.onTreeChapter}/>
                      }
                    </div>
                  </div>
                  {
                    !isDemonstrationCourse && teamTeachers?.length > 0 && <div className={styles.contentBottomR}>
                      <div className={styles.titleContainer}>
                        教研团队
                        <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
                      </div>
                      <div className={styles.infoContainer}>
                        {this.renderInfoContainerItem(teamTeachers)}
                        {/* <div className={styles.infoContainerItem}>
                  <div className={styles.infoContainerItemImg}>
                    <img src={imgUrlForZhongZhi("lineImg.png")} alt="" />
                  </div>
                  <div className={styles.infoContainerItemRight}>
                    <div className={styles.infoContainerItemRightName}>徐泽盛</div>
                    <div className={styles.infoContainerItemRightDesc}>公共基础知识公共基础知识公共基础知识</div>
                  </div>
                </div> */}
                      </div>
                    </div>
                  }
                </div>
                :
                <div style={{display: 'none'}} className={styles.contentBottom}>
                  <div className={styles.contentBottomL} style={{width: isDemonstrationCourse ? '100%' : ''}}>
                    <div className={styles.contentBottomEmpty}>
                      <div className={styles.titleContainer}>
                        课程目录
                        <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
                      </div>
                      <div className={styles.emptyCenterContainer}>
                        <img src={imgUrlForZhongZhi("emptyGrayCenterContainerImg.png")} alt=""/>
                        <p>还没有制作课程，快去创建课时制作课程吧~</p>
                        <div onClick={this.goCatalogEditor}>创建课时</div>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
          {
            isShowMore && <div className={styles.showMoreWrapper} onClick={() => {
              this.setState({
                isShowMore: false
              });
            }}>
              <div className={styles.showMoreContainer} onClick={(e) => e.stopPropagation()}>
                <img className={styles.showMoreCloseImg} src={imgUrlForZhongZhi("showMoreCloseImg.png")} alt=""
                     onClick={() => {
                       this.setState({
                         isShowMore: false
                       });
                     }}/>
                <div className={styles.titleContainer}>
                  课程介绍
                  <img className={styles.lineImg} src={imgUrlForZhongZhi("lineImg.png")} alt=""/>
                </div>
                {/* <p>{str}</p> */}
                <div dangerouslySetInnerHTML={{__html: courseVO?.information}} className={styles.infoContent}></div>
              </div>
            </div>
          }
        </Spin>

        <Modal
          className="class_modeld"
          visible={isShowDemonstrationCourse}
          onCancel={() => {
            this.setState({
              isShowDemonstrationCourse: false
            });
          }}
          footer={null}
        >
          <div className="class_modeld-con">
            <div className='class_modeld_title'>
              <span>请选择申请的示范课类型</span>（可多选）
            </div>
            <div className='class_modeld_center'>
              {/* <div style={{ color: schoolJoin ? 'gray' : '' }} onClick={() => { */}
              <div onClick={() => {
                // if (!schoolJoin) {
                this.schoolLesson('school');
                // }
              }}
                   className={applyType.indexOf('school') != -1 ? 'class_modeld_center_left class_modeld_center_right' : 'class_modeld_center_left'}>学校示范课
              </div>
              {/* <div style={{ color: platformJoin ? 'gray' : '' }} onClick={() => { */}
              <div onClick={() => {
                // if (!platformJoin) {
                this.schoolLesson('platform');
                // }
              }}
                   className={applyType.indexOf('platform') != -1 ? 'class_modeld_center_left class_modeld_center_right' : 'class_modeld_center_left'}>平台示范课
              </div>
            </div>
            <div className='class_modeld_button'>
              <button className='class_modeld_buttonno' onClick={() => {
                this.setState({
                  isShowDemonstrationCourse: false
                });
              }}>取消
              </button>
              <button onClick={this.applyExampleCourse} className='class_modeld_buttonyes'>确定</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CourseDetailsComponent;
