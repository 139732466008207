/*
 * @Descripttion:
 * @version:
 * @Author: nliu
 * @Date: 2020-10-10 19:46:02
 * @LastEditors: nliu
 * @LastEditTime: 2020-11-10 19:33:57
 */
export const UPDATE_TOKEN: string = 'UPDATE_TOKEN';

export const UPDATE_PHONE: string = 'UPDATE_PHONE';

export const CLEAR_TOKEN: string = 'CLEAR_TOKEN';

export const GETLIMITDATA: string = 'GETLIMITDATA';

export const UPDATE_USER: string = 'UPDATE_USER';

export const CLEAR_USER: string = 'CLEAR_USER';

export const UPDATE_CRUMBS_DATA: string = 'UPDATE_CRUMBS_DATA';

export const HOME_SAVE_NOTICE_DATA: string = 'HOME_SAVE_NOTICE_DATA';

export const SAVE_CURSEVO_DATA: string = 'SAVE_CURSEVO_DATA';

export const UPDATE_MEG_COUNT: string = 'UPDATE_MEG_COUNT';