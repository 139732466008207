import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateCrumbsData} from '@/store/action';
import Crumbs from '@@/Crumbs';
import SelectTreeCmpForDetail from './components/SelectTreeCmpForDetail/index';
import RichTextPreview from '@/components/RichTextPreview/index';
import Empty from '@/components/Empty/index';
import {getIp, imgUrlForZhongZhi} from '@/utils';
import {getParam} from '@/utils/util';
import {delCatalog, getCatalogFile, getCourseInfo, getRecyleCatalog, getResourceByCatalog, recoverCatalog} from '@/api';
import axios from '../../axios';
import styles from './index.module.scss';
import {message} from 'antd';
import {CatalogTitleType} from "../../utils/publicType";
import env from "@/env";

const preview = getIp();
const {xDocUrl, xDocUrlHttps} = env;
const targetData = {};


class RecycleBin extends Component {
  constructor() {
    super();
    this.state = {
      navType: 3,        // 1 教案   2 课件   3 学案
      catalogList: [],
      openUrl: '',
      stepList: [],//预览内容
      catalogId: "",
      selectedKeys: '',
      fileVOList: []
    };
  }

  componentDidMount() {
    // this.getCourseInfo();
    this.getRecyleCatalog();
    document.documentElement.scrollTop = 0;
  }

  getChildren = (arr = [], ary, ary_) => {
    const first = arr[0];
    const first_ = arr[0];
    ary.push(first?.id);
    ary_.push(first_);
    if (first?.children) {
      this.getChildren(first?.children, ary, ary_);
    }
  }

  getChildrenId(data) {
    const ary = [];
    const ary_ = [];
    this.getChildren(data, ary, ary_);
    return {
      ary,
      ary_,
    };
  }

  getRecyleCatalog = () => {
    let {courseId} = getParam();
    axios.get(getRecyleCatalog, {
      params: {
        courseId
      }
    }).then(res => {
      if (!res) return;
      if (Object.prototype.toString.call(res) === '[object Object]') {
        this.setState({
          catalogList: []
        });
        return;
      }
      this.setState({
        catalogList: this.transformData(res),
      }, () => {
        const {ary} = this.getChildrenId(this.state.catalogList);
        this.getCatalogFile(ary[ary?.length - 1]);
        this.getResourceByCatalog(ary[ary?.length - 1]);
      });
      this.setTitle(1);
    });
  }

  transformData = catalogList => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (childList && Array.isArray(childList)) {
        return {...item, name: item?.catalogName, children: this.transformData(item?.childList)};
      }
      return {...item, name: item?.catalogName};
    });
  }
  getNode = (value) => {
    let node = [];
    let currentNode = targetData[value];
    node.push(currentNode.value);
    if (currentNode.parent) {
      node = [...this.getNode(currentNode.parent.value), ...node];
    }
    return node
  }
  getCourseInfo = () => {
    let {courseId, catalogId} = getParam();
    catalogId = localStorage.getItem('/teachersspace/courseDetailsDetailed/selectedKeys') || catalogId;

    axios.get(getCourseInfo, {
      params: {
        courseId,
      },
    }).then(res => {
      if (!res) return;
      let {catalogList} = res;
      this.setState({
        catalogList: this.transformData(catalogList),
      }, () => {
        const {ary} = this.getChildrenId(this.state.catalogList);
        //获取路由传参的章节
        this.getCatalogFile(catalogId);
        // this.getCatalogFile(ary[ary?.length - 1]);
        //
        this.getResourceByCatalog(catalogId);
        // this.getResourceByCatalog(ary[ary?.length - 1]);
        console.log(catalogList, catalogId);
        this.loopsTree(catalogList)
        console.log("targetData", targetData);
        //获取父节点
        const target = this.getNode(catalogId)
        console.log("target-----------------", target);
        this.setState({
          // catalogId: ary[ary?.length - 1],
          target
        });
      });
      this.setTitle(1);
    });
  }

  setTitle = () => {
    this.props.updateCrumbsData([
      {
        name: '课时',
        path: '-1'
      },
      {
        name: '课时详情',
        path: '-1'
      },
      {
        name: '回收站',
        path: ''
      },
    ]);
  }
  // 富文本内容预览内容
  getResourceByCatalog = (catalogId, attach = 1) => {
    let {id, courseId} = getParam();
    axios.get(getResourceByCatalog, {
      params: {
        catalogId,
        courseId: courseId || id,
        attach
      }
      // params: {
      //   catalogId:'05c9d80054d2528d5639710a5c222aef',
      //   courseId:'0a6d0fb2287d732a62907ede09070115'
      // }
    }).then(res => {
      if (!res) return;
      this.setState({
        stepList: res.stepList
      });
      console.log(res);
    });
  }
  getCatalogFile = catalogId => {
    let {courseId} = getParam();
    axios.get(getCatalogFile, {
      params: {
        catalogId,
        courseId
      }
    }).then(res => {
      if (!res) return;
      let {fileVOList} = res;
      this.setState({
        fileVOList,
        openUrl: fileVOList?.find(item => item?.detailType === this.state.navType)?.fileUrl
      });
    });
  }

  // 切换章节
  onTreeChapter = (selectChapterValue, expandedKeys, nodeClassName) => {
    this.changeNavType(3)
    if (nodeClassName !== CatalogTitleType.treeNodeTitle && nodeClassName !== CatalogTitleType.treeChildrenTitle) {
      // console.log(selectChapterValue, expandedKeys);
      this.getCatalogFile(expandedKeys[expandedKeys?.length - 1]);
      this.getResourceByCatalog(expandedKeys[expandedKeys?.length - 1]);
      this.setState({
        catalogId: expandedKeys[expandedKeys?.length - 1],
      });
    } else {
      this.setState({
        fileVOList: [],
        openUrl: '',
        stepList: []
      })
    }

  }

  changeNavType = navType => {
    this.setState({
      navType,
      openUrl: this.state.fileVOList?.find(item => item?.detailType === navType)?.fileUrl
    });
    this.setTitle(navType);
  }

  onRef = ref => {
    this.ref = ref;
  }

  recovery = () => {
    let {catalogList} = this.state;
    if (catalogList.length <= 0) {
      message.warn('暂无恢复的内容');
      return;
    }
    let {checkedId, undelCatalogs} = this.ref.state;
    if (checkedId.length === 0) {
      message.warn('请选择需要恢复的目录');
      return
    }
    let {courseId} = getParam();
    if (!checkedId || !courseId) return;
    console.log("undelCatalogs", undelCatalogs);
    axios.post(recoverCatalog, {
      all: 1,
      catalogIds: checkedId,
      undelCatalogs,
      courseId,
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('恢复成功');
      setTimeout(() => {
        window.location.reload();
        this.getRecyleCatalog();
      }, 1000)
    });
  }

  delete = () => {
    let {checkedId, undelCatalogs} = this.ref.state;
    console.log(checkedId);
    if (checkedId.length === 0) {
      message.warn('请选择需要删除的目录');
      return
    }
    if (!checkedId) return;
    let {courseId} = getParam();
    // if (checkedNumber !== 3) {
    //   message.destroy();
    //   message.warning('只能彻底删除三级目录内容');
    //   return;
    // }
    axios.post(delCatalog, {
      catalogIds: checkedId,
      undelCatalogs,
      sureDelete: 1,
      courseId
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('删除成功');
      setTimeout(() => {
        window.location.reload();
        this.getRecyleCatalog();
      }, 1000)
    });
  }

  render() {
    let {navType, catalogList, openUrl} = this.state;
    // let openUrl = 'https://cdn.zjyjc.com/%E8%B5%84%E6%BA%90/%E6%95%99%E8%BE%85/31a082c039cb612e3f13c4974b6f28a4.pptx'
    return (
      <div className={styles.wrapper}>
        <div className={styles.crumbsWrapper}><Crumbs/></div>
        <div className={styles.left}>
          <div className={styles.leftBottom}>
            <div className={styles.leftBottomTitle}>回收站</div>
            <div className={styles.leftBottomContent}>
              {
                catalogList?.length > 0 ?
                  <SelectTreeCmpForDetail selectTreeData={catalogList} expandedKeys={''}
                                          onTreeActive={this.onTreeChapter} onRef={this.onRef}/>
                  :
                  <div className={styles.leftBottomContentEmptyContainer}>
                    <img src={imgUrlForZhongZhi("leftBottomContentEmptyContainer.png")} alt=""/>
                    <p>暂无删除目录<span></span></p>
                  </div>
              }
            </div>
            {
              catalogList?.length > 0 && <div className={styles.leftBottomFooter}>
                <div className={styles.leftBottomFooterL} onClick={this.delete}>
                  <img src={imgUrlForZhongZhi("recycleBinImg.png")} alt=""/>
                  <span>彻底删除</span>
                </div>
                <div className={styles.leftBottomFooterR} onClick={this.recovery}>
                  <img src={imgUrlForZhongZhi("recoveryImg.png")} alt=""/>
                  <span>恢复</span>
                </div>
              </div>
            }
          </div>
        </div>
        {
          catalogList?.length <= 0 ?
            <div className={styles.right}>
              <div className={styles.rightEmpty}>
                <img src={imgUrlForZhongZhi("recycleBinEmpty.png")} alt=""/>
              </div>
              <p className={styles.rightEmptyText}>回收站内暂无内容～</p>
            </div>
            :
            <div className={styles.right}>
              <div className={styles.rightTop}>
                <div className={`${styles.navItem} ${navType === 3 ? styles.active : ''}`}
                     onClick={this.changeNavType.bind(this, 3)}>进入学习
                </div>
                <div className={`${styles.navItem} ${navType === 2 ? styles.active : ''}`}
                     onClick={this.changeNavType.bind(this, 2)}>教学资源
                </div>
                <div className={`${styles.navItem} ${navType === 1 ? styles.active : ''}`}
                     onClick={this.changeNavType.bind(this, 1)}>教案
                </div>
              </div>
              <div className={styles.rightBottom}>
                {
                  navType === 1 && <div>
                    {
                      openUrl ? <iframe className={styles.iframe}
                                        src={openUrl.includes('https') ? (xDocUrlHttps + openUrl) : openUrl.includes('http') ? (xDocUrl + openUrl) : (xDocUrl + preview + openUrl)}
                                        width="100%" frameBorder='0'></iframe>
                        :
                        <Empty title="暂无内容"/>
                    }
                  </div>
                }
                {
                  navType === 2 && <div>
                    {
                      openUrl ? <iframe className={`${styles.iframe} ${styles.iframeCourse}`}
                                        src={openUrl.includes('https') ? (xDocUrlHttps + openUrl) : openUrl.includes('http') ? (xDocUrl + openUrl) : (xDocUrl + preview + openUrl)}
                                        width="100%" frameBorder='0'></iframe>
                        :
                        <Empty title="暂无内容"/>
                    }
                  </div>
                }
                {
                  navType === 3 && <div>
                    {
                      this.state.stepList?.length > 0 ?
                        <RichTextPreview stepList={this.state.stepList}/>
                        :
                        <Empty title="暂无内容"/>
                    }
                  </div>
                }
              </div>
            </div>
        }
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
});

export default connect(({crumbsData}) => ({crumbsData}), mapAction)(RecycleBin);
