
import React, { Component } from 'react';
import * as echarts from 'echarts';
import "./index.scss";
import _ from "lodash";
const option = {
    backgroundColor: '#fff',
    color: ['#73A0FA'],
    title: {
        text: '课时学习情况',
        textStyle: {
            fontSize: 14,
            color: '#17181A',
            fontWeight: 'normal',
            fontStyle: 'normal',
        },
        left: '-1%'
    },
    tooltip: {
        // show: false,
    },
    grid: {
        left: '0',
        right: '25',
        bottom: '5',
        top: '50',
        containLabel: true,
    },
    xAxis: {
        type: 'category',
        // boundaryGap: false,
        data: [],
        splitLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        axisLine: {
            show: false,
        },
    },
    yAxis: {
        type: 'value',
        // min: 1.51,
        // max: 1.56,
        axisLabel: {
            formatter: '{value}',
            color: '#999',
            textStyle: {
                fontSize: 12,
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#ccc',
                type: '',
            },
        },
        axisTick: {
            show: false,
        },
        axisLine: {
            show: false,
        },
    },
    series: [
        {
            name: '',
            type: 'bar',
            smooth: false,
            zlevel: 1,
            barWidth: 10,
            itemStyle: {    // 图形的形状
                barBorderRadius: 5
            },
            // symbol: 'none',
            data: [ // 之前的data数据是 [1.544, 1.545, 1.539.....] 数组形式的，现在得修改成[{...}, {...}, {...}] 具体数据如下

            ],
        },
        {
            name: '',
            type: 'bar',
            barWidth: 10,
            barGap: '-100%',
            data: [],
            itemStyle: {
                normal: {
                    color: '#DEE5FD',
                    barBorderRadius: 5,
                }
            },
        },
    ],
};

export default class VerticalHistogram extends Component {
    state = {
    }

    componentDidMount() {
        console.log(this.props.data);
        const { data } = this.props
        setTimeout(() => {
            this.initEcharts(data.x, data.y)
        }, 200)
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        // this.initEcharts()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize); //销毁
    }

    onResize = () => {
        if (this.myChart) {
            this.myChart.resize()
        }
    }

    //封装Echarts
    initEcharts = (seriesdata, xAxisdata) => {
        console.log(seriesdata, xAxisdata);
        this.myChart = echarts.init(this.el);
        // option.series[0].data=[1.544, 1.545, 1.539]
        // option.xAxis.data=['1月', '2月', '3月', '4月', '5月', '6月']

        let maxArr = (new Array(seriesdata.length)).fill(100);
        option.series[0].data = seriesdata
        option.series[1].data = maxArr
        option.xAxis.data = xAxisdata
        option.title.text = '任务点完成情况'
            option.color = '#2252F2'
        this.myChart.setOption(option);
        window.addEventListener('resize', this.onResize);
    }

    render() {
        const { } = this.state;
        return (
            <div className='histogramwarp'>
                <div
                    ref={v => this.el = v}
                    style={{ width: "100%", height: '100%', background: '#FFFFFF' }}
                >
                </div>

            </div>

        );
    }
}

