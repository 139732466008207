import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Tree, Empty, Button } from "antd";
import { imgUrlForZhongZhi } from '@/utils';
import { getParam } from '@/utils/util';
import "./styles.less";
import {CatalogTitleType} from "../../utils/publicType";

const result = [
  {
    id: "1",
    name: "第一单元",
    children: [
      {
        id: "1-1",
        name: "1 天地人",
        children: [
          {
            id: "1-1-1",
            name: "1 天地人 - 1",
            children: [
              {
                id: "1-1-1-1",
                name: "1 天地人 - 1 - 1",
              },
              {
                id: "1-1-1-2",
                name: "1 天地人 - 1 -2",
              },
            ]
          },
          {
            id: "1-1-2",
            name: "2 金木水火土 - 1",
            children: [
              {
                id: "1-1-2-1",
                name: "2 金木水火土 - 1- 1",
              },
              {
                id: "1-1-2-2",
                name: "2 金木水火土 - 1 -2",
              },
            ]
          }
        ]
      },
      {
        id: "1-2",
        name: "2 金木水火土",
        children: [
          {
            id: "1-2-1",
            name: "1 金木水火土 - 1",
          },
          {
            id: "1-2-2",
            name: "2 金木水火土 - 2",
          }
        ]
      },
      {
        id: "1-3",
        name: "3 口耳目",
      }
    ],
  },
  {
    id: "2",
    name: "第二单元",
    children: [
      {
        id: "2-1",
        name: "1 a o e",
      },
      {
        id: "2-2",
        name: "2 i u ü y w",
      }
    ],
  },
  {
    id: "3",
    name: "全书复习",
    children: [
      {
        id: "3-1",
        name: "全书复习",
      },
    ],
  },
];
let arr = [];

const { TreeNode } = Tree;
class SelectTreeCmp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedKeys: [],
      selectedKeys: [],
      parentKey: [],
      parentData: {
        title: '',
        key: '',
        childTitle: '',
        childKey: '',
      }, // 保存给父级的数据
    }
    this.valueMap = {};
  }

  componentDidMount() {
    // 默认展开
    const { selectTreeData, type, value, expandedKeys } = this.props;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData;
    // const {ary: expandedKeys, ary_ } = this.getChildrenId(data);
    const { ary, ary_ } = this.getChildrenId(data);
    this.loops(data);
    console.log(this.loops(data))
    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }

    let newExpandedKeys = expandedKeys || ary;
    if (newExpandedKeys.length !== 0) {
      if (type === 'bourseBefore') {
        this.setState({
          expandedKeys: value,
          parentData,
        })
      } else {
        this.setState({
          expandedKeys: newExpandedKeys,
          parentData,
        });
      }

    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectTreeData, type, value } = nextProps;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData;
    const { ary: expandedKeys, ary_ } = this.getChildrenId(data);

    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }

    if (JSON.stringify(selectTreeData) !== JSON.stringify(this.props.selectTreeData)) {
      if (type === 'bourseBefore') {
        this.setState({
          expandedKeys: value,
          parentData
        })
      } else {
        this.setState({
          expandedKeys,
          parentData,
        });
      }
    }
  }

  getChildren = (arr = [], ary, ary_) => {
    const first = arr[0];
    const first_ = arr[0];
    ary.push(first?.id);
    ary_.push(first_);
    if (first?.children) {
      this.getChildren(first?.children, ary, ary_)
    }
  }

  getChildrenId(data) {
    const ary = [];
    const ary_ = []
    this.getChildren(data, ary, ary_);
    return {
      ary,
      ary_,
    }
  }

  loops = (list, parent) => {
    return (list || []).map(({ children, id, name }) => {
      const node = (this.valueMap[id] = {
        parent,
        id,
        name,
      });
      node.children = this.loops(children, node);
      return node;
    });
  }

  getPath = (id) => {
    const path = [];
    const path_ = []
    let current = this.valueMap[id];

    while (current) {
      path.unshift(current);
      path_.unshift(current.id);
      current = current.parent;
    }

    return {
      path,
      path_
    }
  }

  //选中的回调
  onSelect = (_, obj) => {
    console.log(this.props.selectTreeData, obj, 'obj')
    let { parentData, expandedKeys } = this.state;
    let objPath = this.getPath(obj.node.key);
    const index = expandedKeys.indexOf(obj.node.key);

    if (obj.node.className === CatalogTitleType.treeNodeTitle) {
      const parentData_ = {
        ...parentData,
        title: obj.node.title,
        key: obj.node.key
      }

      if (index !== -1) {
        this.setState({
          expandedKeys: [],
          parentData: parentData_,
        });
      } else {
        this.setState({
          expandedKeys: objPath.path_,
          parentData: parentData_,
        });
      }
    } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
      if (index !== -1) {
        objPath.path_.pop();

        this.setState({
          expandedKeys: objPath.path_,
        });
      } else {
        this.setState({
          expandedKeys: objPath.path_,
        });
      }

    } else {
      const parentData_ = {
        ...parentData,
        childTitle: obj.node.title,
        childKey: obj.node.key,
      };

      this.setState({ parentData: parentData_, expandedKeys: objPath.path_ }, () => {
        if (!this.props.onTreeActive) return;

        this.props.onTreeActive(this.state.parentData, objPath.path_);
      });
    }
  };

  //展开的回调
  onExpend = (_, obj) => {
    const { parentData } = this.state;
    let objPath = this.getPath(obj.node.key);

    if (obj.expanded) {
      if (obj.node.className === CatalogTitleType.treeNodeTitle) {
        const parentData_ = {
          ...parentData,
          title: obj.node.title,
          key: obj.node.key
        };

        this.setState({
          expandedKeys: [obj?.node?.key],
          parentData: parentData_,
        });
      } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
        this.setState({ expandedKeys: objPath.path_ });
      }
    } else {
      if (obj.node.className === CatalogTitleType.treeNodeTitle) {
        this.setState({ expandedKeys: [] });
      } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
        objPath.path_.pop();
        this.setState({ expandedKeys: objPath.path_ });
      }
    }
  };
  //查看课程
  catCurriculum = (id, ele, isOuterParent) => {
    console.log(id, ele, isOuterParent)
    localStorage.setItem('/teachersspace/courseDetailsDetailed/selectedKeys', '');
    let { courseId, isEdit, schoolId, isDemonstrationCourse, permissionData, expandedKeys, selectTreeData } = this.props;
    console.log("courseId=>", courseId)
    //获取当前选中的节点数组
    // this.getExpandedKeys(selectTreeData,id);


    // console.log("arr",arr)
    if (isEdit) {
      if (isDemonstrationCourse) {
        // 示范课路由跳转
        this.props.history.push(`/demonspace/courseDetailsDetailed?id=${courseId}&courseId=${courseId}&catalogId=${id}&isEdit=${isEdit}&schoolId=${schoolId}&isDemonstrationCourse=${isDemonstrationCourse ? isDemonstrationCourse : ''}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage ? getParam().isJiaoXueKongJianPage : ''}&isJiaoXueKongJianPage1=${getParam().isJiaoXueKongJianPage1 ? getParam().isJiaoXueKongJianPage1 : ''}&courseCatalogEdit=${permissionData?.courseCatalogEdit || ''}&courseShare=${permissionData?.courseShare || ''}`);
      } else {
        // 我的课程路由跳转
        this.props.history.push(`/teachersspace/courseDetailsDetailed?id=${courseId}&courseId=${courseId}&catalogId=${id}&isEdit=${isEdit}&schoolId=${schoolId}&isDemonstrationCourse=${isDemonstrationCourse ? isDemonstrationCourse : ''}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage ? getParam().isJiaoXueKongJianPage : ''}&isJiaoXueKongJianPage1=${getParam().isJiaoXueKongJianPage1 ? getParam().isJiaoXueKongJianPage1 : ''}&courseCatalogEdit=${permissionData?.courseCatalogEdit || ''}&courseShare=${permissionData?.courseShare || ''}`);
      }
    } else {
      this.props.history.push(`/courseCenter/courseDetailsDetailed?courseId=${courseId}&catalogId=${id}&isEdit=${isEdit ? isEdit : ''}&schoolId=${schoolId}&isDemonstrationCourse=${isDemonstrationCourse ? isDemonstrationCourse : ''}&isJiaoXueKongJianPage=${getParam().isJiaoXueKongJianPage ? getParam().isJiaoXueKongJianPage : ''}&isJiaoXueKongJianPage1=${getParam().isJiaoXueKongJianPage1 ? getParam().isJiaoXueKongJianPage1 : ''}&onlyCheck=${getParam()?.onlyCheck||''}`);
    }
  }


  // getExpandedKeys = (list,id) => {
  //   (list || []).filter((item,index)=>{
  //     if(item?.id == id){
  //       arr.push(id);
  //     }else if(item?.children&&item?.children.length>0){
  //       arr.push(item?.id)
  //       this.getExpandedKeys(item?.children,id);
  //     }
  //   })
  // return (selectTreeData || []).map(({ children, id, name }) => {
  //   const node = (this.valueMap[id] = {
  //     parent,
  //     id,
  //     name,
  //   });
  //   node.children = this.getExpandedKeys(children, node);
  //   return node;
  // });
  // }
  renderTitle = ({ ele, isChildren, isOuterParent }) => {
    if (isOuterParent) {
      return (<div>
        <span className="numberOfTitle">{ele.name}</span>
        <div className="numberOfTitleRightContainer">
          <span className='numberOfTitleRightText'>[{ele?.childNum || 0}课时]</span>
        </div>
      </div>);
    }
    if (isChildren) {
      return ele.name;
    }
    return (<div onClick={this.catCurriculum.bind(this, ele?.id, ele, isOuterParent)}>
      <span className="numberOfTitle">{ele?.name}</span>
      {
        ele?.fileCount != 0 && ele?.fileCount && <span className="numberOfClassHours">{ele?.fileCount}</span>
      }
      <div className="numberOfTitleRightContainer">
        <span className='numberOfTitleRightText'>查看课程</span>
        <img className='numberOfTitleRightImg' src={imgUrlForZhongZhi("numberOfTitleRightImg.png")} alt="" />
      </div>
    </div>);
  }

  mapData = (children) => {
    if (children && Array.isArray(children)) {
      return children.map((ele) => {
        if (ele.children && Array.isArray(ele.children)) {
          return (
            <TreeNode
              // title={ele.name}
              // title={this.renderTitle(ele,true)}
              title={this.renderTitle({ ele, isChildren: true })}
              key={ele.id}
              className="tree_children_title"
            >
              {this.mapData(ele.children)}
            </TreeNode>
          );
        } else {
          return (
            <TreeNode
              // title={ele.name}
              title={this.renderTitle({ ele })}
              key={ele.id}
              className="tree_inside_title"
            />
          );
        }
      });
    }
    return [];
  };

  render() {
    const content = [];
    const { selectTreeData } = this.props;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData?.map(item => ({ ...item, isOuterParent: true }));

    data &&
      data.map((item) => {
        if (item) {
          content.push(
            <TreeNode
              // title={item.name}
              title={this.renderTitle({ ele: item, isOuterParent: true })}
              key={item.id}
              className="tree_node_title"
            >
              {this.mapData(item.children)}
            </TreeNode>
          );
        }
      });

    return (
      <div className="components_selectTree" onClick={(e) => e.stopPropagation()}>
        {data?.length > 0 ? (
          <Tree
            onExpand={this.onExpend}
            expandedKeys={this.state.expandedKeys}
            onSelect={this.onSelect}
          >
            {content}
          </Tree>
        ) : (
          <Empty description={false} imageStyle={{ height: 215 }} />
        )}
      </div>
    );
  }
}

export default withRouter(SelectTreeCmp);
