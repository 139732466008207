import React, { Component } from "react";
import { Slider } from 'antd';
import { getIp, imgUrlForZhongZhi } from '@/utils/index';
import { setTimesMin_ } from '@/utils/util';
import styles from './index.module.scss';

class AudioPlay extends Component {
  constructor(props) {
    super();
    this.state = {
      isPlay: false,
      isMuted: false,
      allTime: 0,
      currentTime: 0,
      isShowSlowTip: false,
      isShowFastTip: false,
    };
  }

  componentDidMount() {
    if (this.audioDom) {
      this.audioDom.addEventListener('pause', () => {
        this.setState({
          isPlay: false,
        });
      });
    }
  }

  // 该视频已准备好开始播放
  onCanPlay = () => {
    const audio = this.audioDom;
    this.setState({
      allTime: audio.duration,
    });
  };

  playAudio = () => {
    const audio = this.audioDom;
    let audios = document.getElementsByTagName('audio') || document.getElementsByTagName('video');
    [...audios].forEach(item => {
      item.isPlay = false;
      item.pause();
    });
    audio.isPlay = true;
    let currentTime = audio.currentTime;
    let duration = audio.duration;
    if (currentTime === duration) {
      this.setState({
        currentTime: 0,
        isPlay: true,
      }, () => {
        audio.currentTime = 0;
        audio.play();
      });
    } else {
      audio.play();
      this.setState({
        isPlay: true,
      });
    }
  };

  pauseAudio = () => {
    const audio = this.audioDom;
    audio.pause();
    this.setState({
      isPlay: false,
    });
  };

  changeTime = (e) => {
    const value = e;
    const audio = this.audioDom;
    this.setState({
      currentTime: value,
    });
    audio.currentTime = value;
    if (value === audio.duration) {
      this.setState({
        isPlay: false,
      });
    }
  };

  // 当前播放位置改变时执行
  onTimeUpdate = () => {
    const audio = this.audioDom;
    this.setState({
      currentTime: audio.currentTime,
    });
    if (audio.currentTime === audio.duration) {
      this.setState({
        isPlay: false,
      });
    }
  };

  slow = () => {
    clearTimeout(this.slowTimer);
    clearTimeout(this.fastTimer);
    this.setState({
      isShowSlowTip: true,
      isShowFastTip: false,
    }, () => {
      this.slowTimer = setTimeout(() => {
        this.setState({
          isShowSlowTip: false
        }, () => {
          clearTimeout(this.slowTimer);
        });
      }, 2000);
      let target = this.audioDom.currentTime - 5;
      if (target <= 0) {
        target = 0;
      }
      this.audioDom.currentTime = target;
      this.setState({
        currentTime: target
      });
    });
  }

  fast = () => {
    clearTimeout(this.slowTimer);
    clearTimeout(this.fastTimer);
    this.setState({
      isShowSlowTip: false,
      isShowFastTip: true,
    }, () => {
      this.fastTimer = setTimeout(() => {
        this.setState({
          isShowFastTip: false
        }, () => {
          clearTimeout(this.fastTimer);
        });
      }, 2000);
      let target = this.audioDom.currentTime + 5;
      let { allTime } = this.state;
      if (target >= allTime) {
        target = allTime;
      }
      this.audioDom.currentTime = target;
      this.setState({
        currentTime: target
      });
    });
  }

  render() {
    const { src,hasSitu } = this.props;
    const {
      isPlay,
      allTime,
      currentTime,
    } = this.state;

    return <div className={styles.outWrapper}>
      <div className={styles.imgBox}>
        <img src={imgUrlForZhongZhi('audio-bg.png')} alt='' />
      </div>
      <audio
        className='audio-wrap'
        src={src}
        ref={(audio) => {
          this.audioDom = audio;
        }}
        preload={"auto"}
        onCanPlay={this.onCanPlay}
        onTimeUpdate={this.onTimeUpdate}
      >
        <track src={src} kind="captions" />
      </audio>
      <div className={styles.bottom}>
        {
          isPlay && this.audioDom.isPlay ?
            <img onClick={this.pauseAudio} className={styles.playBtn} src={imgUrlForZhongZhi('pause.png')} alt='' />
            :
            <img onClick={this.playAudio} className={styles.playBtn} src={imgUrlForZhongZhi('play.png')} alt='' />
        }
        <div className={styles.playprocess}>
          <Slider
            key="1"
            tipFormatter={null}
            value={currentTime}
            step={0.01}
            max={allTime}
            onChange={this.changeTime}
            disabled={hasSitu==1?true:false}
          />
        </div>
        <div className={styles.timeContainer}>
          <span className={styles.timeText}>{setTimesMin_(currentTime)}/</span>
          <span className={styles.timeText}>{setTimesMin_(allTime)}</span>
        </div>
      </div>
    </div>;
  }
}

export default AudioPlay;
