
import React, { Component } from 'react';
import * as echarts from 'echarts';
import "./index.scss";
import _ from "lodash";
const option = {
    backgroundColor: '#fff',
    color: ['#73A0FA'],
    title: {
        text: '课时学习情况',
        textStyle: {
            fontSize: 14,
            color: '#17181A',
            fontWeight: 'normal',
            fontStyle: 'normal',
        },
        left: '-1%'
    },
    tooltip: {
        // show: false,
    },
    legend: {
        show: true,
        icon:'circle',
        left: 'right',
        top:'0',
        itemWidth: 6, // 图例图形的宽度
        textStyle: {
            color: '#8E9AB3',
            fontSize: 12, 

        }
      },
    grid: {
        left: '0',
        right: '25',
        bottom: '5',
        top: '50',
        containLabel: true,
    },
    xAxis: {
        type: 'category',
        // boundaryGap: false,
        data: [],
        splitLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        axisLine: {
            show: false,
        },
    },
    yAxis: {
        type: 'value',
        // min: 1.51,
        // max: 1.56,
        axisLabel: {
            formatter: '{value}',
            color: '#999',
            textStyle: {
                fontSize: 12,
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#ccc',
                type: '',
            },
        },
        axisTick: {
            show: false,
        },
        axisLine: {
            show: false,
        },
    },
    series: [
        {
            name: '中等',
            type: 'bar',
            barWidth: 10,
            stack: '次',
            data: [11,20,30,26,53,24,19],
            itemStyle: {
              normal: {
                barBorderRadius: [0,0,5,5],
                color: '#F3AD3C'
              }
            }
          },
          {
            name: '良好',
            type: 'bar',
            barWidth:10,
            stack: '次',
            data:  [11,20,30,26,53,24,19],
            itemStyle: {
              normal: {
                //颜色渐变
                color: '#34B53A'
              }
            }
          },
          {
            name: '优秀',
            type: 'bar',
            barWidth: 10,
            stack: '次',
            data:  [11,20,30,26,53,24,19],
            itemStyle: {
              normal: {
                barBorderRadius: [5,5,0,0],
                color: '#2252F2'
              }
            }
          },
    ],
};

export default class VerticalHistogram extends Component {
    state = {
    }

    componentDidMount() {
        console.log(this.props.data);
        const { data, type } = this.props
        setTimeout(() => {
            this.initEcharts(data.x, data.y, type)
        }, 200)
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        // this.initEcharts()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize); //销毁
    }

    onResize = () => {
        if (this.myChart) {
            this.myChart.resize()
        }
    }

    //封装Echarts
    initEcharts = (seriesdata, xAxisdata, type) => {
        console.log(seriesdata, xAxisdata);
        this.myChart = echarts.init(this.el);
        // option.series[0].data=[1.544, 1.545, 1.539]
        // option.xAxis.data=['1月', '2月', '3月', '4月', '5月', '6月']

        option.series[0].data = seriesdata.a
        option.series[1].data = seriesdata.b
        option.series[2].data = seriesdata.c
        option.xAxis.data = xAxisdata
        option.title.text = '任务点完成情况'
            option.color = '#2252F2'
        this.myChart.setOption(option);
        window.addEventListener('resize', this.onResize);
    }

    render() {
        const { } = this.state;
        return (
            <div className='histogramwarp'>
                <div
                    ref={v => this.el = v}
                    style={{ width: "100%", height: '100%', background: '#FFFFFF' }}
                >
                </div>

            </div>

        );
    }
}

