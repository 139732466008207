
import React, { Component } from 'react';
import * as echarts from 'echarts';
import "./index.scss";
import _ from "lodash";

const option = {
    backgroundColor: '#fff',
    color: ['#73A0FA'],
    title: {
        text: '课时学习情况',
        textStyle: {
            fontSize: 14,
            color: '#17181A',
            fontWeight: 'normal',
            fontStyle: 'normal',
        },
        left:'-1%'
    },
    tooltip: {
        // show: false,
    },
    grid: {
        left: '0',
        right: '25',
        bottom: '5',
        top: '50',
        containLabel: true,
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: [],
        splitLine: {
            show: false,
        },
        axisTick: {
            show: false,
        },
        axisLine: {
            show: false,
        },
        axisLabel: {
            formatter: '{value}',
            color: '#8E9AB3',
            textStyle: {
                fontSize: 12,
            },
        },
    },
    yAxis: {
        type: 'value',
        // min: 1.51,
        // max: 1.56,
        axisLabel: {
            formatter: '{value}',
            color: '#999',
            textStyle: {
                fontSize: 12,
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#E8E8E8',
                type: '',
            },
        },
        axisTick: {
            show: false,
        },
        axisLine: {
            show: false,
        },
    },
    series: [
        {
            name: '',
            type: 'line',
            smooth: false,
            // symbol: 'none',
            symbolSize:8,
            data: [ // 之前的data数据是 [1.544, 1.545, 1.539.....] 数组形式的，现在得修改成[{...}, {...}, {...}] 具体数据如下

            ],
            itemStyle: { // 当前折现下面的渐变色配置项（具体渐变颜色修改一下即可）
                normal: {
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 1, 0, 0.5, [
                            {
                                offset: 0.2,
                                color: 'rgba(122,165,250,0)',
                            },
                            {
                                offset: 1,
                                color: 'rgba(122,165,250,0.4)',
                            },
                        ]),
                    },
                },
            },
        },
    ],
};

export default class VerticalHistogram extends Component {
    state = {
    }

    componentDidMount() {
        console.log(this.props.data);
        const {data,type}=this.props
        setTimeout(() => {
            this.initEcharts(data.y,data.x,type)
          }, 200)
    }

    componentWillReceiveProps(nextProps) {
        // console.log(nextProps);
        // this.initEcharts()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize); //销毁
    }

    onResize = () => {
        if (this.myChart) {
            this.myChart.resize()
          }
    }

    //封装Echarts
    initEcharts = (seriesdata,xAxisdata,type) => {
        console.log(seriesdata,xAxisdata);
        this.myChart = echarts.init(this.el);
        // option.series[0].data=[1.544, 1.545, 1.539]
        // option.xAxis.data=['1月', '2月', '3月', '4月', '5月', '6月']

        option.series[0].data=seriesdata
        option.xAxis.data=xAxisdata
        if(type==1){
            option.title.text='课时学习情况';
            option.color='#FE4A01';
            option.series[0].itemStyle.normal.areaStyle.color=new echarts.graphic.LinearGradient(0, 1, 0, 0.5, [
                {
                    offset: 0.2,
                    color: 'rgba(254, 74, 1, 0)',
                },
                {
                    offset: 1,
                    color: 'rgba(252, 75, 5, 0.2)',
                },
            ]);
        }else if(type==2){
            option.title.text='任务点完成情况';
            option.color='#2252F2';
            option.series[0].itemStyle.normal.areaStyle.color=new echarts.graphic.LinearGradient(0, 1, 0, 0.5, [
                {
                    offset: 0.2,
                    color: 'rgba(46, 91, 255, 0)',
                },
                {
                    offset: 1,
                    color: 'rgba(34, 82, 242, 0.2)',
                },
            ]);
        }
        this.myChart.setOption(option);
        window.addEventListener('resize', this.onResize);
    }

    render() {
        const { } = this.state;
        return (
            <div className='histogramwarp'>
                <div
                    ref={v => this.el = v}
                    style={{ width: "100%", height: '100%', background: '#FFFFFF' }}
                >
                </div>

            </div>

        );
    }
}
