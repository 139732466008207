import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Button, Form, message, Upload} from 'antd';

import {uploadFile} from '@/api';
import {imgUrlForZhongZhi} from '@/utils';
import styles from './index.module.scss';

const uploadModal = forwardRef(({uploadTypeInfo, ...props}, ref) => {

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState();
  const [imgShow, setImgShow] = useState(true);
  const [fileInfo, setFileInfo] = useState({});

  const onFinished = () => {
    return form;
  };
  useImperativeHandle(ref, () => {
    return {
      onFinished
    };
  });
  const reUpload = () => {
    setImgShow(false);
  };
  const handleChange = (info) => {
    console.log(info);
    const name = info?.file?.name;
    const size = info.size / 1024;

    setFileList(info.fileList.slice()); // Note: A new object must be used here!!!

    if (info.file.status === 'uploading') {
      console.log("uploading");
      setFileInfo({name, size});
      setImgShow(false);
    }
    if (info.file.status === 'done') {
      console.log("done");
      const code = info?.file?.response?.code;
      if (code == 0) {
        message.success(`${name} 上传成功`);
        setFileList([]);
      } else {
        message.success(`${name} 上传失败`);
      }

      setImgShow(false);
      setFileInfo({});

    } else if (info.file.status === 'error') {
      setFileList([]);
      message.error(`${info.file.name} 上传失败`).then();
      setFileInfo({});
      setImgShow(false);
    }

  };
  // 文件上传
  const uploadProps = {
    name: 'file',
    action: uploadFile,
    multiple: false,
    fileList,
    listType: 'text',
    onChange: handleChange,
    onRemove(file) {
      setFileList([]);
    },

    progress: {
      strokeColor: {
        '0%': '#00C250',
        '100%': '#00C250',
      },
      strokeWidth: 3,
      format: (percent) => {
        return `${parseFloat(percent.toFixed(2))}%`;
      },
    },
  };
  return (<div className={styles.uploadContainer}>

    <div className={styles.upload}>
      <div className={styles.upLoadImgBox}>
        {
          imgShow ? <img className={styles.upLoadImg} src={imgUrlForZhongZhi('upLoad.png')} alt=""/> :
            <div className={styles.fileInfo}>
              <p>{fileInfo?.name}</p>
              <p>{fileInfo?.size}</p>
            </div>
        }
        {
          imgShow ? <Upload accept={uploadTypeInfo.prevfixList.toString()} {...uploadProps} key="3624">
              <Button icon={null}>插入{uploadTypeInfo.zh}</Button>
            </Upload> :
            <div onClick={reUpload}>重新上传</div>
        }

      </div>
    </div>
    <div className={styles.specRequire}>
      {
        uploadTypeInfo.tips.map((item, index) => {
          return (
            <p>{index + 1}、{item}</p>
          );
        })
      }
    </div>

  </div>);
});
export default uploadModal;
