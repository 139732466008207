import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateCrumbsData,savecCurseVOData } from '@/store/action';
import CourseDetailsComponent from '@@/CourseDetailsComponent';
import { imgUrlForZhongZhi } from '@/utils';
import { getParam } from '@/utils/util';
import {
  getCourseInfo,
} from '@/api';
import axios from '../../axios';
import styles from './index.module.scss';

class spaceCourseDetails extends Component {
  constructor() {
    super();
    this.state = {
      courseVO: {},
      catalogList: [],
      teamTeachers: [],
      permissionData:{}
    };
  }

  componentDidMount() {

    this.getCourseInfo();
  }

  setTitle = (courseVO) => {
    let { isEdit, isDemonstrationCourse,applyType } = getParam();
    if (isEdit) {
      let crumbsData = [
        {
          name: '课程',
          path: '/teacher'
        },
        {
          name: '课程管理',
          path: '/teacher'
        },
        {
          name: '我的课程',
          path: '/teacher'
        },
      ]
      if(isDemonstrationCourse){
        crumbsData.push({
          name: applyType == '1' ? '平台示范课' : '学校示范课',
          path: '/teacher'
        },{
          name: courseVO?.courseName,
          path: '/teacher'
        })
      }else{
        crumbsData.push({
          name: courseVO?.courseName,
          path: '/teacher'
        })
      }
      this.props.updateCrumbsData(crumbsData);
    } else {
      this.props.updateCrumbsData([
        {
          name: '教师空间',
          path: '/teacher'
        },
        {
          name: courseVO?.courseType === '1' ? '公共课' : '专业课',
          path: '/teacher'
        },
        {
          name: courseVO?.subjectName,
          path: '/teacher'
        },
        {
          name: courseVO?.courseName,
          path: '/teacher'
        },
      ]);
    }
  }

  transformData = catalogList => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (childList && Array.isArray(childList)) {
        return { ...item, name: item?.catalogName, children: this.transformData(item?.childList) };
      }
      return { ...item, name: item?.catalogName };
    });
  }

  getCourseInfo = () => {
    let { id } = getParam();
    axios.get(getCourseInfo, {
      params: {
        courseId: id,
      },
    }).then(res => {
      if (!res) return;
      let { courseVO, catalogList, teamTeachers,permissionData } = res;
      this.setState({
        courseVO,
        catalogList: this.transformData(catalogList),
        teamTeachers,
        permissionData
      },()=>{
        this.replaceParamVal('courseShare',permissionData?.courseShare)
        this.replaceParamVal('isJiaoXueKongJianPage',courseVO?.courseName)
      });
      this.setTitle(res.courseVO);
      this.props.savecCurseVOData(res.courseVO)
    });
  }
  replaceParamVal =(paramName,replaceWith) =>{
    let oUrl = window.location.href.toString();
    let re=eval('/('+ paramName+'=)([^&]*)/gi');
    let nUrl = oUrl.replace(re,paramName+'='+replaceWith);
　　 window.location.href=nUrl
  }
  render() {
    let { isEdit, courseType, isDemonstrationCourse,id } = getParam();
    let { courseVO, catalogList, teamTeachers,permissionData } = this.state;
    return (
      <div className={styles.wrapper}>
        <CourseDetailsComponent id={id} isEdit={isEdit} history={this.props.history} isDemonstrationCourse={isDemonstrationCourse}  permissionData={permissionData} courseVO={courseVO} catalogList={catalogList} teamTeachers={teamTeachers} />
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
  savecCurseVOData(res = {}) {
    dispatch(savecCurseVOData(res));
  },
});

export default connect(({ crumbsData,curseVOData }) => ({ crumbsData,curseVOData }), mapAction)(spaceCourseDetails);
