import React, { useEffect, useState } from 'react';
import { RouteProps, Switch, Route, Redirect } from 'react-router-dom';

import './css/layout.scss';
import Header from '../Header/Header.js';
import axios from '../../axios';
import { querySchoolByRealmName } from '../../api';
import {dymicSettingWebsiteInfo, getIp} from '../../utils/index';

interface Props {
  routes: Array<RouteProps>;
}

export default function Layout({ routes }: Props): JSX.Element {
  const [schoolObj, setSchoolObj] = useState({});

  const querySchoolByRealmNameFn = () => {
    axios.get(querySchoolByRealmName, {
      params: {
        realmName: getIp()
      }
    }).then(res => {
      if (!res) return;
      // @ts-ignore
      const {schoolName, logoUrl} = res
      dymicSettingWebsiteInfo(schoolName, logoUrl)
      setSchoolObj(res);
    });
  };

  useEffect(() => {
    querySchoolByRealmNameFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`slayout-wrap`}>
      <header>
        <Header />
      </header>
      <main className="slayout-main">
        <Switch>
          {routes.map((route, i) => (
            <Route key={i} {...route} />
          ))}
          <Redirect exact from="/" to="/home" />
        </Switch>
      </main>
      <footer className="slayout-footer d-flex justify-content-center align-items-center">
        {/* Copyright &copy;2021 济南智学酷教育科技有限公司 All Rights Reserved. 鲁ICP备18048664号-1 */}
        {/* <div className='bottomContainerLayout'>
          <span>关于我们</span>
          <span className='bottomlineLayout'>/</span>
          <span>帮助中心</span>
          <span className='bottomlineLayout'>/</span>
          <span>意见反馈</span>
          <span className='bottomlineLayout'>/</span>
          <span>用户协议</span>
          <p>Copyright &copy;2021 济南智学酷教育科技有限公司 All Rights Reserved. 鲁ICP备18048664号-1</p>
        </div> */}
        <div className='bottomContainerTwo'>
          <p className='schoolName'>{(schoolObj as any).schoolName}</p>
          <div className='addreddPhoneEmail'>
            <span>地址：{(schoolObj as any).address}</span>
            <span>电话：{(schoolObj as any).mobile}</span>
            <span>E-mail：{(schoolObj as any).email}</span>
          </div>
          <div className='agreement'>
            <span onClick={()=>{
                window.open('/#/UserAgreement');
              }}>用户协议</span>
            <span>Copyright &copy;2021  鲁ICP备18048664号-1  </span>
          </div>
        </div>
      </footer>
    </div>
  );
}
