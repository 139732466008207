import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './style/custom.scss';
import './style/common.scss';
import 'react-custom-audio/dist/main.css';
import 'swiper/swiper-bundle.min.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorker from './serviceWorker';
import './utils/rem';
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
