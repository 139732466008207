import React from "react";
import { getParam } from '../../utils/util';

class LoginWX extends React.Component {
  constructor() {
    super();
    this.state = {

    };
  }

  componentDidMount() {
    let { code, domain } = getParam();
    if (code) {
      window.location.replace(`${domain}/#/home?code=${code}`);
    }
  }

  render() {
    return (
      <div></div>
    );
  }
}

export default LoginWX;
