import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './css/nav.scss';

function Nav() {
  const { pathname } = useLocation();
  const links = useMemo(
    () => [
      {
        name: '首页',
        path: '/home',
      },
      {
        name: '课程中心',
        path: '/courseCenter',
      },
      {
        name: '公告通知',
        path: '/zzgg',
      },
      {
        name: '教师空间',
        path: '/teacher',
      },
    ],
    []
  );

  return (
    <>
      {
        links.map(({ name, path }) => (
          <Link
            to={path}
            key={name}
            className={
              `mag-r-60 snav-link text-decoration-none ${(new RegExp(path, 'g')).test(pathname) ? 'active' : ''}`
            }
          >
            <span>{name}</span>
          </Link>
        ))
      }
    </>
  );
}

export default Nav;
