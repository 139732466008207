import React, { Component } from "react";
import { Tree, Empty, Checkbox } from "antd";
import { imgUrlForZhongZhi } from '@/utils';
import "./styles.less";
import { number } from "prop-types";
import {CatalogTitleType} from "../../../../utils/publicType";

const { TreeNode } = Tree;
export default class SelectTreeCmpForDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: [],
      selectedKeys: [],
      parentKey: [],
      parentData: {
        title: '',
        key: '',
        childTitle: '',
        childKey: '',
      }, // 保存给父级的数据
      checkedId: [],
      undelCatalogs:[], //被选中的未被删除的父级
      checkedNumber: 1     // 选中的是第几级菜单（彻底删除只能删除三级的）
    };
    this.valueMap = {};
    console.log("props",props);
  }

  componentDidMount() {
    typeof this.props.onRef === 'function' && this.props.onRef(this);
    // 默认展开
    const { selectTreeData, type, value, expandedKeys } = this.props;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData;
    const { ary, ary_ } = this.getChildrenId(data);
    this.loops(data);
    this.setState({
      selectedKeys: [ary[ary?.length - 1]]
    })
    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }
    console.log(ary, ary_);
    let newExpandedKeys = expandedKeys || ary;
    if (newExpandedKeys.length !== 0) {
      if (type === 'bourseBefore') {
        this.setState({
          expandedKeys: value,
          parentData
        })
      } else {
        this.setState({
          expandedKeys: newExpandedKeys,
          parentData
        });
      }

    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    //该方法内禁止访问this
    if (nextProps.checkedId !== prevState.checkedId) {
      //通过对比nextProps和prevState，返回一个用于更新状态的对象
      return {
        value: nextProps.email
      }
    }
    //不需要更新状态，返回null
    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.email) {
      // 做一些需要this.props的事
    }
  }
  componentWillReceiveProps(nextProps) {
    const { selectTreeData, type, value } = nextProps;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData;
    const { ary: expandedKeys, ary_ } = this.getChildrenId(data);

    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }

    if (JSON.stringify(selectTreeData) !== JSON.stringify(this.props.selectTreeData)) {
      if (type === 'bourseBefore') {
        this.setState({
          expandedKeys: value,
          parentData
        })
      } else {
        this.setState({
          expandedKeys,
          parentData,
        });
      }
    }
  }

  getChildren = (arr = [], ary, ary_) => {
    const first = arr[0];
    const first_ = arr[0];
    ary.push(first?.id);
    ary_.push(first_);
    if (first?.children) {
      this.getChildren(first?.children, ary, ary_)
    }
  }

  getChildrenId(data) {
    const ary = [];
    const ary_ = []
    this.getChildren(data, ary, ary_);
    return {
      ary,
      ary_,
    }
  }

  loops = (list, parent) => {
    return (list || []).map(({ children, id, name }) => {
      const node = (this.valueMap[id] = {
        parent,
        id,
        name,
      });
      node.children = this.loops(children, node);
      return node;
    });
  }

  getPath = (id) => {
    const path = [];
    const path_ = []
    let current = this.valueMap[id];

    while (current) {
      path.unshift(current);
      path_.unshift(current.id);
      current = current.parent;
    }

    return {
      path,
      path_
    }
  }

  //选中的回调
  onSelect = (sel, obj) => {
    let { parentData, expandedKeys } = this.state;
    let objPath = this.getPath(obj.node.key);
    const index = expandedKeys.indexOf(obj.node.key);
    console.log(sel, obj);
    if (obj.node.className === CatalogTitleType.treeNodeTitle) {
      const parentData_ = {
        ...parentData,
        title: obj.node.title,
        key: obj.node.key
      }

      if (index !== -1) {
        this.setState({
          expandedKeys: [],
          parentData: parentData_,
        });
      } else {
        this.setState({
          expandedKeys: objPath.path_,
          parentData: parentData_,
          selectedKeys: sel
        });
      }
      this.props.onTreeActive(this.state.parentData, objPath.path_, obj.node.className);
    } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
      if (index !== -1) {
        objPath.path_.pop();

        this.setState({
          expandedKeys: objPath.path_,
          selectedKeys: sel
        });
      } else {
        this.setState({
          expandedKeys: objPath.path_,
          selectedKeys: sel
        });
      }
      this.props.onTreeActive(this.state.parentData, objPath.path_, obj.node.className);
    } else {
      const parentData_ = {
        ...parentData,
        childTitle: obj.node.title,
        childKey: obj.node.key,
      };

      this.setState({ parentData: parentData_, expandedKeys: objPath.path_,selectedKeys: sel }, () => {
        if (!this.props.onTreeActive) return;

        this.props.onTreeActive(this.state.parentData, objPath.path_, obj.node.className);
      });
    }
  };

  //展开的回调
  onExpend = (_, obj) => {
    const { parentData } = this.state;
    let objPath = this.getPath(obj.node.key);

    if (obj.expanded) {
      if (obj.node.className === CatalogTitleType.treeNodeTitle) {
        const parentData_ = {
          ...parentData,
          title: obj.node.title,
          key: obj.node.key
        };

        this.setState({
          expandedKeys: [obj?.node?.key],
          parentData: parentData_,
        });
      } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
        this.setState({ expandedKeys: objPath.path_ });
      }
    } else {
      if (obj.node.className === CatalogTitleType.treeNodeTitle) {
        this.setState({ expandedKeys: [] });
      } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
        objPath.path_.pop();
        this.setState({ expandedKeys: objPath.path_ });
      }
    }
  };

  catCurriculum = id => {

  }


  onClickCheckBox = (checkedId, number, e) => {
    if (checkedId !== this.state.checkedId) {
      this.setState({
        checkedId,
        checkedNumber: number
      });
    } else {
      this.setState({
        checkedId: '',
        checkedNumber: 1
      });
    }
  }

  renderTitle = ({ ele, isChildren, isOuterParent, number }) => {
    let { checkedId } = this.state;
    // if (isOuterParent) {
    //   return (<div>
    //     <span className="numberOfTitle">{ele.name}</span>
    //     <div className="numberOfTitleRightContainer">
    //       <span className='numberOfTitleRightText'>[{ele?.childNum || 0}课时]</span>
    //     </div>
    //   </div>);
    // }


    // if (isChildren || isOuterParent) {
    //   return ele.name;
    // }


    return (<div onClick={this.catCurriculum.bind(this, ele?.id)}>
      {/* {
        ele?.hasDel === 1 && <Checkbox onClick={this.onClickCheckBox.bind(this, ele?.id, number)} onChange={this.onChange.bind(this, ele?.id)} checked={checkedId === ele?.id} />
      } */}
      {/* <Checkbox onChange={this.onChange.bind(this, ele?.id)} /> */}
      <span className="numberOfTitle">{ele.name?.length > 10 ? ele.name?.slice(0, 10) + '...' : ele.name}</span>
      {
        ele?.fileCount && ele?.fileCount != 0 && <span className="numberOfClassHours">{ele?.fileCount}</span>
      }
      {/* <div className="numberOfTitleRightContainer">
        <span className='numberOfTitleRightText'>查看课程</span>
        <img className='numberOfTitleRightImg' src={imgUrlForZhongZhi("numberOfTitleRightImg.png")} alt="" />
      </div> */}
    </div>);
  }

  mapData = (children, number) => {
    number = number + 1;
    if (children && Array.isArray(children)) {
      return children.map((ele) => {
        if (ele.children && Array.isArray(ele.children)) {
          return (
            <TreeNode
              // title={ele.name}
              // title={this.renderTitle(ele,true)}
              title={this.renderTitle({ ele, isChildren: true, number })}
              key={ele.id}
              className="tree_children_title"
            >
              {this.mapData(ele.children, number)}
            </TreeNode>
          );
        } else {
          return (
            <TreeNode
              // title={ele.name}
              title={this.renderTitle({ ele, number })}
              key={ele.id}
              className="tree_inside_title"
            />
          );
        }
      });
    }
    return [];
  };
  onCheck = (checkedId,b,c,d) => {
    console.log(checkedId,b,c,d);
    console.log("this.valueMap=>",this.valueMap);
    // undelCatalogs
    let allUndelCatalogs = [];
    let undelCatalogs = []
    checkedId.forEach((item)=>{
      let parentItem = this.valueMap[item]?.parent
      if(parentItem&&checkedId.indexOf(parentItem?.id)=== -1){
        undelCatalogs.push(parentItem?.id)
        if(parentItem?.parent&&checkedId.indexOf(parentItem?.parent?.id)=== -1){
          undelCatalogs.push(parentItem?.parent?.id)
        }
      }
    })
    // console.log("去重前的 allUndelCatalogs",allUndelCatalogs);
    // console.log("去重后的 allUndelCatalogs",[...new Set(allUndelCatalogs)]);

    // allUndelCatalogs = ['425e79dc914443c196085c21679ed36c', '9c8847b4a10bee1305109c18ea33ba4c', 'aa9a095c7f9339e004c33cb2ea07e2fb']
    // checkedId = ['fb09b0ce27d44f159fa726f10874bada', '30c84469043a0f01c7890d7a9dad2f9c', 'zr5mv1orgu1wxsopnvvyqzbqiratywpv', 'aa9a095c7f9339e004c33cb2ea07e2fb']
    // allUndelCatalogs.forEach((catalog)=>{
    //   if(checkedId.indexOf(catalog) === -1){
    //     arr.push(catalog)
    //   }
    // })
    console.log("undelCatalogs",[...new Set(undelCatalogs)]);
    this.setState({ checkedId,undelCatalogs })
  };
  render() {
    const content = [];
    const { selectTreeData } = this.props;
    // let selectTreeData = result
    let number = 1;
    const data = selectTreeData?.length && selectTreeData?.map(item => ({ ...item, isOuterParent: true }));
    console.log(this.state.selectedKeys);
    console.log(data);
    data &&
      data.map((item) => {
        if (item) {
          content.push(
            <TreeNode
              // title={item.name}
              title={this.renderTitle({ ele: item, isOuterParent: true, number })}
              key={item.id}
              className="tree_node_title"
            >
              {this.mapData(item.children, number)}
            </TreeNode>
          );
        }
      });

    return (
      <div className="components_selectTree2" onClick={(e) => e.stopPropagation()}>
        {data?.length > 0 ? (
          <Tree
            checkable
            onExpand={this.onExpend}
            expandedKeys={this.state.expandedKeys}
            selectedKeys={this.state.selectedKeys}
            onSelect={this.onSelect}
            onCheck={this.onCheck}
          >
            {content}
          </Tree>
        ) : (
          <Empty description={false} imageStyle={{ height: 215 }} />
        )}
      </div>
    );
  }
}
