import React, {Component, useRef} from 'react';
import {Modal, Upload, message, Row, Col, Tooltip, Spin} from 'antd';
import {connect} from 'react-redux';
import {savecCurseVOData} from '../../store/action';
import SelectEditTreeCmp from './SelectEditTreeCmp/index';
import SelectEditTreeCmpRight from './SelectEditTreeCmpRight/index';
import RichMedia from './components/RichMedia/index';
import TeachPlan from './components/TeachPlan/index';
import RichTextPreview from '@/components/RichTextPreview/index';
// import { updateUser } from '@/actions/login';
import _ from 'lodash';
import {imgUrlForZhongZhi, getIp} from '@/utils';
import {
  getCatalog,
  uploadFile,
  saveCatalogFile,
  saveCourseCatalog,
  getCatalogFile,
  getResourceByCatalog,
  saveCatalogResource,
  delCatalogFile,
  getAndSetLastCatalog
} from '@/api';
import axios from '../../axios';
import {getParam} from '@/utils/util';
import {fileTypeMap} from './utils'
import './styles.less';
import {login} from '../../api';
import FullScreenMask from "./components/FullScreenMask";
import {bool} from "prop-types";
import {CatalogTitleType} from "../../utils/publicType";
import env from "@/env";

const preview = getIp();
const {xDocUrl, xDocUrlHttps} = env;

const editorRef = React.createRef();

class catalogEditor extends Component {
  formRef = React.createRef();
  isSaving = false

  constructor(props) {
    super(props)
    this.state = {
      navType: 3,
      path: [],
      modalPath: [],
      isClassModal: false,
      isPreviewModal: false,
      delKey: [],
      delCatalogList: [],
      resourceExt: '',
      resourceExtTeachPlan: '',
      stepList: [],//预览内容
      html: '',
      addKey: [],
      addZiKey: [],
      catalogList: [],
      flatCatalogList: [],
      canEditForSelectedCatalog: false,
      isDel: false,
      delFileNum: 0,
      parentData_: {},
      expandedKeys: "",
      firstExpandedKeys: [],
      isAddChild: false,
      nodeClassName: '',
      resResource: {},
    }
    this.valueMap = {};

  }

  courseId = getParam()?.id

  componentDidMount() {
    this.getCatalog(true);
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  loops = (list, parent) => {
    return (list || []).map(({children, id, name, displayOrder}) => {
      const node = (this.valueMap[id] = {
        parent,
        id,
        name,
        displayOrder
      });
      node.children = this.loops(children, node);
      return node;
    });
  }
  getAndSetLastCatalogFn = (type = false) => {
    return new Promise(async (resolve, reject) => {
      // type-true:存记录 false:取记录

      let params = {courseId: getParam()?.id}
      if (type) {
        params.catalogId = this.state.path[this.state.path.length - 1]
      }
      const res = await axios.get(getAndSetLastCatalog, {params})
      // console.log('type', type, res)
      if (type) {
        resolve({})
      } else {
        if (res && (typeof (res) == 'string')) {
          // let expandedKeys = this.getPath(res)?.path_ || '';

          let {path_ = [], path = []} = this.getPath(res);
          let expandedKeys = path_;
          let expandedPath = path

          this.setState({firstExpandedKeys: expandedKeys, expandedKeys});
          // console.log(999, res, expandedKeys);
          resolve({expandedKeys, expandedPath})

        } else {
          //第一次建立 没有原来的数据 传出第一条
          resolve({})
        }
      }
    })

  }
  // section 切换章节
  onTreeChapter = (selectChapterValue, expandedKeys) => {

    console.log("expandedKeys", expandedKeys)
    this.setState({
      firstExpandedKeys: expandedKeys
    });
    // this.getCatalogFile(expandedKeys[expandedKeys?.length - 1])
    // this.getResourceByCatalog(expandedKeys[expandedKeys?.length - 1])
    // this.props.onOk(selectChapterValue, expandedKeys);
    // this.props.onCancle();
  }
  transformData = catalogList => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (childList && Array.isArray(childList)) {
        return {...item, name: item?.catalogName, isEditable: false, children: this.transformData(item?.childList)};
      }
      return {...item, name: item?.catalogName};
    });
  }
  transformDataChildren = catalogList => {
    return catalogList?.map(item => {
      let children = item?.children;
      if (children && Array.isArray(children)) {
        return {...item, childList: this.transformDataChildren(item?.children)};
      }
      return {...item};
    });
  }
  // 修改seniorId
  changeSeniorId = (catalogList, key) => {
    return catalogList?.map(item => {
      let children = item?.children;
      if (children && Array.isArray(children)) {
        return {...item, seniorId: key, childList: this.changeSeniorId(item?.children, key)};
      }
      return {...item};
    });
  }

  // 获取目录
  getCatalog = (type = false) => {
    let {id} = getParam();
    axios.get(getCatalog, {
      params: {
        courseId: id
      }
    }).then(res => {
      //没有默认加一条父子节点
      let result = res;
      if (result.length === 0) {
        let newList = [{
          catalogName: "1 第一单元",
          courseId: "",
          displayOrder: 1,
          fileCount: 0,
          hasDel: 0,
          id: "",
          isLast: false,
          seniorId: "0",
          nodeId: "0",
          setting: false,
          childList: [{
            catalogName: "1.1 第一课时",
            courseId: "",
            displayOrder: 1,
            fileCount: 0,
            hasDel: 0,
            id: "",
            isLast: true,
            setting: false,
          }]
        }]
        this.saveCourseCatalog(newList, 2);
      } else {
        this.setState({
          // courseVO,
          catalogList: this.transformData(result),
          flatCatalogList: this.flatCatalogList(result)
        }, () => {
          let newCatalogCopy = this.state.catalogList;
          this.loops(newCatalogCopy);
          // 第一次进入获取 其他不获取
          // if (type) {
          this.getAndSetLastCatalogFn(false).then((resolve) => {
            console.log("resolve", resolve)
            let {expandedKeys = [], expandedPath = []} = resolve;
            //第二次 type false
            let newPath = expandedKeys;
            //新创建的 选中第一项
            if (!expandedKeys || expandedKeys.length == 0) {
              const {ary, ary_} = this.getChildrenId(newCatalogCopy);
              newPath = ary;
              expandedPath = ary_;
            }
            console.log("expandedPath------------------------", expandedPath);
            // console.log("async-path", expandedKeys, newPath, newCatalogCopy)
            // console.log("back", expandedPath, expandedKeys)
            let pos = this.getPos(expandedPath)
            // let parentData= {
            //   title: expandedPath[expandedPath.length-1]?.name,
            //   key: expandedPath[expandedPath.length-1]?.id,
            //   pos,
            //   childTitle:'',
            //   childKey: '',
            // } // 保存给父级的数据
            // console.log(parentData,'parentData-------------------');
            // this.setState({parentData})
            let nodeClassName = '';
            let node = expandedPath[expandedPath.length - 1];
            if (!node.parent && Object.keys(resolve).length > 0) { //新增的课时和第一项
              nodeClassName = 'tree_node_title'
            } else {
              if (node.parent && node.children.length > 0) {
                nodeClassName = CatalogTitleType.treeChildrenTitle
              } else {
                nodeClassName = 'tree_inside_title'
              }
            }
            console.log("nodeClassName", nodeClassName)
            this.leftTreeRef.onSelect([], {
              event: "select", selected: false, selectedNodes: [], node: {
                title: expandedPath[expandedPath.length - 1]?.name,
                key: expandedPath[expandedPath.length - 1]?.id,
                pos,
                className: nodeClassName

              }
            });
          })

        });
      }

    });
  }
  getPos = (expandedPath) => {
    let str = '0'
    expandedPath.forEach((item, index) => {
      str = str + '-' + String(item.displayOrder - 1)
    })
    return str
  }
  getChildren = (arr = [], ary, ary_) => {
    const first = arr[0];
    const first_ = arr[0];
    ary.push(first?.id);
    ary_.push(first_);
    if (first?.children) {
      this.getChildren(first?.children, ary, ary_)
    }
  }
  getChildrenId = (data) => {
    const ary = [];
    const ary_ = []
    this.getChildren(data, ary, ary_);
    return {
      ary,
      ary_,
    }
  }
  getPath = (id) => {
    const path = [];
    const path_ = []
    let current = this.valueMap[id];
    // console.log(current, id);
    while (current) {
      path.unshift(current);
      path_.unshift(current.id);
      current = current.parent;
    }

    return {
      path,
      path_
    }
  }
  // 保存文件
  saveCatalogFile = (catalogId, detailFileId, detailType) => {
    let {id} = getParam();
    axios.post(saveCatalogFile, {
      catalogId,
      courseId: id,
      detailFileId,
      detailType
    }).then(res => {
      // console.log(res);
    });
  }

  changeDisplayOrder = data => {
    return data?.map((item, index) => {
      if (item?.children) {
        return {
          ...item,
          displayOrder: index + 1,
          children: this.changeDisplayOrder(item?.children),
          childList: this.changeDisplayOrder(item?.childList)
        };
      }
      return {...item, displayOrder: index + 1};
    });
  }

  // 保存目录
  saveCourseCatalog = (newList, type = '1') => {
    const {path, delKey, delCatalogList, addKey, addZiKey} = this.state;
    let catalogList
    if (type == '1') {
      catalogList = this.transformDataChildren(this.leftTreeRef.state.data)
    }
    // console.log(addKey, addZiKey, "addZiKey");
    // console.log(catalogList, 'catalogListcatalogListcatalogList');
    let addKeyAllList = addKey.concat(addZiKey)
    // console.log(addKeyAllList, 'addKeyAllList');
    for (let index = 0; index < addKeyAllList.length; index++) {
      const element = addKeyAllList[index];
      // console.log(element, "element");
      this.delListId(element, catalogList, addKeyAllList)
    }
    // console.log(catalogList, 'catalogListcatalogListcatalogList');
    let {id} = getParam();
    // console.log(catalogList);
    if (type == '2') {
      catalogList = this.transformData(newList)
    } else {
      catalogList = this.transformData(catalogList)
    }
    //删除新增的id
    if (catalogList.length === 0) {
      message.warn('目录为空，请添加目录后保存');
      return
    }
    axios.post(saveCourseCatalog, {
      catalogList: this.changeDisplayOrder(catalogList),
      courseId: id,
      delCatalogIdsList: delKey,
      delCatalogList,
    }).then(res => {
      // console.log(res);
      if (res?.code == 0) {
        if (type == '1') {
          message.success("保存成功");
        }
        this.setState({addKey: [], addZiKey: [], delKey: [], delCatalogList: [], canEditForSelectedCatalog: true})
        this.getCatalog()
      } else {
        message.warn(res?.message || "保存失败");
      }

    });
  }
  // 获取文件
  getCatalogFile = catalogId => {
    let {id, courseId} = getParam();
    axios.get(getCatalogFile, {
      params: {
        catalogId,
        courseId: courseId || id
      }
    }).then(res => {
      if (!res) return;
      let {fileVOList} = res;
      // console.log(fileVOList);
      this.setState({
        resourceExtTeachPlan: fileVOList?.find(item => item?.detailType === 1)?.fileUrl,
        resourceExt: fileVOList?.find(item => item?.detailType === 2)?.fileUrl
      });
    });
  }

  // 富文本内容预览内容
  getResourceByCatalog = (catalogId, attach = 1) => {
    // console.log(catalogId, attach, 'yyyyyyyyyyyy');
    //attach带附件
    let {id} = getParam();
    axios.get(getResourceByCatalog, {
      params: {
        catalogId,
        courseId: id,
        attach
      }
      // params: {
      //   catalogId: '05c9d80054d2528d5639710a5c222aef',
      //   courseId: '0a6d0fb2287d732a62907ede09070115'
      // }
    }).then(res => {
      if (res.hasOwnProperty('stepList')) {
        this.setState({
          stepList: res.stepList,
          resResource: res
        })
      } else {
        console.log(res, "getResourceByCatalog");
        this.setState({
          stepList: [],
          resResource: {}
        })
      }
      ;

      // console.log(res);
    });
  }
  // 切换
  changeNavType = navType => {
    // console.log(this.state.nodeClassName, "changeNavType")
    // console.log(this.state.nodeClassName ==CatalogTitleType.treeChildrenTitle || this.state.nodeClassName == CatalogTitleType.treeNodeTitle)
    if (navType != '3') {
      if (this.state.nodeClassName == CatalogTitleType.treeChildrenTitle || this.state.nodeClassName == CatalogTitleType.treeNodeTitle) {
        message.warn('请先选中目录下的课时');
        return
      }
      if (!this.state.canEditForSelectedCatalog) {
        message.warn('请先保存目录编辑');
        return
      }
    }
    // if (this.state.selLev == '1' && navType == '3' && !this.state.canEdit) {
    //   message.warn('仅允许最小目录编辑');
    //   return
    // }
    // console.log('lllllllllllllllllll');
    if (navType == 3) {
      this.getResourceByCatalog(this.state.path[this.state.path.length - 1], 1)
    }
    this.setState({
      navType,
    });
  }
  //tanchuang
  handleClass = () => {
    const self = this

    const currentClassModal = this.state.isClassModal
    if (currentClassModal) {
      self.setState({
        isClassModal: !currentClassModal,
      })

    } else {
      this.onCurrentTeachingSaveAction((isSuccess) => {
        if (!isSuccess) return
        self.setState({
          isClassModal: !currentClassModal,
        })
      })
    }
  }

  // section 预览
  handlePreviewClass = () => {
    // 先保存, 在跳转
    const self = this
    this.onCurrentTeachingSaveAction((isSuccess) => {
      if (!isSuccess) {
        return
      }
      const courseId = self.courseId
      const catalogId = self.state.path[this.state.path.length - 1]
      const w = window.open('about:blank');
      // 要打开的新页面的url
      w.location.href = `/#/ContentPreview?courseId=${courseId}&catalogId=${catalogId}`
    })


    // console.log(this.state.path[this.state.path.length - 1]);
    // this.getResourceByCatalog(this.state.path[this.state.path.length - 1], 1)
    // this.setState({
    //   isPreviewModal: type,
    // }, () => {
    //   if (!type) {
    //     // this.props.history.go(-1)
    //   }
    // })
  }

  // section 左边栏切换
  onTreeSelect = (data, path, isModal, parentData_ = {}, nodeClassName, ifSave = true) => {

    console.log("===> ", '切换')


    let action = (data, path, isModal, parentData_ = {}, nodeClassName, ifSave = true) => {
      // console.log('+++++++++++++++++++++++++++++++++++')
      // console.log(data, path, isModal, parentData_, nodeClassName)
      let {flatCatalogList, addZiKey} = this.state;
      // console.log("data=>", data);
      // console.log("path=>", path,);
      // console.log("isModal=>", isModal);
      // console.log("parentData_=>", parentData_);
      // console.log("flatCatalogList=>", flatCatalogList)
      // console.log(parentData_?.pos, "onTreeSelect");
      // console.log("addZiKey=>", addZiKey)

      let canEdit = false;
      let selLev;


      // if (addZiKey.indexOf(path[path.length - 1]) != -1) {
      //   // console.log(44444444444444);
      //   message.info("请保存课程编辑后选择该目录");
      //   this.setState({
      //     canEditForSelectedCatalog: false
      //   });
      //   return;
      // } else {

      if (addZiKey.indexOf(path[path.length - 1]) != -1) {
        // console.log(44444444444444);
        // message.info("请保存课程编辑后选择该目录");
        message.warn('请先保存目录编辑');
        // this.setState({
        //   canEditForSelectedCatalog: false
        // });
        // return;
      }
      if (isModal) {
        this.setState({
          modalPath: path,
          expandedKeysRight: path,
          // parentData_,
          nodeClassName
        });
        return;
      }
      if (path.length > 0) {
        let currentId = path[path.length - 1];
        let selItem = flatCatalogList.find(item => item.id == currentId);
        // console.log("selItem", selItem)
        canEdit = flatCatalogList.length > 0 && selItem?.isLast && selItem.seniorId != 0 && addZiKey.indexOf(path[path.length - 1]) === -1;
        selItem && selItem.seniorId == '0' ? selLev = 1 : selLev = 0;
        // console.log(selLev, 'selLev', selItem, 'canEdit')

        this.setState({
          selLev,
          canEdit,
          expandedKeys: path,
          nodeClassName
        })

        if (canEdit) {
          this.getCatalogFile(currentId)
          this.getResourceByCatalog(currentId, 1)
        }
      }

      if (isModal) {
        // console.log(path);
        this.setState({
          modalPath: path,
          // canEditForSelectedCatalog:canEdit
          parentData_,
          nodeClassName
        })
      } else {

        const self = this
        this.setState({
          resResource: {}
        }, () => {

          self.setState({
            // catalogList: data,
            path: path,
            canEditForSelectedCatalog: canEdit,
            parentData_,
            nodeClassName,
            firstExpandedKeys: path
          }, () => {
            self.changeNavType(3);
            if (ifSave) {
              // 记录最后选择章节
              self.getAndSetLastCatalogFn(true)
            }
          })
        })

      }
      // }
    }


    const isCanSave = this.isNeedSaveBeforeExchange() && !isModal

    console.log('是否可以切换', isCanSave)
    if (isCanSave) {
      this.onCurrentTeachingSaveAction((isSuccess) => {
        if (!isSuccess) {
          return
        }
        action(data, path, isModal, parentData_, nodeClassName, ifSave)
      })
    } else {
      action(data, path, isModal, parentData_, nodeClassName, ifSave)
    }
  }

// 是不是可以保存
  isNeedSaveBeforeExchange = () => {
    let courseId = this.courseId;
    if (!courseId) {

      return false;
    }
    let catalogId = this.state.path && Array.isArray(this.state.path) && this.state.path.length > 0 ? this.state.path[this.state.path.length - 1] : null;
    if (!catalogId) {
      return false;
    }

    console.log("courseId:", courseId, '\n catalogId:', catalogId,)
    return true
  }

  treeToArray = (datas) => {
    console.log(datas);
    var res = []
    for (const item of datas) {
      const {children, ...i} = item
      console.log(item, children, i);
      if (children && children.length) {
        res = res.concat(this.treeToArray(children))
      }
      res.push(i)
    }
    return res
  }
  // 删除的
  onTreeDel = (pro_key, pro_item) => {
    let action = (key, item) => {
      let {path, delKey, delCatalogList} = this.state;
      delKey.push(key);
      let delItems = this.treeToArray([item])
      delCatalogList = delCatalogList.concat(delItems)

      // console.log("delCatalogList",delCatalogList);
      // console.log("key",key);
      // console.log("path", path);
      // 已删除的目录包括被选中的
      if (path.indexOf(key) > -1) {
        path = []
      }
      this.setState({
        delKey,
        delCatalogList,
        path
      }, () => {
        // console.log("catalogList", this.transformDataChildren(this.Child.state.data));

      })
    }

    this.onCurrentTeachingSaveAction((isSuccess) => {
      if (!isSuccess) {
        return
      }
      action(pro_key, pro_item)
    })

  }
  // 添加的
  onTreeAdd = (pro_key) => {

    let action = (key) => {
      // console.log(key);
      let addKey = this.state.addKey
      addKey.push(key)
      this.setState({
        addKey
      }, () => {
        // console.log(this.state.addKey);
      })
    }

    this.onCurrentTeachingSaveAction((isSuccess) => {
      if (!isSuccess) {
        return
      }
      action(pro_key)
    })

  }
  // 添加的子目录
  onTreeZiAdd = (pro_key) => {
    let action = (key) => {
      // console.log(key);
      let addZiKey = this.state.addZiKey
      addZiKey.push(key)
      this.setState({
        addZiKey
      }, () => {
        // console.log(this.state.addZiKey);
      })
    }

    this.onCurrentTeachingSaveAction((isSuccess) => {
      if (!isSuccess) {
        return
      }
      action(pro_key)
    })
  }
  // 添加子目录后返回数据
  onTreeBackReturnData = (data) => {
    // console.log(data, 'onTreeBackReturnDataonTreeBackReturnData');
    this.setState({catalogList: _.cloneDeep(data), flatCatalogList: this.flatCatalogList(data)}, () => {
      this.loops(this.state.catalogList);
    })
  }

  createRandom = () => {
    let str = 'qwertyuiopasdfghjklzxcvbnm0123456789';
    let strArr = str.split('');
    let createSingRandom = () => Math.floor(Math.random() * (str.length - 1));
    let randomStr = '';
    Array(32).fill(1).map(() => {
      randomStr += strArr[createSingRandom()];
    });
    return randomStr;
  }

  // 添加同级
  addEquative = () => {
    const {catalogList, path} = this.state;
    console.log("添加同级", catalogList, path);
    if (path.length <= 1) {
      // console.log(444);
      this.onTreeAdd((catalogList?.length + 1).toString())
      let name = (catalogList?.length + 1).toString() + ' ' + '目录'
      catalogList.push({
        name,
        catalogName: name,
        defaultValue: name,
        // id: (catalogList?.length + 1).toString(), // 这个 key 应该是唯一的。 Tip: The key should be unique
        id: this.createRandom(),
        displayOrder: (catalogList?.length + 1).toString(),
        // parentKey: key,
        isEditable: false,
        seniorId: 0
      });
    } else {
      this.addNode(path[path.length - 2], catalogList)
    }
    this.setState({catalogList: _.cloneDeep(catalogList), flatCatalogList: this.flatCatalogList(catalogList)}, () => {
      this.loops(this.state.catalogList);
    })
  }
  createName = (nameArr, length) => {
    let name = 'default';
    nameArr[nameArr.length - 1] = length + 1
    name = nameArr.join(".") + " " + "目录"
    return name
  }
  // 添加
  addNode = (key, data) => {
    const {parentData_} = this.state;
    // console.log(parentData_, key, data, "addNode");

    data.map((item) => {
      let name = 'default';
      let nameArr = []
      if (parentData_?.pos) {
        let pos = parentData_?.pos;
        nameArr = pos.split('-').splice(1);
        for (let i in nameArr) {
          nameArr[i] = Number(nameArr[i]) + 1
        }
        // console.log("nameArr",nameArr)
        // nameArr[nameArr.length - 1] = nameArr[nameArr.length - 1] + 1

      }
      if (item.id === key) {
        if (item.children) {
          // console.log(item, 4111111111111)
          item.children.push({
            name: this.createName(nameArr, item.children.length),
            catalogName: this.createName(nameArr, item.children.length),
            defaultValue: this.createName(nameArr, item.children.length),
            // id: key + item.children.length + 1, // 这个 key 应该是唯一的。 Tip: The key should be unique
            id: this.createRandom(),
            parentKey: key,
            isEditable: false,
            displayOrder: item.children.length + 1,
            seniorId: key
          });
          item.childList.push({
            name: this.createName(nameArr, item.children.length),
            catalogName: this.createName(nameArr, item.children.length),
            defaultValue: this.createName(nameArr, item.children.length),
            // id: key + item.children.length + 1, // 这个 key 应该是唯一的。 Tip: The key should be unique
            id: this.createRandom(),
            parentKey: key,
            isEditable: false,
            displayOrder: item.children.length + 1,
            seniorId: key
          });
        } else {
          // console.log(item, 42222222222)
          item.children = [];
          item.childList = [];
          item.children.push({
            name: name,
            catalogName: name,
            defaultValue: name,
            // id: key + 1, // 这个 key 应该是唯一的。 Tip: The key should be unique
            id: this.createRandom(),
            parentKey: key,
            isEditable: false,
            displayOrder: item.children.length + 1,
            seniorId: key
          });
          item.childList.push({
            name: name,
            catalogName: name,
            defaultValue: name,
            // id: key + 1, // 这个 key 应该是唯一的。 Tip: The key should be unique
            id: this.createRandom(),
            parentKey: key,
            isEditable: false,
            displayOrder: item.children.length + 1,
            seniorId: key
          });
        }
        return;
      }
      if (item.children) {
        this.addNode(key, item.children);
      }
    });
  }


  // 上移
  moveUp = () => {
    let action = () => {
      const {catalogList, path} = this.state;
      // console.log(catalogList, path);
      // if (path.length <= 1) {

      // } else {
      this.moveUpNode(path[path.length - 1], catalogList)
      this.setState({catalogList})
      // }
    }


    this.onCurrentTeachingSaveAction((isSuccess) => {
      if (!isSuccess) {
        return
      }
      action();
    })
  }
  // 上移
  moveUpNode = (key, data) =>
    data.map((item, index) => {
      if (item.id === key) {
        // console.log(data, index);
        if (index >= 1) {
          // console.log(item);
          item.displayOrder = item.displayOrder - 1
          data[index - 1].displayOrder = item.displayOrder + 1
          data.splice(index - 1, 0, item);
          data.splice(index + 1, 1)
        } else {
          message.warn('已是第一章节')
          // console.log(9999999999999999999999999999);
        }
        return;
      }
      if (item.children) {
        this.moveUpNode(key, item.children);
      }
    });
  // 下移
  moveDown = () => {
    let action = () => {
      const {catalogList, path} = this.state;
      // console.log(catalogList, path);
      this.moveDownNode(path[path.length - 1], catalogList)
      // console.log(catalogList);
      this.setState({catalogList})
    }

    this.onCurrentTeachingSaveAction((isSuccess) => {
      if (!isSuccess) {
        return
      }
      action()
    })
  }
  // 下移
  moveDownNode = (key, data) => {
    let algin = true
    data.map((item, index) => {
      let savedata = []
      if (item.id === key && algin) {
        savedata = item
        // console.log(data, index);
        if (index + 2 <= data.length) {
          algin = false
          item.displayOrder = item.displayOrder + 1
          data[index + 1].displayOrder = item.displayOrder - 1
          data.splice(index, 1)
          data.splice(index + 1, 0, savedata);
        } else {
          message.warn('已是最后章节')
          // console.log(9999999999999999999999999999);
        }
        return;
      }
      if (item.children) {
        this.moveDownNode(key, item.children);
      }
    });
  }

  // 任意移动
  moveArbitrarily = () => {
    const {path} = this.state;
    if (path.length >= 1) {
      this.handleClass()
    }

  }

  checkLength = (path, modalPath) => {
    let pathLen = path?.length;
    let modalPathLen = modalPath?.length;
    if (pathLen === 1) {
      if (modalPathLen >= 3) {
        return true;
      }
      return;
    }
    if (pathLen === 2) {
      if (modalPathLen <= 2) {
        return;
      }
      return true;
    }
    if (pathLen === 3) {
      if (modalPathLen <= 2) {
        return;
      }
      return true;
    }
  }

  // 保存移动
  saveMove = () => {
    const {catalogList, path, modalPath} = this.state;
    // console.log(catalogList, path, modalPath, '---------------');
    // console.log(path,this.valueMap[path[path.length-1]]);
    let canMoveLev1 = false;//一级移动限制
    let canMoveLev2 = false;//二级移动限制
    if (path.length === 1 && this.valueMap[path[path.length - 1]].children.length > 0) {

      if (modalPath.length > 1) {
        canMoveLev1 = true
      } else {
        this.valueMap[path[path.length - 1]].children.forEach((itemA) => {
          if (itemA.children.length > 0) {
            canMoveLev1 = true
          }
        })
      }
    }
    if (path.length === 2 && this.valueMap[path[path.length - 1]].children.length > 0 && modalPath.length >= 2) {
      canMoveLev2 = true
    }
    // console.log(this.checkLength(path, modalPath)||canMoveLev1)
    // return;
    if (this.checkLength(path, modalPath) || canMoveLev1 || canMoveLev2) {
      message.destroy();
      message.warning('目录最多只能三级');
      return;
    }
    if (JSON.stringify(path) === JSON.stringify(modalPath) || modalPath?.length === 0 || (path[path?.length - 2] === modalPath[modalPath?.length - 1])) {
      this.handleClass();
      return;
    }
    ;
    this.findCatalogList(path[path.length - 1], catalogList, modalPath[modalPath.length - 1]);
    this.setState({catalogList});
    this.handleClass();
  }

  // 根据id查找内容
  findCatalogList = (key, data, modalkey) => {
    // console.log(key, modalkey, data,'findCatalogList');
    data.map((item, index) => {
      let savedata = []
      if (item.id === key) {
        // console.log(item);
        let arr = []
        arr.push(item)
        // 修改seniorId
        let savedata1 = this.changeSeniorId(arr, modalkey)
        // console.log(savedata1);
        savedata = savedata1[0]
        // console.log(savedata);
        data.splice(index, 1)
        this.moveNode(modalkey, this.state.catalogList, savedata, index)
        return;
      }
      if (item.children) {
        this.findCatalogList(key, item.children, modalkey);
      }
    });
  }

  // 删除id
  delListId = (key, data, addKeyAllList) => {
    // console.log(key, data, "delListId");

    data.map((item, index) => {
      // console.log(item,"itemitemitemitemitemitemitemitemitemitem")
      if (item.id === key) {
        // console.log(item,"item.id === key");
        // 如果父节点不在新增中，不删除seniorId
        if (addKeyAllList.indexOf(item.seniorId) > -1) {
          delete item.seniorId
        }
        delete item.id;
        delete item.nodeId;
        return;
      }
      if (item.seniorId === key) {
        // console.log(item,"item.seniorId === key");
        if (addKeyAllList.indexOf(item.seniorId) > -1) {
          delete item.seniorId
        }
        delete item.id;
        delete item.nodeId;
        return;
      }
      if (item.childList) {
        this.delListId(key, item.childList, addKeyAllList);
      }
    });
  }

  // 保存后移动
  moveNode = (key, data, savedata) => {
    // console.log(666, key, data);
    data.map((item) => {
      if (item.id === key) {
        //修改seniorId,保存
        savedata.seniorId = item.id;
        if (item.children) {
          // console.log('savedata', item,savedata);

          item.children.push(
            savedata
          );
          item.childList.push(
            savedata
          );
        } else {
          item.children = [];
          item.childList = [];
          item.children.push(
            savedata
          );
          item.childList.push(
            savedata
          );
        }
        return;
      }
      if (item.children) {
        this.moveNode(key, item.children, savedata);
      }
    });
  }
  onLeftTreeRef = (ref) => {
    this.leftTreeRef = ref;
  }

  addSubdirectory = () => {
    const {path, stepList, resourceExtTeachPlan, resourceExt} = this.state;
    console.log(path, stepList, resourceExtTeachPlan, resourceExt, 888);
    if (path.length > 2) {
      message.warn("目录最多创建三级");
      return;
    }
    if (path.length === 0) {
      message.warn("请选择目录");
      return;
    }
    //如果该节点下 没有内容(教案或课件或学案任一)，直接添加，否则添加提示框。
    // console.log("addZi", path, stepList, resourceExtTeachPlan, resourceExt)
    if ((path.length > 1 && stepList.length > 0) || resourceExtTeachPlan || resourceExt) {
      this.setState({isAddChild: true});
      return;
    } else if (path.length >= 1) {
      this.leftTreeRef.onAdd(path[path.length - 1]);
    }
    if (this.state.navType != 1) {
      this.setState({
        navType: 1,
        canEditForSelectedCatalog: false
      });
    }
  }
  onChildAdd = () => {
    const {path} = this.state;
    if (path.length >= 1) {
      this.leftTreeRef.onAdd(path[path.length - 1]);
    }
  }
  // 删除
  delFile = (val, showMsg = false) => {
    const {path} = this.state;
    // console.log(val, path[path?.length - 1]);

    axios.get(delCatalogFile, {
      params: {
        catalog: path[path?.length - 1],
        detailType: val
      }
    }).then(res => {
      // console.log(res);
      if (!res) return;
      if (val == 1) {
        this.setState({
          resourceExtTeachPlan: '',
          isDel: false,
          delFileNum: 0
        }, () => {
          if (showMsg) {
            message.success('删除成功')
          }
        })
      } else {
        this.setState({
          resourceExt: '',
          isDel: false,
          delFileNum: 0
        }, () => {
          if (showMsg) {
            message.success('删除成功')
          }
        })
      }

    });
  }
  // 上传教案前判断
  onUploadTeachingPlanBeforeAction = file => {
    // console.log(this.state)
    // console.log(file);
    // // const isJpgOrPng = file.type === 'application/vnd.ms-powerpoint';
    // if (!isJpgOrPng) message.error('你只能上传ppt格式文件!');

    // // const isLt5M = file.size / 1024 / 1024 < 5;
    // // if (!isLt5M) message.error('图片大小不能超过5MB!');

    // return isJpgOrPng;
    return true
  }
// 上传教案回调
  onUploadTeachingPlanChangeAction = async info => {
    const {path} = this.state;
    // console.log(path);
    // console.log(info.file);
    if (info.file.status === "done") {
      const cdnUrl = _.get(info, "file.response.result.cdnUrl");
      const fileId = _.get(info, "file.response.result.fileId");
      if (cdnUrl) {
        message.success("上传成功");
        this.saveCatalogFile(path[path?.length - 1], fileId, 1)
        // console.log(fileId);
        this.setState({
          resourceExtTeachPlan: cdnUrl
        })

        return false;
      }
    }

    if (info.file.status === "error") {
      message.error("上传失败,请重新上传");
      return;
    }
  };

  // 上传课件的判断
  onUploadCourseWaveBeforeAction = file => {

    // console.log(file);
    // // const isJpgOrPng = file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.documen';
    // if (!isJpgOrPng) message.error('你只能上传word格式文件!');

    // // const isLt5M = file.size / 1024 / 1024 < 5;
    // // if (!isLt5M) message.error('图片大小不能超过5MB!');

    // return isJpgOrPng;
    return true
  }

  // 上传课件回调
  onUploadCourseWaveChangeAction = async info => {
    const {path} = this.state;
    // console.log(path);
    // console.log(info.file);
    if (info.file.status === "done") {
      const cdnUrl = _.get(info, "file.response.result.cdnUrl");
      const fileId = _.get(info, "file.response.result.fileId");
      if (cdnUrl) {
        message.success("上传成功");
        this.saveCatalogFile(path[path?.length - 1], fileId, 2)
        // console.log(fileId);
        this.setState({
          resourceExt: cdnUrl
        })

        return false;
      }
    }

    if (info.file.status === "error") {
      message.error("上传失败,请重新上传");
      return;
    }
  };

  flatCatalogList = (data) => {
    let temp = [].concat(data);

    function getNewData(arr = [], result = []) {
      arr.forEach(value => {
        if (value.childList && value.childList.length > 0) {
          value.isLast = false;
          getNewData(value.childList, result)
        } else {
          value.isLast = true;

        }
        result.push(value)

      })
      return result;
    }

    let d = getNewData(temp)
    return d;
  }

  //删除学案
  onDelTeachingAction = () => {
    let catalogId = this.state.path && Array.isArray(this.state.path) && this.state.path.length > 0 ? this.state.path[this.state.path.length - 1] : null;
    let courseId = this.courseId;
    let delId = editorRef?.current?.state?.delIds;
    let params = {
      "catalogId": catalogId,
      "courseId": courseId,
      "delStepList": delId,
      "stepList": []
    }
    axios.post(saveCatalogResource, params).then(res => {
      // console.log('学案删除成功');
      // if (res.code === 0) {
      //   message.success('添加成功')
      // } else {
      //   message.error('添加失败!' + res.message)
      // }
    })
  }
  //section 保存学案 开始
  onCurrentTeachingSaveAction = (boolCallBack) => {
    let editor = editorRef?.current
    // console.log('当前编译器', editor)
    if (!(this.state.canEditForSelectedCatalog && editor)) {
      if (boolCallBack && typeof boolCallBack === 'function') {
        boolCallBack(true)
      }
      return
    }

    if (this.isSaving) {
      return
    }

    const self = this

    let actionCallBack = (bool) => {
      setTimeout(() => {

        self.maskViewControl(false)

        if (boolCallBack && typeof boolCallBack === 'function') {
          boolCallBack(bool)
        }
      }, 400)
    }

    let catalogId = this.state.path && Array.isArray(this.state.path) && this.state.path.length > 0 ? this.state.path[this.state.path.length - 1] : null;
    let courseId = this.courseId;
    if (!courseId) {
      message.info('请选择课程')
      actionCallBack(false)
      return;
    }
    if (!catalogId) {
      message.info('请选择目录')
      actionCallBack(false)
      return;
    }
    let emptyContent = editor.checkEmpty()
    if (emptyContent) {
      message.info('请填写内容')
      actionCallBack(false)
      return;
    }
    message.loading('保存中...')

    self.maskViewControl(true)


    let html = editor.getHtml();
    let filesData = editor.state.filesData;

    let teachinglinkData = editor.state.teachinglinkData;

    let delId = editor.state.delIds;


    let params = {
      "catalogId": catalogId,
      "courseId": courseId,
      "delStepList": delId,
      "stepList": []
    }
    const $ = window.jQuery;
    //资源类型<>0=文本&1=课件&2=图片&3=文档&4=视频&5=音频&6=测试&7=链接&8=附件
    let olfEls = Array.from(html); //$("#getResult").children();
    let els = []; //$("#getResult").children();
    let obj = []
    let huanjieId = 0;
    let order = 0;

    // console.log("teachinglinkData==================", teachinglinkData)
    // console.log("olfEls===========",olfEls);

    olfEls.forEach((el) => {
      let childNodes = Array.from(el.childNodes);
      // console.log("childNodes",childNodes,el.tagName,el?.id);
      // console.log("childNodes tagName",childNodes[0]?.tagName);
      if (el.tagName === 'UL' || el.tagName === 'OL') {
        els.push(el)
      } else if (childNodes.length > 1) {
        if (el?.id.indexOf('teachinglink_' !== -1)) { //环节
          for (var i = 0; i < childNodes.length; i++) {
            // for(var i = childNodes.length-1;i>=0;i--) {
            if (childNodes[i].tagName === 'BR') {
              el.removeChild(childNodes[i]);
            } else {
              els.push(childNodes[i])
            }
          }
          // els.push(el)
        } else {
          els = els.concat(childNodes)
        }
      } else if ((el.tagName === 'P' && childNodes[0]?.tagName === 'IFRAME') || (el.tagName === 'P' && childNodes[0]?.tagName === 'A' && childNodes[0]?.id.indexOf('p_' !== -1))) {
        els.push(childNodes[0])
      } else {
        els.push(el)
      }
    })

    console.log("els", els);
    let huanjie = [], huanjieOrder = 1;

    for (let i = 0; i < els.length; i++) {
      const element = els[i];
      let eid = $(element).attr("id") || '';

      if (eid.indexOf('teachinglink_') >= 0) {

        let t = teachinglinkData.find(item => item.id == eid.replace('teachinglink_', ''));
        console.log("t", t);
        let {id, flag, displayOrder, name: stepName} = t
        let idobj = {id}
        if (flag == 0 || id == "default") {
          delete idobj.id
        }

        huanjie.push({displayOrder: huanjieOrder, stepName, catalogId, resourceList: [], ...idobj})
        huanjieOrder++;
      }
    }

    if (huanjie.length < teachinglinkData.length) {
      let {id, flag, displayOrder, name: stepName} = teachinglinkData[0]
      huanjie.unshift({
        displayOrder: 0, stepName, catalogId, resourceList: [], id
      })
    }


    params.stepList = huanjie;

    console.log("huanjie================", huanjie)
    for (let i = 0; i < els.length; i++) {

      let index = i, element = els[i];
      let $el = $(element), tagName = element.tagName;

      let isHuanjie = $el.attr('id') ? $el.attr('id').length > 0 ? /^teachinglink_/ig.test($el.attr('id')) : false : false

      if (isHuanjie) {
        ++huanjieId;
        order = 0;
      }
      // console.log("split", $el.attr("data-split"));
      if (!isHuanjie) {
        obj.push({
          index,
          $obj: $(element),
          tagName: tagName ? tagName : 'P',
          type: $el.attr("data-split") ? 'wenjian' : $el.attr('id') && /^teachinglink_/ig.test($el.attr('id')) ? 'teachinglink' : 'text',
          huanjieId,
          displayOrder: order
        })

        order++;
      }
    }


    function splitBy(arr, condition) {
      let temp = [...arr], result = {}, start = 0;
      while (temp.length > 0) {
        let index = temp.findIndex(condition);
        if (index == 0) {
          result[start] = temp.splice(0, 1);
          start++;
        } else if (index > 0) {
          result[start] = temp.splice(0, index);
          start++;
        } else {
          result[start] = temp.splice(0);
        }
      }

      return result;
    }

    let groupObj = _.groupBy(obj, "huanjieId");

    // console.log("groupObj================", groupObj)

    for (let [k, v] of Object.entries(groupObj)) {
      let a = splitBy(v, item => item.type == "wenjian")
      // params.stepList[k]
      // console.log(k, '=======', a)
      // debugger
      for (let [index, value] of Object.entries(a)) {
        // console.log(index, value,"963")
        let res = {catalogId, displayOrder: index}
        let type = value[0].type;
        if (type == 'text') {
          // console.log("item",item);
          // console.log("context",item.$obj.context.data);
          // console.log( item.$obj.prop('outerHTML'));
          res.resContent = value.map(item => {
            return (item.$obj.context.data ? item.$obj.context.data : item.$obj.prop('outerHTML'))
          }).join('');
          console.log("resContent", res.resContent);
          res.resType = 0;
        } else {
          let type = value[0].$obj.attr('type'),
            resId = value[0].$obj.attr('data-fileId'), fileInfo = filesData.find(item => item.fileId == resId);
          console.log(fileTypeMap, type, "fileTypeMap")
          console.log(fileInfo, "fileInfo")
          res.resId = resId;
          res.resType = fileTypeMap.get(type).value;
          res.resTitle = fileInfo.fileInfo.name;
          res.url = fileInfo.fileUrl;
          res.hasSitu = fileInfo.hasSitu ? 1 : 0;
          res.hasTask = fileInfo.hasTask ? 1 : 0;
          res.stepId = "";
        }
        params.stepList[k].resourceList.push(res);
      }
    }

    axios.post(saveCatalogResource, params).then(res => {
      if (res.code === 0) {
        message.destroy()

        message.success('保存成功')

        actionCallBack(true)

      } else {
        message.destroy()

        message.error('保存失败!' + res.message)
        actionCallBack(false)
      }
    })
  }
  //section 保存学案 结束

  richRef = (el) => {
    editorRef.current = el
  }

  // section mask
  maskViewControl = (isSaving) => {
    this.isSaving = isSaving
    this.maskViewRef.changeState(isSaving)
  }

  onMaskShowControlRef = (ref) => {
    // 将子组件的实例存到 this.childRef 中, 这样整个父组件就能拿到
    this.maskViewRef = ref
  }

  render() {
    const {
      delFileNum,
      isDel,
      catalogList,
      navType,
      isClassModal,
      resourceExt,
      resourceExtTeachPlan,
      isPreviewModal,
      path = [],
      canEditForSelectedCatalog,
      expandedKeys,
      expandedKeysRight,
      firstExpandedKeys,
      isAddChild,
      resResource
    } = this.state;
    let {isEdit, isDemonstrationCourse, id, courseId} = getParam();

    let currentId = null;
    if (path.length > 0) {
      currentId = path[path.length - 1]
    }
    const {user} = this.props
    const uploadTeachingPlanMap = {
      name: "file",
      accept: '.doc, .docx',
      action: uploadFile,
      headers: {token: this.props.token},
      beforeUpload: this.onUploadTeachingPlanBeforeAction,
      onChange: this.onUploadTeachingPlanChangeAction,
      data: {
        uid: this.props.user?.uid,
        bizType: 4
      },
      // fileList: fileList   //上传文件列表
      // disabled:true
    };
    const uploadCourseWaveMap = {
      name: "file",
      action: uploadFile,
      accept: '.ppt, .pptx',
      headers: {token: this.props.token},
      beforeUpload: this.onUploadCourseWaveBeforeAction,
      onChange: this.onUploadCourseWaveChangeAction,
      data: {
        uid: this.props.user?.uid,
        bizType: 4
      },
      // fileList: fileList   //上传文件列表
      // disabled:true
    };


    return (
      <div className='pages_catalog_editor'>
        <FullScreenMask onMaskShowControlRef={this.onMaskShowControlRef}/>


        <div className='pages_catalog_editor_left'>
          <div className='catalog_editor_left_top'>
            <div className='newcourse' onClick={this.addEquative}>
              <img src={imgUrlForZhongZhi('icon-add.png')} alt=""/>
              同级目录
            </div>
            <div className='catalog_editor_move' onClick={this.moveUp}>
              <img src={imgUrlForZhongZhi('moveup.png')} alt=""/>
              <p>上移</p>
            </div>
            <div className='catalog_editor_move' onClick={this.moveDown}>
              <img src={imgUrlForZhongZhi('movedown.png')} alt=""/>
              <p>下移</p>
            </div>
            <div className='catalog_editor_move' onClick={this.moveArbitrarily}>
              <img src={imgUrlForZhongZhi('move.png')} alt=""/>
              <p>移动</p>
            </div>
          </div>
          <div className='catalog_editor_left_center'>
            <div className='catalog_editor_left_center_add' onClick={this.addSubdirectory}>
              <img src={imgUrlForZhongZhi('icon-add-white.png')} alt=""/>
              添加子目录
            </div>
            {catalogList?.length > 0 &&
              // section 目录
              <SelectEditTreeCmp onRef={this.onLeftTreeRef}
                                 isEdit={isEdit}
                                 isDemonstrationCourse={isDemonstrationCourse}
                                 schoolId={user.schoolId}
                                 courseId={courseId}
                                 selectTreeData={catalogList}
                                 expandedKeys={expandedKeys}
                                 firstExpandedKeys={firstExpandedKeys}
                                 navType={navType}
                                 onTreeActive={this.onTreeChapter}
                                 onTreeSelect={this.onTreeSelect}
                                 onTreeDel={this.onTreeDel}
                                 onTreeAdd={this.onTreeAdd}
                                 onTreeZiAdd={this.onTreeZiAdd}
                                 onTreeBackReturnData={this.onTreeBackReturnData}/>}
            {/* <DragTable></DragTable> */}
          </div>
          <div className='catalog_editor_left_footer'>
            <div className='catalog_editor_footer_button' onClick={this.saveCourseCatalog}>保存课程编辑</div>
          </div>
        </div>
        <div className='pages_catalog_editor_right'>
          <div className='pages_catalog_editor_right_top'>
            <div className='pages_catalog_editor_right_top_left'>
              <div
                className={navType == 3 ? 'catalog_editor_right_top_default catalog_editor_right_top_select' : 'catalog_editor_right_top_default'}
                onClick={() => this.changeNavType(3)}>进入学习
              </div>
              <div
                className={navType == 2 ? 'catalog_editor_right_top_default catalog_editor_right_top_select' : 'catalog_editor_right_top_default'}
                onClick={() => this.changeNavType(2)}>教学资源
              </div>
              <div
                className={navType == 1 ? 'catalog_editor_right_top_default catalog_editor_right_top_select' : 'catalog_editor_right_top_default'}
                onClick={() => this.changeNavType(1)}>教案
              </div>
            </div>
            <div className='pages_catalog_editor_right_top_right'>
              {
                !canEditForSelectedCatalog ? '' :
                  navType == 1 ?
                    <div className='teachplan_right'>
                      {
                        resourceExtTeachPlan &&
                        <div className='teachplan_del' onClick={() =>

                          this.setState({
                            isDel: true,
                            delFileNum: 1
                          })
                        }>
                          删除
                        </div>
                      }

                      <Upload {...uploadTeachingPlanMap}>
                        <div className='teachplan_button'>
                          上传教案
                        </div>
                      </Upload>
                    </div>
                    :
                    navType == 2 ?
                      <div className='teachplan_right'>
                        {
                          resourceExt && <div className='teachplan_del' onClick={() =>

                            this.setState({
                              isDel: true,
                              delFileNum: 2
                            })
                          }>
                            删除
                          </div>
                        }
                        <Upload {...uploadCourseWaveMap}>
                          <div className='teachplan_button'>
                            上传课件
                          </div>
                        </Upload>
                      </div>
                      :
                      <div className='teachplan_right'>
                        <div className='teachplan_del' onClick={this.onCurrentTeachingSaveAction}>
                          保存
                        </div>
                        <Tooltip title="预览仅展示保存后的内容">
                          <div className='teachplan_button' onClick={this.handlePreviewClass.bind(this, true)}>
                            预览
                          </div>
                        </Tooltip>
                      </div>
              }

            </div>
          </div>
          {
            !canEditForSelectedCatalog ? <div className='pages_catalog_editor_right_bottom empty'>
                <div className='content'>
                  <Row gutter={[0, 20]}>
                    <Col span={24}><img src="/resources/images/emptyNormal.png" style={{width: 88, height: 88}}/></Col>
                    <Col span={24}>请选择目录</Col>
                  </Row>
                </div>
              </div> :


              <div className='pages_catalog_editor_right_bottom'>
                {
                  navType === 1 &&
                  <div className='teach_plan_page'>
                    <div className='teach_plan_page_con'>
                      {
                        resourceExtTeachPlan ?
                          <iframe
                            width='100%'
                            height='100%'
                            frameBorder='0'
                            src={
                              resourceExtTeachPlan.includes("https")
                                ? xDocUrlHttps + resourceExtTeachPlan
                                : resourceExtTeachPlan.includes("http")
                                  ? xDocUrl + resourceExtTeachPlan
                                  : xDocUrl + preview + resourceExtTeachPlan
                            }
                          />
                          :
                          <div className='teach_plan_page_conno'>
                            <img src={imgUrlForZhongZhi('upLoad.png')} alt=""/>
                            <div>
                              <Upload {...uploadTeachingPlanMap}>
                                <div className='button'>
                                  上传教案
                                </div>
                              </Upload>
                            </div>
                            <p>还没有上传教案，请上传～</p>
                          </div>
                      }

                    </div>
                  </div>
                  // <TeachPlan />
                }
                {
                  navType === 2 &&
                  <div className='teach_plan_page'>
                    <div className='teach_plan_page_con'>
                      {
                        resourceExt ?
                          <iframe
                            width='100%'
                            height='100%'
                            frameBorder='0'
                            src={
                              resourceExt.includes("https")
                                ? xDocUrlHttps + resourceExt
                                : resourceExt.includes("http")
                                  ? xDocUrl + resourceExt
                                  : xDocUrl + preview + resourceExt
                            }
                          />
                          :
                          <div className='teach_plan_page_conno'>
                            <img src={imgUrlForZhongZhi('upLoad.png')} alt=""/>
                            <div>
                              <Upload {...uploadCourseWaveMap}>
                                <div className='button'>
                                  上传课件
                                </div>
                              </Upload>
                            </div>
                            <p>还没有上传课件，请上传～</p>
                          </div>
                      }

                    </div>
                  </div>
                }
                {
                  navType === 3 && <RichMedia
                    catalogId={currentId}
                    courseId={this.courseId}
                    onRef={this.richRef}
                    upLoad={this.upLoad}
                    uploadResult={this.uploadResult}
                    isPreviewModal={isPreviewModal}
                    resResource={resResource}
                  />
                }
              </div>
          }
        </div>
        <Modal
          className="tree_modeld"
          visible={isClassModal}
          onCancel={this.handleClass}
          footer={null}
          destroyOnClose
        >
          <div className="tree_modeld-con">

            <i className='sub_line'></i>
            <div className='class_modeld_title'>
              <span>移动到</span>
            </div>
            <div className='move_tips'>
              <img src={imgUrlForZhongZhi('icon_move_tip.png')} alt=""/>
              选择要移动到的目录，被移动目录（包含子目录）会成为所选目录的子目录
            </div>
            <div className='class_modeld_center'>
              {catalogList?.length > 0 && <SelectEditTreeCmpRight
                isEdit={isEdit}
                isModal={true}
                isDemonstrationCourse={isDemonstrationCourse}
                schoolId={user.schoolId}
                courseId={courseId}
                selectTreeData={catalogList}
                expandedKeys={expandedKeysRight}
                firstExpandedKeys={[]}
                // onTreeActive={this.onTreeChapter}
                onTreeSelect={this.onTreeSelect}
              />}
            </div>
            <div className='class_modeld_button'>
              <button className='class_modeld_buttonno' onClick={() => this.handleClass()}>取消</button>
              <button onClick={this.saveMove} className='class_modeld_buttonyes'>确定</button>
            </div>
          </div>
        </Modal>
        <Modal
          className="preview_modeld"
          visible={isPreviewModal}
          onCancel={this.handlePreviewClass.bind(this, false)}
          footer={null}
          destroyOnClose
        >
          <div className="tree_modeld-con">
            <RichTextPreview stepList={this.state.stepList}/>
          </div>
        </Modal>
        {/* <Modal title="提示" visible={isDel} cancelText="取消" okText="确定" onOk={() => {
          this.delFile(delFileNum)
        }} onCancel={(e) => {
          console.log('e', e);
          this.setState({
            isDel: false,
            delFileNum: 0
          })
        }}>
          <p>请确认是否删除该资源</p>
        </Modal> */}
        {
          isDel && <Modal
            className="class_modeld"
            style={{width: '234px'}}
            visible={isDel}
            onClick={() => {
              this.setState({
                isDel: false,
                delFileNum: 0
              })
            }}
            footer={null}
            destroyOnClose
          >
            <div className="class_modeld-con">
              <div className='class_modeld_center' style={{color: '#686F7E'}}>
                请确认是否删除该资源
              </div>
              <div className='class_modeld_button'>
                <button className='class_modeld_buttonno'
                        onClick={() => {
                          this.setState({
                            isDel: false,
                            delFileNum: 0
                          })
                        }}
                >取消
                </button>
                <button onClick={() => {
                  this.delFile(delFileNum, true)
                }} className='class_modeld_buttonyes'>确定
                </button>
              </div>

            </div>
          </Modal>
        }


        <Modal
          className="class_modeld"
          visible={isAddChild}
          onCancel={() => {
            this.setState({isAddChild: false})
          }}
          footer={null}
          destroyOnClose
        >
          <div className="class_modeld-con">
            <div className='class_modeld_title'>
              <span>添加子节点</span>
            </div>
            <div className='class_modeld_center'>
              是否增加子级，增加子级后选中目录下的课程内容将自动删除。
            </div>
            <div className='class_modeld_button'>
              <button className='class_modeld_buttonno' onClick={() => {
                this.setState({isAddChild: false})
              }}>取消
              </button>
              <button onClick={() => {
                this.setState({
                  isAddChild: false
                }, () => {
                  this.delFile(1);
                  this.delFile(2);
                  this.onDelTeachingAction();
                  this.changeNavType(3);
                  this.onChildAdd();
                  this.setState({
                    canEditForSelectedCatalog: false
                  })
                })
              }} className='class_modeld_buttonyes'>确定
              </button>
            </div>
          </div>
        </Modal>
        <div style={{display: 'none'}} id="getResult"></div>
      </div>
    )
  }
}

const mapAction = (dispatch) => ({
  savecCurseVOData(res = {}) {
    dispatch(savecCurseVOData(res));
  },
});
export default connect(({curseVOData, token, user}) => ({curseVOData, token, user}), mapAction)(catalogEditor);
