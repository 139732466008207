import React, { Component } from 'react';
import { Carousel, message, Tabs, Button, Form, Input, Select, DatePicker, Upload } from 'antd';
import { connect } from 'react-redux';
import { savecCurseVOData } from '../../store/action';
// import { updateUser } from '@/actions/login';
import Paginations from '@@/Paginations';
import Crumbs from '@@/Crumbs';
import _ from 'lodash';
import Ueditor from '../ueditor/ueditor'
import { imgUrl, showImg, defaultImg, imgUrlForZhongZhi } from '@/utils';
import {
  subjectList, query2CollegeMajors, courseCover, getCourseInfo, saveCourse, uploadFile
} from '@/api';
import axios from '../../axios';
import { move, getParam } from '@/utils/util';
import './styles.less';
import moment from 'moment';
import RichTextEditor from '../../components/RichTextEditor';
import ImgCrop from "antd-img-crop";
const FormItem = Form.Item;
const { Option } = Select;

// const preview = 'http://test-manage.zjyjc.com';

const nameRules = { required: true, message: "请输入课程名称" };


class courseEditor extends Component {
  formRef = React.createRef();
  state = {
    subjectListData: [],
    provinceData: [],
    page: 0,
    defaultcover: 999999999,//默认图
    covers: [],//默认封面
    coverFileId: '',
    courseType: '',
    courseVO: '',
    fileList: [],
    newInformation: "",
    showRichText:false
  }

  componentDidMount() {
    console.log(this.props.curseVOData, 'curseVOData');
    const { id, coverFileId, courseName, courseType, teacherId, teaName, subjectId, collegeId, majorId, bookName, bookAuthor, press, publishDate, information,fileId } = this.props.curseVOData
    this.setState({
      courseVO: this.props.curseVOData,
      courseType,
      fileUrl: coverFileId,
      id,
      teacherId,
      information,
      fileId
      // fileList:[{

      // }]
    }, () => {
      console.log(this.formRef.current);
      this.formRef.current.setFieldsValue({
        courseName,
        courseType,
        teaName,
        subjectId,
        collegeId: collegeId == 0 ? undefined : collegeId,
        majorId,
        bookName,
        bookAuthor,
        press,
        publishDate: publishDate ? moment(publishDate) : ''
      })
      setTimeout(()=>{
        this.setState({showRichText:true})
      },500)
      // this.formRef.current.setFieldsValue(this.props.curseVOData)
    })
    // this.getCourseInfo()
    this.subjectList()
    this.query2CollegeMajors(this.props.user.schoolId)
    this.courseCover()
  }
  componentDidUpdate(prevProps) {
    console.log(prevProps);
    if (this.props.curseVOData !== prevProps.curseVOData) {
      console.log(this.formRef.current);
      // if (this.formRef.current) {
      //     this.formRef.current.setFieldsValue(this.props.resource)
      // }
    }
  }
  // 选择课程类型
  selectType = value => {
    console.log(value);
    this.setState({
      courseType: value
    })
  }
  onReset = () => {
    this.formRef.current.resetFields();
  };
  onFinish = (val) => {
    console.log("onfinish", val);
    this.saveCourse(val)

  };
  onFinishFailed = (val) => {
    console.log("onfinishfailed", val);
  };
  // 取消
  outPage = () => {
    this.props.history.go(-1);
  }
  // 教师保存课程信息-
  saveCourse = async (val) => {
    console.log(val);
    const { coverFileId, id, teacherId, newInformation } = this.state
    let {fileId:prevCoverFileId} = this.props.curseVOData
    const param = {
      // classId,
      // classType: 2,
      // flag,
      // userIdList,
      teacherId,
      hasDel: 0,
      // hasExample: 0,
      // collegeId: 0,
      displayOrder: 0,
      schoolId: this.props.user.schoolId,
      coverFileId:coverFileId||prevCoverFileId,
      id,
      information: newInformation,
    }
    if (typeof (val?.publishDate?.format) == 'function') {
      param.publishDate = val?.publishDate.format('YYYY-MM-DD')
    }
    if (newInformation.length == 0) {
      message.warn('请输入课程介绍');
      return
    }
    // console.log(param, val, { ...val, ...param }, "saveCourse---------------------------------------");
    // console.log(this.props.curseVOData, "this.props.curseVOData");
    // return
    const res = await axios.post(saveCourse, { ...val, ...param });
    if (res.result == true) {
      this.props.history.go(-1);
    }
    // console.log(res);
  }
  // 学科列表
  subjectList = async () => {
    const param = {

    }
    const res = await axios.get(subjectList, { params: param });
    console.log(res);
    this.setState({
      subjectListData: res
    })
  }
  // 学校专业级联

  query2CollegeMajors = async (schoolId) => {
    const param = {
      schoolId
    }
    console.log(param);
    const res = await axios.post(query2CollegeMajors, param);
    console.log(res);
    this.setState({
      provinceData: res,
      cities: res[0].majors,
      collegeId: res[0].collegeId,
    }, () => {
      this.handleFirstChange(this.props.curseVOData.collegeId)
      console.log(this.state.collegeId, this.state.majorId, this.state.schoolId);
    })
  }
  getCourseInfo = () => {
    let { id } = getParam();
    axios.get(getCourseInfo, {
      params: {
        courseId: id,
      },
    }).then(res => {
      if (!res) return;
      let { courseVO, catalogList, teamTeachers } = res;
      console.log(courseVO, 'courseVO');
      this.setState({
        courseVO,
      });
    });
  }
  // 查询课程默认封面
  courseCover = async () => {
    const param = {

    }
    const res = await axios.get(courseCover, { params: param });
    console.log(res);
    this.setState({
      covers: res.covers
    }, () => {

      move(this.spread, 'answerItem');
    })
  }
  // 选择默认封面
  defaultCover = (index, fileId, fileUrl) => {
    console.log(fileId);
    this.setState({
      defaultcover: index,
      coverFileId: fileId,
      fileUrl,
      fileId
    })
  }
  // 左右切换
  spread = (param) => {
    const { conList, page, covers } = this.state

    // 传出值
    // let json = JSON.parse(JSON.stringify(testItem));
    console.log(param, covers)

    if (param == "向左") {
      console.log(page);
      if (page == covers.length - 4) {
        return
      }
      this.setState({
        page: page + 1
      }, () => {
        // let selectcon = document.getElementsByClassName('EnglishTingxie-yuan')[this.state.page - 1]
        // let noselectcon = document.getElementById('page' + this.state.page)
        // document.getElementById('page' + this.state.page) && document.getElementById('page' + this.state.page).scrollIntoView({ behavior: 'smooth', inline: "center" })
        document.getElementById('answerItem').scrollTo(200 * this.state.page, 0)
      })

    } else if (param == "向右") {
      if (page == 0) {
        return
      }
      // console.log(page);
      this.setState({
        page: page - 1
      }, () => {
        // let selectcon = conList?.length == 1 ? document.getElementsByClassName('EnglishTingxie-yuan')[this.state.page] : document.getElementsByClassName('EnglishTingxie-yuan')[this.state.page + 1]
        // let noselectcon = document.getElementById('page' + this.state.page)
        // document.getElementById('page' + this.state.page) && document.getElementById('page' + this.state.page).scrollIntoView({ behavior: 'smooth', inline: "center" })
        document.getElementById('answerItem').scrollTo(200 * this.state.page, 0)

      })
    }
  }
  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file?.type == 'image/gif';
    if (!isJpgOrPng) message.error('你只能上传JPG/PNG/GIF格式文件!');

    const isLt5M = file.size / 1024 / 1024 < 4;
    if (!isLt5M) message.error('图片大小不能超过4MB!');

    return isJpgOrPng && isLt5M;
  }

  handleChange = info => {
    console.log(info);
    if (info.file.status === "done") {
      const cdnUrl = _.get(info, "file.response.result.cdnUrl");
      const fileId = _.get(info, "file.response.result.fileId");
      if (cdnUrl) {
        console.log('222');
        message.success("上传成功");
        this.setState({
          defaultcover: 999999999,//默认图
          coverFileId: fileId,
          fileUrl: cdnUrl,
          fileId
        })
        // this.updateAvatar(fileId);
        // this.props.updateUser({ ...this.props.user, avatarUrl: cdnUrl });
        return false;
      }
    }

    if (info.file.status === "error") {
      message.error("上传失败,请重新上传");
      return;
    }
  };
  handleFirstChange = (value) => {
    const { provinceData, cityData, diData } = this.state;
    console.log(provinceData, value);
    let citiesindex
    for (let index = 0; index < provinceData.length; index++) {
      const element = provinceData[index].collegeId;
      if (value == element) {
        citiesindex = index
      }
    }
    console.log(citiesindex);
    // provinceData:res,
    // cities:res[0].majors,
    // secondCity:res[0].majors[0].majorName,
    if (citiesindex) {
      if (!provinceData[citiesindex].majors) {
        return
      }
      this.setState({
        cities: provinceData[citiesindex].majors,
      }, () => {
      })
    }

  };
  handleProvinceChange = (value, option) => {
    const { provinceData, cityData, diData } = this.state;
    console.log(provinceData, value, option);
    let citiesindex
    for (let index = 0; index < provinceData.length; index++) {
      const element = provinceData[index].collegeId;
      if (value == element) {
        citiesindex = index
      }
    }
    // provinceData:res,
    // cities:res[0].majors,
    // secondCity:res[0].majors[0].majorName,
    if (!provinceData[citiesindex].majors) {
      return
    }
    this.setState({
      cities: provinceData[citiesindex].majors,
    }, () => {
      this.formRef.current.setFieldsValue({
        majorId: this.state.cities[0].majorId,
      })
    })
  };
  render() {
    const { fileUrl, courseType, subjectListData, provinceData, cities, fileList, content, coverFileId, covers, page, defaultcover, courseVO, information,showRichText,fileId } = this.state;
    let { isDemonstrationCourse } = getParam();
    const uploadProps = {
      name: "file",
      action: uploadFile,
      headers: { token: this.props.token },
      beforeUpload: this.beforeUpload,
      onChange: this.handleChange,
      data: {
        uid: 3,
        bizType: 4
      },
      // fileList: fileList   //上传文件列表
      // disabled:true
    };

    return (
      <div className='pages_courseeditor'>
        <div className='courseeditor_course'>
          <div className='course_go_back'>
            <Crumbs />
          </div>
          <Form
            ref={this.formRef}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            onReset={this.onReset}
            layout='vertical'
            size='middle'
            initialValues={{
              // teacherId: '1',
              // collegeId: provinceData[0]?.collegeName,
              // courseName:courseVO?.courseName
            }}
          >
            <div className='course_main'>
              <h1>课程信息</h1>
              <div className='add_form'>
                <div className='add_form_left'>
                  <FormItem label="课程名称" name="courseName" rules={[nameRules]}>
                    <Input placeholder="请输入课程名称" maxLength={15} />
                  </FormItem>
                  <FormItem label="课程类型" name="courseType">
                    <Select placeholder="请选择" onChange={this.selectType} disabled={isDemonstrationCourse}>
                      <Option value="1">公共课</Option>
                      <Option value="2">专业课</Option>
                    </Select>
                  </FormItem>
                  <FormItem label="课程老师" name="teaName">
                    <Input defaultValue="张三" disabled />
                  </FormItem>
                  {courseType == 1 ?
                    <FormItem label="所属学科" name="subjectId" rules={[{ required: true, message: "请选择所属学科" }]}>
                      <Select placeholder="请选择" disabled={isDemonstrationCourse}>
                        {subjectListData && subjectListData.map((province, index) => (
                          <Option value={province?.value}>{province?.title}</Option>
                        ))}
                      </Select>
                    </FormItem>
                    :
                    courseType == 2 ?
                      <div>
                        <FormItem label="所属院系" name="collegeId" rules={[{ required: true, message: "请选择所属院系" }]}>
                          <Select placeholder="请选择" onChange={this.handleProvinceChange} disabled={isDemonstrationCourse}>
                            {provinceData && provinceData.map((province, index) => (
                              <Option value={province?.collegeId}>{province?.collegeName}</Option>
                            ))}
                          </Select>
                        </FormItem>
                        <FormItem label="所属专业" name="majorId" rules={[{ required: true, message: "请选择所属专业" }]}>
                          <Select placeholder="请选择" disabled={isDemonstrationCourse}>
                            {cities && cities.map((province, index) => (
                              <Option value={province?.majorId}>{province?.majorName}</Option>
                            ))}
                          </Select>
                        </FormItem>
                      </div>


                      : ''
                  }

                </div>
                <div className='add_form_right'>
                  <FormItem label="教材名称" name="bookName">
                    <Input placeholder="请输入教材名称" />
                  </FormItem>
                  <FormItem label="教材作者" name="bookAuthor">
                    <Input placeholder="请输入教材作者" />
                  </FormItem>
                  <FormItem label="出版社" name="press">
                    <Input placeholder="请输入出版社" />
                  </FormItem>
                  <FormItem label="出版日期" name="publishDate">
                    <DatePicker placeholder="请选择日期" style={{width:'100%'}}/>
                  </FormItem>
                </div>
              </div>
              <h1>课程封面</h1>
              <div className='center1_top'>
                <div className='center1_topleft'>
                  <img src={fileUrl || imgUrlForZhongZhi('ad_cover.png')} alt="" />
                </div>
                <div className='center1_topright'>
                  <ImgCrop aspect={526/296} modalTitle={'编辑封面'} modalCancel={'取消'} rotate>

                  <Upload {...uploadProps}>
                    <div className='topright_upload'>
                      <img src={imgUrlForZhongZhi('icon-add.png')} alt="" />
                      上传封面
                    </div>
                  </Upload>
                  </ImgCrop>
                  <p><div></div> 作为课程的宣传图，用于课程主页的显示</p>
                  <p><div></div> 支持JPG.JPEG.PNG.GIF格式</p>
                  <p><div></div>文件大小4MB，1000*600以上像素</p>
                  <p><div></div>您可以自己上传图片，也可以从下面这些图片选择使用</p>
                </div>
              </div>
              <div className='cover_wrap'>
                <div className='cover_left' onClick={() => this.spread('向右')}>
                  <img src={page == 0 ? imgUrlForZhongZhi('goleftno.png') : imgUrlForZhongZhi('goleft.png')} alt="" />
                </div>
                <div className='center1_buttom' id="answerItem">
                  <div className='EnglishTingxie-con'>
                    {
                      covers && covers.map((coverval, index) => {
                        return (
                          <div id={'page' + index} onClick={() => this.defaultCover(index, coverval?.fileId, coverval?.fileUrl)} className={fileId == coverval?.fileId ? 'con-img select' : 'con-img'}>
                          {/* <div id={'page' + index} onClick={() => this.defaultCover(index, coverval?.fileId, coverval?.fileUrl)} className={defaultcover == index ? 'con-img select' : 'con-img'}> */}
                            <img className='img_1' src={fileId == coverval?.fileId ? imgUrlForZhongZhi('yselect.png') : imgUrlForZhongZhi('noselect.png')} alt="" />
                            <img className='img_2' src={coverval?.fileUrl} alt="" />
                            <p>封面1</p>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className='cover_right' onClick={() => this.spread('向左')}>
                  <img src={page == covers.length - 4 ? imgUrlForZhongZhi('gorightno.png') : imgUrlForZhongZhi('goright.png')} alt="" />
                </div>
              </div>
              <h1 className='course_intro'> <i>*</i>课程介绍</h1>
              <div className="course_content">
                {/* <Ueditor content={content} onChange={this.handleChange} editorHandle={this.editorHandle} ue={this.ue} /> */}

                  {
                    showRichText ?
                    <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={information}
                      editorType={2}
                      onChange={(html) => {
                        let str = `<audio controls="controls" style="display: none;"></audio>`
                        if (html.indexOf(str) > -1) {
                          html = html.replace(str, "");
                        }
                        // html = `<p>${html}</p>`
                        console.log(html);
                        this.setState({
                          newInformation: html
                        })
                        this.currentTestItem = { ...this.currentTestItem, stem: html };
                      }} /> :
                      <></>
                  }

              </div>
              <div className='class_modeld_button'>
                <button type="button" className='class_modeld_buttonno' onClick={this.outPage}> 取消</button>
                <button htmlType="submit" className='class_modeld_buttonyes' >确定</button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}
const mapAction = (dispatch) => ({
  savecCurseVOData(res = {}) {
    dispatch(savecCurseVOData(res));
  },
});
export default connect(({ curseVOData, user }) => ({ curseVOData, user }), mapAction)(courseEditor);
