import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateCrumbsData } from '../../store/action';
import styles from './index.module.scss';
import {  getIp } from '../../utils/index';
class Crumbs extends Component {
  state = {

  }

  componentDidMount() {

  }

  renderCrumbs = (data) => {
    return data?.map((item, index) => {
      if(!item?.name) return '';
      if (index === data?.length - 1) {
        return <span style={{cursor:'pointer'}} onClick={()=>{
          if ( item?.path ) {
            let url = `${getIp()}/#${item?.path}`;
            if ( item?.path == -1 ) {
              window.history.go(-1)
            }
            window.open(url, "_self");
          }
        }}>{item?.name}</span>;
      }
      return <span  style={{cursor:'pointer'}} onClick={()=>{
        if ( item?.path ) {
          let url = `${getIp()}/#${item?.path}`;
          if ( item?.path == -1 ) {
            window.history.go(-1)
          }
          window.open(url, "_self");
        }
      }}>{item?.name + ' / '}</span>;
    });
  }

  render() {
    return (
      <div className={styles.wrapper}>
        {this.renderCrumbs(this.props.crumbsData)}
      </div>
    );
  }
}
const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
});
export default connect(({ crumbsData }) => ({ crumbsData }), mapAction)(Crumbs);
