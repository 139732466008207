import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle, Fragment} from 'react'
import {connect} from 'react-redux';
import {
  Button,
  Form,
  Input,
} from 'antd'

import {updateUser} from '@/store/action';
import './index.scss';

const FormItem = Form.Item

let addIndexMap = {}

const EditForm = forwardRef(({uploadType, ...props}, ref) => {

  const [form] = Form.useForm()

  const onFinish = (values) => {
    // debugger
    uploadType == 'link' && props.transTest && props.transLink({...values})
    uploadType == 'tests' && props.transLink && props.transTest({...values})
    props.closeModal && props.closeModal()
  }

  const getTestInitialValue = () => {
    const {catalogId} = props
    console.error('catalogId', catalogId)
    if (undefined === catalogId) {
      return '测试'
    }

    let currentIndex = addIndexMap[catalogId] || 1
    addIndexMap[catalogId] = currentIndex + 1
    return '测试 ' + currentIndex
  }

  // useEffect(() => {
  //   form.setFieldsValue(detail)
  // }, [])
  return <Form form={form} className='inlin-label-form' onFinish={onFinish}
               layout="vertical"
    // initialValues={{
    //   layout: 'vertical',
    // }}
  >
    {
      uploadType == "link" && <Fragment>
        <FormItem label={<div className='labelName'>设置链接名称</div>} name="linkName" labelCol={{span: 6}}
                  rules={[{required: true, message: '请输入链接名称'}]}>
          <Input placeholder="请输入链接名称"/>
        </FormItem>
        <FormItem label={<div className='labelName'>链接</div>} name="linkAddress" labelCol={{span: 6}}
                  rules={[{required: true, message: '请输入链接'}]}>
          <Input placeholder="请输入链接"/>
        </FormItem>
        <p className='tips'>您输入的超链接中不包含http等协议名称，默认将为您添加http://前缀</p>
        <FormItem className='submitItem'>
          <Button type="primary" htmlType="submit" className='btnBlue'>确定</Button>
        </FormItem>
      </Fragment>
    }
    {
      uploadType == "tests" && <Fragment>
        <FormItem label={<div className='labelName'>测试标题</div>}
                  name="testName"
                  labelCol={{span: 6}}
                  initialValue={getTestInitialValue()}
                  rules={[
                    {
                      required: true,
                      message: '请输入测试标题'
                    }
                  ]}>
          <Input placeholder="请输入测试标题"/>
        </FormItem>
        <p className='tips'>输入测试标题并点“确定”后，请及时点“编辑”按钮去编辑测试内容</p>
        <FormItem className='submitItem'>
          <Button type="primary" htmlType="submit" className='btnBlue'>确定</Button>
        </FormItem>
      </Fragment>
    }


  </Form>
})
const mapAction = (dispatch) => ({
  updateUser(user) {
    dispatch(updateUser(user));
  },
});
export default connect(({user}) => ({user}), mapAction)(EditForm);
