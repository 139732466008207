import React from "react";
import { connect } from 'react-redux';
import { Radio, Popconfirm, Checkbox, Space, message } from 'antd';
import ReactDOM from 'react-dom';
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import styles from './index.module.scss';
import axios from '../../../../axios';
import { getExamTestList, addTest, saveExamStudent } from '../../../../api';
import { imgUrlForZhongZhi } from '../../../../utils/index';
import { divideMathText, getInnerText, getParam } from '../../../../utils/util';
import { renderHTML } from "../../../../utils/react-mathjax";
import PreviewOfTestQuestions from '../../../PreviewOfTestQuestions/PreviewOfTestQuestions';
import RichTextEditor from '../../../../components/RichTextEditor';
import TopicSelectionOfQuestion from '../TopicSelectionOfQuestion/TopicSelectionOfQuestion';

let optionAll = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

class EditOfTestQuestions extends React.Component {
  contentRef = React.createRef();
  constructor() {
    super();
    this.state = {
      currentTestItem: {},
      currentTestItemBackups: {},
      activeFlagList: [],
      tempAry: [],
      currentTestType: 1,
      examName: '',
      isShowPreviewOfTestQuestions: false,
      isShowAnalysis: true,
      isEdit: false,
      isShowTopicSelectionOfQuestion: false,
    };
  }

  componentDidMount() {
    let { examId, examName, isTaskPoint } = getParam();
    this.setState({
      isTaskPoint
    });
    this.getExamTestList(examId, examName);
    setTimeout(() => {
      this.aa()
    }, 1000);
  }
  componentDidUpdate(){
    let that = this;
    console.log('event ===========')
    this.aa();
    window.onmessage = function (event) {
      console.log('event ===========', event)
      if(event.data.editType === '0'){
        that.aa(event.data.url)
      }
    }
  }
  aa =  (url ='')=>{
    console.log('url',url);
    const domUtils = window.UE.dom.domUtils;
    var iframes=document.getElementsByTagName("iframe"); 
    if ( iframes[0] ){
      var iframes_new = iframes[0].contentWindow.document.getElementsByTagName("iframe")
      console.log('aa--------domUtils',iframes[0]);
      for(var i=0;i<iframes_new?.length;i++) 
      { 
        let selNode = iframes_new[i].contentWindow.document.getElementById('jsAudio');
        if (selNode) {
          if(selNode.className.match('aa')&&selNode.src!=url){
            selNode.pause()
            iframes_new[i].contentWindow.document.getElementById('input1').hidden = false
            iframes_new[i].contentWindow.document.getElementById('input2').hidden = true
            // console.log('input2-----------------',iframes_new[i].contentWindow.document.getElementById('input2'))
          }
        }
      } 
    }
    
   
  }
  //数字转换成文字数字
  numFilter = (value) => {
    value = Number(value);
    let upperCaseNumber = [
      "零",
      "一",
      "二",
      "三",
      "四",
      "五",
      "六",
      "七",
      "八",
      "九",
      "十",
      "百",
      "千",
      "万",
      "亿",
    ];
    let length = String(value).length;
    if (length === 1) {
      return upperCaseNumber[value];
    } else if (length === 2) {
      if (value === 10) {
        return upperCaseNumber[value];
      } else if (value > 10 && value < 20) {
        return "十" + upperCaseNumber[String(value).charAt(1)];
      } else {
        return (
          upperCaseNumber[String(value).charAt(0)] +
          "十" +
          upperCaseNumber[String(value).charAt(1)].replace("零", "")
        );
      }
    }
  };

  computedNumber = () => {
    // 计算答题题号
    let { activeFlagList } = this.state;
    let singleChoiceQuestions = activeFlagList?.find(item => item?.testType === 1);
    let multipleChoiceQuestions = activeFlagList?.find(item => item?.testType === 2);
    let judgeChoiceQuestions = activeFlagList?.find(item => item?.testType === 4);
    let FillInTheBlanksQuestions = activeFlagList?.find(item => item?.testType === 3);
    let answerQuestions = activeFlagList?.find(item => item?.testType === 5);
    let tempAry = [];
    if (singleChoiceQuestions) {
      tempAry.push(singleChoiceQuestions);
    }
    if (multipleChoiceQuestions) {
      tempAry.push(multipleChoiceQuestions);
    }
    if (FillInTheBlanksQuestions) {
      tempAry.push(FillInTheBlanksQuestions);
    }
    if (judgeChoiceQuestions) {
      tempAry.push(judgeChoiceQuestions);
    }
    if (answerQuestions) {
      tempAry.push(answerQuestions);
    }
    tempAry = tempAry?.map((item, index) => {
      return { ...item, indexNumber: this.numFilter(index + 1) };
    });
    this.setState({
      tempAry
    });
  }

  getExamTestList = (examId, examName) => {
    if (!examId) return;
    axios.get(getExamTestList, {
      params: {
        examId
      }
    }).then(res => {
      if (!res) return;
      this.setState({
        activeFlagList: res?.testList || [],
        examName: res?.examName || examName,
      }, () => {
        this.setState({
          currentTestItem: (res?.testList || [])?.sort((a, b) => a?.testType - b?.testType)[0] || {},
          currentTestItemBackups: (res?.testList || [])?.sort((a, b) => a?.testType - b?.testType)[0] || {},
          currentTestType: ((res?.testList || [])?.sort((a, b) => a?.testType - b?.testType)[0] || {})?.testType || 1
        });
        this.currentTestItem = (res?.testList || [])?.sort((a, b) => a?.testType - b?.testType)[0] || {};
        this.computedNumber();
      });
    });
  }

  transFormQuestionType = testType => {
    if (testType === 1) {
      return '单选题';
    }
    if (testType === 2) {
      return '多选题';
    }
    if (testType === 4) {
      return '判断题';
    }
    if (testType === 3) {
      return '填空题';
    }
    if (testType === 5) {
      return '解答题';
    }
  }

  renderTestTitle = (testList, testType) => {
    let { tempAry, currentTestItem, activeFlagList } = this.state;
    console.log(tempAry, 'tempArytempAry')
    return <div className={styles.leftTestTitleWrapper}>
      <p className={styles.leftTestTitleBig}>{tempAry?.find(item => item?.testType === testType)?.indexNumber}、{this.transFormQuestionType(testType)}</p>
      {
        testList?.map((item, index) => (<div key={index} className={`${styles.leftTestTitle} ${currentTestItem?.id === item?.id ? styles.active : ''}`} onClick={() => {
          this.setState({
            currentTestItem: item,
            currentTestItemBackups: item,
            currentTestType: item?.testType || 1
          });
          this.currentTestItem = item;
        }}>
          <div className={styles.leftTestTitleL}>
            {/* {renderHTML(divideMathText("(" + (index + 1) + ")." + item?.stem))} */}
            {
              "(" + (index + 1) + ")." + (getInnerText(item?.stem) || '...')
            }
          </div>
          <img className={styles.leftTestTitleR} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={() => {
            this.setState({
              activeFlagList: activeFlagList.filter(itm => itm?.id !== item?.id)
            });
          }} />
        </div>))
      }
    </div>;
  }

  handleChange = (event) => {
    this.setState({
      examName: event.target.value
    });
  }

  onDifficultyChange = (event) => {
    this.setState({
      currentTestItem: { ...this.state.currentTestItem, difficulty: event.target.value }
    });
    this.currentTestItem = { ...this.currentTestItem, difficulty: event.target.value };
  }

  onJudgeChange = (event) => {
    this.setState({
      currentTestItem: { ...this.state.currentTestItem, courseTestJudgeAnwser: { ...(this.state.currentTestItem?.courseTestJudgeAnwser || {}), rightFlag: event.target.value } }
    });
    this.currentTestItem = { ...this.currentTestItem, courseTestJudgeAnwser: { ...(this.currentTestItem?.courseTestJudgeAnwser || {}), rightFlag: event.target.value } };
  }

  closeModal = () => {
    this.setState({
      isShowPreviewOfTestQuestions: false,
      isShowTopicSelectionOfQuestion: false,
    });
  }

  onRadioChange = e => {
    let { currentTestItem } = this.state;
    let currentTestItemThis = this.currentTestItem;
    this.setState({
      currentTestItem: {
        ...currentTestItem, courseTestChooseAnwser: (currentTestItem?.courseTestChooseAnwser || [])?.map(item => {
          if (item?.optionName === e.target.value) {
            return { ...item, rightFlag: 1 };
          }
          return { ...item, rightFlag: 0 };
        })
      }
    });
    this.currentTestItem = {
      ...currentTestItemThis, courseTestChooseAnwser: (currentTestItemThis?.courseTestChooseAnwser || [])?.map(item => {
        if (item?.optionName === e.target.value) {
          return { ...item, rightFlag: 1 };
        }
        return { ...item, rightFlag: 0 };
      })
    };
  }

  unique = (arr) => {
    for (var i = 0; i < arr.length; i++) {
      for (var j = i + 1; j < arr.length; j++) {
        if (arr[i]?.optionValue == arr[j]?.optionValue) {
          arr.splice(j, 1);
          j--;
        }
      }
    }
    return arr;
  }

  select = testBasketList => {
    let { activeFlagList } = this.state;
    this.setState({
      isShowTopicSelectionOfQuestion: false,
      // activeFlagList: this.unique([...activeFlagList, ...testBasketList])
      activeFlagList: [...activeFlagList, ...testBasketList]
    }, () => {
      this.computedNumber();
    });
    message.destroy();
    message.success('选入成功');
  }

  addTest = () => {
    let { activeFlagList, currentTestType } = this.state;
    let { stem, resolution, courseTestChooseAnwser, courseTestBlankEasyAnwser, courseTestJudgeAnwser } = this.currentTestItem;
    let { courseId } = getParam();
    if (!stem) {
      message.destroy();
      message.warning('题干不能为空');
      return;
    }
    if (!resolution) {
      message.destroy();
      message.warning('解析不能为空');
      return;
    }
    if (currentTestType === 1 || currentTestType === 2) {
      if ((courseTestChooseAnwser?.length > 0 && courseTestChooseAnwser?.find(item => !item?.optionValue))) {
        message.destroy();
        message.warning('选项答案不能为空');
        return;
      }
      if (courseTestChooseAnwser?.every(item => !item?.rightFlag)) {
        message.destroy();
        message.warning('请选择正确答案');
        return;
      }
    }
    if (currentTestType === 3 || currentTestType === 5) {
      if ((courseTestBlankEasyAnwser?.length > 0 && courseTestBlankEasyAnwser?.find(item => !item?.answerText))) {
        message.destroy();
        message.warning('选项答案不能为空');
        return;
      }
    }
    if (currentTestType === 4) {
      if (!courseTestJudgeAnwser) {
        message.destroy();
        message.warning('选项答案不能为空');
        return;
      }
    }
    if (currentTestType === 1 || currentTestType === 2) {
      let length = courseTestChooseAnwser?.length;
      let newCourseTestChooseAnwser = this.unique(courseTestChooseAnwser);
      if (length !== newCourseTestChooseAnwser?.length) {
        message.destroy();
        message.warning('选项内容不可重复');
        return;
      }
    }
    // if ((courseTestChooseAnwser || []).find(item => !item?.optionValue) && (courseTestBlankEasyAnwser || []).find(item => !item?.answerText) && !courseTestJudgeAnwser) {
    //   message.destroy();
    //   message.warning('选项答案不能为空');
    //   return;
    // }
    
    axios.post(addTest, {
      ...this.currentTestItem,
      courseTestChooseAnwser: this.currentTestItem?.courseTestChooseAnwser?.map((item, index) => ({ ...item, optionName: optionAll[index] })),
      testType: currentTestType, difficulty: this.currentTestItem?.difficulty || '1', courseId
    }).then(res => {
      if (!res) return;
      message.destroy();
      message.success('保存成功');
      this.contentRef.current.scrollTop = 0;
      if (activeFlagList?.find(item => item?.id === res?.id)) {
        this.setState({
          activeFlagList: activeFlagList?.map(i => {
            if (i?.id === res.id) {
              return res;
            }
            return i;
          }),
        });
        return;
      }
      this.setState({
        activeFlagList: [...activeFlagList, res],
      }, () => {
        this.computedNumber();
        // 重置题目
        this.setState({
          currentTestItem: {
            stem: '   ',
            resolution: '    '
          }
        }, () => {
          this.setState({
            currentTestItem: this.state.currentTestItemBackups
          });
        });
        this.currentTestItem = this.state.currentTestItemBackups;
      });
    });
  }

  saveExamStudent = () => {
    let { examId } = getParam();
    let { activeFlagList } = this.state;
    if (activeFlagList?.length > 0 && examId) {
      axios.post(saveExamStudent, {
        examId,
        testIds: activeFlagList?.map(item => item?.id)
      }).then(res => {
        if (!res) return;
        message.destroy();
        message.success('保存成功');
        window.close();
      });
    } else {
      window.close();
    }
  }

  render() {
    let { activeFlagList, currentTestType, examName, isShowPreviewOfTestQuestions, currentTestItem, currentTestItemBackups, isShowAnalysis, isEdit, isShowTopicSelectionOfQuestion, isTaskPoint } = this.state;
    let singleChoiceQuestions = activeFlagList?.filter(item => item?.testType === 1);
    let multipleChoiceQuestions = activeFlagList?.filter(item => item?.testType === 2);
    let judgeChoiceQuestions = activeFlagList?.filter(item => item?.testType === 4);
    let FillInTheBlanksQuestions = activeFlagList?.filter(item => item?.testType === 3);
    let answerQuestions = activeFlagList?.filter(item => item?.testType === 5);
    console.log(this.currentTestItem, '++++++++++++++++++++++++++++++++++', currentTestItem)
    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <span className={styles.topTitle}>测试标题</span>
          <div className={styles.topTitleIpt}>
            {examName}
            {/* <Input value={examName} onChange={this.handleChange} /> */}
          </div>
          <div className={styles.topRight}>
            <div className={styles.topRightBtnL} onClick={() => {
              this.setState({
                isShowPreviewOfTestQuestions: true
              });
            }}>预览</div>
            <div className={styles.topRightBtnR} onClick={this.saveExamStudent}>保存</div>
          </div>
        </div>
        <div className={styles.left}>
          <div className={styles.leftTop}>
            <img src={imgUrlForZhongZhi("editNumberIcon.png")} alt="" />
            <span>题量：</span>
            <span className={styles.leftTopNum}>{activeFlagList?.length || 0}</span>
          </div>
          {
            singleChoiceQuestions?.length > 0 && this.renderTestTitle(singleChoiceQuestions, 1)
          }
          {
            multipleChoiceQuestions?.length > 0 && this.renderTestTitle(multipleChoiceQuestions, 2)
          }
          {
            FillInTheBlanksQuestions?.length > 0 && this.renderTestTitle(FillInTheBlanksQuestions, 3)
          }
          {
            judgeChoiceQuestions?.length > 0 && this.renderTestTitle(judgeChoiceQuestions, 4)
          }
          {
            answerQuestions?.length > 0 && this.renderTestTitle(answerQuestions, 5)
          }
        </div>
        <div className={styles.right} ref={this.contentRef}>
          <div className={styles.rightTop}>
            <div className={styles.rightTopTab}>
              <img src={imgUrlForZhongZhi(`tab${currentTestType}.png`)} alt="" />
              <div className={`${styles.rightTopTabItem1} ${currentTestType === 1 ? styles.active : ''}`} onClick={() => {
                this.setState({
                  currentTestItem: {
                    stem: '   ',
                    courseTestChooseAnwser: [{
                      optionName: "A",
                      optionValue: '   '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                  currentTestItemBackups: {
                    stem: '   ',
                    courseTestChooseAnwser: [{
                      optionName: "A",
                      optionValue: '   '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                }, () => {
                  this.setState({
                    currentTestType: 1,
                    isEdit: true,
                    currentTestItem: {
                      courseTestChooseAnwser: [{
                        optionName: "A",
                        optionValue: ''
                      }]
                    },
                    currentTestItemBackups: {
                      courseTestChooseAnwser: [{
                        optionName: "A",
                        optionValue: ''
                      }]
                    },
                  });
                });
                this.currentTestItem = {
                  courseTestChooseAnwser: [{
                    optionName: "A",
                    optionValue: ''
                  }]
                };
              }}>单选题</div>
              <div className={`${styles.rightTopTabItem2} ${currentTestType === 2 ? styles.active : ''}`} onClick={() => {
                this.setState({
                  currentTestItem: {
                    stem: '   ',
                    courseTestChooseAnwser: [{
                      optionName: "A",
                      optionValue: '   '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                  currentTestItemBackups: {
                    stem: '   ',
                    courseTestChooseAnwser: [{
                      optionName: "A",
                      optionValue: '   '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                }, () => {
                  this.setState({
                    currentTestType: 2,
                    isEdit: true,
                    currentTestItem: {
                      courseTestChooseAnwser: [{
                        optionName: "A",
                        optionValue: ''
                      }]
                    },
                    currentTestItemBackups: {
                      courseTestChooseAnwser: [{
                        optionName: "A",
                        optionValue: ''
                      }]
                    },
                  });
                });
                this.currentTestItem = {
                  courseTestChooseAnwser: [{
                    optionName: "A",
                    optionValue: ''
                  }]
                };
              }}>多选题</div>
              <div className={`${styles.rightTopTabItem3} ${currentTestType === 3 ? styles.active : ''}`} onClick={() => {
                this.setState({
                  currentTestItem: {
                    stem: '   ',
                    courseTestBlankEasyAnwser: [{
                      answerText: ' '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                  currentTestItemBackups: {
                    stem: '   ',
                    courseTestBlankEasyAnwser: [{
                      answerText: ' '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                }, () => {
                  this.setState({
                    currentTestType: 3,
                    isEdit: true,
                    currentTestItem: {
                      courseTestBlankEasyAnwser: [{
                        answerText: '   '
                      }]
                    },
                    currentTestItemBackups: {},
                  });
                });
                this.currentTestItem = {
                  courseTestBlankEasyAnwser: [{
                    answerText: '   '
                  }]
                };
              }}>填空题</div>
              <div className={`${styles.rightTopTabItem4} ${currentTestType === 4 ? styles.active : ''}`} onClick={() => {
                this.setState({
                  currentTestItem: {
                    stem: '   ',
                    courseTestJudgeAnwser: {
                      rightFlag: '   '
                    },
                    resolution: '    ',
                    difficulty: '1'
                  },
                  currentTestItemBackups: {
                    stem: '   ',
                    courseTestJudgeAnwser: [{
                      rightFlag: '   '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                }, () => {
                  this.setState({
                    currentTestType: 4,
                    isEdit: true,
                    currentTestItem: {
                      courseTestJudgeAnwser: [{
                        rightFlag: 0
                      }]
                    },
                    currentTestItemBackups: {
                      courseTestJudgeAnwser: [{
                        rightFlag: 0
                      }]
                    },
                  });
                });
                this.currentTestItem = {};
              }}>判断题</div>
              <div className={`${styles.rightTopTabItem5} ${currentTestType === 5 ? styles.active : ''}`} onClick={() => {
                this.setState({
                  currentTestItem: {
                    stem: '   ',
                    courseTestBlankEasyAnwser: [{
                      answerText: '   '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                  currentTestItemBackups: {
                    stem: '   ',
                    courseTestBlankEasyAnwser: [{
                      answerText: '   '
                    }],
                    resolution: '    ',
                    difficulty: '1'
                  },
                }, () => {
                  this.setState({
                    currentTestType: 5,
                    isEdit: true,
                    currentTestItem: {
                      courseTestBlankEasyAnwser: [{
                        answerText: ''
                      }],
                    },
                    currentTestItemBackups: {
                      courseTestBlankEasyAnwser: [{
                        answerText: ''
                      }],
                    },
                  });
                });
                this.currentTestItem = {
                  courseTestBlankEasyAnwser: [{
                    answerText: ''
                  }],
                };
              }}>简答题</div>
            </div>
            <div className={styles.rightTopRight} onClick={() => {
              this.setState({
                isShowTopicSelectionOfQuestion: true
              });
            }}>
              <img src={imgUrlForZhongZhi("chooseTest.png")} alt="" />
              <span>题库选题</span>
            </div>
          </div>
          {(activeFlagList?.length <= 0 && !isEdit) ? <div className={styles.rightBottomEmpty}>
            <p>您好</p>
            <p>请在上方点击题型按钮添加题目，然后在此编辑区添加内容</p>
          </div> :
            <div className={styles.rightBottomContont} >
              <div className={styles.rightBottomContontItem}>
                <div className={styles.rightBottomContontStemL}>题干</div>
                {
                  currentTestItem?.stem ? <div className={styles.rightBottomContontStemR} key={'stem1'}>
                    <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={currentTestItem?.stem} onChange={(html) => {
                      this.currentTestItem = { ...this.currentTestItem, stem: html };
                    }} />
                  </div>
                    :
                    <div className={styles.rightBottomContontStemR} key={'stem2'}>
                      <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={currentTestItem?.stem} onChange={(html) => {
                        this.currentTestItem = { ...this.currentTestItem, stem: html };
                      }} />
                    </div>
                }
              </div>
              {/* 选择题 */}
              {
                (currentTestType === 1 || currentTestType === 2) && <div className={styles.rightBottomContontItem}>
                  <div className={styles.rightBottomContontOption}>
                    <span className={styles.rightBottomContontTitle}>选项</span>
                    <div className={styles.rightBottomContontOptionTR}>
                      <img src={imgUrlForZhongZhi('optionIcon.png')} alt="" />
                      <span>在当前页面选中的选项为本题的正确答案</span>
                    </div>
                  </div>
                  {
                    currentTestType === 1 ?
                      <Radio.Group onChange={this.onRadioChange}
                        value={(currentTestItem?.courseTestChooseAnwser || [{
                          optionName: 'A'
                        }]).find(item => item?.rightFlag)?.optionName}
                      >
                        {/* <Space direction="vertical"> */}
                        {
                          (currentTestItem?.courseTestChooseAnwser || [{}]).map((item, index) => (
                            <div key={index} className={styles.delContainer}>
                              <Popconfirm overlayClassName='myPopconfirm' title="请确认是否删除该选项？" okText="确定" cancelText="取消" onConfirm={() => {
                                if ((currentTestItem?.courseTestChooseAnwser || [{}])?.length <= 1) return;
                                this.setState({
                                  currentTestItem: { ...currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}]).filter((i, childIndex) => childIndex !== index) }
                                }, () => {
                                  this.setState({
                                    currentTestItem: { ...this.state.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}])?.map((i, indexI) => ({ ...i, optionName: optionAll[indexI] })) }
                                  });
                                });
                                this.currentTestItem = { ...this.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}]).filter((i, childIndex) => childIndex !== index) };
                                this.currentTestItem = { ...this.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}]).map((i, indexI) => ({ ...i, optionName: optionAll[indexI] })) };
                              }}>
                                <img className={styles.optionContainerDel} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" />
                              </Popconfirm>
                              {/* <img className={styles.optionContainerDel} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={() => {
                                if ((currentTestItem?.courseTestChooseAnwser || [{}])?.length <= 1) return;
                                this.setState({
                                  currentTestItem: { ...currentTestItem, courseTestChooseAnwser: (currentTestItem?.courseTestChooseAnwser || [{}]).filter((i, childIndex) => childIndex !== index) }
                                });
                                this.currentTestItem = { ...this.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}]).filter((i, childIndex) => childIndex !== index) };
                              }} /> */}
                              <Radio key={index} value={optionAll[index]}>
                                {optionAll[index]}
                                <div className={styles.optionContainer} onChange={e => e.stopPropagation()}>
                                  {
                                    item?.optionValue ?
                                      <div key={'optionValue1'}>
                                        <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={item?.optionValue} onChange={(html) => {
                                          this.currentTestItem = {
                                            ...this.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}])?.map((i, indexChild) => {
                                              if (index === indexChild) {
                                                return { ...i, optionValue: html };
                                              }
                                              return i;
                                            })
                                          };
                                        }} />
                                      </div>
                                      :
                                      <div key={'optionValue2'}>
                                        <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={item?.optionValue} onChange={(html) => {
                                          this.currentTestItem = {
                                            ...this.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}])?.map((i, indexChild) => {
                                              if (index === indexChild) {
                                                return { ...i, optionValue: html };
                                              }
                                              return i;
                                            })
                                          };
                                        }} />
                                      </div>
                                  }
                                </div>
                              </Radio></div>))
                        }
                        {/* </Space> */}
                      </Radio.Group>
                      :
                      <Space direction="vertical">
                        {
                          (currentTestItem?.courseTestChooseAnwser || [{}]).map((item, index) => (
                            <div key={index} className={styles.delContainer}>
                              <Popconfirm overlayClassName='myPopconfirm' title="请确认是否删除该选项？" okText="确定" cancelText="取消" onConfirm={() => {
                                if ((currentTestItem?.courseTestChooseAnwser || [{}])?.length <= 1) return;
                                this.setState({
                                  currentTestItem: { ...currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}]).filter((i, childIndex) => childIndex !== index) }
                                });
                                this.currentTestItem = { ...this.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}]).filter((i, childIndex) => childIndex !== index) };
                              }}>
                                <img className={styles.optionContainerDel} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" />
                              </Popconfirm>
                              {/* <img className={styles.optionContainerDel} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={() => {
                                if ((currentTestItem?.courseTestChooseAnwser || [{}])?.length <= 1) return;
                                this.setState({
                                  currentTestItem: { ...currentTestItem, courseTestChooseAnwser: (currentTestItem?.courseTestChooseAnwser || [{}]).filter((i, childIndex) => childIndex !== index) }
                                });
                                this.currentTestItem = { ...this.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [{}]).filter((i, childIndex) => childIndex !== index) };
                              }} /> */}
                              <Radio checked={item?.rightFlag} onClick={() => {
                                this.setState({
                                  currentTestItem: {
                                    ...currentTestItem, courseTestChooseAnwser: (currentTestItem?.courseTestChooseAnwser || [])?.map(i => {
                                      if (i?.optionName === item?.optionName) {
                                        return { ...i, rightFlag: +(!item?.rightFlag) };
                                      }
                                      return i;
                                    })
                                  }
                                });
                                this.currentTestItem = {
                                  ...this.currentTestItem, courseTestChooseAnwser: (this.currentTestItem?.courseTestChooseAnwser || [])?.map(i => {
                                    if (i?.optionName === item?.optionName) {
                                      return { ...i, rightFlag: +(!item?.rightFlag) };
                                    }
                                    return i;
                                  })
                                };
                              }}>
                                {optionAll[index]}
                                <div className={styles.optionContainer}>
                                  {
                                    item?.optionValue ?
                                      <div key={'optionValueD1' + index}>
                                        <RichTextEditor key={'option' + index} id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={item?.optionValue} onChange={(html) => {
                                          this.currentTestItem = {
                                            ...this.currentTestItem, courseTestChooseAnwser: this.currentTestItem?.courseTestChooseAnwser?.map((i, childIndex) => {
                                              if (childIndex === index) {
                                                return { ...i, optionValue: html };
                                              }
                                              return i;
                                            })
                                          };
                                        }} />
                                      </div>
                                      :
                                      <div key={'optionValueD2' + index}>
                                        <RichTextEditor key={'option' + index} id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={item?.optionValue} onChange={(html) => {
                                          this.currentTestItem = {
                                            ...this.currentTestItem, courseTestChooseAnwser: this.currentTestItem?.courseTestChooseAnwser?.map((i, childIndex) => {
                                              if (childIndex === index) {
                                                return { ...i, optionValue: html };
                                              }
                                              return i;
                                            })
                                          };
                                        }} />
                                      </div>
                                  }

                                </div>
                              </Radio></div>))
                        }
                      </Space>
                  }
                  <div className={styles.rightBottomContontOptionAdd} onClick={() => {
                    if (currentTestItem?.courseTestChooseAnwser?.length >= 10) {
                      message.destroy();
                      message.warning('最多设置10个选项');
                      return;
                    }
                    let id = Math.random();
                    this.setState({
                      currentTestItem: {
                        ...currentTestItem, courseTestChooseAnwser: [...(currentTestItem?.courseTestChooseAnwser || []), {
                          optionName: optionAll[(currentTestItem?.courseTestChooseAnwser || [])?.length],
                          id
                        }]
                      }
                    });
                    this.currentTestItem = {
                      ...this.currentTestItem, courseTestChooseAnwser: [...(this.currentTestItem?.courseTestChooseAnwser || []), {
                        optionName: optionAll[(this.currentTestItem?.courseTestChooseAnwser || [])?.length],
                        id
                      }]
                    };
                  }}>
                    <img src={imgUrlForZhongZhi('optionAddIcon.png')} alt="" />
                    <span>增加选项</span>
                  </div>
                </div>
              }
              {/* 填空题 */}
              {
                currentTestType === 3 && <div className={styles.rightBottomContontItem}>
                  <div className={styles.rightBottomContontOption}>
                    <span className={styles.rightBottomContontTitle}>答案</span>
                    <div className={styles.rightBottomContontOptionTR}>
                      <img src={imgUrlForZhongZhi('optionIcon.png')} alt="" />
                      <span>请将每空答案填写在对应输入框中</span>
                    </div>
                  </div>
                  <div className={styles.rightBottomContontAnswer}>
                    {
                      (currentTestItem?.courseTestBlankEasyAnwser || [{}])?.map((item, index) => (<div key={'courseTestBlankEasyAnwser' + index} className={styles.delContainer}>
                        <Popconfirm overlayClassName='myPopconfirm' title="请确认是否删除该选项？" okText="确定" cancelText="取消" onConfirm={() => {
                          if ((currentTestItem?.courseTestBlankEasyAnwser || [{}])?.length <= 1) return;
                          this.setState({
                            currentTestItem: { ...currentTestItem, courseTestBlankEasyAnwser: (this.currentTestItem?.courseTestBlankEasyAnwser || [{}]).filter((i, childIndex) => childIndex !== index) }
                          });
                          this.currentTestItem = { ...this.currentTestItem, courseTestBlankEasyAnwser: (this.currentTestItem?.courseTestBlankEasyAnwser || [{}]).filter((i, childIndex) => childIndex !== index) };
                        }}>
                          <img className={`${styles.optionContainerDel} ${styles.optionContainerDel1}`} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" />
                        </Popconfirm>
                        {/* <img className={`${styles.optionContainerDel} ${styles.optionContainerDel1}`} src={imgUrlForZhongZhi('showMoreCloseImg.png')} alt="" onClick={() => {
                          if ((currentTestItem?.courseTestBlankEasyAnwser || [{}])?.length <= 1) return;
                          this.setState({
                            currentTestItem: { ...currentTestItem, courseTestBlankEasyAnwser: (currentTestItem?.courseTestBlankEasyAnwser || [{}]).filter((i, childIndex) => childIndex !== index) }
                          });
                          this.currentTestItem = { ...this.currentTestItem, courseTestBlankEasyAnwser: (this.currentTestItem?.courseTestBlankEasyAnwser || [{}]).filter((i, childIndex) => childIndex !== index) };
                        }} /> */}
                        <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={(currentTestItem?.courseTestBlankEasyAnwser || [])[index]?.answerText} onChange={(html) => {
                          this.currentTestItem = {
                            ...this.currentTestItem, courseTestBlankEasyAnwser: this.currentTestItem?.courseTestBlankEasyAnwser?.map((i, indexI) => {
                              if (index === indexI) {
                                return { ...i, answerText: html };
                              }
                              return i;
                            }) || [{
                              answerText: html
                            }]
                          };
                        }} /></div>))
                    }
                  </div>
                  <div className={styles.rightBottomContontOptionAdd} onClick={() => {
                    this.setState({
                      currentTestItem: { ...currentTestItem, courseTestBlankEasyAnwser: [...(currentTestItem?.courseTestBlankEasyAnwser || []), {}] }
                    });
                    this.currentTestItem = { ...this.currentTestItem, courseTestBlankEasyAnwser: [...(this.currentTestItem?.courseTestBlankEasyAnwser || []), {}] };
                  }}>
                    <img src={imgUrlForZhongZhi('optionAddIcon.png')} alt="" />
                    <span>增加更多</span>
                  </div>
                  <div className={styles.rightBottomContontOptionTip}>
                    <p>提示：</p>
                    <p>1、每空答案支持多种表述方式，答案设置时请用分号“；”隔开。如：水；H2O</p>
                    <p>2、如果试题答案是数字，可以设置一个范围，两个数字之间必须有减号“-”。如：1-9，学生填写1到9之间的数字都算正确（包括1和9）</p>
                  </div>
                </div>
              }
              {/* 解答题 */}
              {
                currentTestType === 5 && <div className={styles.rightBottomContontItem}>
                  <div className={styles.rightBottomContontOption}>
                    <span className={styles.rightBottomContontTitle}>答案</span>
                    <div className={styles.rightBottomContontOptionTR}>
                      <img src={imgUrlForZhongZhi('optionIcon.png')} alt="" />
                      <span>请将每空答案填写在对应输入框中，字数不限</span>
                    </div>
                  </div>
                  <div className={styles.rightBottomContontAnswer}>
                    {
                      (currentTestItem?.courseTestBlankEasyAnwser || [])[0]?.answerText ?
                        <div key={'courseTestAnwser1'}>
                          <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={(currentTestItem?.courseTestBlankEasyAnwser || [])[0]?.answerText} onChange={(html) => {
                            this.currentTestItem = {
                              ...this.currentTestItem, courseTestBlankEasyAnwser: this.currentTestItem?.courseTestBlankEasyAnwser?.map(i => ({ ...i, answerText: html })) || [{
                                answerText: html
                              }]
                            };
                          }} />
                        </div>
                        :
                        <div key={'courseTestAnwser2'}>
                          <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={(currentTestItem?.courseTestBlankEasyAnwser || [])[0]?.answerText} onChange={(html) => {
                            this.currentTestItem = {
                              ...this.currentTestItem, courseTestBlankEasyAnwser: this.currentTestItem?.courseTestBlankEasyAnwser?.map(i => ({ ...i, answerText: html })) || [{
                                answerText: html
                              }]
                            };
                          }} />
                        </div>
                    }
                  </div>
                </div>
              }
              {/* 判断题 */}
              {
                currentTestType === 4 && <div className={styles.rightBottomContontItem}>
                  <div className={styles.rightBottomContontOption}>
                    <span className={styles.rightBottomContontTitle}>答案</span>
                    <div className={styles.rightBottomContontOptionTR}>
                      <img src={imgUrlForZhongZhi('optionIcon.png')} alt="" />
                      <span>在当前页面选中的选项为本题的正确答案</span>
                    </div>
                  </div>
                  <div className={styles.rightBottomContontAnswer}>
                    <Radio.Group onChange={this.onJudgeChange} value={currentTestItem?.courseTestJudgeAnwser?.rightFlag}>
                      <Radio value={1}>正确</Radio>
                      <Radio value={0}>错误</Radio>
                    </Radio.Group>
                  </div>
                </div>
              }
              <div className={styles.rightBottomContontItem}>
                <Checkbox onClick={() => {
                  this.setState({
                    isShowAnalysis: !isShowAnalysis
                  });
                }} checked={isShowAnalysis} />
                <span className={styles.rightBottomContontItemAnalysisText}>答案解析</span>
                {
                  currentTestItem?.resolution ? <div key={'Analysis1'} className={styles.rightBottomContontItemAnalysisBox} style={{ display: isShowAnalysis ? 'block' : 'none' }}>
                    <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={currentTestItem?.resolution} onChange={(html) => {
                      this.currentTestItem = { ...this.currentTestItem, resolution: html };
                    }} />
                  </div>
                    :
                    <div key={'Analysis2'} className={styles.rightBottomContontItemAnalysisBox} style={{ display: isShowAnalysis ? 'block' : 'none' }}>
                      <RichTextEditor id={"noticeContent" + Math.random()} style={{ width: '100%' }} value={currentTestItem?.resolution} onChange={(html) => {
                        this.currentTestItem = { ...this.currentTestItem, resolution: html };
                      }} />
                    </div>
                }
              </div>
              <div className={styles.difficultyContainer}>
                <span className={styles.difficultyTitle}>难度</span>
                <Radio.Group onChange={this.onDifficultyChange} value={currentTestItem?.difficulty || '1'}>
                  <Radio value={'1'}>易</Radio>
                  <Radio value={'2'}>中档</Radio>
                  <Radio value={'3'}>难</Radio>
                </Radio.Group>
              </div>
              <div className={styles.submitContainer}>
                <div className={styles.btnL} onClick={this.addTest}>保存题目</div>
                <div className={styles.btnR} onClick={() => {
                  this.setState({
                    currentTestItem: {
                      stem: '   ',
                      resolution: '    '
                    }
                  }, () => {
                    this.setState({
                      currentTestItem: currentTestItemBackups
                    });
                  });
                  this.currentTestItem = currentTestItemBackups;
                }}>重置题目</div>
              </div>
            </div>
          }
        </div>
        {
          isShowPreviewOfTestQuestions && <div className={styles.PreviewOfTestQuestionsWrapper}>
            <PreviewOfTestQuestions onClose={this.closeModal} activeFlagList={activeFlagList} examName={examName} isTaskPoint={isTaskPoint} />
          </div>
        }
        {
          isShowTopicSelectionOfQuestion && <TopicSelectionOfQuestion onClose={this.closeModal} select={this.select} selectedDate={activeFlagList} />
        }
      </div>
    );
  }
}

export default EditOfTestQuestions;
