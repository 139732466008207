import React from "react";
import { connect } from 'react-redux';
import { Radio, Input, Checkbox, Space } from 'antd';
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import styles from './index.module.scss';
import axios from '../../../../axios';
import { getExamTestList, courseTypeList, subjectList, queryCollegeMajors, getQuestionBank } from '../../../../api';
import { imgUrlForZhongZhi } from '../../../../utils/index';
import { divideMathText } from '../../../../utils/util';
import { renderHTML } from "../../../../utils/react-mathjax";
import Paginations from '@@/Paginations';
import Empty from '../../../../components/Empty';

class TopicSelectionOfQuestion extends React.Component {
  contentRef = React.createRef()
  constructor(props) {
    super();
    this.state = {
      activeFlagList: [],
      paginationList: {
        current: 1,
        pageSize: 3,
        total: 1,
      },
      courseTypeListData: [],
      type: props.user?.subjectId ? 1 : 2,    // 1 是公共课，2是专业课
      subjectListData: [],
      currentSubjectValue: '0',
      majorCategoriesList: [],
      currentMajorCategoriesId: 0,
      questionTypeList: [{
        testTypeName: '全部',
        testType: ''
      }, {
        testTypeName: '单选题',
        testType: 1
      }, {
        testTypeName: '多选题',
        testType: 2
      }, {
        testTypeName: '判断题',
        testType: 4
      }, {
        testTypeName: '填空题',
        testType: 3
      }, {
        testTypeName: '解答题',
        testType: 5
      }],
      currentTestType: '',
      difficultyList: [{
        name: '全部',
        value: ''
      }, {
        name: '容易',
        value: 1
      }, {
        name: '中档',
        value: 2
      }, {
        name: '难题',
        value: 3
      }],
      currentDifficulty: '',
      testBasketList: [],
    };
  }

  componentDidMount() {
    // this.getExamTestList();
    this.courseTypeList();
  }

  getCourseListBySchool = () => {
    let { type, currentSubjectValue, currentMajorCategoriesId, currentDifficulty, currentTestType, paginationList } = this.state;
    let params = {
      difficulty: currentDifficulty,
      pageNum: paginationList?.current,
      pageSize: paginationList?.pageSize,
      testType: currentTestType,
      userId: this.props.user?.uid,
      courseType:type
    };
    if (type === 1) {
      params.subjectId = currentSubjectValue;
    } else {
      params.majorId = currentMajorCategoriesId;
    }
    axios.post(getQuestionBank, params).then(res => {
      this.setState({
        paginationList: {
          ...this.state.paginationList,
          total: res?.total
        },
        activeFlagList: res?.records
      });
    });
  }

  courseTypeList = () => {
    axios.get(courseTypeList).then(res => {
      if (!res) return;
      this.setState({
        courseTypeListData: res
      }, () => {
        this.subjectList();
        this.queryCollegeMajors();
      });
    });
  }

  subjectList = () => {
    axios.get(subjectList).then(res => {
      if (!res) return;
      this.setState({
        subjectListData: res,
      }, () => {
        this.getCourseListBySchool();
      });
    });
  }

  // 专业
  queryCollegeMajors = () => {
    axios.post(queryCollegeMajors, {
      collegeId: 0,
      schoolId: this.props.user?.schoolId
    }).then(res => {
      if (!res) {
        return;
      };
      this.setState({
        majorCategoriesList: res,
        currentMajorCategoriesId: 0
      }, () => {
        this.getCourseListBySchool();
      });
    });
  }

  changeSubject = currentSubjectValue => {
    this.setState({
      currentSubjectValue,
      paginationList: {
        ...this.state.paginationList,
        current: 1
      },
    }, () => {
      this.getCourseListBySchool();
    });
  }

  changeMajorCategories = currentMajorCategoriesId => {
    this.setState({
      currentMajorCategoriesId,
    }, () => {
      this.getCourseListBySchool();
    });
  }

  // 分页器 动态获取到页码
  onChangeCurPageOrPageSize = (current, pageSize) => {
    console.log(current, pageSize);
    this.contentRef.current.scrollTop = 0
    this.setState({
      paginationList: {
        current,
        pageSize: 3,
        // total: 180,
      },
    }, () => {
      this.getCourseListBySchool();
    });
  }

  optionRender = option => {
    if (option.includes('wordDivDot')) {
      option = option.replace(/<p>/gim, "");
      return option;
    }
    return option;
  }

  renderAnswersText = (testItem, testType) => {
    if (testType === 1) {
      return testItem?.courseTestChooseAnwser?.find(item => item?.rightFlag)?.optionName;
    }
    if (testType === 2) {
      return (testItem?.courseTestChooseAnwser || testItem?.courseTestChooseAnwser)?.filter(item => item?.rightFlag)?.map(item => item?.optionName)?.join(' ');
    }
    if (testType === 4) {
      return testItem?.courseTestJudgeAnwser?.rightFlag ? <CheckOutlined style={{ transform: 'translateY(-4px)' }} /> : <CloseOutlined style={{ transform: 'translateY(-4px)' }} />;
    }
    if (testType === 3 || testType === 5) {
      let str = '';
      (testItem.courseTestBlankEasyAnwser || []).map((item, index) => {
        if (index === testItem?.courseTestBlankEasyAnwser?.length - 1) {
          str += item?.answerText;
        } else {
          str += item?.answerText + '、';
        }
      });
      return renderHTML(divideMathText(str));
    }
  }

  renderTestQuestions = (testList) => {
    let { currentTestId, testBasketList } = this.state;
    let { selectedDate } = this.props;
    if (!testList?.length) {
      return <div className={styles.empty}><Empty title="暂无试题" /></div>;
    }
    return testList?.map((item, index) => (<div key={index} className={`${styles.testItem} ${currentTestId === item?.id ? styles.active : ''}`} onClick={() => {
      this.setState({
        currentTestId: item?.id
      });
    }}>
      <div className={styles.stem}>
        <div className={styles.stemL}>
          {index + 1 + '.'}
        </div>
        <div className={styles.stemR}>
          {renderHTML(divideMathText(item?.stem))}
        </div>
      </div>
      {
        item?.courseTestChooseAnwser &&
        <>
          {
            (item?.courseTestChooseAnwser || []).sort(function (a, b) {
              return (a.optionName + "").localeCompare(
                b.optionName + ""
              );
            }).map((itm, index) => (
              <div className={styles.answer_vo}
                key={index}>
                <span className={`${styles.option_letter}`} >
                  {itm.optionName + '.'}
                </span>
                <div className={styles.answer_content}>
                  {renderHTML(this.optionRender(itm.optionValue))}
                </div>
              </div>
            ))}
        </>
      }
      {
        item?.testType === 4 && <>
          <div className={styles.answer_vo}
            key={index}>
            <span className={`${styles.option_letter}`} >
              A.
            </span>
            <div className={styles.answer_content}>正确</div>
          </div>
          <div className={styles.answer_vo}
            key={index}>
            <span className={`${styles.option_letter}`} >
              B.
            </span>
            <div className={styles.answer_content}>错误</div>
          </div>
        </>
      }
      <div className={styles.showAnalysisBtnContainer}>
        <div className={styles.showAnalysisBtn} onClick={() => {
          this.changeIsShowAnswer(item);
        }}>
          {/* <img className={styles.showAnalysisBtnImg} src={imgUrlForZhongZhi("showAnalysisBtnImg.png")} alt="" /> */}
          <div className={styles.showAnalysisBtnContent}>
            <span>解析</span>
            {
              item?.isShowAnswer ?
                <img src={imgUrlForZhongZhi("showAnalysisBtnContentImgUp.png")} alt="" />
                :
                <img src={imgUrlForZhongZhi("showAnalysisBtnContentImg.png")} alt="" />
            }
          </div>
        </div>
        <div className={`${styles.showAnalysisBtn} ${styles.showAnalysisBtn1}`} onClick={() => {
          if (selectedDate?.find(i => i?.id === item?.id)) {
            return;
          }
          if (testBasketList?.find(i => i?.id === item?.id)) {
            this.setState({
              testBasketList: testBasketList?.filter(ii => ii.id !== item?.id)
            });
          } else {
            this.setState({
              testBasketList: [...testBasketList, item]
            });
          }
        }}>
          <div className={`${styles.showAnalysisBtnContent} ${selectedDate?.find(i => i?.id === item?.id) ? styles.showAnalysisBtnContent2 : testBasketList?.find(i => i?.id === item?.id) ? styles.showAnalysisBtnContent3 : styles.showAnalysisBtnContent1}`}>
            <span>{selectedDate?.find(i => i?.id === item?.id) ? '已选' : testBasketList?.find(i => i?.id === item?.id) ? '移除' : '选入'}</span>
          </div>
        </div>
      </div>
      <div className={styles.analysisContainer} style={{ display: item?.isShowAnswer ? 'block' : 'none' }}>
        <div className={styles.analysisContainerT}>
          <span>【答案】
            {this.renderAnswersText(item, item?.testType)}
          </span>
        </div>
        <div className={styles.analysisContainerB}>
          <span className={styles.analysisLeft}>【解析】</span>
          <div className={styles.analysisRight}>
            {renderHTML(divideMathText(item?.resolution || "暂无解析"))}
          </div>
        </div>
      </div>
    </div>));
  }

  changeIsShowAnswer = item => {
    let { activeFlagList } = this.state;
    this.setState({
      activeFlagList: activeFlagList?.map(i => {
        if (i?.id === item?.id) {
          return {
            ...i, isShowAnswer: !i?.isShowAnswer
          };
        }
        return i;
      })
    });
  }

  getExamTestList = () => {
    axios.get(getExamTestList, {
      params: {
        examId: '1'
      }
    }).then(res => {
      if (!res) return;
      this.setState({
        activeFlagList: res?.testList || [],
      });
    });
  }

  render() {
    let { type, courseTypeListData, subjectListData, currentSubjectValue, majorCategoriesList, currentMajorCategoriesId, questionTypeList, currentTestType, difficultyList, currentDifficulty, activeFlagList, testBasketList } = this.state;
    const { current, pageSize, total } = this.state.paginationList;
    return (
      <div className={styles.topicSelectionOfQuestionBankWraper}>
        <div className={styles.numberWraper}>
          <img src={imgUrlForZhongZhi('topicSelectionOfQuestionNumber.png')} alt="" />
          <div className={styles.number}>{testBasketList?.length || 0}</div>
          <div className={styles.text}>试</div>
          <div className={styles.text}>题</div>
          <div className={styles.text}>篮</div>
        </div>
        <div className={styles.topicSelectionOfQuestionBankContainer} ref={this.contentRef}>
          <p className={styles.title}>题库选题</p>
          <img className={styles.close} onClick={() => {
            this.props.onClose();
          }} src={imgUrlForZhongZhi('topicSelectionOfQuestionBankContainerClose.png')} alt="" />
          <div className={styles.header}>
            <div className={styles.nav}>
              <span className={styles.navName}>类型</span>
              <div className={styles.navItem}>
                {
                  courseTypeListData?.map((item, index) => (<span key={index} className={type === item?.value ? styles.active : ''} onClick={() => {
                    this.setState({
                      type: item?.value,
                      currentSubjectValue: '0',
                      currentCollegesId: 0,
                      currentMajorCategoriesId: 0,
                      paginationList: {
                        ...this.state.paginationList,
                        current: 1
                      },
                    }, () => {
                      this.queryCollegeMajors();
                      this.getCourseListBySchool();
                    });
                  }}>{item?.title}</span>))
                }
              </div>
            </div>
            {
              type === 1 ?
                <div className={styles.nav}>
                  <span className={styles.navName}>学科</span>
                  <div className={styles.navItem}>
                    <span className={currentSubjectValue == '0' ? styles.active : ''} onClick={this.changeSubject.bind(this, '0')}>全部</span>
                    {
                      subjectListData?.map((item, index) => (<span className={currentSubjectValue === item?.value ? styles.active : ''} key={index} onClick={this.changeSubject.bind(this, item?.value)}>{item?.title}</span>))
                    }
                  </div>
                </div>
                :
                <>
                  <div className={styles.nav}>
                    <span className={styles.navName}>专业</span>
                    <div className={styles.navItem}>
                      {
                        majorCategoriesList?.length > 0 && <span onClick={this.changeMajorCategories.bind(this, 0)} className={currentMajorCategoriesId == 0 ? styles.active : ''}>全部</span>
                      }
                      {
                        majorCategoriesList?.map((item, index) => (<span onClick={this.changeMajorCategories.bind(this, item?.id)} key={index} className={currentMajorCategoriesId === item?.id ? styles.active : ''}>{item?.majorName}</span>))
                      }
                      {/* {
                        majorCategoriesList?.map((item, index) => (<span onClick={this.changeMajorCategories.bind(this, item?.id)} key={index} className={currentMajorCategoriesId === item?.id ? styles.active : ''}>{item?.majorCategory}</span>))
                      } */}
                    </div>
                  </div>
                </>
            }
            <div className={styles.nav}>
              <span className={styles.navName}>题型</span>
              <div className={styles.navItem}>
                {
                  questionTypeList?.map((item, index) => (<span key={index} className={currentTestType === item?.testType ? styles.active : ''} onClick={() => {
                    this.setState({
                      currentTestType: item?.testType,
                      paginationList: {
                        ...this.state.paginationList,
                        current: 1
                      },
                    }, () => {
                      this.getCourseListBySchool();
                    });
                  }}>{item?.testTypeName}</span>))
                }
              </div>
            </div>
            <div className={styles.nav}>
              <span className={styles.navName}>难度</span>
              <div className={styles.navItem}>
                {
                  difficultyList?.map((item, index) => (<span key={index} className={currentDifficulty === item?.value ? styles.active : ''} onClick={() => {
                    this.setState({
                      currentDifficulty: item?.value,
                      paginationList: {
                        ...this.state.paginationList,
                        current: 1
                      },
                    }, () => {
                      this.getCourseListBySchool();
                    });
                  }}>{item?.name}</span>))
                }
              </div>
            </div>
          </div>
          {
            this.renderTestQuestions(activeFlagList)
          }
          <div className={styles.footer}>
            <Paginations
              current={current}
              pageSize={pageSize}
              total={total}
              handleChange={this.onChangeCurPageOrPageSize}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
            />
            <div className={styles.btnContainer}>
              <div className={styles.btnL} onClick={() => {
                // this.setState({
                //   testBasketList: []
                // });
                this.props.onClose();
              }}>取消</div>
              <div className={styles.btnR} onClick={() => {
                if (testBasketList?.length > 0) {
                  this.props.select(testBasketList);
                } else {
                  this.props.onClose();
                }
              }}>确定</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ user }) => ({ user }))(TopicSelectionOfQuestion);
