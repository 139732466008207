import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, Popover, Tooltip, Select, Icon, Input, message } from 'antd';
import { SearchOutlined, createFromIconfontCN } from '@ant-design/icons';
import { imgUrlForZhongZhi } from '@/utils';
import { isFinishCourse, queryPageListClassTeaching, queryPageMemberList, saveOrUpdateClassUser, endCourse, queryPageListStudent, query2CollegeMajors, queryListClassAd, queryColleges, queryCollegeMajors, saveClassTeaching, updateClassName, dissolveClass, studyChart, courseArchiveList } from '@/api';
import Echars from '../../components/RadarEcharts/RadarEcharts';
import Paginations from '@@/Paginations';
import LineChart from './LineChart/index';
import HistoGram from './HistoGram/index';
import StackBarChart from './StackBarChart/index';
import Empty from '@@/Empty';
import { getParam } from '@/utils/util';
import './styles.scss';
import axios from '../../axios';
const { Option } = Select;
const { Search } = Input;
class classLearning extends Component {
  constructor(props) {
    super();
    this.state = {
      CurrentPageSelectData: [],
      courseName: 1,
      classSelect: 0,
      isModal: false,
      isDissolveClassModal: false,
      isEndCourseModal: false,
      classList: [],
      data: {
        x: [20, 50, 80, 70, 45, 85],
        y: ['1月', '2月', '3月', '4月', '5月', '6月']
      },
      dataBroken: {},//折线数据
      paginationList: {
        current: 1,
        pageSize: 10,
        pages: 0,
        total: 20,
      },
      uid: '',
      schoolId: '',
      courseId: '',
      selectStudentList: [],//学生选择
      createClassName: '',
      modalNumber: 0,
      datastack: {
        x: { a: [20, 50, 80, 70, 45, 85], b: [20, 50, 80, 70, 45, 85], c: [20, 50, 80, 70, 45, 85] },
        y: ['1月', '2月', '3月', '4月', '5月', '6月']
      },
      applyTitle: [
        '课时名称', '课时学习情况', '任务点完成情况', '作业完成情况', '作业统计(优/良/差)'
      ],
      applyList: [
        { a: '哈哈哈哈哈哈好 哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
        { a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈' },
      ],
      diCity: [],
      applyTitlenumber: {
        a: '哈哈哈', b: '哈哈哈', c: '哈哈哈', d: '哈哈哈', e: '哈哈哈'
      },
      provinceData: ['Zhejiang', 'Jiangsu'],
      cityData: {
        Zhejiang: ['Hangzhou', 'Ningbo'],
        Jiangsu: ['Nanjing', 'Suzhou'],
      },
      diData: {
        Hangzhou: ['Hangzhou', 'Ningbo', 'Wenzhou'],
        Ningbo: ['Nanjing', 'Suzhou', 'Zhenjiang'],
        Nanjing: ['Nanjing', 'Suzhou', 'Zhenjiang'],
        Suzhou: ['Nanjing', 'Suzhou', 'Zhenjiang'],
      },
      modalClassName: '',
      selectAllOld: [],           // 所有选中的学生id
      addAry: [],                 // 新增的学生id
      delAry: [],                 // 删除的学生id
      showAddClass: true
    };
  }
  componentWillUnmount() {
  }

  componentDidMount() {
    const { cityData, provinceData, diData } = this.state;
    // if (getParam()?.isjieke == '1') {
    //   this.setState({
    //     showAddClass: false
    //   })
    // }
    this.setState({
      courseId: getParam()?.id,
      uid: this.props.user.uid,
      schoolId: this.props.user.schoolId,
    })
    // this.altRows('alterdetails')
    // this.setState({
    //   cities: cityData[provinceData[0]],
    //   secondCity: cityData[provinceData[0]][0],
    //   diCity: diData[cityData[provinceData[0]][0]],
    //   diCitySelect: diData[cityData[provinceData[0]][0]][0]
    // })
    // this.query2CollegeMajors(this.props.user.schoolId)
    this.queryColleges()
    // this.queryPageListClassTeaching('1642eef1194b063a4afebdf5039838ec', 0, 1, 1)
    this.queryPageListClassTeaching(getParam()?.id, 0, this.props.user.schoolId, this.props.user.uid)
    this.isFinishCourse(getParam()?.id)
  }
  componentWillUnmount() {
    // this.ChangeParam('isjieke', '0')
  }
  // 院系
  queryColleges = () => {
    let schoolId = this.props.user?.schoolId;
    if (!schoolId) return;
    axios.post(queryColleges, {
      schoolId: this.props.user?.schoolId
    }).then(res => {
      if (!res) return;
      let provinceData = res
      provinceData.unshift({ collegeName: '全部院系', id: 0 })
      this.setState({
        provinceData,
        currentCollegesId: provinceData[0]?.id
      }, () => {
        this.queryCollegeMajors();
      });
    });
  }

  // 专业
  queryCollegeMajors = () => {
    let collegeId = this.state.currentCollegesId;
    // if (!collegeId) return;
    axios.post(queryCollegeMajors, {
      collegeId,
      schoolId: this.props.user?.schoolId
    }).then(res => {
      if (!res) return;
      let cities = res
      cities.unshift({ majorName: '全部专业' })
      console.log(res);
      this.setState({
        cities,
        collegeId: cities[0]?.collegeId,
        majorId: cities[0]?.id,
        secondCity: cities[0]?.majorName,
        // currentMajorCategoriesId: res[0]?.id
        // currentMajorCategoriesId: 0
      }, () => {

        this.queryListClassAd(collegeId, this.state.majorId, this.state.schoolId)
      });
    });
  }
  // 学校专业级联

  query2CollegeMajors = async (schoolId) => {
    const param = {
      schoolId
    }
    const res = await axios.post(query2CollegeMajors, param);

    let provinceData = res

    // provinceData.unshift({ collegeName: '全部院系', collegeId: '', schoolId: 127, majors: [{ majorId: '', majorName: '全部专业', schoolId: 127 }] })
    this.setState({
      provinceData,
      cities: provinceData[0].majors,
      secondCity: provinceData[0].majors[0].majorName,
      collegeId: provinceData[0].collegeId,
      majorId: provinceData[0].majors[0].majorId,
      schoolId: provinceData[0].majors[0].schoolId,
    }, () => {
      console.log(this.state.collegeId, this.state.majorId, this.state.schoolId);
      this.queryListClassAd(this.state.collegeId, this.state.majorId, this.state.schoolId)
    })
  }

  // 查询行政班
  queryListClassAd = async (collegeId, majorId, schoolId,) => {
    const param = {
      collegeId,
      hasFinish: 0,
      schoolId,
      majorId
    }
    const res = await axios.get(queryListClassAd, { params: param });
    let diCity = res
    diCity.unshift({ className: '全部班级', id: '' })
    this.setState({
      diCity,
      diCitySelect: diCity[0]?.className,
      xzclassId: diCity[0]?.id
    })
  }

  // 修改班级名
  updateClassName = async (classId, className) => {
    const { schoolId, uid, courseId } = this.state
    const param = {
      classId,
      className
    }
    console.log(param);
    const res = await axios.post(updateClassName, param);

    this.queryPageListClassTeaching(courseId, 0, schoolId, uid)
    console.log(res);
  }
  confimDissolveClass = (id, name) => {
    this.setState({
      classId: id,
      modalClassName: name
    }, () => {
      this.handleDissolveChange()
    })
  }
 
  // 学情趋势
  studyChart = async (classId) => {
    let { id } = getParam();
    const param = {
      classId,
      courseId: id
    };
    console.log(param);
    const res = await axios.post(studyChart, param);
    console.log(res);
    this.setState({
      dataBroken: res,
      // classId
    })

    console.log('dataBroken', res)
  }
  
  // 结课
  endCourse = async () => {
    // console.log(444);
    // return
    let {courseId} = this.state;
    const param = {
      courseId
    }
    const res = await axios.get(endCourse, { params: param });
    if (!res) return;
    message.success('结课成功,正在跳转教学空间...');
    this.handleEndCourseChange();
    this.isFinishCourse(courseId)
    this.setState({ dataBroken: {}, showAddClass: false })
    console.log(res);
    this.queryPageListClassTeaching(getParam()?.id, 0, this.props.user.schoolId, this.props.user.uid)
    setTimeout(()=>{
      this.props.history.replace('/teacher')
    },3000)
  }
  ChangeParam(name, value) {
    var url = window.location.href;
    var newUrl = "";
    var reg = new RegExp("(^|)" + name + "=([^&]*)(|$)");
    var tmp = name + "=" + value;
    if (url.match(reg) != null) {
      newUrl = url.replace(eval(reg), tmp);
    }
    else {
      if (url.match("[\?]")) {
        newUrl = url + "&" + tmp;
      }
      else {
        newUrl = url + "?" + tmp;
      }
    }
    window.location.href = newUrl;
  }
  //查询班级是否结课
  isFinishCourse = async (courseId) => {
    const res = await axios.get(isFinishCourse, { params: { courseId: courseId } });
    this.setState({
      showAddClass: !res.result
    })
    console.log('resjieke00000000000000000000', res.result)
  }
  // 查询教学班
  queryPageListClassTeaching = async (courseId, hasFinish = 0, schoolId, teaId) => {
    const param = {
      courseId,
      hasFinish,
      schoolId,
      teaId
    }
    console.log(param);
    const res = await axios.get(queryPageListClassTeaching, { params: param });
    console.log(res);
    if (hasFinish == 0) {
      res.records[0]?.id && this.studyChart(res.records[0]?.id)
      this.setState({
        applyTitle: ['序号', '学号', '姓名', '专业', '班级'],
        classList: res.records,
        classId: res.records[0]?.id,
        className: res.records[0]?.className,
        classCnt: res.records[0]?.classCnt || 0
      })
    } else {
      this.setState({
        applyTitle: ['序号', '学号', '姓名', '专业', '班级'],
        classListEnd: res.records,
        classId: res.records[0]?.id,
      })
    }

  }
  // 查看结课班级
  courseArchiveList = async (courseId) => {
    console.log(666666666666666666);
    const param = {
      courseId
    }
    const res = await axios.get(courseArchiveList, { params: param });
    console.log(res instanceof Array, "classListEnd");
    // if (!res.result) {
    //   return
    // }
    console.log(99999);
    this.setState({
      applyTitle: ['序号', '学号', '姓名', '专业', '班级'],
      classListEnd: res instanceof Array ? res : '',
      // classId:res.records[0]?.id,
    })
  }

  // 查询教学班学生
  queryPageMemberList = async (pageNum = 1, pageSize = 10, classId = '', keyWords = '') => {
    const param = {
      classId,
      pageNum,
      pageSize,
      keyWords
    }
    const res = await axios.get(queryPageMemberList, { params: param });
    const { current, size, total, records, pages } = res;
    console.log(res);
    const paginationList = {
      current,
      pageSize: size,
      pages,
      total,
    }
    console.log(paginationList);
    this.setState({
      paginationList,
      applyList: records
    })
  }
  // 查询教学班学生(所有)
  queryPageMemberListAll = async (classId = '') => {
    const param = {
      classId,
      pageNum: 1,
      pageSize: 100000,
    };
    const res = await axios.get(queryPageMemberList, { params: param });
    const { records } = res;
    this.setState({
      selectAllOld: records?.map(item => item?.userId)
    });
  }
  // 查询行政班学生
  queryPageListStudent = async (pageNum = 1, pageSize = 10, showClassId = '', schoolId = '', collegeId = '', majorId = '', classId = '', keywords = '') => {
 
    const param = {
      schoolId,
      collegeId,
      majorId,
      classId,
      // excludeClassId,
      pageNum,
      pageSize,
      // classType: 1, //bug-5658修改
      keywords: this.state.searchVal || keywords,
      showClassId,
      classType: 1
    }
    const res = await axios.get(queryPageListStudent, { params: param });
    const { current, size, total, records, pages } = res;
    console.log(res);
    const paginationList = {
      current,
      pageSize: size,
      pages,
      total,
    }
    let selectStudentList = this.state.selectStudentList
    let selectStudentListOld = this.state.selectStudentListOld || [];
    for (let index = 0; index < records.length; index++) {
      const element = records[index]?.isSelect;
      console.log(element);
      if (element) {
        selectStudentList.push(records[index]?.userId)
        selectStudentListOld.push(records[index]?.userId)
      }
    }
    // console.log("selectStudentList2", selectStudentList);
    // console.log("selectStudentListOld2", selectStudentListOld);
    this.setState({
      paginationList,
      applyList: records,
      selectStudentList,
      selectStudentListOld: Array.from(new Set(selectStudentListOld))
    }, () => {
      const CurrentPageSelectData = [];
      this.state.applyList && this.state.applyList.map((val, index) => {
        if (this.state.selectStudentList.indexOf(val?.userId) != -1) {
          CurrentPageSelectData.push(val?.userId)
        }
      })
      this.setState({ CurrentPageSelectData }, () => {
        console.log('CurrentPageSelectData-------------------------', this.state.CurrentPageSelectData, this.state.selectStudentListOld)
      })
    })
  }

  // 保存学生到班级
  saveOrUpdateClassUser = async (classId, userIdList, flag) => {
    const { schoolId, uid, courseId } = this.state
    const param = {
      classId,
      classType: 2,
      flag,
      userIdList
    }
    const res = await axios.post(saveOrUpdateClassUser, param);
    this.queryPageListClassTeaching(courseId, 0, schoolId, uid);
  }
  // 创建班级
  saveClassTeaching = async (className, userIdList, courseId = '04d79035dc4d5631ea47a89760646eca', schoolId = 1, teaId = 1, courseName) => {
    const param = {
      courseId,
      classType: 2,
      className,
      courseName,
      teaId,
      userIdList,
      schoolId,
    }
    console.log(param);
    const res = await axios.post(saveClassTeaching, param);
    this.queryPageListClassTeaching(courseId, 0, schoolId, teaId)
    if (res.code == 0) {
      this.handleOk()
    }
    console.log(res);
  }

  // 保存学生设置
  saveStudentCon = () => {
    const { selectStudentListOld, selectStudentList, addAry, delAry } = this.state;
    // let delStudentData = this.studentListSelect(selectStudentListOld, selectStudentList)//减
    // let addStudentData = this.studentListSelect(selectStudentList, selectStudentListOld)//加
    console.log(addAry, delAry);
    // return
    this.addUser(addAry);
    this.delUser(delAry);
    this.handleOk();
  }
  // 筛选
  studentListSelect = (arr1, arr2) => {
    let c = []
    for (let index = 0; index < arr1.length; index++) {
      const element = arr1[index];
      if (arr2.indexOf(element) == -1) {
        c.push(element)
      }
    }
    return c
  }
  // 保存学生
  addUser = (valarr) => {
    const { classId } = this.state;
    if (valarr.length == 0) {
      return;
    }
    this.saveOrUpdateClassUser(classId, valarr, 1);
  }

  // 删除学生
  delUser = (valarr) => {
    const { classId } = this.state;
    if (valarr.length == 0) {
      return
    }
    this.saveOrUpdateClassUser(classId, valarr, 2)
  }

  goRecord = (modalNumber, id) => {
    console.log(modalNumber);
    this.queryPageMemberListAll(id);
    this.setState({
      applyList: [],
      createClassName: ''
    })
    if (modalNumber == 0 || modalNumber == 1 || modalNumber == 3 || modalNumber == 4) {
      this.setState({
        applyTitlenumber: {
          a: '序号', b: '学号', c: '姓名', d: '专业', e: '班级'
        },
      })
      if (modalNumber == 0 || modalNumber == 4) {
        this.queryPageMemberList(1, 10, id)
      } else if (modalNumber == 1 || modalNumber == 3) {
        this.queryPageListStudent(1, 10, id, this.state.schoolId)
      }

    } else {

    }
    this.setState({
      isModal: !this.state.isModal,
      modalNumber,
      classId: id
    }, () => {

      // this.altRows('alternatecolor')
    })
  }
  handleOk = () => {

    this.setState({
      isModal: !this.state.isModal,
      selectStudentListOld: [],
      selectStudentList: [],
      selectAllOld: [],
      addAry: [],
      delAry: [],
    }, () => {
      if (this.state.modalNumber == 1 || this.state.modalNumber == 3) {
        this.handleProvinceChange('0', { key: '0', value: '0', children: '全部院系' }, false)
        console.log(this.state.selectStudentList);
        console.log(this.state.selectStudentListOld);
      }
    });
  }
  handleDissolveChange = () => {
    this.setState({
      isDissolveClassModal: !this.state.isDissolveClassModal
    });
  }
  handleEndCourseChange = () => {
    this.setState({
      isEndCourseModal: !this.state.isEndCourseModal
    });
  }
  handleDissolveOk = async () => {
    // 删除班级
    const { schoolId, uid, courseId, classId, changeClassName } = this.state
    const param = {
      classId
    }
    console.log(param);
    const res = await axios.post(dissolveClass, param);
    this.queryPageListClassTeaching(courseId, 0, schoolId, uid)
    console.log(res);

    this.handleDissolveChange()
  }

  //授课切换
  cutSetCourse = (title) => {
    const { schoolId, uid } = this.state
    if (title == 1) {
      this.setState({
        courseName: 1
      })
    } else {
      this.setState({
        courseName: 2
      })
      this.courseArchiveList(this.state.courseId)
      // this.queryPageListClassTeaching(this.state.courseId, 1, schoolId, uid)
    }
  }
  // 分页器 动态获取到页码
  onChangeCurPageOrPageSize = (current, pageSize) => {
    console.log(current, pageSize);
    const paginationList = {
      ...this.state.paginationList,
      current,
      pageSize
    }
    console.log(paginationList);
    const { schoolId, collegeId, xzclassId, majorId, classId, modalNumber } = this.state;
    console.log(modalNumber, 'modalNumber');
    if (modalNumber == 0 || modalNumber == 4) {
      this.queryPageMemberList(current, pageSize, classId)
    } else if (modalNumber == 1 || modalNumber == 3) {
      this.setState({ paginationList }, () => { this.queryPageListStudent(current, pageSize, classId, schoolId, collegeId, majorId, xzclassId); });
    }
  }
  cutClass = (index, id, className, classCnt) => {
    console.log(index);
    this.studyChart(id)
    this.setState({
      classSelect: index,
      classId: id,
      className,
      classCnt
    })
  }

  altRows(id) {
    if (document.getElementsByTagName) {
      var table = document.getElementById(id);
      var rows = table.getElementsByTagName("tr");

      for (let i = 0; i < rows.length; i++) {
        if (i % 2 == 0) {
          rows[i].className = "evenrowcolor";
        } else {
          rows[i].className = "oddrowcolor";
        }
      }
    }
  }

  handleProvinceChange = (value, option, type = true) => {
    const { provinceData, cityData, diData } = this.state;
    console.log(provinceData, value, option);

    // provinceData:res,
    // cities:res[0].majors,
    // secondCity:res[0].majors[0].majorName,
    // if (value==0) {
    //   this.setState({
    //     cities: [],
    //     secondCity: '',
    //     collegeId: '',
    //     majorId: [],
    //     schoolId: '',
    //     diCitySelect: '',
    //     diCity: []
    //   })
    //   return
    // }
    this.setState({
      currentCollegesId: provinceData[value].id,
      collegeId: provinceData[value].id,
      majorId: ''
      // cities: provinceData[value].majors,
      // secondCity: provinceData[value].majors[0].majorName,
      // collegeId: provinceData[value].collegeId,
      // majorId: provinceData[value].majors[0].majorId,
      // schoolId: provinceData[value].majors[0].schoolId,
      // diCity: diData[cityData[value][0]],
      // diCitySelect: diData[cityData[value][0]][0]
    }, () => {
      this.queryCollegeMajors()
      if (type) {
        const { schoolId, collegeId, xzclassId, majorId, classId } = this.state;
        this.queryPageListStudent(1, 10, classId, schoolId, collegeId)
      }
    })
  };

  onSecondCityChange = (value, option) => {
    const { cities, cityData, diData, classId } = this.state;
    console.log(cities, value, option);
    this.setState({
      collegeId: cities[value]?.collegeId,
      majorId: cities[value]?.id,
      secondCity: cities[value]?.majorName,
      // secondCity: option.children,
      // collegeId: cities[value].collegeId,
      // majorId: cities[value].majorId,
      // schoolId: cities[value].schoolId,
      // diCitySelect: cities[value].majorName
      // diCity: diData[value],
      // diCitySelect: diData[value][0]
    }, () => {
      const { schoolId, collegeId, xzclassId, majorId } = this.state;
      this.queryListClassAd(this.state.currentCollegesId, this.state.majorId, this.state.schoolId)
      this.queryPageListStudent(1, 10, classId, schoolId, collegeId, majorId)
    })
  };

  onSecondDiCityChange = (value, option) => {
    const { diCity, classId } = this.state;
    console.log(diCity, value, option);
    this.setState({
      diCitySelect: option.children,
      collegeId: diCity[value].collegeId,
      // majorId: diCity[value].majorId,
      // schoolId: diCity[value].schoolId,
      xzclassId: diCity[value].id
    }, () => {
      const { schoolId, collegeId, xzclassId, majorId } = this.state;
      console.log(schoolId, collegeId, majorId, xzclassId);
      // this.queryListClassAd(this.state.collegeId,this.state.majorId,this.state.schoolId)
      this.queryPageListStudent(1, 10, classId, schoolId, collegeId, majorId, xzclassId)
    })
  }

  // 学生选择
  selectStudent = (val) => {
    console.log('------------')
    const { selectStudentList, applyList, modalNumber, CurrentPageSelectData } = this.state
    console.log(val);

    let arr = selectStudentList
    let allarr = []
    for (let index = 0; index < applyList.length; index++) {
      const element = applyList[index].userId;
      allarr.push(element)
    }
    if (val == 'all') {
      console.log(222222222222);
      console.log(CurrentPageSelectData, applyList);
      // debugger
      if (CurrentPageSelectData.length == applyList.length) { //全选
        // this.delUser(selectStudentList)
        var temp = []; //临时数组1
        var temparray = [];//临时数组2
        for (var i = 0; i < CurrentPageSelectData.length; i++) {
          temp[CurrentPageSelectData[i]] = true;
        };
        for (var i = 0; i < selectStudentList.length; i++) {
          if (!temp[selectStudentList[i]]) {
            temparray.push(selectStudentList[i]);
          };
        };
        console.log('去重复', temparray)
        this.setState({
          selectStudentList: temparray,
          CurrentPageSelectData: []
        }, () => {
          this.changeStudentListAll();
        });
      } else { //反选
        // this.addUser(allarr)
        console.log(selectStudentList.concat(allarr), Array.from(new Set(selectStudentList.concat(allarr))));
        this.setState({
          selectStudentList: Array.from(new Set(selectStudentList.concat(allarr))),
          CurrentPageSelectData: allarr
        }, () => {
          this.changeStudentListAll();
        });
      }
    } else {
      // let valarr = []
      if (modalNumber == 1) {
        // if (selectStudentList?.find(item => { return item == val }) == undefined) {
        //   valarr.push(val)
        //   console.log(val);
        //   // this.addUser(valarr)
        // } else {
        //   valarr.push(val)
        //   // this.delUser(valarr)
        // }

      }
      this.setState(({ selectStudentList }) => ({
        selectStudentList: selectStudentList?.find(item => { return item == val }) == undefined
          ? [...selectStudentList, val]
          : selectStudentList?.filter(item => item != val),
      }), () => {
        const CurrentPageSelectData = [];
        applyList && applyList.map((val, index) => {
          if (this.state.selectStudentList.indexOf(val?.userId) != -1) {
            CurrentPageSelectData.push(val?.userId)
          }
        })
        this.setState({ CurrentPageSelectData }, () => {
          this.changeStudentList(val);
        })
        console.log('CurrentPageSelectData-------------------------selectStudentList', CurrentPageSelectData, selectStudentList)
      })
    }
  }

  // 全选时修改新增，删除数组
  changeStudentListAll = () => {
    let { applyList = [] } = this.state;
    applyList.forEach(item => {
      setTimeout(() => {
        this.changeStudentList(item?.userId);
      }, 0);
    });
  }

  // 修改新增，删除数组
  changeStudentList = (val) => {
    let { CurrentPageSelectData, selectAllOld, addAry, delAry } = this.state;
    let newAddAry = [...addAry];
    let newDelAry = [...delAry];
    if (CurrentPageSelectData?.includes(val)) {
      if (selectAllOld?.includes(val)) {
        newAddAry = newAddAry?.filter(item => item !== val);
        newDelAry = newDelAry?.filter(item => item !== val);
      } else {
        newDelAry = newDelAry?.filter(item => item !== val);
        newAddAry = newAddAry?.filter(item => item !== val);
        newAddAry.push(val);
      }
    } else {
      if (selectAllOld?.includes(val)) {
        newAddAry = newAddAry?.filter(item => item !== val);
        newDelAry = newDelAry?.filter(item => item !== val);
        newDelAry.push(val);
      } else {
        newDelAry = newDelAry?.filter(item => item !== val);
        newAddAry = newAddAry?.filter(item => item !== val);
      }
    }
    this.setState({
      addAry: newAddAry,
      delAry: newDelAry
    });
  }

  //创建班级
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({
      createClassName: event.target.value
    })
  }
  // 查询班级学生输入
  handleChange1 = (event) => {
    console.log(event.target.value);
    const { classId } = this.state

    this.queryPageMemberList(1, 10, classId, event.target.value)
    console.log(event.target.value);
  }
  createClass = () => {

    const { classId, selectStudentList, createClassName, schoolId, uid, courseId } = this.state
    if (createClassName == '') {
      console.log('请输入班级名称');
      message.error('请输入班级名称');
      return
    }
    // let courseId='04d79035dc4d5631ea47a89760646eca'
    console.log(createClassName);
    this.saveClassTeaching(createClassName, selectStudentList, courseId, schoolId, uid, getParam().isJiaoXueKongJianPage)
  }
  // 输入
  onSearch = (value) => {
    const { schoolId, collegeId, xzclassId, majorId, classId, modalNumber } = this.state;
    console.log(value);
    this.setState({
      searchVal: value
    });
    this.queryPageListStudent(1, 10, classId, schoolId, collegeId, majorId, xzclassId, value);
  }
  //弹窗
  handleClass = () => {
    this.setState({
      isOthorModal: !this.state.isOthorModal,
    })
  }
  //修改名称
  changeClassname = (id, name) => {
    console.log(id, name)
    this.setState({
      classId: id,
      changeClassName: name,
      modalClassName: name
    }, () => {
      this.handleClass()
    })
  }
  // 修改名称
  handleChangeClass = (event) => {
    this.setState({
      modalClassName: event.target.value
    })
  }

  // 提交修改名称
  inputChangeName = () => {
    const { modalClassName, classId } = this.state;
    if (!modalClassName) {
      message.destroy();
      message.warn('请输入班级名称');
      return
    }
    this.updateClassName(classId, modalClassName)
    this.handleClass()
  }

  //弹窗1
  studentList1 = (modalIndex) => {
    // console.log(modalIndex);
    const { CurrentPageSelectData, applyTitle, applyList, provinceData, cities, secondCity, diCity, diCitySelect, modalNumber, applyTitlenumber, selectStudentList, className } = this.state;
    const { current, pageSize, pages, total } = this.state.paginationList;
    return (
      <div className="studentlist">
        {
          modalIndex == 1 ?
            <div className="studentlist_top">
              <h1>学生管理</h1>
              <div className='studentlist_right'>
                {/* <span className='newcourse' onClick={this.addUser}>
                  <img src={imgUrlForZhongZhi('icon-add.png')} alt="" />
                  添加学生
                </span> */}
                {/* <span className='record' onClick={this.delUser}>删除学生</span> */}
              </div>
            </div>
            :
            modalIndex == 3 ?
              <div className='creatclass'>
                <h1>创建班级</h1>
                <div className='creatclass_titlename'>
                  <div className='creatclass_titlename_con'>
                    班级名称
                    <div></div>
                  </div>
                  <div className='titlename_input'><Input placeholder="请输入班级名称（限15字以内）" maxLength={'15'} bordered={false} onChange={this.handleChange} /></div>

                </div>
                <div className='creatclass_titlestudent'>
                  <div className='creatclass_titlestudent_con'>
                    选择学生
                    <div></div>
                  </div>
                </div>
              </div>
              :
              modalIndex == 4 ?
                <div className='classStudent'>
                  <h1>班级学生</h1>

                  <Input placeholder="请输入学号或姓名" bordered={false} onChange={this.handleChange1} />
                </div>
                :
                <h1>1111111</h1>
        }

        {/* secondCity diCitySelect */}
        <div className={modalIndex == 1 ? 'pd-20 con-list' : modalIndex == 3 ? 'no-border con-list' : 'con-list'}>
          {
            modalIndex == 1 || modalIndex == 3 ?
              <div className='con-list_select'>
                <Select style={{ width: "18%" }} defaultValue={provinceData[0]?.collegeName} onChange={this.handleProvinceChange}>
                  {provinceData.map((province, index) => (
                    <Option key={index}>{province?.collegeName}</Option>
                  ))}
                </Select>
                <Select style={{ width: "18%" }} value={secondCity} defaultValue={cities && cities[0]?.majorName} onChange={this.onSecondCityChange}>
                  {cities && cities.map((city, index) => (
                    <Option key={index}>{city?.majorName}</Option>
                  ))}
                </Select>
                <Select style={{ width: '18%' }} value={diCitySelect} onChange={this.onSecondDiCityChange}>
                  {diCity && diCity.map((di, index) => (
                    <Option key={index}>{di?.className}</Option>
                  ))}
                </Select>
                <Search placeholder="请输入学生姓名关键字" onSearch={this.onSearch} style={{ width: '40%' }} />
              </div>
              :
              modalIndex == 4 ?
                ''
                :
                ''
          }

          {
            applyList?.length > 0 ?
              <table className="altrowstable" id="alternatecolor">
                <thead>
                  <tr>
                    {/* {applyTitle && applyTitle.map((val, index) => {
                  return (
                    <th key={index}>{val}</th>
                  )
                })} */}
                    <th>
                      <div className='selectxuhaowrap'>
                        <div
                          className={(CurrentPageSelectData.length == applyList.length) ? 'selectxuhao pass' : 'selectxuhao'}
                          onClick={() => this.selectStudent('all')}></div>
                        {applyTitlenumber.a}
                      </div>
                    </th>
                    <th>{applyTitlenumber.b}</th>
                    <th>{applyTitlenumber.c}</th>
                    <th>{applyTitlenumber.d}</th>
                    <th>{applyTitlenumber.e}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    applyList && applyList.map((val, index) => {
                      return (
                        <tr key={index} className={selectStudentList.indexOf(val?.userId) == -1 ? '' : 'selecttr'}>
                          <td>
                            <div className='selectxuhaowrap' onClick={() => this.selectStudent(val?.userId)}>
                              <div
                                className={selectStudentList.indexOf(val?.userId) == -1 ? 'selectxuhao' : 'selectxuhao pass'}
                              ></div>
                              {index + 1}
                            </div>
                          </td>
                          <td>{val?.stuNo}</td>
                          <td>{val?.fullName}</td>
                          <td>{val?.majorName}</td>
                          <td>{val?.className}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>

              </table>
              :
              <div style={{ height: '5rem', position: 'relative' }}>
                <Empty title="暂无学生" />
              </div>
          }

        </div>
        {
          applyList?.length > 0 && <div className='news_footer' id='news_footer'>
            <Paginations
              current={current}
              pageSize={pageSize}
              pages={pages}
              total={total}
              handleChange={this.onChangeCurPageOrPageSize}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
            />
          </div>
        }
        {
          modalIndex == 3 ?
            <div className='input'>
              <button className='input_no' onClick={this.handleOk}>取消</button>
              <button className='input_save' onClick={this.createClass}>创建</button>
            </div>
            :
            ''
        }
        {
          modalIndex == 1 ?
            <div className='input'>
              <button className='input_no' onClick={this.handleOk}>取消</button>
              <button className='input_save' onClick={this.saveStudentCon}>确认</button>
            </div>
            :
            ''
        }

      </div>
    )
  }
  studentList = (modalIndex) => {
    const { applyTitle, applyList } = this.state;
    const { current, pageSize, pages, total } = this.state.paginationList;
    return (
      <div className="studentlist">
        {modalIndex == 4 ?
          <div className='classStudent'>
            <h1>班级学生</h1>
            <Input width={25} placeholder="请输入关键字查询" prefix={<img src={imgUrlForZhongZhi('icon_glass.png')} />} style={{ width: '305px' }} onChange={this.handleChange1} />
          </div>
          :
          <h1>查看成员</h1>}
        <div className="con-list">
          <table className="altrowstable" id="alternatecolor">
            <thead>
              <tr>
                {applyTitle && applyTitle.map((val, index) => {
                  return (
                    <th key={index}>{val}</th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {
                applyList && applyList.map((val, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{val?.stuNo}</td>
                      <td>{val?.fullName}</td>
                      <td>{val?.majorName}</td>
                      <td>{val?.className}</td>
                    </tr>
                  )
                })
              }
            </tbody>

          </table>
        </div>
        <div className='news_footer' id='news_footer'>
          <Paginations
            current={current}
            pageSize={pageSize}
            pages={pages}
            total={total}
            handleChange={this.onChangeCurPageOrPageSize}   //当前页及条数改变都会从子组件触发此方法，用于传递页码和条数
          />
        </div>
      </div>
    )
  }
  render() {
    const { titleName, courseName, classSelect, isOthorModal, data, dataBroken, applyTitle, applyList, isModal, modalNumber, classList, classListEnd, classCnt, className, courseId, modalClassName, classId, isDissolveClassModal, showAddClass,isEndCourseModal } = this.state
    // console.log("classListEnd=>", classListEnd)
    return (
      <div className='pages_class_learning'>
        <div className='class_learning'>
          <div className='class_learning_title'>
            <div className='main_right_top_mycourse'>
              <span className={courseName == 1 ? 'spanselect' : ''} onClick={() => this.cutSetCourse(1)}>授课中</span>
              <span className={courseName == 2 ? 'spanselect' : ''} onClick={() => this.cutSetCourse(2)}>已结课</span>
            </div>
          </div>
          {
            courseName == 1 ?
              <div className='class_main'>
                <div className='main_left'>
                  <div className='main_left_title'>
                    <div className='main_left_titlename'>
                      我的班级
                      <div></div>
                    </div>
                    <div className='main_left_titlejieke' onClick={() => this.handleEndCourseChange()}>
                      <img src={imgUrlForZhongZhi('endke.png')} alt="" />
                      结课
                    </div>
                  </div>
                  <div className='main_left_con'>
                    {
                      classList && classList.map((classdata, index) => {
                        return (
                          <div key={classdata?.id} className={classSelect == index ? 'main_left_con_every main_left_con_everyselect' : 'main_left_con_every'} onClick={() => this.cutClass(index, classdata?.id, classdata?.className, classdata?.classCnt)}>
                            <span>{classdata?.className}</span>
                            <Popover placement="bottom"
                              // trigger="click"
                              content={
                                <div className='popclasscon'>
                                  <p onClick={() => this.goRecord(0, classdata?.id)}>查看成员</p>
                                  <p onClick={() => this.goRecord(1, classdata?.id)}>学生管理</p>
                                  <p onClick={() => this.changeClassname(classdata?.id, classdata?.className)}>修改名称</p>
                                  <p onClick={() => this.confimDissolveClass(classdata?.id, classdata?.className)}>解散班级</p>
                                </div>
                              }>
                              <span className='img_box'><img src={imgUrlForZhongZhi('dianclass.png')} alt="" /></span>
                            </Popover>
                          </div>
                        )
                      })
                    }

                    {/* <div className={classSelect == 1 ? 'main_left_con_every main_left_con_everyselect' : 'main_left_con_every'} onClick={() => this.cutClass(1)}>
                      2021级几点2班
                      <Popover placement="bottom"
                        // trigger="click"
                        content={
                          <div className='popclasscon'>
                            <p>查看成员</p>
                            <p>学生管理</p>
                            <p>修改名称</p>
                            <p>解散班级</p>
                          </div>
                        }>
                        <img src={imgUrlForZhongZhi('dianclass.png')} alt="" />
                      </Popover>
                    </div> */}
                  </div>
                  {/* {
                    showAddClass && */}
                    <div className='establish_class' onClick={() => this.goRecord(3)}>
                      <img src={imgUrlForZhongZhi('establish_class.png')} alt="" />
                      创建班级
                    </div>
                  {/* } */}

                </div>
                <div className='main_right'>
                  {
                    !dataBroken.classHours && !dataBroken.taskPoints ?
                      <Empty title="暂无授课班级" imgUrl="courseNull.png" /> :
                      <Fragment>
                        <div className='main_right_title'>
                          <div className='main_right_titlename'>
                            {className}
                          </div>
                          <div className='main_right_titlestudent' onClick={() => this.goRecord(0, classId)}>
                            学生数量：{classCnt}名
                          </div>
                        </div>
                        <div className='learning_trend'>
                          <h1>学情趋势</h1>
                          <div className='learning_trend_con'>
                            {

                              <>
                                <div className='trend_con'>
                                  {dataBroken.classHours && <LineChart data={dataBroken.classHours} type={1}></LineChart>}
                                </div>
                                <div className='trend_con'>
                                  {dataBroken.taskPoints && <LineChart data={dataBroken.taskPoints} type={2}></LineChart>}
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      </Fragment>
                  }




                </div>
              </div>
              :
              <div className='finish_class'>
                {
                  classListEnd ? Array.isArray(classListEnd) && classListEnd.map((val) => {
                    return (
                      <div className='finish_class_every' onClick={() => this.goRecord(4, val?.id)}>
                        <div className='every'>

                          <h1>{val?.className}</h1>
                          <div className='finish_class_every_bottom'>
                            <div className='finish_left'>
                              <p>{'结课时间'}</p>
                              <p>{val?.finishTime}</p>
                            </div>
                            <div className='finish_right'>
                              <span>{val?.classCnt}</span>名
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }) :
                    <Empty title="暂无已结课内容" />
                }
              </div>
          }

          <Modal
            className="classmodal"
            visible={isModal}
            onCancel={this.handleOk}
            footer={null}
            destroyOnClose
          >
            <div className="checkmodeldictation-con">
              {modalNumber == 0 || modalNumber == 4 ? this.studentList(modalNumber) : this.studentList1(modalNumber)}
            </div>
          </Modal>
          <Modal
            className="class_modeld"
            visible={isDissolveClassModal}
            onCancel={this.handleDissolveChange}
            footer={null}
            destroyOnClose
          >
            <div className="class_modeld-con">
              <div className='class_modeld_title'>
                <span>解散班级</span>
              </div>

              <div className='class_modeld_center'>
                确认解散班级:{modalClassName}?
              </div>
              <div className='class_modeld_button'>
                <button className='class_modeld_buttonno' onClick={this.handleDissolveChange}>取消</button>
                <button onClick={this.handleDissolveOk} className='class_modeld_buttonyes'>确定</button>
              </div>

            </div>
          </Modal>
          <Modal
            className="class_modeld"
            visible={isEndCourseModal}
            onCancel={this.handleEndCourseChange}
            footer={null}
            destroyOnClose
          >
            <div className="class_modeld-con">
              {/* <div className='class_modeld_title'>
                <span>解散班级</span>
              </div> */}

              <div className='class_modeld_center' style={{padding:'0 40px 0'}}>
                所有班级都将进入结课模式，学习行为将不会被统计。请确认是否结课？
              </div>
              <div className='class_modeld_button'>
                <button className='class_modeld_buttonno' onClick={this.handleEndCourseChange}>取消</button>
                <button onClick={this.endCourse} className='class_modeld_buttonyes'>确定</button>
              </div>

            </div>
          </Modal>
          <Modal
            className="class_modeld"
            visible={isOthorModal}
            onCancel={this.handleClass}
            footer={null}
            destroyOnClose
          >
            <div className="class_modeld-con">
              <div className='class_modeld_title'>
                <span>修改名称</span>
              </div>

              <div className='class_modeld_center'>
                <div className='changename_input'>
                  {/* <Input placeholder={modalClassName} value={''} maxLength={'15'} onChange={this.handleChangeClass} /> */}
                  <Input placeholder={modalClassName} maxLength={'15'} onChange={this.handleChangeClass} />
                </div>
              </div>
              <div className='class_modeld_button'>
                <button className='class_modeld_buttonno' onClick={this.handleClass}>取消</button>
                <button onClick={this.inputChangeName} className='class_modeld_buttonyes'>确定</button>
              </div>

            </div>
          </Modal>


        </div>
      </div>
    )
  }
}

export default connect(({ user }) => ({ user }))(classLearning)
