import React, { Component } from "react";
import { Tree, Empty, Checkbox } from "antd";
import "./styles.less";
import {CatalogTitleType} from "../../../../utils/publicType";

const { TreeNode } = Tree;
export default class SelectTreeCmpForSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedKeys: [],
      selectedKeys: [],
      parentKey: [],
      parentData: {
        title: '',
        key: '',
        childTitle: '',
        childKey: '',
      }, // 保存给父级的数据
      checkedId: ''
    };
    this.valueMap = {};
  }

  componentDidMount() {
    // 默认展开
    const { selectTreeData, type, value, expandedKeys } = this.props;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData;
    // const {ary: expandedKeys, ary_ } = this.getChildrenId(data);
    const { ary, ary_ } = this.getChildrenId(data);
    this.loops(data);

    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }

    let newExpandedKeys = expandedKeys || ary;
    if (newExpandedKeys.length !== 0) {
      if (type === 'bourseBefore') {
        this.setState({
          expandedKeys: value,
          parentData,
        })
      } else {
        this.setState({
          expandedKeys: newExpandedKeys,
          parentData,
        });
      }

    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectTreeData, type, value, expandedKeys } = nextProps;
    console.log(selectTreeData,expandedKeys,'expandedKeysexpandedKeysexpandedKeysexpandedKeysexpandedKeysexpandedKeysexpandedKeysexpandedKeysexpandedKeysexpandedKeys')
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData;
    const { ary, ary_ } = this.getChildrenId(data);

    const parentData = {
      ...this.state.parentData,
      title: ary_[0].name,
      key: ary_[0].id,
    }

    if (JSON.stringify(selectTreeData) !== JSON.stringify(this.props.selectTreeData)) {
      if (type === 'bourseBefore') {
        this.setState({
          expandedKeys: value,
          parentData
        })
      } else {
        this.setState({
          expandedKeys: expandedKeys || ary,
          parentData,
        });
      }
    }
  }

  getChildren = (arr = [], ary, ary_) => {
    const first = arr[0];
    const first_ = arr[0];
    ary.push(first?.id);
    ary_.push(first_);
    if (first?.children) {
      this.getChildren(first?.children, ary, ary_)
    }
  }

  getChildrenId(data) {
    const ary = [];
    const ary_ = []
    this.getChildren(data, ary, ary_);
    return {
      ary,
      ary_,
    }
  }

  loops = (list, parent) => {
    return (list || []).map(({ children, id, name }) => {
      const node = (this.valueMap[id] = {
        parent,
        id,
        name,
      });
      node.children = this.loops(children, node);
      return node;
    });
  }

  getPath = (id) => {
    const path = [];
    const path_ = []
    let current = this.valueMap[id];

    while (current) {
      path.unshift(current);
      path_.unshift(current.id);
      current = current.parent;
    }

    return {
      path,
      path_
    }
  }

  //选中的回调
  onSelect = (_, obj) => {
    let { parentData, expandedKeys } = this.state;
    let objPath = this.getPath(obj.node.key);
    const index = expandedKeys.indexOf(obj.node.key);

    if (obj.node.className === CatalogTitleType.treeNodeTitle) {
      const parentData_ = {
        ...parentData,
        title: obj.node.title,
        key: obj.node.key
      }

      if (index !== -1) {
        this.setState({
          expandedKeys: [],
          parentData: parentData_,
        });
      } else {
        this.setState({
          expandedKeys: objPath.path_,
          parentData: parentData_,
        });
      }
    } else if (obj.node.className ===CatalogTitleType.treeChildrenTitle) {
      if (index !== -1) {
        objPath.path_.pop();

        this.setState({
          expandedKeys: objPath.path_,
        });
      } else {
        this.setState({
          expandedKeys: objPath.path_,
        });
      }

    } else {
      const parentData_ = {
        ...parentData,
        childTitle: obj.node.title,
        childKey: obj.node.key,
      };

      this.setState({ parentData: parentData_, expandedKeys: objPath.path_ }, () => {
        if (!this.props.onTreeActive) return;

        this.props.onTreeActive(this.state.parentData, objPath.path_);
      });
    }
  };

  //展开的回调
  onExpend = (_, obj) => {
    const { parentData } = this.state;
    let objPath = this.getPath(obj.node.key);

    if (obj.expanded) {
      if (obj.node.className.match(CatalogTitleType.treeNodeTitle)) {
        const parentData_ = {
          ...parentData,
          title: obj.node.title,
          key: obj.node.key
        };

        this.setState({
          expandedKeys: [obj?.node?.key],
          parentData: parentData_,
        });
      } else if (obj.node.className.match('tree_children_title')) {
        this.setState({ expandedKeys: objPath.path_ });
      }
    } else {
      if (obj.node.className.match(CatalogTitleType.treeNodeTitle)) {
        this.setState({ expandedKeys: [] });
      } else if (obj.node.className.match('tree_children_title')) {
        objPath.path_.pop();
        this.setState({ expandedKeys: objPath.path_ });
      }
    }
  };

  catCurriculum = id => {

  }

  onChange = (checkedId, e) => {
    // if (e.target.checked) {
    //   this.setState({
    //     checkedId
    //   });
    // } else {
    //   this.setState({
    //     checkedId: ''
    //   });
    // }
  }

  onClickCheckBoxSetting = (checkedId, setting, e) => {
    e && e.stopPropagation();
    this.props.upDataSettingTree(checkedId, setting);
  }

  renderTitle = ({ ele, isChildren, isOuterParent }) => {
    return (<div onClick={this.catCurriculum.bind(this, ele?.id)}>
      <Checkbox onClick={this.onClickCheckBoxSetting.bind(this, ele?.id, !(ele?.setting))} checked={ele?.setting} />
      {/* <Checkbox onClick={this.onClickCheckBox.bind(this, ele?.id)} onChange={this.onChange.bind(this, ele?.id)} checked={ele?.setting} /> */}
      <span className="numberOfTitle">{ele.name?.length > 10 ? ele.name?.slice(0, 10) + '...' : ele.name}</span>
    </div>);
  }
  confirmLev= (data,lev)=>{
    for(let i in data){
      let item = data[i];
      item.lev = lev;
      if(item.children){
        this.confirmLev(item.children,lev+1)
      }
    }
  }

  mapData = (children) => {
    if (children && Array.isArray(children)) {
      return children.map((ele) => {
        if (ele.children && Array.isArray(ele.children)) {
          return (
            <TreeNode
              title={this.renderTitle({ ele, isChildren: true })}
              key={ele.id}
              className={`tree_children_title lev_${ele.lev}`}
            >
              {this.mapData(ele.children)}
            </TreeNode>
          );
        } else {
          return (
            <TreeNode
              title={this.renderTitle({ ele })}
              key={ele.id}
              className={`tree_inside_title lev_${ele.lev}`}
            />
          );
        }
      });
    }
    return [];
  };

  render() {
    const content = [];
    const { selectTreeData } = this.props;
    // let selectTreeData = result
    const data = selectTreeData?.length && selectTreeData?.map(item => ({ ...item, isOuterParent: true }));
    this.confirmLev(data,1)
    data &&
      data.map((item) => {
        if (item) {
          content.push(
            <TreeNode
              // title={item.name}
              title={this.renderTitle({ ele: item, isOuterParent: true })}
              key={item.id}
              className={`tree_node_title lev_${item?.lev}`}
            >
              {this.mapData(item.children)}
            </TreeNode>
          );
        }
      });

    return (
      <div className="components_selectTree9" onClick={(e) => e.stopPropagation()}>
        {data?.length > 0 ? (
          <Tree
            onExpand={this.onExpend}
            // expandedKeys={this.state.expandedKeys}
            onSelect={this.onSelect}
          >
            {content}
          </Tree>
        ) : (
          <Empty description={false} imageStyle={{ height: 215 }} />
        )}
      </div>
    );
  }
}
