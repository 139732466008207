import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import { imgUrlForZhongZhi, getIp } from '@/utils';
const preview = getIp();
class UserAgreement extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    componentDidMount() {

    }



    render() {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div className={styles.nav}>
                            <h3 className={styles.navName}>用户协议 </h3>
                        </div>
                        <div className={styles.con}>
                            欢迎申请使用典技通教育科技有限公司（以下简称“典技通”）提供的多技通软件（以下简称“本软件”）。您（以下或简称“用户”）在使用典技通提供的各项服务（以下简称“本服务”）之前，请您务必审慎阅读、充分理解并选择接受或不接受本服务协议（以下简称“本协议”）及《用户协议和隐私条款》各条款内容，包括但不限于免除或者限制典技通责任的条款，特别是免除或限制责任的条款、争议解决和法律适用条款。未成年人应在法定监护人陪同下阅读。 <br />
                            当您按照注册页面提示填写信息、阅读并同意本协议由且完成全部注册程序后，或您按照激活页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他多技通学习平台允许的方式实际使用本服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得“典技通”学习平台对您问询的解答等理由，主张本协议无效，或要求撤销本协议。<br />
                            本协议可由典技通随时更新，更新后的协议条款一旦公布即代替旧协议条款，恕不再另行通知，用户可在本软件查阅最新版协议条款。在典技通修改本协议条款后，如果用户不接受修改后的条款，请立即停止使用典技通提供的服务，用户继续使用典技通提供的服务将被视为已接受了修改后的协议。 <br />
                            本协议内容同时包括典技通可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，用户同样应当遵守。<br />
                            若您有问题，请通过文末的联系方式联系我们。<br />
                        </div>
                        <div className={styles.title}>• 缔约主体</div>
                        <div className={styles.con}>本协议通过适用多技通平台产品移动客户端以及其他方式使用“多技通”学习平台服务的用户（以下简称“用户”或“您”），与典技通共同缔结。 </div>
                        <div className={styles.title}>• 协议内容</div>
                        <div className={styles.con}>
                            本软件所包含的所有文字、图标、用户界面、可视界面、图片、商标、标识、声音、音乐、美术作品及计算机编码（合称为“内容”），包括但不限于该等内容的设计、结构、选择、协调、表达、界面外观及排序等，均属典技通或其他权利人所有，且受著作权法、专利法、商标法以及各类其他知识产权法的保护。 <br />
                            除本用户协议明确规定外，未经典技通或其他权利人事先明确书面同意，您不得为出版或发行或为任何其他商业目的以任何方式将本软件的任何部分及任何内容复制、再制、重印、上传、发布、公开展示、编码、翻译、传输或散布至任何其他终端、网站或其他媒介。 <br />
                            您同意授予典技通以非排他性、不可转让、免费的权利在合理商业使用范围内使用上述所有数据。
                        </div>
                        <div className={styles.title}>• 本软件的获取</div>
                        <div className={styles.con}>您可以从得到典技通授权的第三方获取本软件，如果您从未经典技通授权的第三方获取本软件或与本软件名称相同的安装程序，典技通无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。 </div>

                        <div className={styles.title}>• 本软件的安装与卸载</div>
                        <div className={styles.con}>典技通可能为不同的终端设备开发了不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。<br />下载安装程序后，您需要按照该程序提示的步骤正确安装。如果您不再需要使用本软件或者需要安装新版软件，可以自行卸载。 </div>

                        <div className={styles.title}>• 本软件的更新</div>
                        <div className={styles.con}>为了改善用户体验、完善服务内容，典技通将不断努力开发新的服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。<br />为了保证本软件及服务的安全性和功能的一致性，典技通有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。本软件新版本发布后，旧版本的软件可能无法使用。典技通不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。</div>

                        <div className={styles.title}>• 注册及帐号管理</div>
                        <div className={styles.con}>
                            用户注册成为本软件用户即可获得一个ID（以下或称“帐号”），本软件提供的某些功能或服务可能要求您开立帐号。对于维护您持有帐号信息的保密性，包括您的密码，您需要妥善的进行保管。您同意一旦发生任何对您帐号或密码未经授权的使用，或者任何其他对安全的破坏情况，应当立即通知典技通。<br />
                            多技通账号的所有权归典技通所有，您完成申请注册手续后，获得多技通账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖。<br />本协议其他条款未明示授权的其他一切权利仍由典技通保留，您在行使这些权利时须另外取得典技通的书面许可。典技通如果未行使前述任何权利，并不构成对该权利的放弃。
                            用户应遵守本协议的各项条款，正确、适当地使用本服务，如用户违反本协议中的任何条款，典技通有权依据协议中断或终止对违约用户账号提供服务。<br />
                            如用户注册的免费网络服务的账号在任何连续180日内未实际使用，或者用户注册的收费网络服务的账号在其订购的收费网络服务的服务期满之后连续180日内未实际使用，则多技通有权删除该账号并停止为该用户提供相关的网络服务如果典技通发现或收到他人举报或投诉用户违反本协议约定的，典技通有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规账号处以包括但不限于警告、限制或禁止使用部分或全部功能、账号封禁直至注销的处罚，并有权公告处理结果。
                        </div>
                        <div className={styles.title}>• 用户个人信息保护</div>
                        <div className={styles.con}>保护用户个人信息是典技通的一项基本原则，典技通将会采取合理的措施保护用户的个人信息。除法律法规规定的情形外，未经用户许可典技通不会向第三方公开、透露用户个人信息。典技通对相关信息采用专业加密存储与传输方式，保障用户个人信息的安全。</div>
                        <div className={styles.title}>• 服务使用规范</div>
                        <div className={styles.con}>
                            用户在使用本软件时，必须遵守中华人民共和国相关法律法规及相关国际公约的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为:<br />
                            1. 用户不得上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：<br />
                            • 违反中华人民共和国宪法确定的基本原则的；<br />
                            • 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；<br />
                            • 损害国家荣誉和利益的；<br />
                            • 煽动民族仇恨、民族歧视、破坏民族团结的；<br />
                            • 散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
                            •散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
                            •含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；<br />
                            •以非法民间组织名义活动的；<br />
                            •不符合应当遵守法律法规、社会主义制度、国家利益、公民合法利益、公众秩序、社会道德风尚和信息真实性等“七条底线”要求的；<br />
                            •含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。<br />
                            2.用户不得利用“本服务制作、上载、复制、发布、传播如下干扰本软件正常运营，以及侵犯其他用户或第三方合法权益的内容：<br />
                            •含有任何性或性暗示的；<br />
                            •含有辱骂、恐吓、威胁内容的；<br />
                            •含有骚扰、垃圾广告、恶意信息、诱骗信息的；<br />
                            •涉及他人隐私、个人信息或资料的；<br />
                            •侵害他人一般人格权、名誉权、肖像权、知识产权、商业秘密等合法权利的内容；<br />
                            •含有其他干扰本软件正常运营和侵犯其他用户或第三方合法权益内容的信息。<br />
                            3.用户不得为任何非法目的而使用软件，不利用本软件从事以下活动：<br />
                            •未经允许，进入计算机信息网络或者使用计算机信息网络资源的；<br />
                            •未经允许，对计算机信息网络功能进行删除、修改或者增加；<br />
                            •未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；<br />
                            •故意制作、传播计算机病毒等破坏性程序的；<br />
                            •其他危害计算机信息网络安全的行为。<br />
                            4.用户不得利用本软件从事进行如下行为：<br />
                            •提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义；<br />
                            •强制、诱导其他用户关注、点击链接页面或分享信息；<br />
                            •虚构事实、隐瞒真相以误导、欺骗他人的；<br />
                            •侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；<br />
                            •未经典技通书面许可利用多技通账号和任何功能进行推广或互相推广的；<br />
                            •利用技术手段批量建立虚假账号；<br />
                            •制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；<br />
                            （8）其它违反法律法规规定、侵犯其他用户合法权益、干扰本软件正常运营的行为。<br />
                            5.凡用户昵称中出现如下信息者（包括但不限于），多技通权进行屏蔽、锁定，甚至注销：<br />
                            •使用严重违反国家法律法律及风序良俗，包含或暗藏反动、色情等信息（含符号、地址等）的昵称；<br />
                            •使用党和国家领导人、古今中外比较有影响的名人、网络上知名人士的真实姓名、字、号、艺名、笔名、头衔等为昵称；<br />
                            •使用国家机构、企事业单位、知名公司或品牌的名称及标识为昵称；<br />
                            •含性别歧视、种族歧视、地域歧视等信息的昵称；<br />
                            •含自侮辱、猥亵或者对他人进行侮辱、挑衅、漫骂、人身攻击等不文明、不健康信息的昵称；<br />
                            •含易产生歧义、引起他人误解的信息的昵称；<br />
                            •未获得“多技通”官方正式许可而使用带有多技通相关名称的昵称；<br />
                            •“多技通”老师、助教及社区管理人员的昵称近似或雷同，并可能引起混淆或纠纷的；带有明显广告性质的昵称；<br />
                            •带有其它法律法规以及多技通禁止规则中所包含信息的昵称<br />
                            6.用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括但不限于为解决争议因此产生的律师费、诉讼费，您同意赔偿典技通与合作公司、关联公司上述费用，并使之免受损害。对此，典技通有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收账号、追究法律责任等措施。
                            对恶意注册账号或利用账号进行违法活动，捣乱、骚扰、欺骗其他用户以及其他违反本协议的行为，典技通有权回收其账号。同时，典技通会视司法部门的要求协助调查。<br />
                            7.用户须对自己在使用本软件过程中的行为承担法律责任，自行对传送信息的真实性、合法性、无害性、准确性、有效性等全权负责。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在本软件首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，典技通会对用户进行追偿。
                        </div>
                        <div className={styles.title}>• 用户注意事项</div>
                        <div className={styles.con}>
                            您理解并同意：为了向您提供有效的服务，本软件会利用您终端设备的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。<br />
                            您理解并同意典技通将会尽其商业上的合理努力保障您在本软件及服务中的数据存储安全，但是，典技通并不能就此提供完全保证，包括但不限于以下情形：<br />
                            1.典技通不对您在本软件及服务中相关数据的删除或储存失败负责；<br />
                            2.典技通有权根据实际情况自行决定单个用户在本软件及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份本软件及服务中的相关数据；<br />
                            3.如果您停止使用本软件及服务或服务被终止或取消，典技通可以从服务器上永久地删除您的数据。服务停止、终止或取消后，典技通没有义务向您返还任何数据。<br />
                            用户在使用本软件及服务时，须自行承担如下来自典技通不可掌控的风险内容，包括但不限于：<br />
                            1.由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；<br />
                            2.用户必须选择与所安装终端设备相匹配的软件版本，否则，由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担；<br />
                            3.用户在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担；<br />
                            4.由于无线网络信号不稳定、无线网络带宽小等原因，所引起的多技通登录失败、资料同步不完整、页面打开速度慢等风险。<br />
                            您理解并同意，本软件同大多数互联网软件一样，可能会受多种因素影响，包括但不限于用户原因、网络服务质量、社会环境等；也可能会受各种安全问题的侵扰，包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁您的终端设备信息和数据安全，继而影响本软件的正常使用等。因此，您应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。

                        </div>
                        <div className={styles.title}>• 免责声明</div>
                        <div className={styles.con}>
                            典技通会致力于向您提供安全、稳定的优质服务。但是，典技通不承诺本软件的任何内容、服务或功能无任何错误或不中断，或者您对本软件的使用将产生特定结果。本软件及其内容是基于“现状”且“可获得”而提供。本软件提供的所有信息可不经通知而变更。典技通不作任何明示或默示保证，包括任何正确性、非侵权、适销性及适用性的保证。对于任何与您对本软件和/或典技通服务的使用有关的任何第三方的作为、不作为和行为，典技通不承担责任。如果对本软件或任何内容有任何不满意，您可以停止使用本软件或任何该内容。<br />
                            上述免责声明适用于因任何不履行、错误、疏忽、中断、删除、缺陷、操作或传输迟延、病毒、通信线路故障、失窃或破坏或未经授权、篡改或使用（无论是违约、侵权、过失或任何其他诉因）而造成的任何损害、责任或伤害。<br />
                            典技通保留在任何时候不经通知进行以下任何行为的权利：<br />
                            （1）基于任何原因，修改，中止或终止本软件或其任何部分的运行或访问；<br />
                            （2）修改或变更本软件或其任何部分及适用政策或条款；以及<br />
                            （3）在进行任何定期或非定期维护、错误纠正或其他变更所必需时，中断本软件或其任何部分的运行。<br />
                        </div>
                        <div className={styles.title}>• 违反本用户协议</div>
                        <div className={styles.con}>
                            您同意，若典技通认为您违反了本用户协议或可能与您使用本软件相关的其他协议或指引，典技通可以终止您使用本软件的权限。<br />
                            您同意，典技通可自行决定且无需提前通知，因下列原因终止您访问本软件的权限，包括（但不限于）：（1）应政府部门和司法机关的要求；（2）本软件或本软件提供的任何服务的中止或重大修改；或（3）不可预期的技术问题。<br />
                            若因您违反本用户协议致使典技通对您提起法律诉讼，除法律授予典技通的任何其他救济外，典技通有权从您处获得且您同意支付所有合理的律师费及该等诉讼费用。您同意，因对本使用协议的任何违反导致典技通终止您使用本软件的权限，典技通概不对您或任何第三方承担责任。
                        </div>
                        <div className={styles.title}>• 知识产权</div>
                        <div className={styles.con}>
                            本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及本软件包含的任何文字、图表、音频、视频或软件（包括但不限于软件中包含的图表、动画、音频、视频、界面设计、版面框架、数据和程序、代码、文档）等信息或材料均受著作权法、商标法、专利法或其它法律法规、国际条约的保护，未经相关权利人书面同意，用户不得以任何方式实施、使用或转让该等知识产权、信息或材料。<br />
                            本协议未授予用户使用典技通任何商标、服务标记、标识、域名和其他显著品牌特征的权利。<br />
                            除本协议明确允许的以外，用户不得以任何形式或任何方式对典技通服务部分或全部内容进行修改、出租、租赁、出借、出售、分发、复制、创作衍生品或用于任何商业用途。<br />
                            用户在典技通发布的信息不得侵犯任何第三人的知识产权，未经相关权利人之事先书面同意，用户不得以任何方式上传、发布、修改、传播或复制任何受著作权保护的材料、商标或属于其他人的专有信息。<br />
                            若您认为您的知识产权或其他合法权益被侵犯，请与典技通联系。<br />
                        </div>
                        <div className={styles.title}>• 争议解决</div>
                        <div className={styles.con}>本用户协议及您与典技通的关系适用于中华人民共和国大陆地区法律，但不包括其冲突法规范。您和典技通同意服从典技通所在地法院的司法管辖。</div>
                        <div className={styles.title}>• 其他</div>
                        <div className={styles.con}>
                            若本协议的任何条款被享有合法管辖权的法院裁定为无效或不可执行，则该等条款应在最低必要范围内予以限制或排除，并以最能体现本用户协议意图的新条款予以替换，以使本用户协议继续完全有效。<br />
                            本用户协议构成您与典技通之间与您使用本软件相关的整体协议，并在此取代及取消您与典技通之间先前存在的与该等使用相关的任何书面或口头协议或理解。<br />
                            本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。<br />
                        </div>
                        <div className={styles.title}>• 联系方式</div>
                        <div className={styles.con}>如果您对本协议有任何疑问、意见或建议，通过以下方式与我们联系：400-011-0256（周一至周五8:30-17:30）。</div>
                        <div className={styles.title}>隐私政策</div>
                        <div className={styles.con}>
                            本政策仅适用于典技通教育科技有限公司（下称“我们”或“典技通”）提供的产品或服务，包括多技通网站、多技通初中版、多技通高中版及未设独立隐私政策的关联公司网站及/或登录相关客户端。如我们的产品或服务中使用了我们提供的产品或服务但未设独立隐私权政策的，则本政策同样适用于该部分产品或服务。我们及关联公司就其向您提供的产品或服务单独设立有隐私权政策的，则相应产品或服务适用相应隐私权政策。<br />
                            需要特别说明的是，本隐私政策不适用于其他第三方向您提供的服务。对于由其他第三方提供的服务（不论该等服务是否通过多技通平台提供），还请您仔细阅读该第三方服务提供者的相应隐私政策，以便保护您的合法权益。<br />
                            如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：电话：400-011-0256<br />
                            本政策将帮助您了解以下内容：<br />

                            1、我们收集哪些您的个人信息<br />

                            2、我们如何收集您的个人信息<br />

                            3、我们如何使用您的个人信息<br />

                            4、我们如何委托处理、共享、转让、公开披露您的个人信息<br />

                            5、我们如何保护您的个人信息<br />

                            6. 第三方共享信息<br />

                            7、您的权利<br />

                            8、我们如何处理未成年人的个人信息<br />

                            9、您的个人信息如何在全球范围转移<br />

                            10、本政策如何更新<br />

                            11、如何联系我们<br />
                            多技通深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，多技通承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
                            <br />
                            请在使用我们的产品（或服务）前，仔细阅读并了解本协议（以下简称“隐私政策”，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用我们的产品或服务。如果您不同意本隐私政策任何内容，您应立即停止使用多技通产品或服务。当您使用多技通提供的任一服务时，即表示您已同意我们按照本隐私政策来合法使用和保护您的个人信息。<br />
                            我们十分重视未成年人的个人信息保护，如您为未成年人，我们要求您务必请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。如果没有父母或监护人的同意，未成年人不应创建自己的个人信息主体账户。
                            <br />

                        </div>
                        <div className={styles.title}>一、我们收集哪些您的个人信息</div>
                        <div className={styles.con}>
                            1、在您使用多技通的过程中，为保证服务的正常进行以及保障您的账号安全，我们会收集您的昵称、头像、手机号码、性别、学校、班级、地区和设备属性信息(包括但不限于IMEI、IMSI、设备MAC地址、必要的软件列表、设备序列号)。
                            <span className={styles.con_con}>
                                手机号码属于敏感信息，收集该信息时为了满足相关法律法规的网络实名制要求。若您不提供此类信息，可能导致您无法正常使用我们的服务。<br />
                            </span>
                            2、我们需要收集您的硬件信息用于提供统计分析服务，并通过地理位置校准报表数据准确性。需要收集您的设备属性信息(包括但不限于IMEI、IMSI、设备MAC地址、必要的软件列表、设备序列号)。<br />
                            3、您可自主选择向我们提供或允许我们收集下列信息：<br />
                            （1）您在多技通客户端发布、上传的信息；请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在发布、上传时选择包含个人信息的内容，请您更加谨慎地考虑是否在使用我们的服务时共享甚至公开分享相关信息。若您公开发布、上传的信息中涉及儿童个人信息的，您需在发布前征得对应儿童监护人的同意。
                            <br />
                            （2）与多技通客服联系时您提供的电话、邮箱、地址、学校、年级或微信等联系方式；<br />
                            （3）您参与问卷调查时向我们发送的问卷答复信息；<br />
                            （4）设备位置信息及权限（如通过GPS、蓝牙或WIFI信号获得的位置信息），以便根据您所在的地域帮助您设置自己的学校、向您匹配视频等内容。<br />
                            （5）设备连接信息（IP地址、电信运营商、使用的语言、访问日期和时间等）。<br />
                            共计5类个人信息。这些信息并非该业务功能运行所必需，但这些信息对改善服务质量、研发新产品或服务等有非常重要的意义。我们不会强制要求您提供这些信息，您如拒绝不会对使用该业务功能产生不利影响。<br />
                            4、在您使用该业务功能时，我们的小程序可能会向您申请下列与个人信息相关的系统权限：<br />
                            （1）存储权限，以便存储小程序的图片和音频文件，节省您的流量；<br />

                            （2）麦克风权限，以便在录制语音评语、练习录音等功能中采集您的声音；<br />

                            （3）相机权限，以便在拍照上传练习、题目、头像等功能中采集所需图像；<br />
                            （4）电话权限，为了正常识别手机设备、运营商网络和本机设备、进行手机认证、保证账号安全；<br />

                            （5）地理位置，为您匹配适合的学习资源。<br />
                            共计5项系统权限。如果您不授权，将会导致我们无法提供该业务功能。除上述权限之外，您可自主选择是否额外授予小程序其他的系统权限<br />
                            还请您注意，您在使用我们服务时自愿发布甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。因此请您在使用我们的服务时更加谨慎地考虑，是否要发布甚至公开分享相关信息。
                        </div>
                        <div className={styles.title}>二、我们如何收集您的个人信息</div>
                        <div className={styles.con}>
                            1、我们或我们的外部供应商，可能通过cookies和web beacon收集和使用您的信息，并将该等信息储存为日志信息。<br />

                            我们使用自己的cookies和web beacon，目的是为您提供更好的用户体验和服务，并用于以下用途：<br />

                            (1)记住您的身份。例如：cookies和web beacon有助于我们辨认您作为我们的注册用户的身份，或保存您向我们提供的有关您的喜好或其他信息。<br />

                            (2)分析您使用我们服务的情况。例如，我们可利用cookies和web beacon来了解您使用我们的服务进行什么活动，或哪些服务最受您的欢迎。<br />

                            您可以通过终端设备的系统设置里清除cookies或web beacon。但请注意，如果清除cookies或web beacon，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。<br />
                            2、征得您授权同意的例外<br />
                            您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：<br />

                            （1）与国家安全、国防安全有关的；<br />

                            （2）与公共安全、公共卫生、重大公共利益有关的；<br />

                            （3）与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；<br />

                            （4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br />

                            （5）您自行向社会公众公开的个人信息；<br />

                            （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br />

                            （7）根据与您签订和履行相关协议或其他书面文件所必需的；<br />

                            （8）用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；<br />

                            （9）为合法的新闻报道所必需的；<br />

                            （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br />

                            （11）法律法规规定的其他情形。<br />
                            请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善我们网页或产品客户端的内容和布局，为业务决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。
                            <br />
                        </div>
                        <div className={styles.title}>三、我们如何使用您的个人信息</div>
                        <div className={styles.con}>
                            1、对于必要的个人信息，我们会用来提供该项业务功能，包括电话号码、姓名、性别、学校、班级、学科等信息，我们也会使用上述信息来维护和改进相应业务功能，开发新的业务功能等。<br />

                            2、对于非必要的个人信息，我们可能会用于以下用途，包括：<br />

                            （1）向您提供您使用的各项服务，并维护、改进这些服务。<br />

                            （2）为提高您使用我们提供的服务的安全性，我们可能会使用或整合您的个人信息以预防、发现、调查可能涉及欺诈、危害安全、非法或违反与我们或其关联方协议、政策或规则的行为，以保护您、我们的其他用户、我们或我们关联方的合法权益。<br />
                            （3）经您许可的其他用途。<br />

                            如我们确需改变您的个人信息收集和使用目的，我们会事先征得您的同意。但无论如何，我们仅在本隐私政策所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。<br />

                            （4）单独的设备信息等无法与任何特定个人直接建立联系的数据，不属于个人信息。如果我们将这类非个人信息与其他信息结合用于识别自然人个人身份，或者将其与个人信息结合使用，则在结合使用期间，此类设备信息将被视为个人信息。<br />
                        </div>
                        <div className={styles.title}>四、我们如何委托处理、共享、转让、公开披露您的个人信息</div>
                        <div className={styles.con}>
                            <p>1、委托处理</p>
                            <p>本业务功能中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供客户支持。</p>
                            <p>对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
                            <p>2、共享</p>
                            <p>我们不会与本公司以外的任何公司、组织和个人分享您的个人信息（如设备识别信息等），除非获得您的明确同意。目前，我们会在以下情形中，向您征求您对共享个人信息的授权同意：</p>
                            <p>我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）共享（他们与您处于同一法域），用作下列用途：</p>
                            <p>（1）向您提供我们的服务；</p>
                            <p>（2）实现“我们如何使用您的个人信息”部分所述目的；</p>
                            <p>（3）履行我们在本隐私政策或多技通其他产品的用户协议中的义务和行使我们的权利</p>
                            <p>（4）理解、维护和改善我们的服务。</p>
                            <p>如我们或我们的关联公司需要与任何上述第三方共享您的个人信息，我们将与其签署严格的保密协定，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息，努力确保该等第三方在使用您的个人信息时遵守本隐私政策及我们要求其遵守的其他适当的保密和安全措施。</p>
                            <p>我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
                            <p>3、转让</p>
                            <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
                            <p>（1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息</p>
                            <p>（2）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。涉及儿童个人信息的，我们也将要求该公司、组织重新征求儿童监护人的授权同意。</p>
                            <p>4、公开披露</p>
                            <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
                            <p>（1）获得您明确同意后；</p>
                            <p>（2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
                            <p>5、共享、转让、公开披露个人信息时事先征得授权同意的例外</p>
                            <p>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
                            <p>（1）与国家安全、国防安全有关的；</p>
                            <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
                            <p>（3）与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
                            <p>（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</p>
                            <p>（5）您自行向社会公众公开的个人信息；</p>
                            <p>（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
                            <p>请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</p>

                        </div>
                        <div className={styles.title}>五、我们如何保护您的个人信息</div>
                        <div className={styles.con}>
                            <p>1、我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，并尽最大合理努力使您的个人信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您个人信息的员工或外包人员也采取了严格管理，包括但不限于采取信息访问权限控制、与接触个人信息的人员签署保密协议、监控该等人员的操作情况等措施。</p>
                            <p>我们指定了专人负责儿童个人信息的保护，严格设定信息访问权限，对可能接触到儿童个人信息的工作人员采取最小够用授权原则，并采取技术措施对工作人员处理儿童个人信息的行为进行记录和管控，避免违法复制、下载儿童个人信息。</p>
                            <p>我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求产品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：</p>
                            <p>（1）完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉；</p>
                            <p>（2）保证我们为您提供服务的安全和质量；</p>
                            <p>（3）您是否同意更长的留存期间；</p>
                            <p>（4）是否存在保留期限的其他特别约定。</p>
                            <p>在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
                            <p>我们非常重视您的个人信息安全。多技通账户信息（包括网站及客户端账号名、密码信息）为非常重要的个人信息，请您妥善设置、保管您的账号名及密码信息。我们将通过备份、对密码进行加密等安全措施以保护您的账户信息等个人信息不丢失、不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完善的安全措施”，因此您对自身重要信息的妥善保管亦将十分重要。</p>
                            <p>2、我们的数据安全能力：我们在信息安全方面已达到信息安全等级保护（三级）认证。</p>
                            <p>3、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
                            <p>4、我们将定期更新并公开安全风险、个人信息安全影响评估等报告的有关内容。</p>
                            <p>5、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
                            <p>6、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</p>
                            <p>我们已制定儿童个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生儿童个人信息安全事件后，我们将按照法律法规的要求，及时向儿童及其监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、儿童及其监护人可自主防范和降低风险的建议、对儿童及其监护人的补救措施等。我们将及时将事件相关情况以app推送通知、发送邮件/短消息等方式告知儿童及其监护人。难以逐一告知时，我们会采取合理、有效的方式发布相关警示信息。同时，我们还将按照监管部门要求，主动上报儿童个人信息安全事件的处置情况。若儿童及其监护人的合法权益受损，我们将依法承担相应的法律责任。</p>
                            <p>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>

                        </div>
                        <div className={styles.title}>六、第三方共享信息</div>
                        <div className={styles.con}>
                            <p>为了提供更为完善的分析和通知等服务，我们的产品集成了以下内容，需要收集的信息说明如下：</p>
                            <p>1、我们的产品集成友盟+SDK，友盟+SDK需要收集您的设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力。</p>
                            <p>2、我们的产品还集成了极光+SDK用以发送系统通知和消息，需要收集您的设备信息，通过识别设备信息为App赋予推送能力，用于消息推送；</p>

                        </div>
                        <div className={styles.title}>七、您的权利</div>
                        <div className={styles.con}>
                            <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
                            <p>1、访问您的个人信息</p>
                            <p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</p>
                            <p>您可以通过“个人中心”中访问和修改您的个人信息。在“个人中心”-顶部信息卡片中访问和修改您的个人信息，包括姓名、学校、班级、学科、教材版本等；</p>
                            <p>如果您无法通过上述链接访问这些个人信息，您可以随时使用我们的 Web 表单联系，拨打400-011-0256通过多技通客服与我们取得联系。我们将在30天内回复您的访问请求。</p>
                            <p>对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请拨打400-011-0256通过多技通客服与我们取得联系。</p>
                            <p>2、更正您的个人信息</p>
                            <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以随时通过在线客服联系我们，或拨打400-011-0256通过多技通客服与我们取得联系。我们将在30天内回复您的更正请求。</p>
                            <p>3、删除您的个人信息</p>
                            <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
                            <p>（1）如果我们处理个人信息的行为违反法律法规；</p>
                            <p>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
                            <p>（3）如果我们处理个人信息的行为违反了与您的约定；</p>
                            <p>（4）如果您不再使用我们的产品或服务，或您注销了账号；</p>
                            <p>（5）如果我们不再为您提供产品或服务。</p>
                            <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
                            <p>当您从我们的服务中删除信息后，因为适用的法律和安全技术等原因，我们可能不会立即在备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，并在备份更新时删除这些信息。</p>
                            <p>4、改变您授权同意的范围</p>
                            <p>每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一部分”）。</p>
                            <p>除此之外，您可以在“设置”菜单中设置或与在线客服联系或改变您的智能移动设备的设置等方式给予或收回您的授权同意。</p>
                            <p>您可以通过以下方式自行操作：</p>
                            <p>通过设备设置菜单-关闭定位、相机、照片、麦克风等权限。</p>
                            <p>当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
                            <p>5、个人信息主体注销账户</p>
                            <p>您可以随时联系客服人员注销此前注册的账号，在您注销账号之后，我们会按照您的要求删除您的个人信息或对其匿名化处理，法律法规另有规定的除外。删除后，您的个人信息将无法恢复或找回</p>
                            <p>如果您保留您的账号，我们将视为您继续使用多技通平台提供的服务，在此期间，为了实现服务目的，我们将依照相关法律法规的要求存储您的个人信息；在注销账户之后，我们将停止为您提供产品或服务，</p>
                            <p>6、约束信息系统自动决策</p>
                            <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害多技通的商业秘密或其他用户权益、社会公共利益的前提下提供适当的救济方式。</p>
                            <p>7、响应您的上述请求</p>
                            <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
                            <p>我们将在30天内做出答复。如您不满意，还可以通过以下途径投诉：拨打400-011-0256通过多技通客服与我们取得联系。</p>
                            <p>对于您的合理请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
                            <p>在以下情形中，我们将无法响应您的请求：</p>
                            <p>（1）与个人信息控制者履行法律法规规定的义务相关的；</p>
                            <p>（2）与国家安全、国防安全直接相关的；</p>
                            <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
                            <p>（4）与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
                            <p>（5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
                            <p>（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
                            <p>（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
                            <p>（8）涉及商业秘密的。</p>

                        </div>
                        <div className={styles.title}>八、我们如何处理未成年人的个人信息</div>
                        <div className={styles.con}>
                            <p>我们十分重视未成年人的个人信息保护。在我们的产品或服务中，我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您务必请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</p>
                            <p>如果没有父母或监护人的同意，未成年人不应创建自己的个人信息主体账户。</p>
                            <p>若您是儿童的父母或其他监护人，请您关注您监护的儿童是否是在取得您的授权同意之后使用我们的服务的。</p>
                            <p>对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。</p>
                            <p>尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。</p>
                            <p>对于经父母或其他监护人同意使用我们的产品或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或其他监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</p>

                        </div>
                        <div className={styles.title}>九、您的个人信息如何在全球范围转移</div>
                        <div className={styles.con}>在中华人民共和国境内收集和产生的个人信息，我们会按照法律法规规定，存储在中华人民共和国境内，</div>
                        <div className={styles.title}>十、本政策如何更新</div>
                        <div className={styles.con}>
                            <p>我们的隐私政策可能变更。该等情况下，若您继续使用我们的服务，即表示同意受经修订的隐私政策的约束。</p>
                            <p>未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。</p>
                            <p>对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私政策的具体变更内容）。</p>
                            <p>本政策所指的重大变更包括但不限于：</p>
                            <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
                            <p>2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、并购等引起的所有者变更等；</p>
                            <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
                            <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
                            <p>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
                            <p>6、个人信息安全影响评估报告表明存在高风险时。</p>

                        </div>
                        <div className={styles.title}>十一、如何联系我们</div>
                        <div className={styles.con}>
                            <p>如果您对本协议有任何疑问、意见或建议，可以通过登录应用或网站给我们进行反馈，也可以通过其他方式联系我们，我们的信息如下</p>
                            <p>典技通教育科技有限公司</p>
                            <p>注册地址：河南省郑州市二七区福喜路7号1号楼9层924号</p>
                            <p>办公地址：河南省郑州市二七区福喜路7号1号楼9层924号</p>
                            <p>联系电话：400-011-0256（周一至周五8:30-17:30）。</p>
                            <p>如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：友好协商不成时，您可向郑州市二七区人民法院提起诉讼。</p>
                        </div>
                    </div>


                </div>
            </div >
        );
    }
}

export default connect(({ user }) => ({ user }))(UserAgreement);
