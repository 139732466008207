import { createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from './reducer';
const persistConfig = {
  storage,
  key: 'root',
  stateReconciler: autoMergeLevel2,
};

const myPersistReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(myPersistReducer);

export const persistor = persistStore(store);

export default store;
