import * as types from './constant';
import { Action } from './interface';

export function updateToken(token: string): Action {
  return {
    type: types.UPDATE_TOKEN,
    payload: {
      token,
    },
  };
}

export function updatePhone(phone: string): Action {
  return {
    type: types.UPDATE_PHONE,
    payload: {
      phone,
    },
  };
}

export function clearToken(): Action {
  return {
    type: types.CLEAR_TOKEN,
  };
}

export function updateUser(user = {}): Action {
  return {
    type: types.UPDATE_USER,
    payload: {
      user,
    },
  };
}

export function clearUser(): Action {
  return {
    type: types.CLEAR_USER,
  };
}

export function updateCrumbsData(res = {}): Action {
  return {
    type: types.UPDATE_CRUMBS_DATA,
    payload: {
      crumbsData: res,
    },
  };
}

// 保存公告详情数据
export function saveNotice(res = {}): Action{
  return {
    type: types.HOME_SAVE_NOTICE_DATA,
    payload: {
      noticeData: res,
    },
  };
}

// 课程编辑
export function savecCurseVOData(res = {}): Action{
  return {
    type: types.SAVE_CURSEVO_DATA,
    payload: {
      curseVOData: res,
    },
  };
}
// 未读消息数量
export function updateMsgCount(msgCount = 0): Action{
  return {
    type: types.UPDATE_MEG_COUNT,
    payload: {
      msgCount
    },
  };
}
