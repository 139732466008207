import React from "react";
import { connect } from 'react-redux';
import { Carousel, Input, message } from 'antd';
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import styles from './index.module.scss';
import axios from '../../axios';
import { getExamTestList } from '../../api';
import { updateToken, updateUser, clearToken } from '../../store/action';
import { imgUrlForZhongZhi, imgUrlForZhongZhiApi, getIp } from '../../utils/index';
import { divideMathText } from '../../utils/util';
import { renderHTML } from "../../utils/react-mathjax";

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      currentTestId: '',
      activeFlagList: [],
      examName: '',
      tempAry: []
    };
  }

  computedNumber = () => {
    // 计算答题题号
    let { activeFlagList } = this.state;
    let singleChoiceQuestions = activeFlagList?.find(item => item?.testType === 1);
    let multipleChoiceQuestions = activeFlagList?.find(item => item?.testType === 2);
    let judgeChoiceQuestions = activeFlagList?.find(item => item?.testType === 4);
    let FillInTheBlanksQuestions = activeFlagList?.find(item => item?.testType === 3);
    let answerQuestions = activeFlagList?.find(item => item?.testType === 5);
    let tempAry = [];
    if (singleChoiceQuestions) {
      tempAry.push(singleChoiceQuestions);
    }
    if (multipleChoiceQuestions) {
      tempAry.push(multipleChoiceQuestions);
    }
    if (judgeChoiceQuestions) {
      tempAry.push(judgeChoiceQuestions);
    }
    if (FillInTheBlanksQuestions) {
      tempAry.push(FillInTheBlanksQuestions);
    }
    if (answerQuestions) {
      tempAry.push(answerQuestions);
    }
    tempAry = tempAry?.map((item, index) => {
      return { ...item, indexNumber: index + 1 };
    });
    this.setState({
      tempAry
    });
  }

  getExamTestList = () => {
    let { examId } = this.props;
    axios.get(getExamTestList, {
      params: {
        examId
      }
    }).then(res => {
      if (!res) return;
      this.setState({
        activeFlagList: res?.testList || [],
        examName: res?.examName || ''
      }, () => {
        this.computedNumber();
      });
    });
  }

  componentDidMount() {
    let { examId, activeFlagList, examName } = this.props;
    if (examId) {
      this.getExamTestList();
    } else {
      this.setState({
        activeFlagList: activeFlagList || [],
        examName: examName || ''
      }, () => {
        this.computedNumber();
      });
    }
  }

  optionRender = option => {
    if (option.includes('wordDivDot')) {
      option = option.replace(/<p>/gim, "");
      return option;
    }
    return option;
  }

  changeIsShowAnswer = item => {
    let { activeFlagList } = this.state;
    this.setState({
      activeFlagList: activeFlagList?.map(i => {
        if (i?.id === item?.id) {
          return {
            ...i, isShowAnswer: !i?.isShowAnswer
          };
        }
        return i;
      })
    });
  }

  renderAnswersText = (testItem, testType) => {
    if (testType === 1) {
      return testItem?.courseTestChooseAnwser?.find(item => item?.rightFlag)?.optionName;
    }
    if (testType === 2) {
      return (testItem?.courseTestChooseAnwser || testItem?.courseTestChooseAnwser)?.filter(item => item?.rightFlag)?.map(item => item?.optionName)?.join(' ');
    }
    if (testType === 4) {
      return testItem?.courseTestJudgeAnwser?.rightFlag ? <CheckOutlined style={{ transform: 'translateY(-4px)' }} /> : <CloseOutlined style={{ transform: 'translateY(-4px)' }} />;
    }
    if (testType === 3 || testType === 5) {
      let str = '';
      (testItem.courseTestBlankEasyAnwser || []).map((item, index) => {
        if (index === testItem?.courseTestBlankEasyAnwser?.length - 1) {
          str += item?.answerText;
        } else {
          str += item?.answerText + '、';
        }
      });
      return renderHTML(divideMathText(str));
    }
  }

  renderTestQuestions = (testList) => {
    let { currentTestId } = this.state;
    
    return testList?.map((item, index) => (<div key={index} className={`${styles.testItem} ${currentTestId === item?.id ? styles.active : ''}`} onClick={() => {
      this.setState({
        currentTestId: item?.id
      });
    }}>
      <div className={styles.stem}>
        <div className={styles.stemL}>
          {index + 1 + '.'}
        </div>
        <div className={styles.stemR}>
          {renderHTML(divideMathText(item?.stem))}
        </div>
      </div>
      {
        item?.courseTestChooseAnwser &&
        <>
          {
            (item?.courseTestChooseAnwser || []).sort(function (a, b) {
              return (a.optionName + "").localeCompare(
                b.optionName + ""
              );
            }).map((itm, index) => (
              <div className={styles.answer_vo}
                key={index}>
                <span className={`${styles.option_letter}`} >
                  {itm.optionName + '.'}
                </span>
                <div className={styles.answer_content}>
                  {renderHTML(this.optionRender(itm.optionValue))}
                </div>
              </div>
            ))}
        </>
      }
      {
        item?.testType === 4 && <>
          <div className={styles.answer_vo}
            key={index}>
            <span className={`${styles.option_letter}`} >
              A.
            </span>
            <div className={styles.answer_content}>正确</div>
          </div>
          <div className={styles.answer_vo}
            key={index}>
            <span className={`${styles.option_letter}`} >
              B.
            </span>
            <div className={styles.answer_content}>错误</div>
          </div>
        </>
      }
      <div className={styles.showAnalysisBtnContainer}>
        <div className={styles.showAnalysisBtn} onClick={() => {
          this.changeIsShowAnswer(item);
        }}>
          {/* <img className={styles.showAnalysisBtnImg} src={imgUrlForZhongZhi("showAnalysisBtnImg.png")} alt="" /> */}
          <div className={styles.showAnalysisBtnContent}>
            <span>解析</span>
            {
              item?.isShowAnswer ?
                <img src={imgUrlForZhongZhi("questionsup.png")} alt="" />
                :
                <img src={imgUrlForZhongZhi("questionsdown.png")} alt="" />
            }
          </div>
        </div>
      </div>
      <div className={styles.analysisContainer} style={{ display: item?.isShowAnswer ? 'block' : 'none' }}>
        <div className={styles.analysisContainerT}>
          <span>【答案】
            {this.renderAnswersText(item, item?.testType)}
          </span>
        </div>
        <div className={styles.analysisContainerB}>
          <span className={styles.analysisLeft}>【解析】</span>
          <div className={styles.analysisRight}>
            {renderHTML(divideMathText(item?.resolution || "暂无解析"))}
          </div>
        </div>
      </div>
    </div>));
    // }
  }

  render() {
    let { activeFlagList, tempAry, examName } = this.state;
    let { resTitle } = this.props;
    return (
      <div className={styles.wrapper}>
        {/* <div className={styles.exitContainer} onClick={() => {
          this.props.onClose();
        }}>
          <img className={styles.exitImg} src={imgUrlForZhongZhi("exitImgPreview.png")} alt="" />
          <span>退出预览</span>
        </div> */}
        <div className={styles.container}>
          {/* <div className={styles.TaskPointContainer}>
            <img className={styles.TaskPointImg} src={imgUrlForZhongZhi("TaskPointImg.png")} alt="" />
            <span>任务点</span>
          </div> */}
          <div className={styles.center}>
        
            {
              activeFlagList.length > 0 && <div className={styles.questionsContainer}>
               <p className={styles.title}>{resTitle}</p>
                {
                  this.renderTestQuestions(activeFlagList)
                }
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateToken(token) {
    dispatch(updateToken(token));
  },
  updateUser(user) {
    dispatch(updateUser(user));
  },
  clearToken() {
    dispatch(clearToken());
  },
});

export default connect(({ user }) => ({ user }), mapAction)(Home);
