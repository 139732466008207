type url = string;
const prefix = '/api/jf/stu/';
const userPrefix = '/api/auth/user/';

export const login: url = userPrefix + 'login'; //用户登录接口1

export const shortMessageLogin: url = userPrefix + 'shortMessageLogin'; //用户验证码登录1

export const getOneCode: url = userPrefix + 'getOneCode'; //用户验证码登录1

export const forgetPassword: url = userPrefix + 'forgetPassword'; //忘记密码验证手机号或者邮箱,发送验证码1

export const verification: url = userPrefix + 'verification'; //验证码验证或(绑定邮箱或绑定者手机号)-修改邮箱或者密码1

export const updatePassword: url = userPrefix + 'updatePassword'; //根据邮箱和手机修改密码1

export const wxLogin: url = '/api/auth/api/v1/weixin/wxLogin'; //微信二维码登录1

export const getAccount: url = userPrefix + 'getAccount'; //个人中心获取账户信息1

export const checkBindTelNum: url = userPrefix + 'checkBindTelNum'; //是否绑定手机号1

// export const addMedia: url = '/api/media/api/v1/media/addMedia'; //个人中心上传头像
export const addMedia = '/api/media/api/v1/media/uploadHeadImg'; //个人中心上传头像1

export const updateUserPortrait = userPrefix + 'setting/updateUserPortrait'; //新增,修改用户头像1

export const checkPassword: url = userPrefix + 'checkPassword'; //校验密码1

export const bindOrNot: url = userPrefix + 'bindOrNot'; //判断邮箱或手机号是否绑定1

export const updatePasswordForUnify: url = userPrefix + 'setting/updatePasswordForUnify'; //使用新旧密码校验的方式修改密码1

export const getReferenceIndexData: url = '/api/jf/stu/getReferenceIndexDataByGradeId'; // 首页1
export const gradeInfo: url = '/api/jf/stu/market/book/gradeInfo'; // 首页获取年级列表
export const getSituationObject: url = '/api/jf/stu/studySituation/getSituationOverview'; //获取学情分析-掌握情况概览-根据默认学段1
export const checkCode: url = userPrefix + 'checkCode';  // 1
export const getStudyStage: url = '/api/jf/stu/studySituation/getStudyStage'; //获取某个学员的在学学段1


export const teacherLogin = '/api/zz-sso/auth/user/teacherLogin';  // 老师登录接口-LHS

export const home = '/api/zz-teacher/index/home';  // 教师端-首页

export const queryTeacherBanner = '/api/zz-sso/school/banner/queryTeacherBanner';  // 教师端banner列表-WZH

export const list = '/api/zz-teacher/user/message/list';  // 用户消息列表-MFZ

export const count = '/api/zz-teacher/user/message/count';  // 用户消息列表-MFZ

export const courseTypeList = '/api/zz-teacher/teacher/courseTypeList';  // 课程中心-课程类型-MFZ

export const subjectList = '/api/zz-teacher/teacher/subjectList';  // 课程中心-学科列表-MFZ

export const queryColleges = '/api/zz-sso/school/opt/queryColleges';  // 查询学校学院列表接口-LHS

export const queryMajorCategories = '/api/zz-sso/school/opt/queryMajorCategories';  // 查询学校专业大类列表接口-LHS

export const queryCollegeMajors = '/api/zz-sso/school/opt/queryCollegeMajors';  // 查询院系下的所有专业列表接口-LHS

export const getCourseListBySchool = '/api/zz-teacher/teacher/course/getCourseListBySchool';  // 教师端-获取学校课程列表

export const getCourseInfo = '/api/zz-teacher/teacher/course/getCourseInfo';  // (教师端-web)查询课程详情数据-ZL

export const addTeaCourse = '/api/zz-teacher/teacher/course/addTeaCourse';  // (教师端-web)加入我的课程-ZL

export const password = '/api/zz-sso/auth/user/retrieve/password';  // 忘记密码, 找回密码

export const getCode = '/api/zz-sso/authUser/getCode';  // 取一个验证码

export const alreadJoin = '/api/zz-teacher/teacher/course/alreadJoin';  // (教师端-web)加入过示范课-ZL

export const queryTeacherInformation = '/api/zz-sso/school/teacher/queryTeacherInformation';  // 教师端个人中心-WZH

export const getCatalogFile = '/api/zz-teacher/teacher/course/catalog/getCatalogFile';  // (教师端)查询当前章节下的文件信息-ZL

export const getRecyleCatalog = '/api/zz-teacher/teacher/course/catalog/getRecyleCatalog';  // (教师端-web)查看回收站中的章节信息-ZL

export const recoverCatalog = '/api/zz-teacher/teacher/course/catalog/recoverCatalog';  // (教师端-web)恢复章节信息-ZL

export const delCatalog = '/api/zz-teacher/teacher/course/catalog/delCatalog';  // (教师端-web)删除课程中章节的数据-ZL

export const updateGender = '/api/zz-sso/school/student/updateGender';  // 更新性别-WZH

export const updateStudentPassWord = '/api/zz-sso/school/student/updateStudentPassWord';  // 更新密码-WZH

export const updateAvatar = '/api/zz-sso/school/student/updateAvatar';  // 更新头像-WZH

export const modifyMobileByPassWord = '/api/zz-sso/authUser/web/modifyMobileByPassWord';  // 修改手机号

export const getCatalogSetting = '/api/zz-teacher/teacher/course/catalog/getCatalogSetting';  // (教师端-web)查看课程课时权限设置数据-ZL

// export const permSetting = '/api/zz-teacher/teacher/course/permSetting';  // (教师端-web)课程权限设置-ZL

export const settingPermission = '/api/zz-teacher/teacher/course/catalog/settingPermission';  // (教师端-web)课程权限设置-ZL

export const removeWxMobile = '/api/zz-sso/authUserWx/removeWxMobile';  // 解绑微信手机号

export const querySchoolByRealmName = '/api/zz-sso/school/opt/querySchoolByRealmName';  // 查询学校通过域名接口-LHS

export const logout = '/api/zz-sso/auth/user/logout';  // 用户登出

export const authorize = '/api/zz-sso/authUserWx/authorize';  // 微信web端扫码授权登录回调

export const bindMobileAndLogin = '/api/zz-sso/authUserWx/bindMobileAndLogin';  // web端绑定本地已有的手机账号

export const queryUserInfo = '/api/zz-sso/auth/user/queryUserInfo';  // 获取用户信息接口-LHS

export const teacherVirtualLogin = '/api/zz-sso/auth/user/teacherVirtualLogin';  // 后台跳教师端模拟登录-ZCR


export const getHomeBanner = '/api/admin/ad/editCarousel';  // 轮播图 GET// 新闻
export const getNewsList = '/api/admin/news/getNewsList';  // 数字资源列表 GET

export const querySchoolNotice = '/api/zz-sso/teacher/teacherNotice/querySchoolNotice';  // 公告列表

export const getCourseListByTeacher = '/api/zz-teacher/teacher/course/getCourseListByTeacher';  // 教师我的课程

export const getShowCourseListByTeacher = '/api/zz-teacher/teacher/course/getShowCourseListByTeacher';  // 教师示范课程

export const applyCourseList = '/api/zz-teacher/teacher/course/applyCourseList';  // 我的申请记录

export const applyExampleCourse = '/api/zz-teacher/teacher/course/applyExampleCourse';  // 申请示范课

export const teamList = '/api/zz-teacher/teacher/teamList';  // 教研团队管理-列表

export const teamAddList = '/api/zz-teacher/teacher/teamAddList';  // 教研团队管理-新增教师列表

export const teamAdd = '/api/zz-teacher/teacher/teamAdd';  // 教研团队管理-新增教师

export const teamDel = '/api/zz-teacher/teacher/teamDel';  // 教研团队管理-移除教师

export const teamEditEdit = '/api/zz-teacher/teacher/teamEditEdit';  // 教研团队管理-授权编辑

export const teamEditList = '/api/zz-teacher/teacher/teamEditList';  // 教研团队管理-授权列表

export const teamEditPage = '/api/zz-teacher/teacher/teamEditPage';  // 教研团队管理-授权教师列表-

export const teamPermission = '/api/zz-teacher/teacher/teamPermission';  // 教研团队管理-权限设置

export const teamPermissionData = '/api/zz-teacher/teacher/teamPermissionData';  // 教研团队管理-权限查看

// export const queryColleges = '/api/zz-sso/school/opt/queryColleges';  // 查询学校学院列表接口

export const queryPageListClassTeaching = '/api/zz-sso/classTeaching/web/queryPageListClassTeaching';  //学校或平台查询教学班
export const isFinishCourse = '/api/zz-teacher/teacher/course/isFinishCourse';  //(学校)查询该课程下的班级是否已经结课

export const dissolveClass = '/api/zz-sso/classTeaching/dissolveClass';  // 解散班级

export const saveClassTeaching = '/api/zz-sso/classTeaching/saveClassTeaching';  // 创建教学班级

export const updateClassName = '/api/zz-sso/classTeaching/updateClassName';  // 修改班级名字

export const queryPageMemberList = '/api/zz-sso/classUser/web/queryPageMemberList';  // 查询班级内学生成员

export const saveOrUpdateClassUser = '/api/zz-sso/classUser/web/saveOrUpdateClassUser';  // 添加多个学生到指定班级下

export const queryPageListStudent = '/api/zz-sso/school/student/web/queryPageListStudent';  // 根据学校专业班级查学生

export const query2CollegeMajors = '/api/zz-sso/school/opt/query2CollegeMajors';  // 查询学校专业列表(院系-专业级联)接口

export const queryListClassAd = '/api/zz-sso/classAd/queryListClassAd';  // 查询行政班级信息列表

export const delCourse = '/api/zz-teacher/teacher/course/delCourse';  // (教师端-web)删除课程-ZL

export const endCourse = '/api/zz-teacher/teacher/course/endCourse';  // (教师端-web)教师结课-ZL

export const copyCourse = '/api/zz-teacher/teacher/course/copyCourse';  // (教师端-web)复制课程-ZL

export const saveCourse = '/api/zz-teacher/teacher/course/saveCourse';  // (教师端-web)教师保存课程信息-ZL

export const uploadFile = '/api/zz-sso/opt/uploadFile';  // 单文件上传

export const courseCover = '/api/zz-teacher/teacher/file/courseCover';  // 查询课程默认封面

export const studyChart = '/api/zz-sso/classTeaching/studyChart';  // 学情趋势-LHS

export const conditionSchoolNotice = '/api/zz-sso/teacher/teacherNotice/conditionSchoolNotice';  // 条件查询通知列表-WZH

export const teacherNoticeDetails = '/api/zz-sso/teacher/teacherNotice/teacherNoticeDetails';  // 通知详情-WZH

export const getCatalog = '/api/zz-teacher/teacher/course/catalog/getCatalog';  // (教师端-web)保存章节下的文件-ZL

export const getNotReadApplyCount = '/api/zz-teacher/teacher/course/getNotReadApplyCount';  // (教师端-web)查询申请列表未读条数ZL

export const courseArchiveList = '/api/zz-teacher/teacher/course/courseArchiveList';  // (教师端-web)结课列表数据ZL

export const saveCatalogFile = '/api/zz-teacher/teacher/course/catalog/saveCatalogFile';  // (教师端-web)保存文件

export const saveCourseCatalog = '/api/zz-teacher/teacher/course/catalog/saveCourseCatalog';  // (教师端-web)教师保存课程章节信息-ZL

export const getResourceByCatalog = '/api/zz-teacher/resource/getResourceByCatalog';  // (教师端-web)查询章节下的学信息-ZL

export const saveExam = '/api/zz-teacher/test/saveExam';  //
export const updateExam = '/api/zz-teacher/test/updateExam';  //

// 保存学案
export const saveCatalogResource = `/api/zz-teacher/resource/saveCatalogResource`;

export const createBackupCourse = `/api/zz-teacher/teacher/course/createBackupCourse`;//教师端-web)示范课点击编辑生成副本数据ZL

export const delCatalogFile = `/api/zz-teacher/teacher/course/catalog/delCatalogFile`;//(教师端-web)删除章节下的教案 课件下的文件-ZL

export const submitApply = `/api/zz-teacher/teacher/course/submitApply`;//(教师端-web)示范课修改之后的提交审核ZL

export const recoverCourse = `/api/zz-teacher/teacher/course/recoverCourse`;//(教师端)恢复课程ZL

// 试题
export const getExamTestList = `/api/zz-student/test/getExamTestList`;//查询试卷中试题（JB）

export const addTest = `/api/zz-teacher/test/addTest`;//试题新增

export const getQuestionBank = `/api/zz-teacher/test/getQuestionBank`;//获取题库

export const saveExamStudent = `/api/zz-student/test/saveExam`;//保存试卷数据

export const getAndSetLastCatalog = `/api/zz-teacher/teacher/course/catalog/getAndSetLastCatalog`;//保存试卷数据

// 下载
export const download = `/api/zz-sso/teacher/teacherNotice/download`;//文件下载
