import React, {Component} from 'react';
import RichTextPreview from '../../../components/RichTextPreview/index';
import {
  getResourceByCatalog,
} from '@/api';
import axios from '../../../axios';
import {getParam} from '@/utils/util';
import './styles.less';
import {withRouter} from 'react-router-dom';

class ContentPreview extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props)
    this.state = {
      stepList: [],//预览内容

    }
  }

  componentDidMount() {
    console.log('开始预览')

    const courseId = getParam()?.courseId
    const catalogId = getParam()?.catalogId

    console.log('预览的目录id', catalogId)
    this.getResourceByCatalog(catalogId, courseId)

  }
  // 富文本内容预览内容
  getResourceByCatalog = (catalogId, courseId) => {
    axios.get(getResourceByCatalog, {
      params: {
        catalogId,
        courseId,
        attach: 1
      }
    }).then(res => {
      if (res.hasOwnProperty('stepList')) {
        this.setState({
          stepList: res.stepList,
        })
      } else {
        console.log(res, "getResourceByCatalog");
        this.setState({
          stepList: [],
        })
      }
    });
  }

  render() {
    const {
      stepList
    } = this.state;

    console.log('stepList', stepList)

    return (
      <div className={'preview-page'}>

        <div className={'preview-page-content'}>

          <RichTextPreview stepList={stepList}/>
        </div>
      </div>
    )
  }
}

const mapAction = (dispatch) => ({});
export default withRouter(ContentPreview);
