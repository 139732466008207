/*
 * @Descripttion:
 * @version:
 * @Author: nliu
 * @Date: 2020-09-10 11:29:37
 * @LastEditors: nliu
 * @LastEditTime: 2021-07-22 18:09:26
 */
import React from 'react';
// import MathJax from 'react-mathjax3';

// export function renderingHTMLMath1(html) {
//   if (html) {
//     html = html
//       .replace(/<formula format="latex" type="inline">/g, '<formula format="latex" type="inline">$')
//       .replace(/<\/formula>/g, '$</formula>');
//   }
//   return (
//     <MathJax.Context
//       input="tex"
//       onLoad={() => console.log("Loaded MathJax script!")}
//       onError={(MathJax, error) => {
//         MathJax.Hub.Queue(
//           MathJax.Hub.Typeset()
//         );
//       }}
//       script="/public_static/thirdParty/MathJax-2.7.2/MathJax.js?config=TeX-MML-AM_CHTML"
//       delay={0}
//       options={{
//         messageStyle: 'none',
//         extensions: ['tex2jax.js', 'handle-floats.js', "mml3.js", "content-mathml.js"],
//         jax: ['input/TeX', 'output/HTML-CSS'],
//         tex2jax: {
//           inlineMath: [
//             ['$', '$'],
//             ['\\(', '\\)'],
//             ['$\\ce', '$'],
//           ],
//           displayMath: [
//             ['$$', '$$'],
//             ['\\[', '\\]'],
//           ],
//           processEscapes: true,
//         },
//         TeX: {
//           extensions: ['AMSmath.js', 'AMSsymbols.js', 'noErrors.js', 'noUndefined.js'],
//         },
//         "HTML-CSS": {
//           styles: {
//             ".MathJax_CHTML": {
//               // color:   "red",
//               border: 0,
//               padding: "1px 3px",
//               "font-style": "normal"
//             }
//           }
//         }

//       }}
//     >
//       <MathJax.Html html={html} />
//     </MathJax.Context>
//   );
// }

export function renderHTML(html) {
  if (html) {
    html = html
      .replace(/<formula format="latex" type="inline">/g, '<formula format="latex" type="inline">$')
      .replace(/<\/formula>/g, '$</formula>');
  }
  if (!html) { return ''; }
  let reg = /\[(\d+)\/(\d+)\]/ig;
  html =  html.replace(reg, v => {
    return v.replace('[', '$').replace(']', '$').replace('/', '\\over');
  });
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
}

export function renderingHTMLMath(html = "") {
  html = html.replace(/⏜/g, "^");
  html = html.replace(/○/g, "〇");
  html = html.replace(/(&radic;|√)/g, "<span class='rightTransForm'>√</span>");
  let reg = /<math/;
  let reg1 = /semantics/;
  let reg2 = /msqrt/;     //根式
  // let reg3 = /mn/;
  let reg4 = /mfrac/;     // 分数
  let reg5 = /msup/;     // 分数
  let reg6 = /[^]/;     // 弧度
  // if (reg.test(html) && !reg2.test(html)&& reg4.test(html)) {
  //   return renderingHTMLMath1(html);
  // }
  if (window.android || window.webkit) {
    html = html.replace(/text-decoration:underline wavy;/g, "text-decoration: underline;text-decoration-style:wavy;");
    html = html.replace(//g, "");
    html = html.replace(/class=\"sup/g, 'class=\"sup ipad ');
    html = html.replace(/class='sup/g, "class='sup ipad ");
    html = html.replace(/class=\"sub/g, 'class=\"sub ipad ');
    html = html.replace(/class='sub/g, "class='sub ipad ");
    html = html.replace(/<sup>/g, "<sup class='ipad'>");
    html = html.replace(/<sub>/g, "<sub class='ipad'>");
  }
  html = html.replace(/font-family:Times New Roman/g, "font-family:Times New Roman; font-style:italic; ");
  if (reg.test(html) && !reg1.test(html) && !reg2.test(html) && !reg4.test(html) && !reg5.test(html) && !reg6.test(html)) {
    // if (reg.test(html) && !reg1.test(html) && !reg2.test(html)) {
    return renderHTML(html);
  } else {
    // return renderingHTMLMath1(html);
  }
}
