type  ProjectName = 'yq' | 'djt';
const projectName = process.env.REACT_APP_PROJECT_NAME as ProjectName;
type XDocUrls = {
  xDocUrl: string
  xDocUrlHttps: string
}

function getXDocUrls(): XDocUrls {
  switch (projectName) {
    case "yq":
      return {
        xDocUrl: "https://ow.zjyjc.com/?readpage=10000&nopagebtn=1&addpageborder=1&bottompage=1&bclr=fff&furl=",
        xDocUrlHttps: "https://ow.zjyjc.com/?readpage=10000&ssl=1&nopagebtn=1&addpageborder=1&bottompage=1&bclr=fff&furl=",
      };
    case "djt":
      return {
        xDocUrl: "https://ow365.cn/?i=28942&furl=",
        xDocUrlHttps: "https://ow365.cn/?i=28942&ssl=1&furl="
      };
  }
}

const env = {
  ...getXDocUrls()
};

export default env;
