import React, { Component } from "react";
import styles from './index.module.scss';

class VideoPlay extends Component {
  render() {
    const { src,hasSitu } = this.props;
    console.log(hasSitu,"hasSitu")
    return (<div className={styles.wrapper}>
      <div className={styles.videoContainer}>
      {
        hasSitu==1?<video
          className={styles.video}
          src={src}
          ref={(audio) => {
            this.audioDom = audio;
          }}
          autoPlay
        ></video>:<video
          className={styles.video}
          src={src}
          ref={(audio) => {
            this.audioDom = audio;
          }}
          preload={"auto"}
          controlsList="nodownload"
          controls
        ></video>
      }
        
      </div>
    </div>);
  }
}

export default VideoPlay;
