import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { imgUrlForZhongZhi } from '../../utils/index';
import UploadModal from '@/components/uploadModal/index'
import styles from './index.module.scss';

const propTypes = {
  id: PropTypes.string.isRequired,
  styles: PropTypes.object | null
};

const defaultProps = {};
// let ue;
/**
 *
 */
class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      isShowUpladImg: false,
      isShowUpladAudio: false,
    };
  }
  onChange = (editor) => {
    let html = this.ue.getContent();
    this.props.onChange && this.props.onChange(html)
  }
  componentDidMount() {
    this.ue = window.UE.getEditor(this.props.id, {
      toolbars: [["kityformula", "spechars", "simpleupload", "insertimage"]],
      initialContent: '',//初始化编辑器的内容
      initialFrameHeight: 150,
      /* 上传图片配置项 */
      imageActionName: 'http://localhost:8000/', /* 执行上传图片的action名称 */
      imageFieldName: "file", /* 提交的图片表单名称 */
      imageMaxSiz: 1000000, /* 上传大小限制，单位B */
      imageAllowFiles: [".png", ".jpg", ".jpeg", ".gif", ".bmp"], /* 上传图片格式显示 */
      imageCompressEnable: true, /* 是否压缩图片,默认是true */
      imageCompressBorder: 1600, /* 图片压缩最长边限制 */
      imageInsertAlign: "none", /* 插入的图片浮动方式 */
      imageUrlPrefix: "", /* 图片访问路径前缀 */
      imagePathFormat: "/", /* 上传保存路径,可以自定义保存路径和文件名格式 */
    });
    this.ue.addListener('contentChange', this.onChange);
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.value, this.state.value, '55555555555555555', this.ue)
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      }, () => {
        setTimeout(() => {
          try {
            this.ue.setContent(nextProps.value || '');
            // ue.removeListener('contentChange', this.onChange);
            // this.timer = setTimeout(() => {
            //   clearTimeout(this.timer);
            // this.ue.addListener('contentChange', this.onChange);
            // }, 1000);
          } catch (e) {

          }
        }, 100);
      });
    }
  }
  // componentWillUnmount() {

  //   this.ue.ready(function(){

  //      this.ue.destroy()
  //    })
  // }
  uploadResult = (resulte) => {
    this.setState({
      isShowUpladImg: false
    }, () => {
      let { editorType = 1 } = this.props;
      this.ue.execCommand('insertimage', {
        src: resulte?.cdnUrl,
        width: editorType == 2 ? '472' : 'auto',
        height: editorType == 2 ? 'auto' : '100'
      });
    });
  }

  uploadResultAudio = (resulte) => {
    this.setState({
      isShowUpladAudio: false
    }, () => {
      const uploadType = 'audio';
      const fileId = resulte?.fileId;
      const mode ='edit';
      const nextId = fileId
      const obj = {
        fileUrl:resulte?.cdnUrl,
        id:'audio_'+fileId
      }
      const url = '/ueditor/modules/insertEasyaudio.html';
      var a = '<iframe data-split="1" type="' + uploadType + '" data-fileId="' + fileId + '" cdata_tag="p" id="audio_' + fileId + '" scrolling="no"  class="docframe" type="' + uploadType + '" mode="' + mode + '" src="' + url + '" nextId="' + nextId + '" data="' + encodeURIComponent(JSON.stringify(obj)) + '" frameborder="0" width="300" height="54"></iframe>'
      // this.ue.execCommand('inserthtml', `<audio name=${resulte?.fileInfo?.name} controls="controls" src="${resulte?.cdnUrl}"/`);
      this.ue.execCommand('inserthtml',a)
    });
  }

  closeModal = () => {
    this.setState({
      isShowUpladImg: false,
      isShowUpladAudio: false,
    });
  }

  render() {
    let { id, style, editorType = 1 } = this.props;
    let { isShowUpladImg, isShowUpladAudio } = this.state;
    return <>
      <div style={{ background: '#F7F8FA', border: '1px solid #E6E8EA' }}>
        {
          editorType == '2' && <Fragment>
            <div className={styles.item} onClick={() => {
              this.ue.ready(() => {
                this.ue.execCommand('undo')
              })
            }}>
              <img className={styles.icon} src={imgUrlForZhongZhi("Icon_undo_active@2x.png")} alt="" />
            </div>
            <div className={styles.item} onClick={() => {
              this.ue.ready(() => {
                this.ue.execCommand('redo')
              })
            }}>
              <img className={styles.icon} src={imgUrlForZhongZhi("Icon_redo_active@2x.png")} alt="" />
            </div>
            <div className={styles.item}>
              <img className={styles.icon}
                src={imgUrlForZhongZhi('Icon_freeimages@2x.png')}
                onClick={() => {
                  this.setState({
                    isShowUpladImg: true
                  });
                }}
                alt="" />
            </div>
          </Fragment>
        }
        {
          editorType == '1' && <Fragment>
            <img
              style={{ height: '0.2rem', marginLeft: '0.2rem', cursor: 'pointer' }}
              src={imgUrlForZhongZhi('Icon_kityformula@2x.png')}
              onClick={() => {
                this.ue.ready(() => {
                  this.ue.execCommand('callkityformula');
                });
              }}
              alt="" />
            <img
              style={{ height: '0.2rem', marginLeft: '0.2rem', cursor: 'pointer' }}
              src={imgUrlForZhongZhi('Icon_spechars@2x.png')}
              onClick={() => {
                // ue.execCommand('music', {
                //   url: 'https://cdn.zjyjc.com/%E8%B5%84%E6%BA%90/%E6%95%99%E8%BE%85/%E7%99%BE%E5%B7%9D%E8%B5%84%E6%BA%90/%E9%AB%98%E4%B8%AD%E8%B5%84%E6%BA%90/%E8%8B%B1%E8%AF%AD/%E5%BF%85%E4%BF%AE%E7%AC%AC%E4%B8%80%E5%86%8C/%E9%9F%B3%E9%A2%91/4%40%40%40fdb6de81bffc4ed2b537c9b9b17a4f18.mp3',
                //   width: 400,
                //   height: 95
                // });
                this.ue.ready(() => {
                  this.ue.execCommand('insertspechars');
                });

                // ue.execCommand('inserthtml', '<audio   controls="controls" src="https://cdn.zjyjc.com/%E8%B5%84%E6%BA%90/%E6%95%99%E8%BE%85/%E7%99%BE%E5%B7%9D%E8%B5%84%E6%BA%90/%E9%AB%98%E4%B8%AD%E8%B5%84%E6%BA%90/%E8%8B%B1%E8%AF%AD/%E5%BF%85%E4%BF%AE%E7%AC%AC%E4%B8%80%E5%86%8C/%E9%9F%B3%E9%A2%91/4%40%40%40fdb6de81bffc4ed2b537c9b9b17a4f18.mp3"/>');
              }}
              alt="" />
            <img
              style={{ height: '0.2rem', marginLeft: '0.2rem', cursor: 'pointer' }}
              src={imgUrlForZhongZhi('Icon_freeimages@2x.png')}
              onClick={() => {
                this.setState({
                  isShowUpladImg: true
                });
              }}
              alt="" />
            <img
              style={{ height: '0.2rem', marginLeft: '0.2rem', cursor: 'pointer' }}
              src={imgUrlForZhongZhi('Icon_audio@2x.png')}
              onClick={() => {
                this.setState({
                  isShowUpladAudio: true
                });
              }}
              alt="" />

          </Fragment>
        }



      </div>
      <textarea id={id} style={{ ...style }} value={this.state.value}></textarea>
      {
        isShowUpladImg && <Modal
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px' }}>
              <b style={{ color: "#17181A" }}>{`插入图片`}</b>
              <CloseOutlined style={{ lineHeight: '24px', color: '#69707F', cursor: 'poninter' }}
                onClick={this.closeModal} />
            </div>
          }
          visible maskClosable={false} closable={false} width={556} onCancel={() => {
            this.setState({
              isShowUpladImg: false
            });
          }}
          onOk={() => { }}
          bodyStyle={{ padding: '20px' }}
          footer={null}
        >
          <UploadModal ref={this.formRef} uploadTypeInfo={{
            "tips": ["支持上传格式：png；jpg；jpep；bmp；gif；psd；pcx；cdr；dxf", "图片大小规格要求：10M以内(含10M)"],
            "zh": "图片",
            "prevfixList": ['.png', '.jpg', '.jpep', '.bmp', '.gif', '.psd', '.pcx', '.cdr', '.dxf'],
            "size": 10
            // image/png image/jpeg image/bmp image/gif 都是空的
          }} uploadType={'picture'} uploadResult={this.uploadResult} closeModal={this.closeModal} />
        </Modal>
      }
      {
        isShowUpladAudio && <Modal
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px' }}>
              <b style={{ color: "#17181A" }}>{`插入音频`}</b>
              <CloseOutlined style={{ lineHeight: '24px', color: '#69707F', cursor: 'poninter' }}
                onClick={this.closeModal} />
            </div>
          }
          visible maskClosable={false} closable={false} width={556} onCancel={() => {
            this.setState({
              isShowUpladAudio: false
            });
          }}
          onOk={() => { }}
          bodyStyle={{ padding: '20px' }}
          footer={null}
        >
          <UploadModal ref={this.formRef} uploadTypeInfo={{
            "tips": ["音频格式规格要求：MP3、wav", "音频大小规格要求：50M以内(含50M）"],
            "zh": "音频",
            "prevfixList": ['.mp3', '.wav'],
            "size": 50
            // audio/mpeg
          }} uploadType={'audio'} uploadResult={this.uploadResultAudio} closeModal={this.closeModal} />
        </Modal>
      }
    </>;
  }
}

index.propTypes = propTypes;
index.defaultProps = defaultProps;
// #endregion

export default index;
