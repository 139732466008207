import React, { Component } from "react";
// import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { Slider } from 'antd';
import {imgUrlForZhongZhi } from '@/utils';
import './AudioPlay.scss';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rateList: [1.0, 1.25, 1.5, 2.0],
      playRate: 1.0,
      isPlay: false,
      isMuted: false,
      volume: 100,
      allTime: 0,
      currentTime: 0,
      disabled: false,
    };
  }

  // getDuration() {
  //   const { id, index } = this.props;
  //   let ele = document.getElementById(`audio${id}_${index}`);
  //   if (!ele) return;
  //   ele.load();
  //   ele.onloadedmetadata = ()=> {
  //     this.setState({
  //       allTime: Number(ele.duration) || 0,
  //     });
  //   };
  // }
  // getDuration1() {
  //   const { id, index } = this.props;
  //   let ele = document.getElementById(`audio${id}_${index}`);
  //   if (!ele) return;
  //   ele.play();
  //   ele.pause();
  //   this.onCanPlay();
  // }
  getDuration1() {
    this.timer = window.setInterval(() => {
      let { allTime } = this.state;
      if (allTime > 0) {
        window.clearInterval(this.timer);
      } else {
        this.onCanPlay();
      }
    }, 1000);
  }

  componentDidMount() {
    if (window.android || window.webkit) {
      // setTimeout(() => {
      //   this.onCanPlay();
      // }, 3000);
      // this.timer = window.setInterval(() => {
      // let { allTime } = this.state;
      // if (allTime > 0) {
      //   window.clearInterval(this.timer);
      // } else {
      // this.onCanPlay();
      // }
      // }, 1000);
      if (window.android) {
        // this.getDuration();
        // window.setTimeout(() => {
        //   this.onCanPlay();
        //   window.setTimeout(() => {
        //     this.onCanPlay();
        //   }, 3000);
        // }, 7000);
        this.getDuration1();
      } else if (window.webkit) {
        // setTimeout(() => {
        //   this.onCanPlay();
        // }, 3000);
        this.getDuration1();
      }
    }
  }

  // componentWillUnmount(){
  //   (this.audioDom?.pause || function(){})();
  // }

  formatSecond(time) {
    const second = Math.floor(time % 60);
    let minite = Math.floor(time / 60);
    return `${minite}:${second >= 10 ? second : `0${second}`}`;
  }

  // 该视频已准备好开始播放
  onCanPlay = () => {
    // const { id } = this.props;
    // const audio = document.getElementById(`audio${id}`);
    const audio = this.audioDom || {};
    this.setState({
      allTime: Number(audio.duration) || 0,
    });
  };

  playAudio = () => {
    // const { id } = this.props;
    // const audio = document.getElementById(`audio${id}`);
    const audio = this.audioDom;
    let audios = document.getElementsByTagName('audio');
    [...audios].forEach(item => {
      item.isPlay = false;
      item.pause();
    });
    audio.isPlay = true;
    audio.play();
    this.setState({
      isPlay: true,
    });
  };

  pauseAudio = () => {
    // const { id } = this.props;
    // const audio = document.getElementById(`audio${id}`);
    const audio = this.audioDom;
    audio.pause();
    this.setState({
      isPlay: false,
    });
  };

  onMuteAudio = () => {
    // const { id } = this.props;
    // const audio = document.getElementById(`audio${id}`);
    const audio = this.audioDom;
    this.setState({
      isMuted: !audio.muted,
    });
    audio.muted = !audio.muted;
  };

  changeTime = (e) => {
    const value = e;
    // const { id } = this.props;
    // const audio = document.getElementById(`audio${id}`);
    const audio = this.audioDom;
    this.setState({
      currentTime: value,
    });
    audio.currentTime = value;
    if (value === audio.duration) {
      this.setState({
        isPlay: false,
      });
    }
  };

  // 当前播放位置改变时执行
  onTimeUpdate = () => {
    // const { id } = this.props;
    // const audio = document.getElementById(`audio${id}`);
    const audio = this.audioDom;

    this.setState({
      currentTime: audio.currentTime,
    });
    if (audio.currentTime === audio.duration) {
      this.setState({
        isPlay: false,
      });
    }
  };

  changeVolume = (e) => {
    const { value } = e.target;
    // const { id } = this.props;
    // const audio = document.getElementById(`audio${id}`);
    const audio = this.audioDom;
    audio.volume = value / 100;

    this.setState({
      volume: value,
      isMuted: !value,
    });
  };

  // 倍速播放
  changePlayRate = (num) => {
    this.audioDom.playbackRate = num;
    this.setState({
      playRate: num,
    });
  };

  render() {
    // const { src, id, index, title } = this.props;
    const { src, id, index,resTitle } = this.props;

    const {
      isPlay,
      // isMuted,
      // volume,
      allTime,
      currentTime,
      // rateList,
      // playRate,
      disabled,
    } = this.state;

    return (
      <div>
        {/* <p>{title}</p> */}
        < div className="audio-wrapper" >

          <audio
            id={`audio${id}_${index}`}
            src={src}
            ref={(audio) => {
              this.audioDom = audio;
            }}
            preload={'auto'}
            onCanPlay={this.onCanPlay}
            onTimeUpdate={this.onTimeUpdate}
          >
            <track src={src} kind="captions" />
          </audio>
          <div className="playbtn"> {isPlay && this.audioDom.isPlay ?
            (<div onClick={this.pauseAudio}><img src={imgUrlForZhongZhi('play-start-l.png')} /></div>) :
            (<div onClick={this.playAudio}><img src={imgUrlForZhongZhi("play-end-icon.png")} /></div>)}
          </div>
          <div className="playright">
            <div className="playright_top">
            <div>{resTitle}</div>
            <div className="playtimer">
              <span style={{ color: '#686F7E', fontSize: '0.18rem' }}>{this.formatSecond(currentTime)}</span>
              <span style={{ color: '#686F7E', fontSize: '0.18rem' }}> / {this.formatSecond(allTime)}</span>
            </div>
          </div>

          <div className="playprocess">
            <Slider
              tipFormatter={null}
              // style={{ width: 750 }}
              disabled={disabled}
              value={currentTime}
              step="0.01"
              max={allTime || 0}
              onChange={this.changeTime}
            />
            {/* <input style={{ width: 750 }}
                        type="range"
                        step="0.01"
                        max={allTime}
                        value={currentTime}
                        onChange={this.changeTime}
                        id="hk_inputRange"
                    /> */}
          </div>
          </div>
          

        </div >
      </div>
    );
  }
}

export default App;
