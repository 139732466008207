import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateCrumbsData } from '@/store/action';
import CourseDetailsComponent from '@@/CourseDetailsComponent';
import { imgUrlForZhongZhi } from '@/utils';
import { getParam } from '@/utils/util';
import {
  getCourseInfo,
} from '@/api';
import axios from '../../axios';
import styles from './index.module.scss';

class CourseDetails extends Component {
  constructor() {
    super();
    this.state = {
      courseVO: {},
      catalogList: [],
      teamTeachers: [],
    };
  }

  componentDidMount() {

    this.getCourseInfo();
  }

  setTitle = (courseVO) => {
    let { isEdit, isDemonstrationCourse } = getParam();
    if (isEdit) {
      this.props.updateCrumbsData([
        {
          name: '课程',
          path: '/courseCenter'
        },
        {
          name: '课程管理',
          path: '/courseCenter'
        },
        {
          name: '我的课程',
          path: '/courseCenter'
        },
        {
          name: isDemonstrationCourse ? '我的示范课' : courseVO?.courseName,
          path: ''
        },
      ]);
    } else {
      this.props.updateCrumbsData([
        {
          name: '课程中心',
          path: '/courseCenter'
        },
        {
          name: courseVO?.courseType === '1' ? '公共课' : '专业课',
          path: 'courseCenter/type='+courseVO?.courseType
        },
        {
          name: courseVO?.subjectName,
          path: 'courseCenter/type='+courseVO?.courseType
        },
        {
          name: courseVO?.courseName,
          path: 'courseCenter/type='+courseVO?.courseType
        },
      ]);
    }
  }

  transformData = catalogList => {
    return catalogList?.map(item => {
      let childList = item?.childList;
      if (childList && Array.isArray(childList)) {
        return { ...item, name: item?.catalogName, children: this.transformData(item?.childList) };
      }
      return { ...item, name: item?.catalogName };
    });
  }

  getCourseInfo = () => {
    let { id } = getParam();
    axios.get(getCourseInfo, {
      params: {
        courseId: id,
      },
    }).then(res => {
      if (!res) return;
      let { courseVO, catalogList, teamTeachers } = res;
      this.setState({
        courseVO,
        catalogList: this.transformData(catalogList),
        teamTeachers
      });
      this.setTitle(res.courseVO);
    });
  }

  render() {
    let { isEdit, courseType, isDemonstrationCourse,id } = getParam();
    let { courseVO, catalogList, teamTeachers } = this.state;
    return (
      <div className={styles.wrapper}>
        <CourseDetailsComponent id={id} isEdit={isEdit} isDemonstrationCourse={isDemonstrationCourse} courseType={courseType} courseVO={courseVO} catalogList={catalogList} teamTeachers={teamTeachers} />
      </div>
    );
  }
}

const mapAction = (dispatch) => ({
  updateCrumbsData(res = []) {
    dispatch(updateCrumbsData(res));
  },
});

export default connect(({ crumbsData }) => ({ crumbsData }), mapAction)(CourseDetails);
