import React, {Component} from 'react';
import {getIp, imgUrlForZhongZhi} from '@/utils';
import Audio from '../AudioPlay/AudioPlay';
import {pausedVideoAndAudio} from '@/utils/util';
import PreviewQuestions from '../PreviewQuestions/PreviewQuestions';
import './index.scss';
import env from "@/env";

const preview = getIp();
const {xDocUrl, xDocUrlHttps} = env;
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepList: []
    };
  }

  componentDidMount() {
    const {stepList} = this.props;
    this.setState({
      stepList
    });
    console.log(stepList, "stepList");
    setTimeout(() => {
      pausedVideoAndAudio();
      this.aa();
    }, 1000);
  }

  componentDidUpdate() {
    let that = this;
    // this.aa();
    window.onmessage = function (event) {
      console.log('event222 ===========', event);
      if (event.data.editType === '0') {
        that.aa(event.data.url);
      }
    };
  }

  aa = (url = '') => {
    console.log('url', url);
    var iframes = document.getElementsByTagName("iframe");
    console.log("iframes", Array.isArray(iframes), iframes);
    if (iframes) {
      for (var i = 0; i < iframes?.length; i++) {
        //   iframes[i].contentWindow.document.getElementById('jsAudio').onclick = function(){
        //   alert("1");
        //  };
        //  console.log('iframes',iframes[i])
        //   debugger
        console.log('iframes-----------------', iframes[i].contentWindow.document.getElementById('jsAudio'));
        let selNode = iframes[i].contentWindow.document.getElementById('jsAudio');
        if (selNode) {
          if (selNode.className.match('aa') && selNode.src != url) {
            selNode.pause();
            iframes[i].contentWindow.document.getElementById('input1').hidden = false;
            iframes[i].contentWindow.document.getElementById('input2').hidden = true;
            console.log('input2-----------------', iframes[i].contentWindow.document.getElementById('input2'));
          }
        }
      }

    }
  };

  //格式化文件大小
  renderSize(value) {
    if (null == value || value == '') {
      return "0 Bytes";
    }
    var unitArr = new Array("Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
    var index = 0;
    var srcsize = parseFloat(value);
    index = Math.floor(Math.log(srcsize) / Math.log(1024));
    var size = srcsize / Math.pow(1024, index);
    size = size.toFixed(2);//保留的小数位数
    return size + unitArr[index];
  }

  getResource = (data) => {
    // 资源类型<>0=文本&1=课件&2=图片&3=文档&4=视频&5=音频&6=测试&7=链接&8=附件
    if (data?.resType == 0) {
      return (
        <div dangerouslySetInnerHTML={{__html: data?.resContent}}
        ></div>
      );
    }
    if (data?.resType == 1 || data?.resType == 3) {
      let openUrl = data?.url || '';
      return (
        <div className="iframe_file">
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            src={openUrl.includes('https') ? (xDocUrlHttps + openUrl) : openUrl.includes('http') ? (xDocUrl + openUrl) : (xDocUrl + preview + openUrl)}
            width="100%" frameBorder="0"></iframe>
        </div>
      );
    }
    if (data?.resType == 2) {
      return (
        <div className="png">
          <img id="stopImg" src={data?.url} alt=""/>
        </div>
      );
    }
    if (data?.resType == 4) {
      console.log(data);
      return (
        <div className="mp4">
          <div className={'video-box video' + ((data.hasSitu == 1) ? '-situ' : '-default')}>
            {/* <VideoPage openUrl={data?.url}/> */}
            <video src={data?.url} controls style={{width: '100%', maxHeight: this.state.videoMaxHeight}}
                   controlsList="nodownload"></video>
            {/* <video src="https://v.zjy.partner.taozhi.online/77cfc9d764ca4b0caece980e60fcfb55/112b797bed2c442da90a2d2fffe34f26-7409ffd598e4d20ddd8026cd84370d81-ld.mp4" controls="controls" style={{ width: '100%', maxHeight: this.state.videoMaxHeight }} controlsList="nodownload" ></video> */}
          </div>
        </div>
      );
    }
    if (data?.resType == 5) {
      return (
        <div className="audio_" style={{paddingTop: '30px'}}>
          {/* <div className='audio-title'>{data?.resTitle}</div> */}
          <Audio src={data?.url} key={data?.url} resTitle={data?.resTitle}/>
          {/* {this.fn(data)} */}
        </div>
      );
    }
    if (data?.resType == 6) {
      return (
        <div className="previewQuestions">
          <PreviewQuestions examId={data?.resId} resTitle={data?.resTitle}/>
        </div>
      );
    }
    if (data?.resType == 7) {
      return (
        <div className="h5">
          <a href={data?.url} target="_blank">{data?.resTitle}</a>
          {/* <iframe
                border='0'
                height='100%'
                width='100%'
                frameBorder='0'
                scrolling="yes"
                src={data?.resContent?.includes('http') ? data?.resContent : preview + data?.resContent}
              >
              </iframe> */}
        </div>
      );
    }
    if (data?.resType == 8) {
      return (
        <div className="accessory">
          <div className="accessory_left">
            <img src={imgUrlForZhongZhi('icon_file.png')} alt=""/>
            <span>附件：{data?.fileName}</span>
            <span className="file_size">{this.renderSize(data?.fileSize)}</span>
          </div>
          <div className="accessory_right">
            <a href={data?.url}>
              <img src={imgUrlForZhongZhi('Icondown.png')} alt=""/>
              下载
            </a>
          </div>
        </div>
      );
    }
  };

  render() {
    const {stepList} = this.props;
    return (<div className="richtextpreview">
      {
        stepList && stepList.map((val) => {
          return (
            <div key={val?.id}>
              {val?.stepName && val?.stepName != '' ?
                <div className="titlebac">
                  <div className="titlebac_left"></div>
                  {val?.stepName}</div>
                : ''}
              <div className="resource_wrap">
                {
                  val?.resourceList.map((data, index) => {
                    return (
                      <div key={data?.id} className="resource_evy">

                        {this.getResource(data)}
                        {/* {console.log(data,"=================================")} */}
                      </div>
                    );
                  })
                }
              </div>
            </div>
          );
        })
      }
    </div>);
  }
}
